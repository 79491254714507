import React, { Component, Fragment } from "react";
import { ASSETS } from "../constants/paths";
import { connect } from "react-redux";
import { setFirstCreatedValue } from "../actions";
import AddAdditionalProductComponent from "./common/addAdditionalProductComponent";
import PreviewProductComponent from "./common/PreviewProductComponent";
import RoadShowJoinCampaignComponent from "./common/roadShowJoinCampaignComponent";
import { ROADSHOWLINK } from "../constants/routePaths";
import RecommendedPriceComponent from "./common/recommendedPriceComponent";
import SendEmailComponent from "./common/sendEmailComponent";
import SendEmailModalComponent from "./modal/sendEmailModalComponent";
import SendMailResultModalComponent from "./modal/sendMailResultModalComponent";

class CongratsComponent extends Component {
   state = {}
   changeFormStep = (step) => {
      this.setState({
         formStep:step
      })
      if(!step){
         this.resetValues()
         document.getElementById('vra-choice-root').style.zIndex = null
      }
   }

   resetValues=()=>{
      this.formValues={}
   }
   componentDidMount() {
      this.props.dispatch(setFirstCreatedValue(true))
   }

   openForm = () => {
      this.setState({
         formStep:1
      },()=>{
         if(this.state.formStep){
            document.getElementById('vra-choice-root').style.zIndex ='11'
         }
         
      })
   }

   setDetailValues=(detailValues)=>{
      this.detailValues=detailValues
   }

   setFormValues=(values)=>{
      this.formValues={
         email:values.email,
         etk_allowed:values.etk_allowed,
         kvkk_allowed:values.kvkk_allowed,
         bahtroom_sale_code:this.props.orderSummary.bathroomCabinet.sale_code,
         bahtroom_handle_code:this.props.orderSummary.handleData.sale_code,
         bathroom_leg_code:this.props.orderSummary.stand ? this.props.orderSummary.stand.sale_code : null,
      }
   }

   render() {
      let { language, productStep = { productStep } } = this.props
      return (
         <Fragment>
            {
               this.state.formStep === 1 &&
               <SendEmailModalComponent setFormValues={this.setFormValues} changeFormStep={this.changeFormStep} />
            }
            {
               this.state.formStep === 2 &&
               <SendMailResultModalComponent detailValues={this.detailValues} changeFormStep={this.changeFormStep} />
            }
            <div className={`vra-congrats-container ${window.location.pathname.indexOf(ROADSHOWLINK) !== -1 || (window.location.origin === "https://rootconfigurator.vitraglobal.com" || window.location.origin === "http://localhost:3000" || window.location.origin === "http://rootapp.gulenayva.com") && (this.props.activeLang === "en") ? "roadshow-congrats" : ""}`}>

               <div className="vra-congrats-block">
                  <div className="vra-congrats-item">
                     <div className="vra-img-item vra-bg-item">
                        <picture>
                           <source
                              srcSet={`${ASSETS}/img/home/congrats.png 1x, ${ASSETS}/img/home/congrats-2x.png 2x`}
                              media="(min-width: 1401px)"
                              type="image/png" />
                           <source
                              srcSet={`${ASSETS}/img/home/congrats.webp 1x, ${ASSETS}/img/home/congrats-2x.webp 2x`}
                              media="(min-width: 1401px)"
                              type="image/webp" />
                           <source
                              srcSet={`${ASSETS}/img/home/congrats-mobile.png 1x, ${ASSETS}/img/home/congrats-mobile-2x.png 2x`}
                              media="(max-width: 1400px)"
                              type="image/png" />
                           <source
                              srcSet={`${ASSETS}/img/home/congrats-mobile.webp 1x, ${ASSETS}/img/home/congrats-mobile-2x.webp 2x`}
                              media="(max-width: 1400px)"
                              type="image/webp" />
                           <img src={`${ASSETS}/img/home/congrats.png`} alt="congrats" />
                        </picture>
                     </div>
                     <div className="vra-text-block">
                        <p>{language.congratulations}</p>
                        <span>{language.completed}</span>
                     </div>
                  </div>
                  {/*<AddAdditionalProductComponent/>*/}

                  <PreviewProductComponent isCongrats={true} />
                  {
                     this.props.activeLang === "tr" && <RecommendedPriceComponent productStep={productStep} />
                  }

                  {
                     window.location.pathname.indexOf(ROADSHOWLINK) !== -1 &&
                     <RoadShowJoinCampaignComponent isCongrats={true} />
                  }
                  {
                     ((window.location.origin === "https://rootconfigurator.vitraglobal.com" || window.location.origin === "http://localhost:3000" || window.location.origin === "http://rootapp.gulenayva.com") && (this.props.activeLang === "en")) &&
                     <SendEmailComponent isCongrats={true} openForm={this.openForm}/>


                  }
                  <AddAdditionalProductComponent />

               </div>

            </div>
         </Fragment>
      )
   }
}

const mapStateToProps = (state) => ({
   language: state.languageReducer[state.commonReducer.activeLang],
   activeLang: state.commonReducer.activeLang,
   orderSummary: state.orderSummaryReducer,
   userSelection: state.userSelectionReducer
})
export default connect(mapStateToProps)(CongratsComponent);

