import {
   ASSIGN_PRODUCT_STEP_NUMBER,
   PRODUCT_STEP_NUMBER,
   CHANGE_COLOR_SELECTION,
   CHANGE_BATHROOM_SELECTION,
   CHANGE_COVER_SELECTION,
   CHANGE_ARMATURE_SELECTION,
   CHANGE_TALL_CABINETS_SELECTION,
   CHANGE_OTHER_MODULE_SELECTION,
   RESET_ALL_USER_SELECTION, CHANGE_MIRROR_SELECTION, CHANGE_DOOR_SELECTION
} from "../constants/actionTypes";

const initialState = { productStepNumber: 1, assignmentStepNumber: 1, respColorSelection: "color_group",respBathroomSelection:"bath_title",
   respCoverSelection:"cover_group",respArmatureSelection:"color_group",respTallCabinetSelection:"cabinet_group",respOtherSelection:"module_group",resMirrorSelection:null,resDoorSelection:null};
const productStepReducer = (state = initialState, action) => {
   switch (action.type) {
      case PRODUCT_STEP_NUMBER:
         return {
            ...state,
            productStepNumber: state.productStepNumber + action.payload,
            // respColorSelection: state.productStepNumber + action.payload>2?"color":state.respColorSelection
         }
      case ASSIGN_PRODUCT_STEP_NUMBER:
         let mobilStep = {};
         if(action.payload===2){
            mobilStep.respColorSelection = "color_group"
         }
         if(action.payload===4){
            mobilStep.respBathroomSelection = "bath_title"
         }
         if(action.payload===5){
            mobilStep.respCoverSelection = "cover_group"
         }
         return {
            ...state,
            productStepNumber: action.payload,
            ...mobilStep
         }
      case CHANGE_COLOR_SELECTION:
         return {
            ...state,
            respColorSelection: action.payload
         }
      case CHANGE_BATHROOM_SELECTION:
         return{
            ...state,
            respBathroomSelection:action.payload
         }
      case CHANGE_COVER_SELECTION:
         return{
            ...state,
            respCoverSelection:action.payload
         }
      case CHANGE_ARMATURE_SELECTION:
         return{
            ...state,
            respArmatureSelection:action.payload
         }
      case CHANGE_TALL_CABINETS_SELECTION:
         return {
            ...state,
            respTallCabinetSelection:action.payload
         }
      case CHANGE_OTHER_MODULE_SELECTION:
         return {
            ...state,
            respOtherSelection:action.payload
         }
      case CHANGE_MIRROR_SELECTION:
         return {
            ...state,
            resMirrorSelection:action.payload
         }
         case RESET_ALL_USER_SELECTION:
         return {
            ...state,
            ...initialState
         }
      case CHANGE_DOOR_SELECTION:
         return{
            ...state,
            resDoorSelection:action.payload
         }
      default:
         return state;
   }
}
export default productStepReducer;
