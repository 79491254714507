import React, { Component, Fragment } from 'react';
import { setMirrorType, setOtherModules, setMirrorSize, setMirrorBacklight,changeMirrorSelectionAction} from "../actions";
import { connect } from "react-redux";
import RespMirrorSizeSelectionComponent from "../components/mobile/respMirrorSizeSelectionComponent"
import ResLightComponent from "../components/mobile/resLightComponent";
import RespMirrorCoverDirection from '../components/mobile/respMirrorCoverDirection';

class ResMirrorContainer extends Component {
   constructor(props){
      super(props)
      if(props.setMirrorType){
         if(props.setMirrorType.mirror_style_sizes.length>0){
            this.props.dispatch(changeMirrorSelectionAction('mirror_group'))
         }else {
            this.props.dispatch(changeMirrorSelectionAction('light'))
         }
      }

   }
   state = {
      index: 0
   }
   handleOnclick = (item, index) => {
      this.props.dispatch(setMirrorType(item))

      this.setState({
         index
      })
      this.props.dispatch(setMirrorSize(item.mirror_style_sizes[0] ? item.mirror_style_sizes[0] : null))
      this.props.dispatch(setMirrorBacklight(item.with_backlight ? true : null))
   }

   render() {
      let { resMirrorSelection,setMirrorType} = this.props;
      return (
         <Fragment>
            {/* {
                resMirrorSelection === "mirror_group" &&
                <ResMirrorSelectComponent handleOnclick={this.handleOnclick} index={this.state.index}/>
             }
             {
                resMirrorSelection === "mirror_option" &&
                <ResMirrorOptionComponent index={this.state.index}/>
             } */}
            {
               resMirrorSelection === "mirror_group" &&
               <RespMirrorSizeSelectionComponent  />
            }
            {
               (resMirrorSelection === "cover_direction" && setMirrorType && setMirrorType.id === 2)  &&
               <RespMirrorCoverDirection />
            }


            {
               (resMirrorSelection === "light" ) &&
               // (resMirrorSelection === "light") &&
               <ResLightComponent  index={this.state.index} />

            }
         </Fragment>
      );
   }
}

const mapStateToProps = (state) => ({
   resMirrorSelection: state.productStepReducer.resMirrorSelection,
   setMirrorType:state.userSelectionReducer.setMirrorType
})
export default connect(mapStateToProps)(ResMirrorContainer);
