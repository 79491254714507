import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ASSETS } from "../constants/paths";
import { selectionType, assignProductStep } from "../actions";

class ChosenProductComponent extends Component {
   editSelection = () => {
      this.props.dispatch(selectionType("sink"));
      this.props.dispatch(assignProductStep(1));
   }
   state={
      isMobile:false
   }
   resize = () => {
      if(window.innerWidth>641){
         this.setState({
            isMobile:false
         })
      }else{
         this.setState({
            isMobile:true
         })
      }
   }
   componentDidMount() {
      this.resize()
      window.addEventListener("resize",this.resize)
   }
   componentWillUnmount() {
      this.resize()
      window.removeEventListener("resize",this.resize)
   }

   render() {
      let { bathroomCabinet, handleData, stand, userSelection, activeLang,language, setCoverStyle, orderSummary } = this.props;
      return (
         <div className="vra-chosen-product-wrapper">
            <div className="vra-chosen-top-block">
               <div className="vra-upper-container">
                  <div className="vra-right-block">
                     <div className="vra-img-item">
                        <picture>
                           <img src={userSelection.bathroomPicture ? userSelection.bathroomPicture : `${ASSETS}/img/home/small-cabinet.png`} alt="" />
                        </picture>
                     </div>
                  </div>
                  <div className="vra-left-block">
                     <div className="vra-text-item">
                        <p>{language.washbasin_only}</p>
                        <span>{bathroomCabinet.sale_code}</span>
                     </div>
                     {
                        
                        <div className="vra-product-code-block">
                           {
                              (handleData && handleData.id !== 1) &&
                              <div className="vra-product-code">
                                 <p>{JSON.parse(handleData.transition)[activeLang] || handleData.name} {language.handle}</p>
                                 <span>{setCoverStyle.id==4?orderSummary.handleData.model_code:handleData.sale_code}</span>
                              </div>
                           }
                           {/* Ayak Datasi basilacak */}
                           {
                              stand &&
                              <div className="vra-product-code">
                                 <p>{language.leg}</p>
                                 <span>{stand.sale_code}</span>
                              </div>
                           }
                        </div>
                     }
                  </div>
               </div>
            </div>
            {
               language &&
               <div className="vra-chosen-bottom-block">
                  <div className="vra-edit-item" onClick={this.editSelection}>
                     <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 7L10 13L14 14L13 10L7 4" stroke="black" strokeWidth={2} strokeLinecap="round"
                              strokeLinejoin="round" />
                        <path d="M2 5L5 2L8 5L5 8L2 5Z" stroke="black" strokeWidth={2} strokeLinecap="round"
                              strokeLinejoin="round" />
                     </svg>
                     <span>{language.edit_wash}</span>
                  </div>
                  {
                     activeLang === "tr" &&
                     <div className="vra-chosen-price-item">
                        <p>{language.total_price}</p>
                        <b>{parseInt(parseInt(bathroomCabinet.price) + (handleData && handleData.id !== 1 ? parseInt(handleData.price) : 0) + (stand ? parseInt(stand.price) : 0)).toLocaleString()}<span>&#8378;</span></b>
                     </div>
                  }

               </div>

            }

         </div>
      );
   }
}


const mapStateToProps = (state) => ({
   userSelection: state.userSelectionReducer,
   activeLang: state.commonReducer.activeLang,
   language: state.languageReducer[state.commonReducer.activeLang],
   setCoverStyle: state.userSelectionReducer.setCoverStyle,
   orderSummary: state.orderSummaryReducer
})

export default connect(mapStateToProps)(ChosenProductComponent);
