import React, { Component } from 'react';

class CheckBoxComponent extends Component {
    createMarkup =()=> {
      return {__html:this.props.label};
    }
    render() {
        const { input, id,label, meta: { error, touched }, submitFailed } = this.props;
        return (
            <div className={`checkbox-item red ${touched && error ? "error" : ""}`}>
                <label htmlFor={input.name} className="radio-label">
                    <input id={input.name} {...input} type="checkbox"
                     checked={!!input.value} />
                   <span dangerouslySetInnerHTML={this.createMarkup()}></span>
                </label>
            </div>

        )
    }
}

export default CheckBoxComponent;
