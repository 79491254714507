import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {getFeaturedBathroomDataAction} from '../actions';

class RandomBathProductComponent extends Component {
   randomBathroom = () => {
      let randmArr = this.props.randomArr.filter(item=>item.available_countries.indexOf(this.props.activeLang)!==-1);
      this.props.showLoader();
      let randmInx = Math.floor(Math.random() * (randmArr.length))
      this.props.dispatch(getFeaturedBathroomDataAction(randmArr[randmInx].featuredId));
   }

   render() {
      let {language, isPreview} = this.props
      return (
          <Fragment>
             {
                language &&
                <div className="vra-random-bath-block">
                   <button className="vra-btn vra-white vra-random"
                           onClick={this.randomBathroom}>
                      {
                         isPreview &&
                         <svg width={18} height={14} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1 15H3C4.25903 15 5.44458 14.4072 6.2 13.4L12.8 4.6C13.5554 3.59278 14.741 3 16 3H19"
                                stroke="black" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M17 1L19 3L17 5" stroke="black" strokeWidth={2} strokeLinecap="round"
                                  strokeLinejoin="round"/>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M8.25 5.66667L7 4C6.05573 2.74097 4.57379 2 3 2H1C0.447715 2 0 2.44771 0 3C0 3.55229 0.447715 4 1 4H3C3.94427 4 4.83344 4.44458 5.4 5.2L7 7.33333L8.25 5.66667ZM10.75 12.3333L12 14C12.9443 15.259 14.4262 16 16 16H19C19.5523 16 20 15.5523 20 15C20 14.4477 19.5523 14 19 14H16C15.0557 14 14.1666 13.5554 13.6 12.8L12 10.6667L10.75 12.3333Z"
                                  fill="black"/>
                            <path d="M17 17L19 15L17 13" stroke="black" strokeWidth={2} strokeLinecap="round"
                                  strokeLinejoin="round"/>
                         </svg>
                      }
                      {isPreview ? language.random : language.random_cabinet} </button>
                   {
                      !isPreview &&
                      <Fragment>
                         <p>{language.cabinet_info}</p>

                      </Fragment>
                   }
                </div>
             }


          </Fragment>

      );
   }
}

const mapStateToProps = (state) => ({
   randomArr: state.commonReducer.landingData.attracted_product.filter(item => item.featuredId !== state.commonReducer.lastRandomId),
   language: state.languageReducer[state.commonReducer.activeLang],
   activeLang:state.commonReducer.activeLang
})

export default connect(mapStateToProps)(RandomBathProductComponent);
