import React, {Component, Fragment} from 'react';
import ImageSliderComponent from "./imageSliderComponent";
import {connect} from "react-redux";
import {
   setCoverSelection,
   setCoverSelectionType,
   setSinkCoverDirection
} from "../../actions";

class RespDoorOptionComponent extends Component {
   sliderComponent = React.createRef();

   state = {
      isMobile: false
   }
   changeSelectedItemByIndex = (index) => {
      this.setState({
         activeIndex: null
      })
      let sinkStyle = this.props.setCoverSelectionType || this.props.coverTypes[0];
      if (sinkStyle.sink_cover_stands && sinkStyle.sink_cover_stands.length > 1) {
         let activeStand;
         let activeIndex = 0;
         sinkStyle.sink_cover_stands.map((standItem) => {
            if (activeIndex === index) {
               activeStand = standItem;
            }
            activeIndex++

         })
         this.props.dispatch(setCoverSelection(activeStand))
      } else if (sinkStyle.sink_size_covers.find(item => item.sink_size_id === this.props.sinkSize.id).sink_size.sink_size_cover_directions.length > 1) {
         this.props.dispatch(setSinkCoverDirection(sinkStyle.sink_size_covers.find(item => item.sink_size_id === this.props.sinkSize.id).sink_size.sink_size_cover_directions[index]))
      }

   }
   setColor = (coverSelection, type, coverDirection) => {
      let index = 0;
      let activeIndex;
      if (coverSelection) {
         type.sink_cover_stands.map((sink_stand) => {
            if (sink_stand.id === coverSelection.id) {
               activeIndex = index
            }
            index++
         })
      }
      if (coverDirection) {
         activeIndex = this.props.clickedSinkStyle.sink_size_covers.find(item => item.sink_size_id === this.props.sinkSize.id).sink_size.sink_size_cover_directions.findIndex(item => item.id === coverDirection.id)
      }
      this.setState({
         activeIndex
      })
      this.props.dispatch(setCoverSelection(coverSelection))
      this.props.dispatch(setCoverSelectionType(type))
      this.props.dispatch(setSinkCoverDirection(coverDirection))
   }
   resize = () => {
      if (window.innerWidth > 640) {
         this.setState({
            isMobile: false
         })
      } else {
         this.setState({
            isMobile: true
         })
      }
   }

   componentDidMount() {
      this.resize();
      window.addEventListener("resize", this.resize)
      if (this.props.setCoverSelection) {
         this.setState({
            activeIndex: this.props.clickedSinkStyle.sink_cover_stands.findIndex(stand => stand.id === this.props.setCoverSelection.id)
         })
      }
   }

   componentWillUnmount() {
      window.removeEventListener("resize", this.resize)
      this.resize();
   }

   render() {
      let {coverTypes, setCoverSelectionType, setCoverSelection, activeLang, language, clickedSinkStyle, sinkSize, setSinkSizes} = this.props;
      let sinkSizeCover = clickedSinkStyle ? clickedSinkStyle.sink_size_covers.find(item => item.sink_size_id === sinkSize.id) : null
      let isCoverExist = clickedSinkStyle ? clickedSinkStyle.sink_size_covers.findIndex(item => item.sink_size_id === setSinkSizes.id) : null;
      let withDoorsFor80cm = this.props.sinkSize && this.props.setCoverSelectionType && (this.props.sinkSize.id === 3 || this.props.sinkSize.id === 9 ) && this.props.setCoverSelectionType.id === 1
      return (
          <div className="vra-selections-block vra-mobile-block vra-sizes">
             {
                (isCoverExist === -1 || withDoorsFor80cm) &&
                <div className="vra-tooltip-item full-width">
                   <span>{language.dont_exist.replace("##SIZE##", setSinkSizes.name)}</span>
                </div>
             }

             {

                (isCoverExist > -1 && ((setCoverSelection && clickedSinkStyle.sink_cover_stands.length > 1) || (sinkSizeCover && sinkSizeCover.sink_size.sink_size_cover_directions.length > 1))) &&

                <div className="vra-tooltip-item">
                   <span>{` ${clickedSinkStyle.sink_cover_stands && clickedSinkStyle.sink_cover_stands.length > 1 ? language.leg : language.door_side}`}</span>
                </div>

             }

             {

                (isCoverExist > -1 && clickedSinkStyle && (clickedSinkStyle.sink_cover_stands.length > 1 || (sinkSizeCover && sinkSizeCover.sink_size.sink_size_cover_directions.length > 1))) && activeLang !== 'fr' ?
                <ImageSliderComponent ref={this.sliderComponent} changeItem={this.changeSelectedItemByIndex}
                                      activeIndex={this.state.activeIndex}
                                      groupAndCounts={(clickedSinkStyle.sink_cover_stands.map((item) => item.sink_stand.length))}>
                   {
                      clickedSinkStyle.sink_cover_stands.length > 1 && clickedSinkStyle.sink_cover_stands.map((g, index) =>
                          <Fragment key={index}>
                             <div key={index}
                                  className={`vra-option-item vra-color-option ${setCoverSelection && this.state.isMobile && setCoverSelection.id === g.id ? "active" : ""}`}
                                  onClick={() => {
                                     this.setColor(g, clickedSinkStyle);
                                  }}>
                                <div className="vra-round-item">
                                   <p>{JSON.parse(g.sink_stand.transition)[activeLang]}</p>
                                </div>
                                {/*<span>{JSON.parse(_leg.size_groups.transition)[activeLang]}</span>*/}
                             </div>
                             {
                                (index !== clickedSinkStyle.sink_cover_stands.length - 1) &&
                                <span className="vra-line-item"/>
                             }
                          </Fragment>
                      )
                   }
                   {
                      (sinkSizeCover && sinkSizeCover.sink_size.sink_size_cover_directions.length > 1) && sinkSizeCover.sink_size.sink_size_cover_directions.map((coverDirection, index) =>
                          <Fragment key={index}>
                             <div key={index}
                                  className={`vra-option-item vra-color-option ${this.props.setSinkSizeDirection && this.state.isMobile && this.props.setSinkSizeDirection.id === coverDirection.id ? "active" : ""}`}
                                  onClick={() => {
                                     this.setColor(null, clickedSinkStyle, coverDirection);
                                  }}>
                                <div className="vra-round-item">
                                   <p>{JSON.parse(coverDirection.cover_direction.transition)[activeLang]}</p>
                                </div>
                                {/*<span>{JSON.parse(_leg.size_groups.transition)[activeLang]}</span>*/}
                             </div>
                             {
                                (index !== 1) &&
                                <span className="vra-line-item"/>
                             }
                          </Fragment>
                      )
                   }


                </ImageSliderComponent>
                    : sinkSizeCover && sinkSizeCover.id == 17 && activeLang === 'fr' ?
                    <div className="vra-tooltip-item full-width" >
                       <span>If a leg is requested, it can be ordered separately.</span>
                    </div> : null
             }


          </div>
      );
   }
}

const mapStateToProps = state => ({
   coverTypes: state.sinkReducer.coverTypes,
   setCoverSelectionType: state.userSelectionReducer.setCoverSelectionType,
   setCoverSelection: state.userSelectionReducer.setCoverSelection,
   sinkSize: state.userSelectionReducer.setSinkSizes,
   activeLang: state.commonReducer.activeLang,
   language: state.languageReducer[state.commonReducer.activeLang],
   clickedSinkStyle: state.sinkReducer.coverTypes && state.userSelectionReducer.setCoverSelectionType ? (state.sinkReducer.coverTypes.find(item => item.id === state.userSelectionReducer.setCoverSelectionType.id) || state.sinkReducer.coverTypes[0]) : null,
   setSinkSizeDirection: state.userSelectionReducer.setSinkSizeDirection,
   setSinkSizes: state.userSelectionReducer.setSinkSizes
})

export default connect(mapStateToProps)(RespDoorOptionComponent);
