import React, { Component } from 'react';
import { ASSETS } from '../../constants/paths';

class BannerSlideImgComponent extends Component {
    imgItem = React.createRef();
    render() {
        let {img1x,img2x,activeIndex,index,imgMobil1x,imgMobil2x} = this.props;
        return (
            <div ref={this.imgItem} className={`vra-img-item ${activeIndex === index ? "active" : ""}`} key={index}>
                <picture>
                    <source media="(min-width: 769px)"
                        srcSet={`${ASSETS}${img1x.substr(0, img1x.lastIndexOf(".")) + ".webp"} 1x, ${ASSETS}${img2x.substr(0, img2x.lastIndexOf(".")) + ".webp"} 2x`}
                        type="image/webp" />
                    <source media="(min-width: 769px)"
                        srcSet={`${ASSETS}${img1x} 1x, ${ASSETS}${img2x} 2x`}
                        type="image/jpg" />
                    <source media="(max-width: 768px)"
                        srcSet={`${ASSETS}${imgMobil1x.substr(0, imgMobil1x.lastIndexOf(".")) + ".webp"} 1x, ${ASSETS}${imgMobil2x.substr(0, imgMobil2x.lastIndexOf(".")) + ".webp"} 2x`}
                        type="image/webp" />
                    <source media="(max-width: 768px)"
                        srcSet={`${ASSETS}${imgMobil1x} 1x, ${ASSETS}${imgMobil2x} 2x`}
                        type="image/jpg" />
                    <img src={`${ASSETS}${img1x}`} alt={index.toString()} />
                </picture>
            </div>
        )
    }
};

export default BannerSlideImgComponent;
