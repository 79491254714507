import React, { Component, Fragment } from 'react';
import { ASSETS } from "../constants/paths";
import { connect } from "react-redux";
import {
   getSinkTypesAction,
   setSinkType,
   setSinkSizes,
   setCoverSelectionType,
   setCoverSelection,
   setSinkTitleAction, checkIsLoading, setSinkCoverDirection
} from "../actions";
import MobileSliderComponent from "./mobile/mobileSliderComponent";
import SkeletonComponent from "./common/skeletonComponent";
import OptionSelectionComponent from "./optionSelectionComponent";
import SinkComponent from "./sinkComponent";

class SinkTypeSelectionComponent extends Component {

   state = {
      id: null
   }

   componentDidMount() {
      if (this.props.sinkTypes && this.props.sinkTypes.length < 1) {
         this.props.dispatch(getSinkTypesAction())
      }
      if (!this.props.sinkTypes || this.props.sinkTypes.length === 0) {
         this.props.dispatch(checkIsLoading(true))
      }
   }

   componentDidUpdate(prevProps) {
      if ((!prevProps.sinkTypes || prevProps.sinkTypes.length === 0) && (this.props.sinkTypes && this.props.sinkTypes.length > 0)) {
         /*todo: başlangıç değeri atar*/
         /*this.handleOnChange(this.props.sinkTypes[0]);*/
         this.props.dispatch(checkIsLoading(false))
      }
   }

   changeSelectedItemByIndex = (index) => {
      if (window.innerWidth < 641) {
         this.props.dispatch(setSinkType(this.props.sinkTypes[index]))
         this.props.dispatch(setSinkTitleAction(this.props.sinkTypes[index].size_groups[0]))
         this.props.dispatch(setSinkSizes(this.props.sinkTypes[index].size_groups[0].sizes[0]))
         this.props.dispatch(setCoverSelectionType(null))
         this.props.dispatch(setCoverSelection(null))
         this.props.dispatch(setSinkCoverDirection(null))
      } else {
         this.props.dispatch(setSinkType(this.props.sinkTypes[index]))
      }
   }

   filteredByIndia = (sink) => {
      if (this.props.activeLang === "in"){
         /*id:4 => Root Flat*/
         if(this.props.setCoverStyle.id == 4){
            return sink.id === 5
         }
         /*id:6 => Root Flat*/
         if(this.props.setCoverStyle.id == 6){
            return sink.id === 2
         }
      }else{
         return sink
      }
   }

   render() {
      let { sinkTypes, sinkTypeId, isLoading, activeLang, language } = this.props;
      return (
         <Fragment>
            {
               (sinkTypes && sinkTypes.length > 0 && !isLoading) &&
               <div className="vra-selections-container">
                  <div className="vra-title-head-item">
                     {
                        language &&
                        <h3>{language.choose_washbasin}</h3>
                     }

                  </div>
                  <div className="vra-custom-select-container">
                     <MobileSliderComponent
                     ref={this.sliderComponent}
                        changeItem={this.changeSelectedItemByIndex}
                        activeIndex={sinkTypes && sinkTypes.length > 0 && this.props.sinkTypeId ? sinkTypes.findIndex(item => item.id === this.props.sinkTypeId.id) : 0}>
                        {
                           sinkTypes.filter(this.filteredByIndia).map((sink, index) =>
                              <SinkComponent stateId={this.state.id} key={index} handleOnChange={this.handleOnChange} sinks={sink} />
                           )
                        }
                     </MobileSliderComponent>
                  </div>

                  {/*<MobileSliderComponent changeItem={this.changeSelectedItemByIndex}
                                          activeIndex={sinkTypes && sinkTypes.length > 0 && sinkTypeId ? sinkTypes.findIndex(item => item.id === sinkTypeId.id) : 0}>
                      {
                         sinkTypes && sinkTypes.map((item, index) =>
                             <div
                                 className={`vra-selections-item ${sinkTypeId && sinkTypeId.id === item.id ? "active" : ""}`}
                                 key={index} onClick={() => this.handleOnChange(item)}>
                                <div className="vra-img-item">
                                   <picture>
                                      <img src={`${ASSETS}${item.img}`} alt={index}/>
                                   </picture>
                                </div>
                                <span>{JSON.parse(item.transition)[activeLang] || item.name}</span>
                             </div>
                         )
                      }
                   </MobileSliderComponent>*/}
               </div>
            }
            {
               isLoading &&
               <SkeletonComponent />
            }

         </Fragment>

      );
   }
}

const mapStateToProps = (state) => ({
   sinkTypes: state.sinkReducer.sinkTypes || [],
   sinkTypeId: state.userSelectionReducer.setSinkType,
   userSelectedSinkSizeId: state.userSelectionReducer.setSinkSizes ? state.userSelectionReducer.setSinkSizes.id : null,
   userSelectedCoverId: state.userSelectionReducer.setCoverSelectionType ? state.userSelectionReducer.setCoverSelectionType.id : null,
   isLoading: state.commonReducer.isLoading,
   activeLang: state.commonReducer.activeLang,
   language: state.languageReducer[state.commonReducer.activeLang],
   setCoverStyle:state.userSelectionReducer.setCoverStyle || null
})
export default connect(mapStateToProps)(SinkTypeSelectionComponent);
