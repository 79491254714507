import React, {Component} from 'react';
import {connect} from 'react-redux';
import {DE_HOME_PAGE, HOME_PAGE, ROADSHOWLINK, ROADSHOW_HOME_PAGE} from "../constants/routePaths";
import {
   setRandomValue,
   resetUserSelections,
   getLandingDataAction, getLandingJSONData
} from "../actions";
import BannerComponent from "../components/landing/bannerComponent";
import ProductItemComponent from "../components/landing/productItemComponent";
import SliderComponent from "../components/landing/sliderComponent";
import VideoItemComponent from "../components/landing/videoItemComponent";


class LandingPageComponent extends Component {

   componentDidMount() {
      // this.props.dispatch(getLandingDataAction())
      // getLandingDataAction()
     /* getLandingData().then(res=>{
         if(res.data.success){
            this.props.dispatch(getLandingJSONData(res.data.success))
            // xc(getLandingJSONData(res.data.success))
         }
      }).catch(err=>console.log("ERROR: ",err))*/
      getLandingDataAction().then(res=>{
         this.props.dispatch(getLandingJSONData(res))
      }).catch(err=>console.log("err",err))
      this.props.dispatch(resetUserSelections(true))
      this.props.dispatch(setRandomValue(false))
   }


   openConfigurator = () => {
      this.props.history.push(window.location.pathname.indexOf("/rootkonfigurator")!==-1?DE_HOME_PAGE.replace(":lang", this.props.activeLang) : (window.location.pathname.indexOf(ROADSHOWLINK)!==-1? ROADSHOW_HOME_PAGE.replace(":lang",this.props.activeLang) :HOME_PAGE.replace(":lang",this.props.activeLang)))
   }


   render() {
      let {attractedProduct,activeLang} = this.props;

      return (
          <main className="vra-landing-root">
             <BannerComponent history={this.props.history}/>
             <div className="vra-attention-root">
                   <SliderComponent>
                      {
                         attractedProduct ? attractedProduct.filter(img=>img.available_countries.indexOf(activeLang)>-1).map((item, index) =>
                             <ProductItemComponent {...item} key={index}/>
                         )
                             :<div/>
                      }
                   </SliderComponent>

             </div>
             <VideoItemComponent/>
          </main>
      )
   }
}

const mapStateToProps = (state) => ({
   attractedProduct: state.commonReducer.landingData ? state.commonReducer.landingData.attracted_product : [],
   activeLang: state.commonReducer.activeLang,
   language: state.languageReducer[state.commonReducer.activeLang]
})
export default connect(mapStateToProps)(LandingPageComponent);
