import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { setMirrorBacklight } from "../../actions";
import ImageSliderComponent from "./imageSliderComponent";

class ResLightComponent extends Component {
   constructor(props){
      super(props)
      props.dispatch(setMirrorBacklight({light:true,id:1}))
   }

   sliderComponent=React.createRef();
   state={
      activeIndex:null
   }
   setBacklight = (item) => {
      let activeIndex = item.light ? 0 : 1;
      this.setState({
         activeIndex
      })
      this.props.dispatch(setMirrorBacklight(item))
   }
   componentDidUpdate(prevProps, prevState, snapshot) {
      if(prevProps.withBacklight && this.props.withBacklight && prevProps.withBacklight.id !== this.props.withBacklight.id){
         this.setState({
            activeIndex:0
         })
      }
   }
   componentDidMount() {
     if(this.props.withBacklight){
        let activeIndex = this.props.withBacklight.light ? 0 : 1;
        this.setState({
           activeIndex
        })
     }
   }

   changeSelectedItemByIndex = (index) => {
      this.props.dispatch(setMirrorBacklight({light:index === 0,id:this.props.setMirrorType.id}))
      this.setState({
         activeIndex: null
      })
   }

   render() {
      let { setMirrorType, mirrorType, withBacklight,language,activeLang,clickedMirrorSize} = this.props
      let mirrorId = mirrorType.map(item=>item.id)
      return (
         <div className="vra-selections-block vra-mobile-block vra-sizes">
            {
               (setMirrorType&&setMirrorType.with_backlight !== null) &&
               <div className="vra-tooltip-item">
                  <span>{language.lighting}</span>
               </div>
            }

            {
               (setMirrorType&&setMirrorType.with_backlight !== null) &&
               <ImageSliderComponent ref={this.sliderComponent}
               changeItem={this.changeSelectedItemByIndex}
               activeIndex={this.state.activeIndex}>
                  <div className={`vra-option-item vra-color-option ${withBacklight&&withBacklight.light === true && mirrorId.filter(id=>id === withBacklight.id) && setMirrorType ? "active" : ""}`}>
                     <div onClick={() => {
                        this.setBacklight({light:true,id:setMirrorType.id})

                     }} className={`vra-round-item `}>
                        <p>{language.available}</p></div>

                  </div>
                  <div className={`vra-option-item vra-color-option ${withBacklight&&withBacklight.light === false && mirrorId.filter(id=>id === withBacklight.id) && setMirrorType  ? "active" : ""}`}>
                     <div onClick={() => {
                        this.setBacklight({light:false,id:setMirrorType.id})

                     }} className={`vra-round-item`}>
                        <p>{language.not_available}</p></div>
                  </div>
               </ImageSliderComponent>
            }

         </div>
      );
   }
}

const mapStateToProps = (state) => ({
   setMirrorType: state.userSelectionReducer.setMirrorType,
   withBacklight: state.userSelectionReducer.mirrorWithBackLight,
   mirrorType: state.mirrorReducer.mirrorType || [],
   activeLang: state.commonReducer.activeLang,
   language:state.languageReducer[state.commonReducer.activeLang],
   clickedMirrorSize: state.mirrorReducer.mirrorType && state.userSelectionReducer.withBacklight ? (state.mirrorReducer.mirrorType.find(item => item.id === state.userSelectionReducer.withBacklight.id) || {light:state.mirrorReducer.mirrorType[0].with_backlight,id:state.mirrorReducer.mirrorType[0].id} ) : null
})
export default connect(mapStateToProps)(ResLightComponent);
