import {GET_ARMATURE_TYPES} from "../constants/actionTypes";

const initialState={armatureTypes:[]};

const ArmatureReducer = (state=initialState,action) => {
   switch (action.type) {
      case GET_ARMATURE_TYPES:
         return{
            ...state,
            armatureTypes:action.payload
         }
      default:
         return state
   }
}
export default ArmatureReducer;
