import {combineReducers} from 'redux';
import productStepReducer from "./productStepReducer";
import sinkReducer from "./sinkRedcuer"
import userSelectionReducer from "./userSelectionReducer";
import commonReducer from "./commonReducer";
import armatureReducer from "./armatureReducer";
import mirrorReducer from "./mirrorReducer";
import tallCabinetReducer from "./tallCabinetReducer";
import otherModuleReducer from "./otherModuleReducer";
import orderSummaryReducer from "./orderSummaryReducer"
import languageReducer from "./languageReducer"


const combineRed = combineReducers({
   productStepReducer,
   sinkReducer,
   userSelectionReducer,
   commonReducer,
   armatureReducer,
   mirrorReducer,
   tallCabinetReducer,
   otherModuleReducer,
   orderSummaryReducer,
   languageReducer

});

export default combineRed
