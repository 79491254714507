import React, { Component,Fragment } from 'react';
import { connect } from "react-redux";
import { orderDocumentHTML } from "../../constants/const";
import html2canvas from 'html2canvas';
import { moduleArr } from "../../constants/const";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { getBathroomAreaAction } from '../../actions/index';
import { ASSETS } from '../../constants/paths';
import { formatPrice } from '../../helper';
// import RoadShowFormModalComponent from './roadShowFormModalComponent';
import { withRouter } from 'react-router-dom';
import { ROADSHOWLINK } from '../../constants/routePaths';
import CampaigneModalComponent from './campaigneModalComponent';
import VerificationComponent from './verificationComponent';
import CampaignResultModalComponent from './campaignResultModalComponent';
import QrImageModalComponent from './qrImageModalComponent';
import RecommendedPriceComponent from "../common/recommendedPriceComponent";
import {parse} from "query-string";
import SendEmailModalComponent from './sendEmailModalComponent';
import SendMailResultModalComponent from './sendMailResultModalComponent';

pdfMake.vfs = pdfFonts.pdfMake.vfs;


class ModalButtonComponent extends Component {
   btnBlock=React.createRef()
   formValues={};
   detailValues;

   state = {
      formStep:null,
      isMobile:false,
      totalAdditionalPrice:0,
      totalExpAdditionalPrice:0
   }
   componentDidMount() {
      window.addEventListener("resize",this.setIsMobile)
      this.setIsMobile()

   }
   componentDidUpdate(prevProps, prevState, snapshot) {
      if((this.props.orderSummary && this.props.orderSummary.mirrorData !== prevProps.orderSummary.mirrorData )||
          (this.props.orderSummary.armatureStyleData !== prevProps.orderSummary.armatureStyleData  )||
          (this.props.orderSummary.bathroomCabinet !== prevProps.orderSummary.bathroomCabinet ) ||
          (this.props.orderSummary.otherModules !== prevProps.orderSummary.otherModules ) ||
          (this.props.orderSummary.tallCabinetInfo !== prevProps.orderSummary.tallCabinetInfo )
      ){
         this.totalProductPrice()
      }
   }

   letterModifier = (phrase) => {
      return (this.props.activeLang === "de" || this.props.activeLang === "ru"? phrase : (phrase ? phrase.toLowerCase().split(", ").map(item => item[0].toUpperCase() + item.substring(1, item.length)).join(", ") : ""))
   }
   getBathroomAreaPicture = (cb) => {
      let height;
      if (this.props.userSelection.setSinkSizes && this.props.userSelection.setSinkSizes.sink_size_covers[0]) {
         height = this.props.userSelection.setSinkSizes.sink_size_covers[0].height;
         if (this.props.userSelection.setCoverSelectionType && this.props.userSelection.setSinkSizes.sink_size_covers.find(item => item.id === this.props.userSelection.setCoverSelectionType.id)) {
            height = this.props.userSelection.setSinkSizes.sink_size_covers.find(item => item.id === this.props.userSelection.setCoverSelectionType.id).height;
         }
      }
      this.props.dispatch(getBathroomAreaAction({
         serie: this.props.userSelection.setCoverStyle ? this.props.userSelection.setCoverStyle.name : null,
         color: this.props.userSelection.setCoverColour ? `${this.props.userSelection.setCoverType.id == 3 ? this.props.userSelection.setCoverType.name + " " : ""}${this.props.userSelection.setCoverColour.cover_color.name}` : null,
         placeColorName: this.props.userSelection.setCoverColour ? `${this.props.userSelection.setCoverColour.cover_color.name}` : null,
         size: this.props.userSelection.setSinkSizes ? this.props.userSelection.setSinkSizes.name : null,
         cover: this.props.userSelection.setCoverSelectionType ? this.props.userSelection.setCoverSelectionType.name : null,
         direction: this.props.userSelection.setSinkSizeDirection ? this.props.userSelection.setSinkSizeDirection.cover_direction.name : "Sag",
         handleColor: this.props.userSelection.setHandleTypes ? this.props.userSelection.setHandleTypes.name : null,
         stand_size: this.props.userSelection.setCoverSelection ? this.props.userSelection.setSinkSizes.name : null,
         sinkSerie: this.props.userSelection.setSinkType ? this.props.userSelection.setSinkType.serie_name : null,
         height,
         is_stand: this.props.userSelection.setCoverSelection && this.props.userSelection.setCoverSelection.sink_stand ? this.props.userSelection.setCoverSelection.sink_stand.name : null,
         armature: this.props.userSelection.setArmatureColour ? this.props.userSelection.setArmatureType.name : null,
         armatureColorId: this.props.userSelection.setArmatureColour ? this.props.userSelection.setArmatureColour.id : null,
         otherModule: this.props.userSelection.setOtherModules ? this.props.userSelection.setOtherModules.name : null,
         otherModuleDirection: this.props.userSelection.setOtherModuleCoverDirection ? this.props.userSelection.setOtherModuleCoverDirection.cover_direction.name : null,
         mirror: this.props.userSelection.setMirrorType ? this.props.userSelection.setMirrorType.name : null,
         tallCabinet: this.props.userSelection.setTallCabinetCoverDirection ? this.props.userSelection.setTallCabinetCoverDirection.cover_direction.name : null,
         mirrorSize: this.props.userSelection.mirrorSize ? this.props.userSelection.mirrorSize.size.name : null,
         tallCabinetSizeName: this.props.userSelection.setTallCabinet && this.props.userSelection.setTallCabinet.tall_cabinet_sizes && this.props.userSelection.setTallCabinet.tall_cabinet_sizes[0] ? this.props.userSelection.setTallCabinet.tall_cabinet_sizes[0].size_name : null,
         otherModuleSizeName: this.props.userSelection.setSinkTitle ? this.props.userSelection.setSinkTitle.name : "",
         otherModuleSize: this.props.userSelection.setOtherModules ? this.props.userSelection.setOtherModules.size : null,
         withBackLight: this.props.userSelection.mirrorWithBackLight ? this.props.userSelection.mirrorWithBackLight.light : 0,
         place: this.props.userSelection.place,
         mirrorCoverDirection: this.props.userSelection.selectedMirrorCoverDirection ? this.props.userSelection.selectedMirrorCoverDirection.name : "sag",
         width: true
      }, (bathroomPicture) => {
         if (bathroomPicture === false && this.retryLoad !== true) {
            setTimeout(() => {
               this.retryLoad = true;
               this.getBathroomAreaPicture();
            }, 2000);
         } else {
            this.retryLoad = false;
            cb(bathroomPicture);
         }
      }))
   }
   downloadDocument = () => {
      let { activeLang, language } = this.props;
      var iframe = document.createElement('iframe');
      document.body.style = "overflow:hidden"
      document.body.append(iframe);
      //let bathroomCabinetName = `${JSON.parse(this.props.userSelection.setCoverStyle.transition)[activeLang]}, ${JSON.parse(this.props.userSelection.setCoverColour.cover_color.transition)[activeLang]}, ${JSON.parse(this.props.userSelection.setSinkType.transition)[activeLang]}, <span class="vra-code" style="line-height: 0;">${this.props.userSelection.setSinkSizes.name.split(" ")[0]}</span> ${this.props.userSelection.setSinkSizes.name.split(" ")[1]}, ${JSON.parse(this.props.userSelection.setCoverSelectionType.transition)[activeLang]}, ${JSON.parse(this.props.userSelection.setHandleTypes.transition)[activeLang]}, ${this.props.language.leg} ${this.props.userSelection.setCoverSelection ? JSON.parse(this.props.userSelection.setCoverSelection.sink_stand.transition)[activeLang] : language.not_available} `;
      let bathroomCabinetName = this.props.orderSummary.bathroomCabinet && this.props.orderSummary.bathroomCabinet.description_transition ? JSON.parse(this.props.orderSummary.bathroomCabinet.description_transition)[this.props.activeLang] || JSON.parse(this.props.orderSummary.bathroomCabinet.description_transition).en : `${JSON.parse(this.props.userSelection.setCoverStyle.transition)[activeLang]}, ${JSON.parse(this.props.userSelection.setCoverColour.cover_color.transition)[activeLang]}, ${JSON.parse(this.props.userSelection.setSinkType.transition)[activeLang]}, <span class="vra-code" style="line-height: 0;">${this.props.userSelection.setSinkSizes.name.split(" ")[0]}</span> ${this.props.userSelection.setSinkSizes.name.split(" ")[1]}, ${JSON.parse(this.props.userSelection.setCoverSelectionType.transition)[activeLang]}, ${JSON.parse(this.props.userSelection.setHandleTypes.transition)[activeLang]}, ${this.props.language.leg} ${this.props.userSelection.setCoverSelection ? JSON.parse(this.props.userSelection.setCoverSelection.sink_stand.transition)[activeLang] : language.not_available} `;

      let itemHTMLs = "";
      let totalPrice = 0;
      let extraItemHTML = `<tr style="border-bottom:1px solid #E1E1E1">
      <td style="width: 100%">
          <table>
              <tr style="display: block; height:max-content;"></tr>
              <tr>
                  <td style="width: 195px; min-width: 195px">
                      <p class="tdp-item">##NAME##</p>
                      <span class="vra-desc" style=" display: block">##DESC##</span>
                  </td>
                  <td style="text-align: center; width: 158px">
                      <p class="vra-code">##MODEL_CODE##</p>
                  </td>
                  ##ALL_PRICES##
              </tr>
              <tr style="height:22px;"></tr>
                </table>
            </td>
        </tr>`;
      let extraWithoutPriceItemHTML = `<tr style="border-bottom:1px solid #E1E1E1">
      <td style="width: 100%">
          <table>
             <tr style="display: block; height:max-content;"></tr>
              <tr>
                  <td style="width: 200px; min-width: 200px;">
                      <p class="tdp-item">##NAME##</p>
                      <span class="vra-desc" style=" display: block">##DESC##</span>
                  </td>
                  <td style="text-align: right; width: 250px">
                      <p class="vra-code">##MODEL_CODE##</p>
                  </td>
                 
              </tr>
              <tr style="height:22px;"></tr>
          </table>
      </td>
  </tr>`;
      if ((this.props.activeLang == "fr" || this.props.activeLang == "uk" || this.props.activeLang == "de") && this.props.orderSummary.sinkData) {
         console.log(this.props.activeLang)
         itemHTMLs += extraWithoutPriceItemHTML.replace("##NAME##", this.props.language.washbasin_door)
            .replace("##MODEL_CODE##", this.props.orderSummary.sinkData.sale_code)
            .replace("##DESC##", this.props.activeLang === "ru" ? JSON.parse(this.props.orderSummary.sinkData.description_transition)[this.props.activeLang] || JSON.parse(this.props.orderSummary.sinkData.description_transition).en : this.letterModifier(JSON.parse(this.props.orderSummary.sinkData.description_transition)[this.props.activeLang] || JSON.parse(this.props.orderSummary.sinkData.description_transition).en))
            .replace('##ALL_PRICES##', '')
      }
      // let modifiedBathCabinet = bathroomCabinetName.toLowerCase().split(", ").map(item=>item[0].toUpperCase()+item.substring(1,item.length)).join(", ")
      if (this.props.orderSummary.handleData && this.props.setCoverStyle && this.props.setCoverStyle.defaultHandle.id !== this.props.orderSummary.handleData.id) {
         if (this.props.activeLang !== "tr") {
            itemHTMLs += extraWithoutPriceItemHTML.replace("##NAME##",this.props.activeLang == 'ru' ? "Ручки": this.props.language.handle)
               .replace("##MODEL_CODE##", this.props.setCoverStyle.id == 4 ? this.props.orderSummary.handleData.model_code : this.props.orderSummary.handleData.sale_code)
               .replace("##DESC##", this.props.orderSummary.handleData.description_transition ? JSON.parse(this.props.orderSummary.handleData.description_transition)[this.props.setCoverStyle.id == 4 ? "4" : "5"][this.props.activeLang] || JSON.parse(this.props.orderSummary.handleData.description_transition)[this.props.setCoverStyle.id == 4 ? "4" : "5"].en : "")
               .replace('##ALL_PRICES##', '');
         } else {
            totalPrice += ((this.props.orderSummary.handleCount) * (parseInt(this.props.orderSummary.handleData.campaign_price) || parseInt(this.props.orderSummary.handleData.price)))
            itemHTMLs += extraItemHTML
               .replace("##NAME##", `${this.props.language.handle}${this.props.orderSummary.handleCount > 1 ? `x${this.props.orderSummary.handleCount}` : ""}`)
               .replace("##MODEL_CODE##", this.props.setCoverStyle.id == 4 ? this.props.orderSummary.handleData.model_code : this.props.orderSummary.handleData.sale_code)
               .replace("##DESC##", this.props.orderSummary.handleData.description_transition ?
                  JSON.parse(this.props.orderSummary.handleData.description_transition)[this.props.setCoverStyle.id == 4 ? "4" : "5"][this.props.activeLang] || JSON.parse(this.props.orderSummary.handleData.description_transition)[this.props.setCoverStyle.id == 4 ? "4" : "5"].en : "")
               .replace('##ALL_PRICES##', this.props.orderSummary.handleData.campaign_price ? '<td style="text-align: right"><span style="display: block" class="vra-price-item coded">##PRICE##</span><span style="display: block" class="vra-price-item">##CAMPAIGNE_PRICE##</span></td>' : '<td style="text-align: right"><span style="display: block" class="vra-price-item">##PRICE##</span></td>')
               .replace("##PRICE##", formatPrice(this.props.orderSummary.handleCount * parseInt(this.props.orderSummary.handleData.price)) + " ₺")
               .replace("##CAMPAIGNE_PRICE##", this.props.orderSummary.handleData.campaign_price ? formatPrice(this.props.orderSummary.handleCount * parseInt(this.props.orderSummary.handleData.campaign_price)) + " ₺" : formatPrice(this.props.orderSummary.handleCount * parseInt(this.props.orderSummary.handleData.price)) + " ₺")

         }
      }
      if (this.props.orderSummary.stand) {

         if (this.props.activeLang !== "tr") {
            itemHTMLs += extraWithoutPriceItemHTML.replace("##NAME##", " " + this.props.language.leg)
               .replace("##MODEL_CODE##", this.props.orderSummary.stand.sale_code)
               .replace("##DESC##", this.props.orderSummary.stand.description_transition ? JSON.parse(this.props.orderSummary.stand.description_transition)[this.props.activeLang] || JSON.parse(this.props.orderSummary.stand.description_transition).en : "")
               .replace('##ALL_PRICES##', "")

         } else {
            totalPrice += parseInt(this.props.orderSummary.stand.campaign_price) || parseInt(this.props.orderSummary.stand.price)
            itemHTMLs += extraItemHTML
               .replace("##NAME##", " " + this.props.language.leg)
               .replace("##MODEL_CODE##", this.props.orderSummary.stand.sale_code)
               .replace("##DESC##", this.props.orderSummary.stand.description_transition ? JSON.parse(this.props.orderSummary.stand.description_transition)[this.props.activeLang] || JSON.parse(this.props.orderSummary.stand.description_transition).en : "")

               .replace('##ALL_PRICES##', this.props.orderSummary.stand.campaign_price ? '<td style="text-align: right"><span style="display: block" class="vra-price-item coded">##PRICE##</span><span style="display: block" class="vra-price-item">##CAMPAIGNE_PRICE##</span></td>' : '<td style="text-align: right"><span style="display: block" class="vra-price-item">##PRICE##</span></td>')
               .replace("##PRICE##", formatPrice(this.props.orderSummary.stand.campaign_price || this.props.orderSummary.stand.price) + " ₺")
               .replace('##CAMPAIGNE_PRICE##', this.props.orderSummary.stand.campaign_price ? formatPrice(parseInt(this.props.orderSummary.stand.campaign_price)) + " ₺" : formatPrice(this.props.orderSummary.stand.price) + " ₺")
         }
      }
      this.props.selectedModules.map((item) => {
         if (this.props.orderSummary && this.props.orderSummary[item]) {
            let itemObj = moduleArr.find(moduleItem => moduleItem.objKey === item);
            if (this.props.activeLang !== "tr") {
               itemHTMLs += extraWithoutPriceItemHTML
                  .replace("##NAME##", this.props.orderSummary[item].mirror_style ?
                     this.props.language.mirror : (this.props.orderSummary[item].other_module ?
                        this.props.language.other_module : this.props.language[itemObj.languageName]))
                  .replace("##MODEL_CODE##", this.props.orderSummary[item].sale_code ||
                     this.props.orderSummary[item].sale_id || this.props.orderSummary[item].model_code)
                  .replace("##DESC##", this.activeLang === "ru" ? JSON.parse(this.props.orderSummary[item].description_transition)[this.props.activeLang] || JSON.parse(this.props.orderSummary[item].description_transition).en :  this.letterModifier(JSON.parse(this.props.orderSummary[item].description_transition)[this.props.activeLang] || JSON.parse(this.props.orderSummary[item].description_transition).en))
                  .replace('##ALL_PRICES##', "")

            } else {
               totalPrice += parseInt(this.props.orderSummary[item].campaign_price) || parseInt(this.props.orderSummary[item].price) || 0;
               itemHTMLs += extraItemHTML
                  .replace("##NAME##", this.props.orderSummary[item].mirror_style ?
                     this.props.language.mirror : (this.props.orderSummary[item].other_module ?
                        this.props.language.other_module : this.props.language[itemObj.languageName]))
                  .replace("##MODEL_CODE##", this.props.orderSummary[item].sale_code ||
                     this.props.orderSummary[item].sale_id || this.props.orderSummary[item].model_code)
                  .replace('##ALL_PRICES##', this.props.orderSummary[item].campaign_price ? '<td style="text-align: right"><span style="display: block" class="vra-price-item coded">##PRICE##</span><span style="display: block" class="vra-price-item">##CAMPAIGNE_PRICE##</span></td>' : '<td style="text-align: right"><span style="display: block" class="vra-price-item">##PRICE##</span></td>')
                  .replace("##DESC##", this.letterModifier(JSON.parse(this.props.orderSummary[item].description_transition)[this.props.activeLang] || JSON.parse(this.props.orderSummary[item].description_transition).en))
                  .replace("##PRICE##", (this.props.orderSummary[item].price ? formatPrice(parseInt(this.props.orderSummary[item].price)) + " ₺" : ''))
                  .replace('##CAMPAIGNE_PRICE##', this.props.orderSummary[item].campaign_price ? formatPrice(parseInt(this.props.orderSummary[item].campaign_price)) + " ₺" : formatPrice(parseInt(this.props.orderSummary[item].price)) + " ₺")
            }
         }
      })

      this.getBathroomAreaPicture((bathRoomAreaPicture) => {
         if (bathRoomAreaPicture) {
            setTimeout(() => {
               var iframedoc = iframe.contentDocument || iframe.contentWindow.document;
               totalPrice += (parseInt(this.props.orderSummary.bathroomCabinet.campaign_price) || parseInt(this.props.orderSummary.bathroomCabinet.price))
               iframedoc.body.innerHTML = orderDocumentHTML
                  .replace('##WASHBASIN##', this.props.activeLang === 'tr' ? '<td style="width: 195px;"><p class="plqmdm">##washbasin_only##</p><span class="vra-desc" style=" display: block">##BATHROOM_CABINET_NAME##</span></td>' : '<td style="width: 230px; min-width: 230px"><p class="plqmdm">##washbasin_only##</p><span class="vra-desc" style=" display: block; width: 230px">##BATHROOM_CABINET_NAME##</span></td>')
                  .replace('##BATHROOM_CABINET##', this.props.activeLang === 'tr' ? '<td style="text-align:center; width: 152px"><p class="vra-code">##BATHROOM_CABINET_MODEL##</p></td>' : '<td style="text-align:right;"><p style="width: 300px; text-align: right" class="vra-code">##BATHROOM_CABINET_MODEL##</p></td>')
                  .replace('##PRODUCT_CODE_HTML##', this.props.activeLang === 'tr' ? '<th style="text-align: start" align="center"><p>##PRODUCT_CODE##</p></th>' : '<th style="text-align: right" align="center"><p>##PRODUCT_CODE##</p></th>')
                  .replace("##CHOSEN_PRODUCTS##", this.props.language.chosen_products).replace("##PRODUCT_NAME##", this.props.language.product_name)
                  .replace("##PRODUCT_CODE##", this.props.language.product_code)
                  .replace("##washbasin_only##", this.props.language.washbasin_only)
                  .replace("##BATHAREAIMG##", bathRoomAreaPicture ? bathRoomAreaPicture : this.props.downloadPicturePath)
                  .replace("##BATHROOM_CABINET_NAME##", this.props.activeLang !== "de" && this.props.activeLang !== "ru" ? this.letterModifier(bathroomCabinetName):bathroomCabinetName)
                  .replace("##BATHROOM_CABINET_MODEL##", this.props.orderSummary.bathroomCabinet ? this.props.orderSummary.bathroomCabinet.sale_code : "")
                  .replace('##BATHROOM_CABINET_ALL_PRICES##', this.props.activeLang === 'tr' ? '<td style="text-align: right"><p  class="vra-price-item coded">##BATHROOM_CABINET_PRICE##</p><p style="display: block" class="vra-price-item">##BATHROOM_CABINET_CAMPAIGNE_PRICE##</p></td>' : '')
                  .replace("##BATHROOM_CABINET_PRICE##", this.props.activeLang === "tr" && this.props.orderSummary.bathroomCabinet ? formatPrice(parseInt(this.props.orderSummary.bathroomCabinet.price)) + " ₺" : '')
                  .replace("##BATHROOM_CABINET_CAMPAIGNE_PRICE##", this.props.activeLang === "tr" && this.props.orderSummary.bathroomCabinet && this.props.orderSummary.bathroomCabinet.campaign_price ? formatPrice(parseInt(this.props.orderSummary.bathroomCabinet.campaign_price)) + " ₺" : "")
                  .replace("##EXTRA_ITEMS##", itemHTMLs)
                  .replace("##TOTAL_PRICE##", this.props.activeLang === "tr" ? formatPrice(totalPrice) + " ₺" : "")
                  .replace("##TOTAL_PRICE_TEXT##", this.props.activeLang === "tr" ? '<td style="width: 475px"><p class="vra-code">Toplam&nbsp;&nbsp;&nbsp;fiyat*</p></td>' : "")
                  .replace("##PRICE_HEAD_TEXT##", this.props.activeLang === "tr" ? "Fiyat" : '')
                  .replace("##ECZ_LOGO##", activeLang === "tr" ? "<tr style=\"height: 56px;vertical-align: bottom; border-top: 1px solid #E1E1E1; border-bottom: 1px solid #E1E1E1;\">\n<td\n style=\"width: 100%; vertical-align: middle;\">\n <img width=\"85px\" height=\"32px\" src=\"/assets/img/home/eczacibasi2x.png\" alt=\"\">\n</td>\n<td\n style=\"vertical-align: middle; margin:0 0 5px 0; heigth:100% \"></td>\n</tr>" : "")
               // .replace("##FOOTER##",this.props.language.footer_desc)
               // .replace("##URL##", window.location.hostname || document.location.hostname)

               html2canvas(iframedoc.body, { scale: 3 })
                  .then((canvas) => {
                     const imgData = canvas.toDataURL('image/png');
                     // pdfMake.fonts = {
                     //    OpenSansRegular: {
                     //       normal: ['/assets/fonts/OpenSans-Regular.ttf','OpenSans-Regular']
                     //    }
                     // }
                     pdfMake.fonts = {
                        'OpenSansRegular': {
                           normal: `${window.origin}/assets/fonts/OpenSans-Regular.woff`,
                           bold: `${window.origin}/assets/fonts/OpenSans-Regular.woff`,
                           italics: `${window.origin}/assets/fonts/OpenSans-Regular.woff`,
                           bolditalics: `${window.origin}/assets/fonts/OpenSans-Regular.woff`
                        }
                     }
                     var docDefinition = {
                        pageSize: {
                           width: 595.28,
                           height: 'auto'
                        },

                        content: [
                           {
                              image: imgData,
                              width: 547,
                              alignment: 'center',

                           },
                           {
                              columns: [
                                 {
                                    // auto-sized columns have their widths based on their content
                                    width: 'auto',
                                    text: this.handleFooterDesc(),
                                 },
                                 {
                                    // auto-sized columns have their widths based on their content
                                    width: '*',
                                    style: 'texted',
                                    text: this.props.activeLang === "in" ? "https://www.vitra-india.com/" : window.location.hostname,
                                    link: this.props.activeLang === "in" ? "https://www.vitra-india.com/" : window.location.href
                                 }
                              ],
                              style: 'anotherStyle',
                              columnGap: 100,
                           }
                        ],
                        styles: {
                           anotherStyle: {
                              fontSize: 7,
                              alignment: 'justify',
                              color: '#777777',
                              font: 'OpenSansRegular',
                           },
                           texted: {
                              alignment: 'right',
                           }
                        }
                     };

                     pdfMake.createPdf(docDefinition).download(activeLang === "tr" ? "VitrA_Root_Urun_Listesi.pdf" : (activeLang === "fr" ? "Vitra_Root_liste_des_produits.pdf" : "Vitra_Root_Product_List.pdf"));
                     document.body.removeChild(iframe);
                     document.body.style = "overflow:visible"
                  })

            }, 10);
         }
      })
      this.sendEndDataLayer();
   }
   setIsMobile = () =>{
      if(typeof window !== "undefined" && window.innerWidth <= 640){
         this.setState({
            isMobile: true
         })
      }else{
         this.setState({
            isMobile: false
         })
      }
   }
   downloadPicture = () => {
      var a = document.createElement('a');
      a.href = this.props.downloadPicturePath;
      a.download = this.props.activeLang === "tr" ? "VitrA_Root_Banyo.png" : (this.props.activeLang === "fr" ? "Vitra_Root_I_mage.png" : "Vitra_Root_Bathroom.png");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      this.sendEndDataLayer();
   }

   handleFooterDesc = () => {
      if (this.props.activeLang === "tr" || this.props.activeLang === "en" || this.props.activeLang === "in" || this.props.activeLang === "fr" || this.props.activeLang === "de") {
         return {
            text: this.props.language.footer_desc
         }
      }
      if (this.props.activeLang === "uk") {
         return {
            text: this.props.language.footer_desc,
            link: "https://www.vitra.co.uk/showroomlocator/",
         }
      }
      else {
         return {
            text: ""
         }
      }
   }

   sendEndDataLayer = () => {
      let bathroomCabinetName = JSON.parse(this.props.orderSummary.bathroomCabinet.description_transition)[this.props.activeLang] || JSON.parse(this.props.orderSummary.bathroomCabinet.description_transition).en;

      let addedProducts = [{
         'sku': this.props.orderSummary.bathroomCabinet.sale_code,
         'name': bathroomCabinetName,
         'category': this.props.language.washbasin_only,
         'price': this.props.activeLang === "tr" ? parseInt(this.props.orderSummary.bathroomCabinet.campaign_price) || parseInt(this.props.orderSummary.bathroomCabinet.price) : null
      }];

      if ((this.props.activeLang == "fr" || this.props.activeLang == "uk" || this.props.activeLang == "de") && this.props.orderSummary.sinkData) {
         addedProducts.push({
            'sku': this.props.orderSummary.sinkData.sale_code,
            'name': this.letterModifier(JSON.parse(this.props.orderSummary.sinkData.description_transition)[this.props.activeLang] || JSON.parse(this.props.orderSummary.sinkData.description_transition).en),
            'category': this.props.language.washbasin_door,
            'price': null
         })
      }
      if (this.props.orderSummary.handleData && this.props.setCoverStyle && this.props.setCoverStyle.defaultHandle.id !== this.props.orderSummary.handleData.id) {
         if (this.props.activeLang !== "tr") {
            addedProducts.push({
               'sku': this.props.setCoverStyle.id == 4 ? this.props.orderSummary.handleData.model_code : this.props.orderSummary.handleData.sale_code,
               'name': this.props.orderSummary.handleData.description_transition ? JSON.parse(this.props.orderSummary.handleData.description_transition)[this.props.setCoverStyle.id == 4 ? "4" : "5"][this.props.activeLang] || JSON.parse(this.props.orderSummary.handleData.description_transition)[this.props.setCoverStyle.id == 4 ? "4" : "5"].en : "",
               'category': this.props.language.handle,
               'price': null
            })
         } else {
            addedProducts.push({
               'sku': this.props.setCoverStyle.id == 4 ? this.props.orderSummary.handleData.model_code : this.props.orderSummary.handleData.sale_code,
               'name': this.props.orderSummary.handleData.description_transition ? JSON.parse(this.props.orderSummary.handleData.description_transition)[this.props.setCoverStyle.id == 4 ? "4" : "5"][this.props.activeLang] || JSON.parse(this.props.orderSummary.handleData.description_transition)[this.props.setCoverStyle.id == 4 ? "4" : "5"].en : "",
               'category': `${this.props.language.handle}${this.props.orderSummary.handleCount > 1 ? `x${this.props.orderSummary.handleCount}` : ""}`,
               'price': this.props.orderSummary.handleData.campaign_price ? formatPrice(this.props.orderSummary.handleCount * parseInt(this.props.orderSummary.handleData.campaign_price)) + " ₺" : formatPrice(this.props.orderSummary.handleCount * parseInt(this.props.orderSummary.handleData.price))
            })
         }
      }
      if (this.props.orderSummary.stand) {

         if (this.props.activeLang !== "tr") {
            addedProducts.push({
               'sku': this.props.orderSummary.stand.sale_code,
               'name': this.props.orderSummary.stand.description_transition ? JSON.parse(this.props.orderSummary.stand.description_transition)[this.props.activeLang] || JSON.parse(this.props.orderSummary.stand.description_transition).en : "",
               'category': this.props.language.leg,
               'price': null
            })
         } else {
            addedProducts.push({
               'sku': this.props.orderSummary.stand.sale_code,
               'name': this.props.orderSummary.stand.description_transition ? JSON.parse(this.props.orderSummary.stand.description_transition)[this.props.activeLang] || JSON.parse(this.props.orderSummary.stand.description_transition).en : "",
               'category': this.props.language.leg,
               'price': this.props.orderSummary.stand.campaign_price
            })
         }
      }
      this.props.selectedModules.map((item) => {
         if (this.props.orderSummary && this.props.orderSummary[item]) {
            let itemObj = moduleArr.find(moduleItem => moduleItem.objKey === item);
            if (this.props.activeLang !== "tr") {
               addedProducts.push({
                  'sku': this.props.orderSummary[item].sale_code ||
                     this.props.orderSummary[item].sale_id || this.props.orderSummary[item].model_code,
                  'name': this.letterModifier(JSON.parse(this.props.orderSummary[item].description_transition)[this.props.activeLang] || JSON.parse(this.props.orderSummary[item].description_transition).en),
                  'category': this.props.orderSummary[item].mirror_style ?
                     this.props.language.mirror : (this.props.orderSummary[item].other_module ?
                        this.props.language.other_module : this.props.language[itemObj.languageName]),
                  'price': this.props.orderSummary.stand? this.props.orderSummary.stand.campaign_price:null
               })
            } else {
               addedProducts.push({
                  'sku': this.props.orderSummary[item].sale_code ||
                     this.props.orderSummary[item].sale_id || this.props.orderSummary[item].model_code,
                  'name': this.letterModifier(JSON.parse(this.props.orderSummary[item].description_transition)[this.props.activeLang] || JSON.parse(this.props.orderSummary[item].description_transition).en),
                  'category': this.props.orderSummary[item].mirror_style ?
                     this.props.language.mirror : (this.props.orderSummary[item].other_module ?
                        this.props.language.other_module : this.props.language[itemObj.languageName]),
                  'price': this.props.orderSummary[item].campaign_price ? formatPrice(parseInt(this.props.orderSummary[item].campaign_price)) : formatPrice(parseInt(this.props.orderSummary[item].price))
               })
            }
         }
      })
      window.dataLayer.push({
         'event': 'configurator',
         'addedProducts': addedProducts
      });

   }

   changeFormStep = (step,formType) => {
      this.setState({
         formStep:step,
         formType:formType?formType:this.state.formType
      },()=>{
         if(this.state.formStep){
            this.btnBlock.current.style.zIndex='91'
            document.getElementById('vra-choice-root').style.zIndex='11'
         }else{
            document.getElementById('vra-choice-root').style.zIndex=null
         }
      })
      if(!step){
         this.resetValues()
      }
   }
   resetValues=()=>{
      this.formValues={}
   }
   setFormValues=(values)=>{
      this.formValues={
         phone_number:values.phone ? values.phone.replace(/\s/g, ''):null,
         validation_code:null,
         address:null,
         name:values.name,
         surname:values.surname,
         email:values.email,
         etk_allowed:values.etk_allowed,
         kvkk_allowed:values.kvkk_allowed,
         subscribed:values.permission,
         bahtroom_sale_code:this.props.orderSummary.bathroomCabinet.sale_code,
         bahtroom_handle_code:this.props.orderSummary.handleData.sale_code,
         bathroom_leg_code:this.props.orderSummary.stand ? this.props.orderSummary.stand.sale_code : null,
         armature_code:this.props.orderSummary.armatureStyleData ? this.props.orderSummary.armatureStyleData.model_code:null,
         tallcabinet_code:this.props.orderSummary.tallCabinetInfo ? this.props.orderSummary.tallCabinetInfo.sale_code:null,
         mirror_code:this.props.orderSummary.mirrorData ? this.props.orderSummary.mirrorData.sale_id:null,
         othermodule_code:this.props.orderSummary.otherModules ? this.props.orderSummary.otherModules.sale_code:null
      }
   }
   setDetailValues=(detailValues)=>{
      this.detailValues=detailValues
   }
   componentWillUnmount() {
      window.removeEventListener("resize",this.setIsMobile)
   }
   totalProductPrice=()=>{
      let total = 0
      let expTotal = 0
      if(this.props.orderSummary && this.props.orderSummary.armatureStyleData){
         total = total + parseInt(this.props.orderSummary.armatureStyleData.campaign_price)
         expTotal = expTotal + parseInt(this.props.orderSummary.armatureStyleData.price)
      }

      if(this.props.orderSummary && this.props.orderSummary.mirrorData){
         total = total + parseInt(this.props.orderSummary.mirrorData.campaign_price)
         expTotal = expTotal + parseInt(this.props.orderSummary.mirrorData.price)
      }

      if(this.props.orderSummary && this.props.orderSummary.otherModules){
         total = total + parseInt(this.props.orderSummary.otherModules.campaign_price)
         expTotal = expTotal + parseInt(this.props.orderSummary.otherModules.price)
      }

      if(this.props.orderSummary && this.props.orderSummary.tallCabinetInfo){
         total = total + parseInt(this.props.orderSummary.tallCabinetInfo.campaign_price)
         expTotal = expTotal + parseInt(this.props.orderSummary.tallCabinetInfo.price)
      }
      this.setState({
         totalAdditionalPrice: total,
         totalExpAdditionalPrice: expTotal
      })
   }
   render() {
      let {orderSummary,language, activeLang, location,productStep } = this.props
      let{isMobile} = this.state
      return (
         <div className="vra-selection-btn-container" ref={this.btnBlock}>
            {
               activeLang === "tr" &&
               <div className="vra-selection-price-block">
                  <div className="text-item">
                     <span>Toplam fiyat</span>
                     <div className="price-item">
                        <span>{this.state.totalExpAdditionalPrice === 0 ?
                            parseInt(parseInt(orderSummary.bathroomCabinet.price) + ((orderSummary.handleData && this.props.setCoverStyle && this.props.setCoverStyle.defaultHandle.id !== orderSummary.handleData.id) ? orderSummary.handleCount * parseInt(orderSummary.handleData.price) : 0) + (orderSummary.stand ? parseInt(orderSummary.stand.price) : 0)).toLocaleString()
                            :
                            parseInt(this.state.totalExpAdditionalPrice + parseInt(orderSummary.bathroomCabinet.price) + ((orderSummary.handleData && this.props.setCoverStyle && this.props.setCoverStyle.defaultHandle.id !== orderSummary.handleData.id) ? orderSummary.handleCount * parseInt(orderSummary.handleData.price) : 0) + (orderSummary.stand ? parseInt(orderSummary.stand.price) : 0)).toLocaleString()
                        } &#8378;</span>
                        <b><p>
                           {this.state.totalAdditionalPrice === 0 ?
                               parseInt(parseInt(this.props.orderSummary.bathroomCabinet.campaign_price)+((this.props.orderSummary.handleData && this.props.setCoverStyle && this.props.setCoverStyle.defaultHandle.id !== this.props.orderSummary.handleData.id) ? this.props.orderSummary.handleCount * parseInt(this.props.orderSummary.handleData.campaign_price) : 0) + (this.props.orderSummary.stand ? parseInt(this.props.orderSummary.stand.campaign_price) : 0)).toLocaleString()
                               :
                               parseInt(this.state.totalAdditionalPrice + (parseInt(this.props.orderSummary.bathroomCabinet.campaign_price)+((this.props.orderSummary.handleData && this.props.setCoverStyle && this.props.setCoverStyle.defaultHandle.id !== this.props.orderSummary.handleData.id) ? this.props.orderSummary.handleCount * parseInt(this.props.orderSummary.handleData.campaign_price) : 0) + (this.props.orderSummary.stand ? parseInt(this.props.orderSummary.stand.campaign_price) : 0))).toLocaleString()
                           } <span>&#8378;</span>
                        </p></b>
                     </div>
                  </div>
                  <RecommendedPriceComponent productStep={productStep}/>
               </div>
            }
            {
               (language && activeLang === "tr" && location.pathname.indexOf(ROADSHOWLINK) !== -1) &&
               <div className="vra-btn vra-white vra-campaign-btn" onClick={()=>this.changeFormStep(1,"campaign")}>
                  Kampanyaya katıl
               </div>
            }
            {
               (activeLang === "en" && (window.location.origin === "https://rootconfigurator.vitraglobal.com" || window.location.origin === "http://localhost:3000" || window.location.origin === "http://rootapp.gulenayva.com")) &&
               <div className="vra-btn vra-white vra-campaign-btn" onClick={()=>this.changeFormStep(1,"email")}>
                  Send e-mail 
               </div>
            }
            {
               isMobile &&
                   <Fragment>
                      {
                         (activeLang === "en" || activeLang === "in" || activeLang === "uk" || activeLang === "ru" ) ?
                             <></>
                             :
                             <a href={language.sales_point_link} target="_blank" rel="noreferrer"
                                className="vra-btn vra-white sales-point">
                                <svg width={25} height={25} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                   <path d="M17.5 10.3333C17.5 12.5694 14.6186 16.405 13.2305 18.123C12.85 18.5939 12.15 18.5939 11.7695 18.123C10.3814 16.405 7.5 12.5694 7.5 10.3333C7.5 7.38781 9.73858 5 12.5 5C15.2614 5 17.5 7.38781 17.5 10.3333Z" stroke="black" strokeWidth={2} />
                                   <circle cx="12.5" cy={10} r={2} fill="black" />
                                </svg>
                                {language.sales_points}
                             </a>
                      }
                   </Fragment>
            }
            <div className="vra-top-item">

               <button onClick={this.downloadDocument} className="vra-btn">
                  <svg width={24} height={25} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M3 12.5V20.5H21V12.5" stroke="white" strokeWidth={2} strokeLinecap="round"
                        strokeLinejoin="round" />
                     <path
                        d="M13 4.5C13 3.94772 12.5523 3.5 12 3.5C11.4477 3.5 11 3.94771 11 4.5L13 4.5ZM12 14.5L11.3753 15.2809C11.7405 15.573 12.2595 15.573 12.6247 15.2809L12 14.5ZM17.6247 11.2809C18.056 10.9359 18.1259 10.3066 17.7809 9.87531C17.4359 9.44404 16.8066 9.37412 16.3753 9.71913L17.6247 11.2809ZM7.62469 9.71913C7.19343 9.37412 6.56414 9.44404 6.21913 9.87531C5.87412 10.3066 5.94404 10.9359 6.3753 11.2809L7.62469 9.71913ZM11 4.5L11 14.5L13 14.5L13 4.5L11 4.5ZM12.6247 15.2809L17.6247 11.2809L16.3753 9.71913L11.3753 13.7191L12.6247 15.2809ZM12.6247 13.7191L7.62469 9.71913L6.3753 11.2809L11.3753 15.2809L12.6247 13.7191Z"
                        fill="white" />
                  </svg>
                  {language.download_product_list}
               </button>
               <button className="vra-btn" onClick={this.downloadPicture}>
                  <svg width={24} height={25} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M3 12.5V20.5H21V12.5" stroke="white" strokeWidth={2} strokeLinecap="round"
                        strokeLinejoin="round" />
                     <path
                        d="M13 4.5C13 3.94772 12.5523 3.5 12 3.5C11.4477 3.5 11 3.94771 11 4.5L13 4.5ZM12 14.5L11.3753 15.2809C11.7405 15.573 12.2595 15.573 12.6247 15.2809L12 14.5ZM17.6247 11.2809C18.056 10.9359 18.1259 10.3066 17.7809 9.87531C17.4359 9.44404 16.8066 9.37412 16.3753 9.71913L17.6247 11.2809ZM7.62469 9.71913C7.19343 9.37412 6.56414 9.44404 6.21913 9.87531C5.87412 10.3066 5.94404 10.9359 6.3753 11.2809L7.62469 9.71913ZM11 4.5L11 14.5L13 14.5L13 4.5L11 4.5ZM12.6247 15.2809L17.6247 11.2809L16.3753 9.71913L11.3753 13.7191L12.6247 15.2809ZM12.6247 13.7191L7.62469 9.71913L6.3753 11.2809L11.3753 15.2809L12.6247 13.7191Z"
                        fill="white" />
                  </svg>
                  {language.download_image}
               </button>
            </div>
            {
               !isMobile &&
                   <Fragment>
                      {
                         (activeLang === "en" || activeLang === "in" || activeLang === "uk" || activeLang === "ru") ?
                             <></>
                             :
                         <a href={language.sales_point_link} target="_blank" rel="noreferrer"
                         className="vra-btn vra-white sales-point">
                         <svg width={24} height={25} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                         <path d="M17 9.83333C17 12.0247 14.2325 16.8594 12.8154 19.1914C12.4389 19.811 11.5611 19.811 11.1846 19.1914C9.76748 16.8594 7 12.0247 7 9.83333C7 6.88781 9.23858 4.5 12 4.5C14.7614 4.5 17 6.88781 17 9.83333Z" stroke="black" strokeWidth={2} />
                         <circle cx={12} cy="9.5" r={2} fill="black" />
                         </svg>
                      {language.sales_points}
                         </a>
                      }
                   </Fragment>
            }
            {
               (language && activeLang === "tr" && location.pathname.indexOf(ROADSHOWLINK) === -1) &&
               <a href="https://www.vitra.com.tr/#randevu-olustur" target="_blank" rel="noreferrer"
                  className="vra-btn vra-white arrange-meet">
                  <picture><img src={`${ASSETS}/icons/store.svg`} alt="store" /></picture>
                  VitrA Mağazası ile canlı görüşme randevusu al
               </a>
            }
            {
               (language && language.nerast_showroom_link) &&
               <a href={language.nerast_showroom_link} target="_blank" rel="noreferrer"
                  className="vra-btn vra-white arrange-meet">
                  <picture><img src={`${ASSETS}/icons/store.svg`} alt="store" /></picture>
                  {language.nerast_showroom}
               </a>
            }
            {
               (this.state.formType === "campaign" && this.state.formStep === 1) &&
               <CampaigneModalComponent setFormValues={this.setFormValues} changeFormStep={this.changeFormStep} />
            }
            {
              (this.state.formType === "campaign" && this.state.formStep === 2) &&
              <VerificationComponent  setDetailValues={this.setDetailValues} formValues={this.formValues} changeFormStep={this.changeFormStep}/>
                
            }
            {
               (this.state.formType === "campaign" && this.state.formStep === 3 && this.props.orderSummary.bathroomCabinet) &&
               <CampaignResultModalComponent detailValues={this.detailValues} changeFormStep={this.changeFormStep}/> 
            }

            {
               (this.state.formType === "email" && this.state.formStep === 1) &&
               <SendEmailModalComponent setFormValues={this.setFormValues} changeFormStep={this.changeFormStep} />
            }

            {
               (this.state.formType === "email" && this.state.formStep === 2) &&
               <SendMailResultModalComponent detailValues={this.detailValues} changeFormStep={this.changeFormStep} />
            }
            {
               (this.state.formStep === 3 && !this.props.orderSummary.bathroomCabinet) && <QrImageModalComponent changeFormStep={this.changeFormStep} detailValues={this.detailValues}/>
            }

         </div>
      );
   }
}


const mapStateToProps = (state) => ({
   setCoverStyle: state.userSelectionReducer.setCoverStyle,
   selectedModules: state.orderSummaryReducer.selectedModules,
   orderSummary: state.orderSummaryReducer,
   userSelection: state.userSelectionReducer,
   activeLang: state.commonReducer.activeLang,
   language: state.languageReducer[state.commonReducer.activeLang]
})

export default withRouter(connect(mapStateToProps)(ModalButtonComponent));
