import React, { Component } from 'react';
import { getElementMargin, getElementWidth } from "../../helper";

class ImageSliderComponent extends Component {

    sliderComponent = React.createRef();
    activeIndex = 0
    itemWidth = 0
    translateValue = 0
    initTranslate = 0
    margin = 20
    startPosition = 0;
    maxDragValue;
    setTranslateValue = (activeIndex, translateValue) => {
        activeIndex = activeIndex || this.props.activeIndex;
        if (this.sliderComponent.current && this.sliderComponent.current.children[0] && window.innerWidth < 641) {
            this.itemWidth = getElementWidth(this.sliderComponent.current.children[0])

            this.initTranslate = Math.floor((window.innerWidth / 2) - (this.itemWidth / 2))
            this.sliderComponent.current.style.transform = `translate(${this.initTranslate - (translateValue || 0)}px,0)`

        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.activeIndex !== this.props.activeIndex) {
            this.activeIndex = this.props.activeIndex===0?0: this.props.activeIndex || this.activeIndex
            this.calculateTranslateValue();
            //this.translateValue = this.activeIndex * this.itemWidth + (this.getLineItemCount())
            this.setTranslateValue(this.props.activeIndex,this.translateValue);
        }
    }

    getLineItemCount = () => {
        let lineItem = this.sliderComponent.current.querySelectorAll(".vra-line-item")[0]
        let lineItemCount=0
        let totalCountItem=0;
        if(this.props.groupAndCounts){
            this.props.groupAndCounts.map((countItem)=>{
                totalCountItem+=countItem
                if(totalCountItem<=this.activeIndex){
                    lineItemCount++
                }
            })
        }
        return (getElementWidth(lineItem) || 0)*lineItemCount
    }

    slideFunction = (direction) => {

        let childrensWithOutLineItem = this.sliderComponent.current.querySelectorAll(".vra-option-item")
        // let activeIndex = this.props.activeIndex;
        if (direction === 1 && this.activeIndex !== childrensWithOutLineItem.length - 1) {
            this.activeIndex = this.activeIndex + direction

        } else if (direction === -1 && this.activeIndex !== 0) {
            this.activeIndex = this.activeIndex + direction

        }
        /*todo:updating reducer below*/
        if(this.props.changeItem){
            this.props.changeItem(this.activeIndex)
        }
        //this.itemWidth = getElementWidth(childrensWithOutLineItem[this.activeIndex])

        //this.translateValue = this.activeIndex * this.itemWidth +(this.getLineItemCount())
        this.calculateTranslateValue();
        this.setTranslateValue(this.activeIndex, this.translateValue)

        this.forceUpdate()
    }

    calculateTranslateValue = () => {
        let childrensWithOutLineItem = this.sliderComponent.current.querySelectorAll(".vra-option-item")
        this.translateValue = 0;
        for(let i=0;i<childrensWithOutLineItem.length;i++){
           if(i<this.activeIndex){
              this.translateValue+=getElementWidth(childrensWithOutLineItem[i])
           }
        }
        this.translateValue+=getElementMargin(childrensWithOutLineItem[this.activeIndex])+this.getLineItemCount()
     }

     setMaxDragValue = () => {
        let maxDargValue=0;
        let childrensWithOutLineItem = this.sliderComponent.current.querySelectorAll(".vra-option-item")
        let lineItems = this.sliderComponent.current.querySelectorAll(".vra-line-item")
        for(let i=0;i<childrensWithOutLineItem.length-1;i++){
            maxDargValue+=getElementWidth(childrensWithOutLineItem[i])
        }
        this.maxDragValue=this.initTranslate - (maxDargValue+(lineItems?lineItems.length*getElementWidth(lineItems[0]):0))
     }

    componentDidMount() {
        /*{emre} todo: yapı değişecek!!*/
        //setTimeout(this.setTranslateValue, 250)
        this.setTranslateValue();
        this.setMaxDragValue();
        if (typeof window !== "undefined") {
            this.sliderComponent.current.addEventListener('touchstart', this.handleTouchStart, false);
            this.sliderComponent.current.addEventListener('touchmove', this.handleTouchMove, false);
            //this.dragElement();
            window.addEventListener("resize",this.resizeListener)
        }
    }

    resizeListener = () => {
        this.calculateTranslateValue();
        this.setTranslateValue(this.activeIndex, this.translateValue)
        this.setMaxDragValue();
    }

    componentWillUnmount() {
        this.sliderComponent.current.removeEventListener("touchstart", this.handleTouchStart, false)
        this.sliderComponent.current.removeEventListener("touchmove", this.handleTouchMove, false)
        window.removeEventListener("resize",this.resizeListener)
    }


    dragElement = () => {
        let childrensWithOutLineItem = this.sliderComponent.current.querySelectorAll(".vra-option-item")
        let dragMouseDown = (e) => {
            e = e || window.event;
            this.pos3 = e.touches ? e.touches[0].clientX : e.clientX;
            this.initTranslate = Math.floor((window.innerWidth / 2) - (this.itemWidth / 2))
            this.firstLeftPostion = parseInt(this.sliderComponent.current.style.transform.replace("translate(","").split("px,")[0]);
            document.ontouchend = closeDragElement;
            document.ontouchmove = elementDrag;
        };
        let elementDrag = (e) => {
            e = e || window.event;
            let cursorPosition = e.touches ? e.touches[0].clientX : e.clientX;
            this.pos1 = this.pos3 - cursorPosition;
            let itemWidth = getElementWidth(this.sliderComponent.current.children[0]);
            if (this.sliderComponent.current && this.initTranslate>(this.firstLeftPostion - this.pos1) && this.maxDragValue < (this.firstLeftPostion - this.pos1) ) {
                this.sliderComponent.current.style.transform = `translate(${this.firstLeftPostion - this.pos1}px,0)`
                let nextIndex = parseInt((this.initTranslate-(this.firstLeftPostion - this.pos1)+20)/itemWidth)
                if (this.activeIndex !== nextIndex && childrensWithOutLineItem[nextIndex]) {
                    this.activeIndex = nextIndex;
                    if(this.props.changeItem){
                        this.props.changeItem(this.activeIndex)
                    }
                }
            }
        };
        let closeDragElement = (e) => {
            document.ontouchend = null;
            document.ontouchmove = null;
        };
        if (this.sliderComponent.current) {
            this.sliderComponent.current.ontouchstart = dragMouseDown;
        }
    };

    handleTouchStart = (evt) => {
        this.xDown = evt.touches[0].clientX;
        this.yDown = evt.touches[0].clientY;
    };

    handleTouchMove = (evt) => {
        if (!this.xDown || !this.yDown) {
            return;
        }
        this.xUp = evt.touches[0].clientX;
        this.yUp = evt.touches[0].clientY;

        let xDiff = this.xDown - this.xUp;
        let yDiff = this.yDown - this.yUp;
        if (Math.abs(xDiff) + Math.abs(yDiff) > 10) {
            if (Math.abs(xDiff) > Math.abs(yDiff)) {
                if (xDiff > 0) {

                    this.slideFunction(1);

                } else {
                    this.slideFunction(-1);
                }
            }
            this.xDown = null;
            this.yDown = null;
        }
    };

    render() {
        return (
            <div className={`vra-custom-select-container ${this.props.className ? this.props.className : ""}`} ref={this.sliderComponent}>
                {
                    this.props.children
                }
            </div>
        );
    }
}

export default ImageSliderComponent;
