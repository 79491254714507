import React, {Component} from 'react';
import {Tooltip} from "@chakra-ui/tooltip";
import {connect} from "react-redux";

class DisabledHoverComponent extends Component {
   state={
      isMobile:false
   }
   componentDidMount() {
      this.mobileResizer()
      window.addEventListener("resize",this.mobileResizer)
   }
   componentWillUnmount() {
      window.removeEventListener("resize",this.mobileResizer)
      this.mobileResizer()
   }

   mobileResizer = () => {
      if(window.innerWidth>641){
         this.setState({
            isMobile:false
         })
      }else{
         this.setState({
            isMobile:true
         })
      }
   }
   render() {
      let {isDisabled} = this.props;
      return (
          <Tooltip textAlign="center" placement="top" fontSize="12px" fontFamily="opSemiBold" pt="8px" pb="8px"
                   pr="16px" pl="16px" borderRadius={4} width="100%" isDisabled={window.innerWidth > 640 && !isDisabled}
                   hasArrow label="" bg='white' defaultIsOpen={this.state.isMobile}
                   color='#414141'>
             {this.props.children}
          </Tooltip>
      );
   }
}

const mapStateToProps = (state) => ({
   setSinkSizes: state.userSelectionReducer.setSinkSizes || null,
   activeLang: state.commonReducer.activeLang,
   language: state.languageReducer[state.commonReducer.activeLang],
})
export default connect(mapStateToProps)(DisabledHoverComponent);
