import React, { Component, Fragment } from 'react';
import ImageSliderComponent from "./imageSliderComponent";
import { connect } from "react-redux";
import {
   setMirrorSize,
} from "../../actions";

class RespMirrorSizeSelectionComponent extends Component {
   sliderComponent = React.createRef();
   state = {
      isMobile: false,
      activeIndex: null
   }

   changeSelectedItemByIndex = (index) => {
      let sizeItem = this.props.clickedMirrorStyle.mirror_style_sizes[index]
      this.setState({
         activeIndex: null
      })
      this.props.dispatch(setMirrorSize(sizeItem))
   }

   setSize = (sizeItem) => {
      let activeIndex = this.props.clickedMirrorStyle.mirror_style_sizes.findIndex(item => item.id === sizeItem.id)
      this.setState({
         activeIndex
      })
      this.props.dispatch(setMirrorSize(sizeItem))
   }
   resize = () => {
      if (window.innerWidth > 640) {
         this.setState({
            isMobile: false
         })
      } else {
         this.setState({
            isMobile: true
         })
      }
   }

   componentDidMount() {
      this.resize();
      window.addEventListener("resize", this.resize)
      if (this.props.setMirrorType) {
         let activeIndex = this.props.clickedMirrorStyle.mirror_style_sizes.findIndex(item => item.id === this.props.setMirrorType.id)
         this.setState({
            activeIndex
         })
      }
   }

   componentDidUpdate(prevProps) {
      if (prevProps.clickedMirrorStyle && this.props.clickedMirrorStyle && this.props.clickedMirrorStyle.id !== prevProps.clickedMirrorStyle.id) {
         this.setState({
            activeIndex: 0
         })
      }
   }

   componentWillUnmount() {
      window.removeEventListener("resize", this.resize)
      this.resize();
   }

   render() {
      let { mirrorSize, activeLang, clickedMirrorStyle, language } = this.props;
      return (
         <div className="vra-selections-block vra-mobile-block vra-sizes">
            {
               (clickedMirrorStyle && clickedMirrorStyle.mirror_style_sizes.length > 0) &&
               <div className="vra-tooltip-item">
                  <span>{language.size}</span>
               </div>
            }

            {
               (clickedMirrorStyle && clickedMirrorStyle.mirror_style_sizes.length > 0) &&
               <ImageSliderComponent ref={this.sliderComponent}
                  changeItem={this.changeSelectedItemByIndex}
                  activeIndex={this.state.activeIndex}
               >
                  {
                     clickedMirrorStyle.mirror_style_sizes.sort((a,b)=>parseInt(a.size.name) > parseInt(b.size.name) ? 1 : parseInt(b.size.name) > parseInt(a.size.name) ? -1 :0 ).map((sizeItem, index) =>
                        <div key={index}
                           className={`vra-option-item vra-color-option ${mirrorSize && this.state.isMobile && mirrorSize.id === sizeItem.id ? "active" : ""}`}
                           onClick={() => {
                              this.setSize(sizeItem);
                           }}>
                           <div className="vra-round-item">
                              <p><span>{JSON.parse(sizeItem.size.transition)[activeLang].split(" ")[0]}</span>{JSON.parse(sizeItem.size.transition)[activeLang].split(" ")[1]}</p>
                           </div>
                           {/*<span>{JSON.parse(_sizes.size_groups.transition)[activeLang]}</span>*/}
                        </div>
                     )
                  }


               </ImageSliderComponent>
            }


         </div>
      );
   }
}
const mapStateToProps = state => ({
   setMirrorType: state.userSelectionReducer.setMirrorType,
   mirrorSize: state.userSelectionReducer.mirrorSize,
   language: state.languageReducer[state.commonReducer.activeLang],
   activeLang: state.commonReducer.activeLang,
   clickedMirrorStyle: state.mirrorReducer.mirrorType && state.userSelectionReducer.setMirrorType ? (state.mirrorReducer.mirrorType.find(item => item.id === state.userSelectionReducer.setMirrorType.id) || state.mirrorReducer.mirrorType[0]) : null

})
export default connect(mapStateToProps)(RespMirrorSizeSelectionComponent);
