import React, {Component} from 'react';
import {getElementWidth} from "../../helper";
import DotsComponent from "./dotsComponent";
import {connect} from "react-redux";

class sliderComponent extends Component {
   sliderContainer = React.createRef();
   activeIndex = 0
   // showingCount = window.innerWidth>500?3:1
   showingCount = 3
   offset = 0.2
   translateValue = 0
   itemWidth = 0
   containerWidth=0
   maxTranslateValue=1;
   state={
      showingCount:3
   }

   componentDidMount() {
      // debugger;
      setTimeout(this.calculateItem,1000)
      this.sliderContainer.current.addEventListener('touchstart', this.handleTouchStart, false);
      this.sliderContainer.current.addEventListener('touchmove', this.handleTouchMove, false);
   }

   componentWillUnmount() {
      window.removeEventListener("resize", this.resize)
      this.sliderContainer.current.removeEventListener('touchstart', this.handleTouchStart, false);
      this.sliderContainer.current.removeEventListener('touchmove', this.handleTouchMove, false);
   }

   calculateItem = () => {

      if (this.sliderContainer.current&&this.sliderContainer.current.children) {

         this.itemWidth = getElementWidth(this.sliderContainer.current.children[1])
         this.containerWidth = this.sliderContainer.current.offsetWidth
         this.setState({
            showingCount:Math.floor((this.containerWidth / this.itemWidth) + this.offset)
         })
      }
   }
   slideFunction = (direction) => {
      if ((direction === -1 && this.activeIndex === 0) || (direction === 1 && this.maxTranslateValue < this.translateValue)) {
         return;
      }
      this.activeIndex = this.activeIndex + direction
      this.setTranslateValue()
   }
   resize = () => {
      this.setTranslateValue()
   }
   setTranslateValue = () => {
      this.calculateItem()
     /* if(this.state.showingCount<2){
         this.translateValue= this.activeIndex * Math.round((this.itemWidth-((window.innerWidth/2)-(this.itemWidth/2)))) * this.state.showingCount
         this.maxTranslateValue = (this.sliderContainer.current.children.length * this.itemWidth) - this.sliderContainer.current.offsetWidth - 24
         this.forceUpdate()
         return;
      }*/
      this.translateValue = this.activeIndex * this.itemWidth * this.state.showingCount
      /*//todo: margin dinamikm olucak*/
      this.maxTranslateValue = (this.sliderContainer.current.children.length * this.itemWidth) - this.sliderContainer.current.offsetWidth - 24
      this.forceUpdate()
   }
   handleTouchStart = (evt) => {
      this.xDown = evt.touches[0].clientX;
      this.yDown = evt.touches[0].clientY;
   };

   handleTouchMove = (evt) => {
      if (!this.xDown || !this.yDown) {
         return;
      }
      this.xUp = evt.touches[0].clientX;
      this.yUp = evt.touches[0].clientY;

      let xDiff = this.xDown - this.xUp;
      let yDiff = this.yDown - this.yUp;
      if (Math.abs(xDiff) + Math.abs(yDiff) > 10) {
         if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
               this.slideFunction(1);

            } else {
               this.slideFunction(-1);

            }
         }
         this.xDown = null;
         this.yDown = null;
      }
   };
   resetSliderValues=()=>{
      this.activeIndex = 0
      this.translateValue = 0
   }
   componentDidUpdate(prevProps, prevState, snapshot) {
      if(prevProps.activeLang !== this.props.activeLang){
         this.resetSliderValues()
         this.calculateItem()
      }
   }

   render() {
      let {language}=this.props
      let totalDots=Math.floor(this.props.children&&this.props.children.length/this.state.showingCount)
      // let newTotalDots= this.state.showingCount===2 && this.maxTranslateValue < this.translateValue ? totalDots+1:totalDots
      return (
          <div className="vra-attention-container safe-area">
             <div className="vra-text-block">
                {
                   language &&
                   <h3>{language.interesting_products}</h3>
                }
                <div className="vra-arrow-item">
                   <div
                       className={`vra-product-arrow right  ${this.props.children && ((this.activeIndex+1)*this.state.showingCount) >= (this.props.children.length) ? "" : "active"}`}
                       onClick={()=>this.slideFunction(1)}>
                      <i className="landing-arrow"/>
                   </div>
                   <div className={`vra-product-arrow left ${this.activeIndex !== 0 ? "active" : ""}`} onClick={()=>this.slideFunction(-1)}>
                      <i className="landing-arrow"/>
                   </div>
                </div>
             </div>
             <div className="vra-product-block" ref={this.sliderContainer}
                  style={{transform: `translateX(-${this.maxTranslateValue < this.translateValue ? this.maxTranslateValue : this.translateValue}px)`}}>
                {
                   this.props.children
                }
             </div>
             {/*<DotsComponent children={this.props.children} showingCount={this.showingCount} activeIndex={this.activeIndex}/>*/}
             {
                totalDots ?
                <div className="vra-dots">
                   {
                      Array.from({length:totalDots}).map((_,index)=>
                          <div className={`vra-dot ${this.activeIndex===index?"active":""}`} key={index}/>
                      )
                   }
                </div>
                    :null
             }
          </div>
      );
   }
}
const mapStateToProps = (state) => ({
   language: state.languageReducer[state.commonReducer.activeLang],
   activeLang:state.commonReducer.activeLang
})

export default connect(mapStateToProps)(sliderComponent);
