import React, {Component} from 'react';

class DotsComponent extends Component {
   constructor(props) {
      super(props);

   }
   render() {
      let{activeIndex,children,showingCount}=this.props
      let totalDots=Math.floor(children&&children.length/this.props.showingCount)
      return (
          <div className="vra-dots">
             {
                Array.from({length:totalDots}).map((_,index)=>
                    <div className={`vra-dot ${activeIndex===index?"active":""}`} key={index}/>
                )
             }
          </div>
      );
   }
}

export default DotsComponent;
