import {GET_TALL_CABINETS} from "../constants/actionTypes";

const initialState = {tallCabinets: []};

const tallCabinetReducer = (state = initialState, action) => {
   switch (action.type) {
      case GET_TALL_CABINETS:
         return {
            ...state,
            tallCabinets: action.payload
         }
      default:
         return state
   }
}
export default tallCabinetReducer;
