import React, {Component} from 'react';
import {Box, Text, Wrap, WrapItem, Center, Heading} from "@chakra-ui/react"

class LackSizeComponent extends Component {

   render() {
      let sizeArr = [];
      let {sizes, selectSinkTypeId, isProductImage} = this.props
      sizes.map(item => {
         item.sink_sizes.map(sizeItem => {
            if (sizeItem.sink_type_sizes.findIndex(x => x.sink_type_id == selectSinkTypeId) === -1) {
               sizeArr.push(sizeItem.name)
            }
         })
      })
      return (
          <Box className={`${isProductImage ? "vra-lack-root" : ""}`} m="24px" borderRadius={8} bg="#EEEEEE" p="14px">
             <Text fontFamily="opSemiBold" fontSize="14px">
                Klasik lavabo seçtiğiniz için aşağıdaki ölçüler seçilememektedir.
             </Text>
             <Wrap mt="8px">

                {
                   sizeArr.map((size, index) =>
                       <WrapItem mr="4px" >
                          <Center whiteSpace="nowrap" key={index} fontFamily="opSemiBold" borderRadius={46}
                                  color="white" pl={4} pr={4}
                                  fontSize="14px"
                                  w="100%" h="20px" bg="#414141">
                             {size}
                          </Center>
                       </WrapItem>
                   )
                }

             </Wrap>
          </Box>
      );
   }
}

export default LackSizeComponent;
