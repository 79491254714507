import React, { Component } from 'react'
// import { ASSETS } from '../../constants/paths';

 class ImageModalComponent extends Component {
  render() {
   let {bathPicture} = this.props;
    return (
      <div className="vra-lb-root road-show-root">
      <div className="vra-lb-wrapper">
         <div className="vra-lb-container" >
         <i className="gg-close" onClick={this.props.toggleModal}/>
            <div className="road-show-img-block">
               <picture>
                  <img src={bathPicture} alt="road-show" />
               </picture>
            </div>
         </div>
      </div>
   </div>
    )
  }
}

export default ImageModalComponent
