import React, { Component } from "react";
import { Link } from "react-router-dom";
import { DE_HOME_PAGE, HOME_PAGE, ROADSHOWLINK, ROADSHOW_HOME_PAGE } from "../../constants/routePaths";
import BannerSliderComponent from "./bannerSliderComponent";
import RandomBtnComponent from "./randomBtnComponent";
import { ASSETS } from "../../constants/paths";
import BannerSlideImgComponent from "./bannerSlideImgComponent";
import {connect} from "react-redux";

class BannerComponent extends Component {
   activeIndex = 0;
   translateValue;
   bannerSliderComponent = React.createRef();
   imgItem = React.createRef();
   changeActiveIndex = (direction) => {
      //TODO: data length'inden cekilecek.

      let totalCount = this.props.bannerImg.filter(this.filteredByLang).length-1;
      if ((this.activeIndex + direction) < 0) {
         let activeElement = document.getElementsByClassName("vra-img-item")[totalCount + 2];
         this.translateValue = activeElement.offsetLeft - (this.bannerSliderComponent.current.slideContainer.current.offsetWidth / 2) + (activeElement.offsetWidth / 2);
         this.bannerSliderComponent.current.slideBlock.current.style.transition = "0s";
         this.bannerSliderComponent.current.slideBlock.current.style.transform = `translate3d(${-this.translateValue}px, 0px, 0px)`;
         setTimeout(() => {
            this.bannerSliderComponent.current.slideBlock.current.style.transition = null;
            this.bannerSliderComponent.current.slideBlock.current.style.transform = `translate3d(${-this.translateValue}px, 0px, 0px)`;
            this.activeIndex = totalCount;
            this.forceUpdate();
         },0)
         return;
      }

      if ((this.activeIndex + direction) > totalCount) {
         let activeElement = document.getElementsByClassName("vra-img-item")[0];
         this.translateValue = activeElement.offsetLeft - (this.bannerSliderComponent.current.slideContainer.current.offsetWidth / 2) + (activeElement.offsetWidth / 2);
         this.bannerSliderComponent.current.slideBlock.current.style.transition = "0s";
         this.bannerSliderComponent.current.slideBlock.current.style.transform = `translate3d(${-this.translateValue}px, 0px, 0px)`;
         setTimeout(() => {
            this.bannerSliderComponent.current.slideBlock.current.style.transition = null;
            this.bannerSliderComponent.current.slideBlock.current.style.transform = `translate3d(${-this.translateValue}px, 0px, 0px)`;
            this.activeIndex = 0;
            this.forceUpdate();
         },0)
         return;
      }
      this.activeIndex = this.activeIndex + direction;
      this.forceUpdate();
   }
   resetTranslateValue=()=>{
      this.activeIndex = 0;
      this.translateValue = 0
      this.forceUpdate()
   }
   componentDidUpdate(prevProps, prevState, snapshot) {
      if(prevProps.activeLang !== this.props.activeLang){
         this.resetTranslateValue()
      }
   }

   openConfigurator = () => {
      this.props.history.push(window.location.pathname.indexOf("/rootkonfigurator")!==-1?DE_HOME_PAGE.replace(":lang", this.props.activeLang) : (window.location.pathname.indexOf(ROADSHOWLINK)!==-1 ? ROADSHOW_HOME_PAGE.replace(":lang", this.props.activeLang) : HOME_PAGE.replace(":lang",this.props.activeLang) ))
   }
   setDataLayer = () => {
      if(!window.dataLayer) window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
         'event': 'configurator',
         'buttonName': 'Hemen Başlayın',//Burası rastgele tasarım oluştur olarakta gelebilir.
         'configuratorStep' : '1'
      });
   }
   filteredByLang=(imgItem)=>imgItem.available_countries.indexOf(this.props.activeLang)>-1

   render() {
      let{bannerImg,language,activeLang}=this.props;
      return (
         <div className="vra-banner-root">
            <div className="vra-banner-container safe-area">
               {
                  language &&
                  <div className="vra-text-block">
                     <h1 className={`${this.props.activeLang==="ru"?"ru":""}`}>{language.banner_title}</h1>
                     <p className={`${this.props.activeLang==="ru"?"ru":""}`}>{language.banner_desc}</p>
                     <button onClick={()=>{
                        this.openConfigurator()
                        this.setDataLayer()
                     }} className="vra-btn">{language.lets_start}</button>
                     <RandomBtnComponent />
                  </div>
               }
               <div className="vra-slider-wrapper">
                  <div className="vra-mask-item right" />
                  {/* TODO --> active classı seçili olan oku temsil eder */}
                  <div className="vra-arr-item right active" onClick={() => {
                     this.changeActiveIndex(-1)
                  }}>
                     <div className="gg-arrow " />
                  </div>
                  {
                     (bannerImg && bannerImg.length > 0) &&
                     <BannerSliderComponent ref={this.bannerSliderComponent} changeActiveIndex={this.changeActiveIndex} activeIndex={this.activeIndex}>
                        <BannerSlideImgComponent ref={this.imgItem} {...bannerImg.filter(this.filteredByLang)[bannerImg.filter(this.filteredByLang).length- 1]} activeIndex={this.activeIndex} index={bannerImg.length - 1} />
                        {
                           bannerImg.filter(this.filteredByLang).map((img, index) =>
                              <BannerSlideImgComponent key={index} {...img} activeIndex={this.activeIndex} index={index} />
                           )
                        }
                        <BannerSlideImgComponent {...bannerImg.filter(this.filteredByLang)[0]} activeIndex={this.activeIndex} index={0} />
                        <BannerSlideImgComponent {...bannerImg.filter(this.filteredByLang)[1]} activeIndex={this.activeIndex} index={1} />
                     </BannerSliderComponent>

                  }
                  <div className="vra-arr-item left active" onClick={() => {
                     this.changeActiveIndex(1)
                  }}>
                     <div className="gg-arrow " />
                  </div>
                  <div className="vra-mask-item" />
               </div>
            </div>
         </div>
      )
   }
}
const mapStateToProps=(state)=>({
   bannerImg: state.commonReducer.landingData ? state.commonReducer.landingData.banner : [],
   activeLang: state.commonReducer.activeLang,
   language: state.languageReducer[state.commonReducer.activeLang]
})
export default connect(mapStateToProps) (BannerComponent)
