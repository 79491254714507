import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import CoverStyleModelComponent from "./coverStyleModelComponent";
import {checkIsLoading, getCoverStylesAction, setCoverColour, setCoverStyle, setCoverType} from "../../actions";
import {ASSETS} from "../../constants/paths";


class CoverModelSelectionComponent extends Component {
   sliderComponent=React.createRef();

   constructor(props) {
      super(props);
      this.state={
         id:null,
         mobileTypeSelectionId:null,
         selectedItem:this.props.setCoverStyle
      }
   }


   componentDidMount() {
      this.props.dispatch(getCoverStylesAction())
      if(!this.props.coverStyleData || this.props.coverStyleData.length ===0){
         this.props.dispatch(checkIsLoading(true))
      }
     /* if(this.props.setCoverStyle){

      }*/
   }

   componentDidUpdate(prevProps){

      /*if((!prevProps.coverStyleData || prevProps.coverStyleData.length === 0) && (this.props.coverStyleData && this.props.coverStyleData.length>0) && !this.props.setCoverStyle){

      }*/

      if((!prevProps.coverStyleData || prevProps.coverStyleData.length === 0) && (this.props.coverStyleData && this.props.coverStyleData.length>0)){
         this.props.dispatch(checkIsLoading(false))
      }
   }
   changeSelectedItemByIndex=(index)=>{
      if(window.innerWidth<641){
         this.props.dispatch(setCoverColour(this.props.coverStyleData[index].color_groups[0].colors[0]))
         this.props.dispatch(setCoverStyle(this.props.coverStyleData[index]))
         this.props.dispatch(setCoverType(this.props.coverStyleData[index].color_groups[0]))
      }else{
         this.props.dispatch(setCoverStyle(this.props.coverStyleData[index]))
      }
   }



   handleOnChange = (item) => {

      /*if(window.innerWidth<641){
         this.props.dispatch(setCoverColour(item.color_groups[0].colors[0]))
         this.props.dispatch(setCoverStyle(item))
         this.props.dispatch(setCoverType(item.color_groups[0]))
      }*/
      // this.props.dispatch(changeModelsModal({group:this.props.modelsCovers.group, coverStyle:item, colors:this.props.modelsCovers.colors}))
      this.setState({
         selectedItem:item
      })

   }

   render() {
      let{coverStyleData,isLoading,language}=this.props;
      return (
          <Fragment>
             {
                (coverStyleData && coverStyleData.length>0 && !isLoading) &&
                <div className="vra-selections-container">

                   <div className="vra-title-head-item">
                      {
                         language &&
                         <h3>{language.choose_style} </h3>
                      }
                   </div>
                   <div className="vra-custom-select-container ">
                      {
                         coverStyleData.map((coverStyle,index)=>
                             <CoverStyleModelComponent selectedItem={this.state.selectedItem}  handleOnChange={this.handleOnChange} coverStyle={coverStyle} key={index}/>
                         )
                      }
                   </div>

                </div>
             }

             {/*{
                isLoading&&
                <SkeletonComponent/>
             }*/}

          </Fragment>
      );
   }
}
const mapStateToProps=(state)=>({
   coverStyleData:state.sinkReducer.coverStyles || [],
   setCoverStyle:state.userSelectionReducer.setCoverStyle,
   isLoading:state.commonReducer.isLoading,
   language: state.languageReducer[state.commonReducer.activeLang],
   activeLang:state.commonReducer.activeLang
})
export default connect(mapStateToProps) (CoverModelSelectionComponent);
