import React, {Component, Fragment} from 'react';
import {
   getCoverStylesAction,
   setCoverStyle,
   setCoverColour,
   setCoverType, checkIsLoading
} from "../actions";
import {connect} from "react-redux";
import MobileSliderComponent from "./mobile/mobileSliderComponent";
import SkeletonComponent from "./common/skeletonComponent";
import CoverStyleSelectionComponent from "./coverStyleSelectionComponent";
import {withRouter} from "react-router-dom";
import {ASSETS} from "../constants/paths";


class TypeSelectionComponent extends Component {
   sliderComponent = React.createRef();

   state = {
      id: null,
      mobileTypeSelectionId: null
   }

   componentDidMount() {
      /*if(this.props.coverStyleData && this.props.coverStyleData.length ===0){
         this.props.dispatch(getCoverStylesAction())
      }*/
      this.props.dispatch(getCoverStylesAction())
      if (!this.props.coverStyleData || this.props.coverStyleData.length === 0) {
         this.props.dispatch(checkIsLoading(true))
      }
      if (this.props.setCoverStyle) {

      }
   }

   componentDidUpdate(prevProps) {

      /*if((!prevProps.coverStyleData || prevProps.coverStyleData.length === 0) && (this.props.coverStyleData && this.props.coverStyleData.length>0) && !this.props.setCoverStyle){

         this.props.dispatch(setCoverStyle(this.props.coverStyleData[0]))

      }*/
      if (prevProps.activeLang !== this.props.activeLang) {
         this.props.dispatch(getCoverStylesAction())
      }
      if ((!prevProps.coverStyleData || prevProps.coverStyleData.length === 0) && (this.props.coverStyleData && this.props.coverStyleData.length > 0)) {
         this.props.dispatch(checkIsLoading(false))
      }

   }

   changeSelectedItemByIndex = (index) => {
      if (window.innerWidth < 641) {
         this.props.dispatch(setCoverColour(this.props.coverStyleData[index].color_groups[0].colors[0]))
         this.props.dispatch(setCoverStyle(this.props.coverStyleData[index]))
         this.props.dispatch(setCoverType(this.props.coverStyleData[index].color_groups[0]))
      } else {
         this.props.dispatch(setCoverStyle(this.props.coverStyleData[index]))
      }
   }



   render() {
      let {coverStyleData, isLoading, language} = this.props;
      return (
          <Fragment>
             {
                (coverStyleData && coverStyleData.length > 0 && !isLoading) &&
                <div className="vra-selections-container">

                   <div className="vra-title-head-item">
                      {
                         language &&
                         <h3>{language.choose_style} </h3>
                      }
                   </div>
                   {

                      <MobileSliderComponent ref={this.sliderComponent}
                                             changeItem={this.changeSelectedItemByIndex}
                                             activeIndex={coverStyleData && coverStyleData.length > 0 && this.props.setCoverStyle ? coverStyleData.findIndex(item => item.id === this.props.setCoverStyle.id) : 0}>
                         {
                            coverStyleData.map((coverStyle, index) =>
                                <CoverStyleSelectionComponent

                                    handleOnChange={this.handleOnChange}
                                                              stateId={this.state.id} coverStyle={coverStyle}
                                                              key={index}/>
                            )
                         }
                      </MobileSliderComponent>
                      /*<MobileSliderComponent ref={this.sliderComponent} changeItem={this.changeSelectedItemByIndex} activeIndex={coverStyleData && coverStyleData.length>0 && setCoverStyle ? coverStyleData.findIndex(item=>item.id===setCoverStyle.id):0}>
                         {
                            coverStyleData.map((item,index)=>
                                <div className={`vra-selections-item  ${setCoverStyle&&setCoverStyle.id===item.id ? "active":""} `}  key={index} onClick={()=>this.handleOnChange(item)}>
                                   <div className="vra-img-item vra-slk">
                                      <picture>
                                         <img src={`${ASSETS}${item.select_img}`} alt={index.toString()}/>
                                      </picture>
                                   </div>
                                   <span>{JSON.parse(item.transition)[activeLang]||item.name}</span>
                                </div>
                            )
                         }
                      </MobileSliderComponent>*/
                   }
                </div>
             }

             {
                isLoading &&
                <SkeletonComponent/>
             }

          </Fragment>
      );
   }
}

const mapStateToProps = (state) => ({
   // setCoverColour: state.userSelectionReducer.setCoverColour,
   coverStyleData: state.sinkReducer.coverStyles || [],
   setCoverStyle: state.userSelectionReducer.setCoverStyle,
   // selectedColorId:state.userSelectionReducer.setCoverColour?state.userSelectionReducer.setCoverColour.id:null,
   isLoading: state.commonReducer.isLoading,
   language: state.languageReducer[state.commonReducer.activeLang],
   activeLang: state.commonReducer.activeLang

})
export default withRouter(connect(mapStateToProps)(TypeSelectionComponent));
