import React, {Component} from 'react';
import {setArmatureColour, setArmatureType, setCoverColour,changeSelectedModulesAction} from "../../actions";
import {connect} from "react-redux";
import {ASSETS} from "../../constants/paths";

class ColourOptionSelectComponent extends Component {
   handleOnclick = (color) => {
      if (this.props.isArmature) {
         if(!this.props.setArmatureColour){
            this.props.dispatch(changeSelectedModulesAction("armatureStyleData"))
         }
         this.props.dispatch(setArmatureColour(color))
         this.props.dispatch(setArmatureType(this.props.armature_type))
      } else {
         this.props.dispatch(setCoverColour(color))
      }
   }

   render() {
      let {colors, setCoverColour, setArmatureColour,activeLang} = this.props;
      return (
          <div className="vra-option-block vra-armature-block">
             {/*todo: armatür neden var ? soruclacak {Emre}*/}
             {

                colors && colors.map((color, index) =>
                    <div
                        className={`vra-option-item ${((setCoverColour && setCoverColour.id === color.id) || (setArmatureColour && setArmatureColour.id === color.id)) ? "active" : ""}`}
                        key={index.toString()} onClick={(e) => {
                       this.handleOnclick(color)
                       e.preventDefault()
                       e.stopPropagation()

                        }}>
                       <div className="vra-round-item">
                          <picture>
                             <source srcSet={`${ASSETS}${color.picture.substr(0,color.picture.indexOf("."))+".webp"}`}  type="image/webp"/>
                             <img src={`${ASSETS}${color.picture}`} alt={`armature-${index}`}/>
                          </picture>
                       </div>
                       <span>{ ((color.transition && JSON.parse(color.transition)[activeLang]) || color.name) || (JSON.parse(color.armature_color.transition)[activeLang] || color.armature_color.name)}</span>
                    </div>
                )
             }
          </div>
      );
   }
}

export default connect(state => ({
   setCoverColour: state.userSelectionReducer.setCoverColour,
   setArmatureColour: state.userSelectionReducer.setArmatureColour,
   activeLang:state.commonReducer.activeLang
}))(ColourOptionSelectComponent);
