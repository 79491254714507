import React, {Component} from 'react';
import {connect} from "react-redux";
import {changeModelsModal, setCoverColour, setCoverStyle, setCoverType} from "../../actions";
import {ASSETS} from "../../constants/paths";
import CoverOptionModelComponent from "./coverOptionModelComponent";

class CoverStyleModelComponent extends Component {
   selectBlock=React.createRef();
   constructor(props) {
      super(props);
      this.props.dispatch(changeModelsModal({group:this.props.setCoverType, coverStyle:this.props.setCoverStyle, colors:this.props.setCoverColour}))

   }
   setColours = (colorGroup,coverStyle,color) => {
      this.props.dispatch(setCoverColour(color))
      this.props.dispatch(setCoverStyle(coverStyle))
      this.props.dispatch(setCoverType(colorGroup))
   }


   render() {
      let{coverStyle,index,activeLang,modelsCoverStyle,selectedItem}=this.props;
      return (
          <div ref={this.selectBlock} className={`vra-custom-select-block`}  key={index} onClick={()=> {
             this.props.handleOnChange(coverStyle);
          }}>
             <div className={`vra-custom-select-item ${(selectedItem&&selectedItem.id === coverStyle.id)  ? "active":""}`}>
                <div className="vra-img-item">
                   <picture>
                      <source
                          srcSet={`${ASSETS}${coverStyle.select_img.substr(0,coverStyle.select_img.lastIndexOf("."))+".webp"}`}
                          type="image/webp"/>
                      <source
                          srcSet={`${ASSETS}${coverStyle.select_img.substr(0,coverStyle.select_img.lastIndexOf("."))+".png"}`}
                          type="image/png"/>
                      <img src={`${ASSETS}${coverStyle.select_img}`} alt={index}/>
                   </picture>
                </div>
                <div className="vra-text-item">
                   <p>{JSON.parse(coverStyle.transition)[activeLang]||coverStyle.name}</p>
                </div>
             </div>
             {
               (this.props.coverStyleData && selectedItem&&selectedItem.id === coverStyle.id) &&
                <div className="vra-option-container">
                   {
                      (this.props.coverStyleData.find(item=>item.id==selectedItem.id) && this.props.coverStyleData.find(item=>item.id==selectedItem.id).color_groups)&&this.props.coverStyleData.find(item=>item.id==selectedItem.id).color_groups.map((group,i)=>
                          <CoverOptionModelComponent selectedCoverByModel={this.props.selectedCoverByModel} selectedItem={{...selectedItem}}  key={i} coverStyle={coverStyle} setColours={this.setColours} group={group}/>
                      )
                   }
                </div>
             }
          </div>
      );
   }
}
const mapStateToProps=state=>({
   setCoverColour: state.userSelectionReducer.setCoverColour,
   setCoverStyle:state.userSelectionReducer.setCoverStyle,
   setCoverType:state.userSelectionReducer.setCoverType,
   activeLang: state.commonReducer.activeLang,
   coverStyleData:state.sinkReducer.coverStyles || [],
   modelsCoverStyle:state.userSelectionReducer.modelsChanges ? state.userSelectionReducer.modelsChanges.coverStyle : {},
   modelsCovers:state.userSelectionReducer.modelsChanges
})
export default connect(mapStateToProps) (CoverStyleModelComponent);

