import React, {Component} from 'react';
import {toggleCollapseMenu} from "../actions";
import {connect} from "react-redux";
class MenuButtonComponent extends Component {
   handleOnclick = () => {
      this.props.dispatch(toggleCollapseMenu(!this.props.isCollapseMenuOpen))
   }
   render() {
      return (
          <div className="vra-menu-button-block" onClick={this.handleOnclick}>
             <div className="bar1"/>
             <div className="bar2"/>
             <div className="bar3"/>
          </div>
      );
   }
}
const mapStateToProps=(state)=>({
   isCollapseMenuOpen: state.commonReducer.isCollapseMenuOpen
})
export default connect(mapStateToProps) (MenuButtonComponent);
