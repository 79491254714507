import React, { Component } from 'react';
import { ASSETS } from "../constants/paths";
import { connect } from "react-redux";
import { setMirrorSize, setMirrorType, setMirrorBacklight, changeMirrorSelectionAction } from "../actions";
import MirrorOptionComponent from "./mirrorOptionComponent";

class MirrorSelectBoxComponent extends Component {
   selectBlock = React.createRef();
   state = {
      id: null
   }
   componentDidMount() {
      window.addEventListener("click", this.closeOutsideClick, false)
   }
   componentWillUnmount() {
      window.removeEventListener("click", this.closeOutsideClick, false)
   }
   closeOutsideClick = (e) => {
      if (this.selectBlock.current && !this.selectBlock.current.contains(e.target)) {
         this.setState({
            id: null
         })
      }
   }
   handleOnChange = (item) => {
      if((item.mirror_style_sizes && item.mirror_style_sizes.length > 1) || item.with_backlight){
         if (this.state.id === item.id) {
            this.setState({
               id: null
            })
         } else {
            this.setState({
               id: item.id
            })
         }
      }else{
         this.props.dispatch(setMirrorType(item))
         this.props.dispatch(setMirrorBacklight({ light: false, id: item.id }))
         if (item.mirror_style_sizes.length > 0) {
            this.props.dispatch(setMirrorSize(item.mirror_style_sizes[0]))
            if (this.props.resMirrorSelection !== "mirror_group") {
               this.props.dispatch(changeMirrorSelectionAction("mirror_group"))
            }

         }
      }
      
      if (window.innerWidth < 641) {

         this.props.dispatch(setMirrorType(item))
         this.props.dispatch(setMirrorBacklight({ light: (item.mirror_style_sizes && item.mirror_style_sizes.length > 1) || item.with_backlight? true:false, id: item.id }))
         if (item.mirror_style_sizes.length > 0) {
            this.props.dispatch(setMirrorSize(item.mirror_style_sizes[0]))
            if (this.props.resMirrorSelection !== "mirror_group") {
               this.props.dispatch(changeMirrorSelectionAction("mirror_group"))
               // this.props.dispatch(setMirrorSize(item.mirror_style_sizes[0]))
            }

         } else if((item.mirror_style_sizes && item.mirror_style_sizes.length > 1) || item.with_backlight){
            this.props.dispatch(changeMirrorSelectionAction("light"))
         }
      }
      /*todo: sorulacak*/
      /*if (!this.props.setMirrorType) {
         this.props.dispatch(changeSelectedModulesAction("mirrorData"))
      }
      if (item.with_backlight) {
         this.setBacklight(item.with_backlight)
      } else {
         this.setBacklight(null)
      }
      if (item.mirror_style_sizes) {
         this.setSize(item.mirror_style_sizes[0])
      } else {
         this.setSize(null)
      }*/
   }
   render() {
      let { item, index, setMirrorType, activeLang } = this.props;
      return (
         <div ref={this.selectBlock} key={index}
            className={`vra-custom-select-block  ${this.state.id === item.id ? "opened" : ""} ${setMirrorType && setMirrorType.id === item.id && (window.innerWidth < 641 || !this.state.id) ? "active" : ""}`}
            onClick={() => {this.handleOnChange(item)}}>
            <div
               className={`vra-custom-select-item  ${setMirrorType && setMirrorType.id === item.id && item.mirror_style_sizes.length < 1 && !item.with_backlight ? "active" : ""}`}
               key={index} >
               {
                  ((item.mirror_style_sizes && item.mirror_style_sizes.length > 1) || item.with_backlight) &&
                  <i className="gg-chevron" />
               }
               <div className="vra-img-item">
                  <picture>
                     <source srcSet={`${ASSETS}${item.img && item.img.substr(0, item.img.indexOf(".")) + ".webp"}`} type="image/webp" />
                     <source srcSet={`${ASSETS}${item.img.substr(0,item.img.indexOf("."))+".png"}`}  type="image/png"/>
                     <img src={`${ASSETS}${item.img}`} alt={index} />
                  </picture>
               </div>
               <div className="vra-text-item"><p>{JSON.parse(item.transition)[activeLang] || item.name}</p></div>
            </div>
            {
               ((item.mirror_style_sizes && item.mirror_style_sizes.length > 1) || item.with_backlight) &&
               <MirrorOptionComponent  item={item} setSize={this.props.setSize} index={index} />
            }
         </div>
      );
   }
}
const mapStateToProps = state => ({
   mirrorType: state.mirrorReducer.mirrorType || [],
   setMirrorType: state.userSelectionReducer.setMirrorType,
   mirrorSize: state.userSelectionReducer.mirrorSize,
   coverTypes: state.sinkReducer.coverTypes || [],
   setCoverSelectionType: state.userSelectionReducer.setCoverSelectionType,
   withBacklight: state.userSelectionReducer.mirrorWithBackLight,
   activeLang: state.commonReducer.activeLang,
   language: state.languageReducer[state.commonReducer.activeLang],
   resMirrorSelection: state.productStepReducer.resMirrorSelection

})
export default connect(mapStateToProps)(MirrorSelectBoxComponent);
