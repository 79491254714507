import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {toggleCollapseMenu} from "../actions";

class ShowingChosenItemComponent extends Component {
   handleOnclick = () => {
      this.props.dispatch(toggleCollapseMenu(!this.props.isCollapseMenuOpen))
   }
   state={
      showUp:true
   }
   componentDidMount() {
      setTimeout(()=>this.setState({
         showUp:false
      }),3000)
   }

   render() {
      let {productStepNumber, language} = this.props;


      return (
          <Fragment>
             {
                (productStepNumber < 5) ?
                    <div className="vra-count-block" onClick={this.handleOnclick}>
                       <div className="vra-count-item">
                          <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                             <path fillRule="evenodd" clipRule="evenodd" d="M2 0C0.89543 0 0 0.895431 0 2V20C0 21.1046 0.895431 22 2 22H20C21.1046 22 22 21.1046 22 20V2C22 0.89543 21.1046 0 20 0H2ZM2 2L20 2V20H2V2ZM16.8 7.6C17.1314 7.15817 17.0418 6.53137 16.6 6.2C16.1582 5.86863 15.5314 5.95817 15.2 6.4L9.89181 13.4776L7.70711 11.2929C7.31658 10.9024 6.68342 10.9024 6.29289 11.2929C5.90237 11.6834 5.90237 12.3166 6.29289 12.7071L9.29289 15.7071C9.49788 15.9121 9.78173 16.018 10.0709 15.9975C10.3601 15.9769 10.6261 15.8319 10.8 15.6L16.8 7.6Z" fill="#000" />
                          </svg>
                          {
                             <span>{Object.values(this.props).slice(0,4).filter(item=>item!==null).length}</span>
                          }

                       </div>
                       {
                          this.state.showUp && language &&
                          <p>{language.your_choices}</p>
                       }

                    </div>
                    : <div/>

             }
          </Fragment>

      );
   }
}

const mapStateToProps = (state) => ({
   setCoverType: state.userSelectionReducer.setCoverType,
   setSinkType: state.userSelectionReducer.setSinkType,
   setCoverSelectionType: state.userSelectionReducer.setCoverSelectionType,
   setHandleTypes: state.userSelectionReducer.setHandleTypes,
   isCollapseMenuOpen: state.commonReducer.isCollapseMenuOpen,
   productStepNumber: state.productStepReducer.productStepNumber,
   language: state.languageReducer[state.commonReducer.activeLang]

})
export default connect(mapStateToProps)(ShowingChosenItemComponent);
