import React, {Component} from 'react';
import {ASSETS} from "../constants/paths";
import OptionSelectionComponent from "./optionSelectionComponent";
import {connect} from "react-redux";
import {setCoverColour, setCoverStyle, setCoverType} from "../actions";

class CoverStyleSelectionComponent extends Component {
   selectBlock=React.createRef();
   dataArr=[]
   state={
      id:null,

   }

   componentDidMount() {
      window.addEventListener("click",this.closeOutsideClick,false)
   }
   componentWillUnmount() {
      window.removeEventListener("click",this.closeOutsideClick,false)
   }


   setColours = (colorGroup,coverStyle,color) => {
      if(this.props.isChangeModel){
         this.props.setCoverModel(colorGroup,coverStyle,color)
         return;
      }

      this.props.dispatch(setCoverColour(color))
      this.props.dispatch(setCoverStyle(coverStyle))
      this.props.dispatch(setCoverType(colorGroup))
      this.dataArr.push({coverStyle:coverStyle.name,color:color.cover_color.name})
      this.forceUpdate()
   }


   handleOnChange = (item) => {

      /*if(this.props.selectedColorId!==null && item.cover_style_colors.findIndex(colorItem=>colorItem.cover_color_id===this.props.selectedColorId)===-1){
         this.props.dispatch(setCoverColour(item.cover_style_colors[0].cover_color))
         let coverGroupItem = {...item.cover_style_colors[0].cover_color.cover_color_group};
         coverGroupItem.cover_colors = item.cover_style_colors.map(colorItem=>{
            if(colorItem.cover_color.cover_color_group_id==coverGroupItem.id){
               return colorItem.cover_color
            }
         })
         this.props.dispatch(setCoverType(coverGroupItem))
      }*/
      if(window.innerWidth<641){
         this.props.dispatch(setCoverColour(item.color_groups[0].colors[0]))
         this.props.dispatch(setCoverStyle(item))
         this.props.dispatch(setCoverType(item.color_groups[0]))
      }
      if(this.state.id===item.id){
         this.setState({
            id:null
         })
      }else{
         this.setState({
            id:item.id
         })
      }
   }
   closeOutsideClick = (e) => {
      if(this.selectBlock.current && !this.selectBlock.current.contains(e.target)){
         this.setState({
            id:null
         })
      }
   }
   render() {
      let{stateId,coverStyle,setCoverColour,setCoverStyle,index,activeLang}=this.props;
      let{id}=this.state;
      return (
          <div ref={this.selectBlock} className={`vra-custom-select-block ${window.innerWidth>641?(setCoverStyle&&setCoverStyle.id===coverStyle.id && !id ?"active":""):(setCoverStyle&&setCoverStyle.id===coverStyle.id  ?"active":"")} ${id===coverStyle.id ? " opened":""} `}  key={index} onClick={()=> {
             this.handleOnChange(coverStyle)
          }}>
             <div className="vra-custom-select-item">
                <div className="vra-img-item">
                   <picture>
                      <source
                          srcSet={`${ASSETS}${coverStyle.select_img.substr(0,coverStyle.select_img.lastIndexOf("."))+".webp"}`}
                           type="image/webp"/>
                      <source
                          srcSet={`${ASSETS}${coverStyle.select_img.substr(0,coverStyle.select_img.lastIndexOf("."))+".png"}`}
                           type="image/png"/>
                      <img src={`${ASSETS}${coverStyle.select_img}`} alt={index}/>
                   </picture>
                </div>
                <div className="vra-text-item">
                   <p>{JSON.parse(coverStyle.transition)[activeLang]||coverStyle.name}</p>
                </div>
             </div>
             <i className="gg-chevron"/>
             <div className="vra-option-container">
                {
                   coverStyle.color_groups.map((group,i)=>
                       <OptionSelectionComponent   key={i} coverStyle={coverStyle} setColours={this.setColours} group={group}/>
                   )
                }
             </div>
          </div>
      );
   }
}
const mapStateToProps=state=>({
   setCoverColour: state.userSelectionReducer.setCoverColour,
   setCoverStyle:state.userSelectionReducer.setCoverStyle,
   activeLang: state.commonReducer.activeLang,
   submittedValue:state.commonReducer.submittedValue
})
export default connect(mapStateToProps) (CoverStyleSelectionComponent);
