import React, { Component } from 'react';
import { ASSETS } from "../../../constants/paths";
import FooterPolicyComponent from "./footerPolicyComponent";
import SocialMediaComponent from "./SocialMediaComponent";
import { connect } from "react-redux";

class FooterComponent extends Component {
   render() {
      let { language, activeLang } = this.props;
      return (
         <div className="vra-footer-root">
            <div className="vra-footer-wrapper safe-area">
               <div className="vra-footer-container">
                  <a href="#" className="vra-logo-item">
                     <picture>
                        <source
                            srcSet={`${ASSETS}/img/home/eczacibasi_logo_white.png 1x, ${ASSETS}/img/home/eczacibasi_logo_white_2x.png 2x`}
                            type="image/png"/>
                        <source
                            srcSet={`${ASSETS}/img/home/eczacibasi_logo_white.webp 1x, ${ASSETS}/img/home/eczacibasi_logo_white.png_2x.webp 2x`}
                            type="image/webp"/>
                        <img src={`${ASSETS}/img/home/eczacibasi_logo_white.png`} alt="logo"/>
                     </picture>
                  </a>
                  {
                     language &&
                     <div className="map-block">
                        <ul>
                           <li><a target="_blank" rel="noreferrer" href={language.contact_us_link}>
                              {language.contact_us}
                           </a></li>
                           {
                              activeLang === "tr" &&
                              <li><a target="_blank" rel="noreferrer" href={language.guarantee_link}>
                                 {language.guarantee}
                              </a></li>

                           }
                           {
                              (activeLang === "tr" || activeLang === "en" || activeLang === "uk") &&
                              <li><a target="_blank" rel="noreferrer" href={language.faq_link}>
                                 {language.faq}
                              </a></li>
                           }
                           {
                              activeLang === "tr" &&
                              <li><a target="_blank" rel="noreferrer" href={language.career_link}>
                                 {language.career}
                              </a></li>
                           }

                        </ul>
                     </div>
                  }
                  <SocialMediaComponent />
               </div>

            </div>
            <FooterPolicyComponent />
         </div>
      );
   }
}
const mapStateToProps = state => ({
   language: state.languageReducer[state.commonReducer.activeLang],
   activeLang: state.commonReducer.activeLang
})
export default connect(mapStateToProps)(FooterComponent);
