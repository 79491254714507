import React, {Component} from 'react';
import {ASSETS} from "../constants/paths";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

class NotFoundPage extends Component{

    render(){
        let{activeLang,language}=this.props;
        let homeLink = window.location.pathname.indexOf("/rootkonfigurator")!==-1?"/rootkonfigurator/de":`/`+activeLang
        return (
            <div className="not-found-root">
                <div className="not-found-wrapper safe-area">
                    {
                        language &&
                        <div className="text-block">
                            <h1>404</h1>
                            <span>{language.not_found}</span>
                            <Link to={homeLink} className="error-btn-item">{language.return_home}</Link>
                        </div>
                    }
                    <div className="image-block">
                        <picture>
                            <img src={`${ASSETS}/img/home/error_2x.png`} alt="NotFound"/>
                        </picture>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps=state=>({
    language: state.languageReducer[state.commonReducer.activeLang],
    activeLang: state.commonReducer.activeLang,
})
export default connect(mapStateToProps)  (NotFoundPage);
