import React, {Component} from 'react';
import {ASSETS} from "../../constants/paths";
import { connect } from 'react-redux';
import CampaigneModalComponent from '../modal/campaigneModalComponent';
import VerificationComponent from '../modal/verificationComponent';
import CampaignResultModalComponent from '../modal/campaignResultModalComponent';
import EmailSendFormComponent from '../forms/emailSendFormComponent';
import SendEmailModalComponent from '../modal/sendEmailModalComponent';
import SendMailResultModalComponent from '../modal/sendMailResultModalComponent';

class SendEmailComponent extends Component {
   


   render() {
      return (
          <div className="vra-additional-container">
             <div className="vra-additional-block">
                <div className="vra-img-item">
                   <picture>
                      <source
                          srcSet={`${ASSETS}/img/home/sendMail.png 1x, ${ASSETS}/img/home/sendMail-2x.png 2x`}
                          media="(min-width: 1401px)"
                          type="image/png"/>
                      <source
                          srcSet={`${ASSETS}/img/home/sendMail.webp 1x, ${ASSETS}/img/home/sendMail-2x.webp 2x`}
                          media="(min-width: 1401px)"
                          type="image/webp"/>
                      {/* <source
                          srcSet={`${ASSETS}/img/home/additional-mobile.png 1x, ${ASSETS}/img/home/additional-mobile-2x.png 2x`}
                          media="(max-width: 1400px)"
                          type="image/png"/>
                      <source
                          srcSet={`${ASSETS}/img/home/additional-mobile.png 1x, ${ASSETS}/img/home/additional-mobile-2x.webp 2x`}
                          media="(max-width: 1400px)"
                          type="image/webp"/> */}
                      <img src={`${ASSETS}/img/home/sendMail.png`} alt="sendMail"/>
                   </picture>
                </div>
                <div className="vra-text-block">
                   <p>Send e-mail</p>
                   <span>Send the product list you created to yourself via e-mail.</span>
                </div>
             </div>
             <button onClick={this.props.openForm} className="vra-btn">Send e-mail</button>
             
          </div>
      );
   }
}

const mapStateToProps = (state) => ({
    language: state.languageReducer[state.commonReducer.activeLang],
    orderSummary: state.orderSummaryReducer,
   userSelection: state.userSelectionReducer

})
export default connect(mapStateToProps)(SendEmailComponent);
