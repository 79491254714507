import React, { Component } from 'react'
import CampaigneFormComponent from '../forms/campaigneFormComponent'

class CampaigneModalComponent extends Component {
   state = {
      isError: false
   }
   showErrorMsg = (err) => {
      this.setState({
         isError: err
      })
   }
   
   render() {
      let { changeFormStep, setFormValues } = this.props;
      return (
         <div className="vra-lb-root road-show-root  vra-campaign-register-root">
            <div className="vra-lb-wrapper">
               <div className="vra-lb-container" >
                  <div className="vra-model-content">
                     <div className="vra-top-block">
                        <h3>{'Kampanya kayıt'}</h3>
                        <i className="gg-close" onClick={() => changeFormStep(null)} />
                     </div>
                     <CampaigneFormComponent showErrorMsg={this.showErrorMsg} setFormValues={setFormValues} changeFormStep={changeFormStep} />

                  </div>
                  <div className="vra-campaign-btn-block">
                     {
                         
                        <p style={this.state.isError?{margin:'0 16px 16px 0'}:{}}>{this.state.isError?'Kayıt olurken bir hata oldu!':''}</p>

                     }
                     <div className="vra-btns-block">
                        <button onClick={() => changeFormStep(null)} type="button" className="vra-btn vra-white">Daha sonra kayıt ol</button>
                        <button type="submit" form='road-map-form' className="vra-btn">Devam et</button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      )
   }
}

export default CampaigneModalComponent
