import React, {Component} from 'react';
import {Box, Skeleton} from "@chakra-ui/react";

class LoaderSkeletonComponent extends Component {
   render() {
      let{width,height} =this.props;
      return (
          <Box mt={`${width?"0":"16px"}`} w={width?width:"100%"} h={height?height:"160px"}>
             <Skeleton startColor='gray.200' endColor='gray.300' w="100%" h="100%" borderRadius="8px"/>
          </Box>
      );
   }
}

export default LoaderSkeletonComponent;
