import React, { Component } from 'react';
import { connect } from "react-redux";
import {
   changeColorSelectionAction,
   changeProductStepNumber,
   changeBathroomSelectionAction,
   changeCoverSelectionAction,
   changeArmatureSelectionAction,
   selectionType,
   changeTallCabinetSelectionAction,
   changeOtherModuleSelectionAction,
   createUserBatroomSelectionLogAction,
   createUserArmatureSelectionLogsAction,
   createUserMirrorSelectionLogsAction,
   createUserTallCabinetSelectionLogsAction,
   createUserOtherModuleSelectionLogsAction,
   assignProductStep,
   setArmatureType,
   setArmatureColour,
   setTallCabinetCoverDirection,
   setTallCabinet,
   setOtherModules,
   setOtherModulesCoverDirection,
   setMirrorBacklight,
   setMirrorType,
   setMirrorSize,
   changeMirrorSelectionAction, changeDoorSelection
} from "../../actions";

class MobileButtonComponent extends Component {
   componentDidMount() {
      this.selectedArmatureColour = this.props.setArmatureColour;
      this.selectedArmatureType = this.props.setArmatureType;

      this.selectedTallCabinetDirection = this.props.setTallCabinetCoverDirection;
      this.selectedTallCabinet = this.props.setTallCabinet;

      this.selectedOtherModule = this.props.setOtherModules;
      this.selectedOtherModuleCoverDirection = this.props.setOtherModuleCoverDirection;

      this.selectedMirror = this.props.setMirrorType;
      this.mirrorSize = this.props.mirrorSize;
      this.withBacklight = this.props.withBacklight;
      this.selectedMirrorCoverDirection = this.props.selectedMirrorCoverDirection
   }
   next = () => {
      if (this.checkButtonDisabled()) {
         return;
      }
      if(this.props.activeLang === "in" && this.props.activeStep === 3){
         /*todo: kulp 'india' da yok o yüzden 1 step ileriye gidiyor*/
         this.props.dispatch(assignProductStep(5))
         return;
      }
      if(this.props.activeStep === 3 && this.props.setCoverSelection && this.props.setCoverSelection.sink_stand.id===1 && this.props.resDoorSelection !== "leg_colors"){
         this.props.dispatch(changeDoorSelection("leg_colors"))
         return
      }
      if(this.props.activeStep === 3 && this.props.legColor){
         this.props.dispatch(changeDoorSelection(null))
         this.props.dispatch(changeProductStepNumber(1))
         return
      }


      /*//TODO: tekrar bakılacak */
      /*//TODO: OPTIONLARIN GELİP GEMEYECEGINI BİLİYOR MUYUZ ????*/
      /*if (this.props.activeStep === 9 && this.props.selectionType === "sink") {
         this.props.dispatch(toggleSelectedProductModal(true))
         return;
      }*/

      // if (this.props.activeStep === 2 && this.props.respColorSelection === "color_group") {
      //    this.props.dispatch(changeColorSelectionAction("color"))
      //    this.createBathroomLogs();
      //    return;
      // }
      // if (this.props.activeStep === 4 && this.props.respBathroomSelection === "bath_title") {
      //    this.props.dispatch(changeBathroomSelectionAction("bath_group"))
      //    this.createBathroomLogs();
      //    return;
      // }
      // if (this.props.activeStep === 4 && this.props.respBathroomSelection === "bath_group" && this.props.setSinkSizes.sink_size_cover_directions.length > 1) {
      //    this.props.dispatch(changeBathroomSelectionAction("bath_option"))
      //    this.createBathroomLogs();
      //    return;
      // }
      // if (this.props.activeStep === 5 && this.props.respCoverSelection === "cover_group" && this.props.setCoverSelectionType.sink_cover_stands && this.props.setCoverSelectionType.sink_cover_stands.length > 1) { /**/
      //    this.props.dispatch(changeCoverSelectionAction("cover_option"))
      //    this.createBathroomLogs();
      //    return;
      // }
      // if (this.props.selectionType === "armature" && this.props.respArmatureSelection === "color_group") {
      //    this.props.dispatch(changeArmatureSelectionAction("color_option"))
      //    return;
      // }

      if (this.props.selectionType === "armature") {
         this.props.dispatch(changeArmatureSelectionAction("color_group"))
         this.props.dispatch(selectionType("sink"))
         this.createOtherModulesLog();
         return;
      }
      // if (this.props.selectionType === "tallCabinet" && this.props.respTallCabinetSelection === "cabinet_group" && this.props.setTallCabinet.cover_direction_tall_cabinets.length > 1) {
      //    this.props.dispatch(changeTallCabinetSelectionAction("cabinet_option"))
      //    return;
      // }
      // if (this.props.selectionType === "tallCabinet" && this.props.respTallCabinetSelection === "cabinet_group" && this.props.setTallCabinet.cover_direction_tall_cabinets.length === 1) {
      //    this.props.dispatch(selectionType("sink"))
      //    this.createOtherModulesLog();
      //    return;
      // }
      if (this.props.selectionType === "tallCabinet") {
         this.props.dispatch(changeTallCabinetSelectionAction("cabinet_group"))
         this.props.dispatch(selectionType("sink"))
         this.createOtherModulesLog();
         return;
      }
      /*if (this.props.selectionType === "mirror") {
         this.props.dispatch(selectionType("sink"))
         this.createOtherModulesLog();
         return;
      }*/
      // if (this.props.selectionType === "otherModule" && this.props.respOtherSelection === "module_group") {
      //    this.props.dispatch(changeOtherModuleSelectionAction("module_option"))
      //    return;
      // }
      if (this.props.selectionType === "otherModule") {
         this.props.dispatch(changeOtherModuleSelectionAction("module_group"))
         this.props.dispatch(selectionType("sink"))
         this.createOtherModulesLog();
         return;
      }
      // if (this.props.selectionType === "mirror" && this.props.resMirrorSelection === "mirror_group" &&this.props.setMirrorType.mirror_style_sizes.length>1) {
      //    this.props.dispatch(changeMirrorSelectionAction("mirror_option"))
      //    return;

      // }
      // if(this.props.selectionType === "mirror" && this.props.resMirrorSelection === "mirror_group" && this.props.setMirrorType.mirror_style_sizes.length<1){
      //    this.props.dispatch(changeMirrorSelectionAction("light"))
      //    return;
      // }

      if (this.props.selectionType === "mirror" && this.props.resMirrorSelection === "mirror_group" && this.props.setMirrorType.with_backlight) {
         this.props.dispatch(changeMirrorSelectionAction("light"))
         return;
      }

      if (this.props.selectionType === "mirror" && this.props.resMirrorSelection === "light" && (this.props.selectedSinkSize === "45 cm" || this.props.selectedSinkSize === "60 cm") && this.props.setMirrorType && this.props.setMirrorType.id === 2) {
         this.props.dispatch(changeMirrorSelectionAction("cover_direction"))
         return;
      }

      if (this.props.selectionType === "mirror") {
         this.props.dispatch(changeMirrorSelectionAction("mirror_group"))
         this.props.dispatch(selectionType("sink"))
         return;
      }

      if (this.props.selectionType === "mirror" && this.props.resMirrorSelection === "light") {
         this.props.dispatch(changeMirrorSelectionAction("mirror_group"))
         this.props.dispatch(selectionType("sink"))
         return;
      }


      /* if (this.props.activeStep === 8 && this.props.selectionType !== "sink") {
          this.props.dispatch(toggleSelectedProductModal(true))
       }*/
      if (this.props.activeStep === 6) {
         this.props.dispatch(assignProductStep(this.props.isFirstCreated === true ? 8 : 7))
      } else {
         this.props.dispatch(changeProductStepNumber(1))
      }
      this.createBathroomLogs();


   }

   createBathroomLogs = () => {
      this.props.dispatch(createUserBatroomSelectionLogAction({
         session_id: this.props.userSessionId,
         cover_style_color_id: this.props.userSelection.setCoverColour ? this.props.userSelection.setCoverColour.id : null,
         sink_size_id: this.props.userSelection.setSinkSizes ? this.props.userSelection.setSinkSizes.id : null,
         sink_cover_id: this.props.userSelection.setCoverSelectionType ? this.props.userSelection.setCoverSelectionType.id : null,
         sink_stand_id: this.props.userSelection.setCoverSelection && this.props.userSelection.setCoverSelection.sink_stand ? this.props.userSelection.setCoverSelection.sink_stand.id : null,
         handle_id: this.props.userSelection.setHandleTypes ? this.props.userSelection.setHandleTypes.id : null,
         cover_style_id: this.props.userSelection.setCoverStyle ? this.props.userSelection.setCoverStyle.id : null,
         cover_color_id: this.props.userSelection.setCoverColour ? this.props.userSelection.setCoverColour.id : null,
         stand_id: this.props.userSelection.setCoverSelection ? this.props.userSelection.setCoverSelection.id : null,
         sink_type_id: this.props.sinkType ? this.props.sinkType.id : null,
         cover_direction_id: this.props.userSelection.setSinkSizeDirection ? this.props.userSelection.setSinkSizeDirection.cover_direction.id : null,
         action_status: this.props.productStepNumber == 7 ? "Completed" : "Selected"
      }))
   }

   createOtherModulesLog = () => {
      switch (this.props.selectionType) {
         case "armature":
            this.props.dispatch(createUserArmatureSelectionLogsAction({
               session_id: this.props.userSessionId,
               size: this.props.userSelection.mirrorSize ? this.props.userSelection.mirrorSize.size.id : this.props.userSelection.setSinkSizes.id,
               armature_style_color_id: this.props.userSelection.setArmatureColour ? this.props.userSelection.setArmatureColour.id : null,
               action_status: "Completed"
            }))
            break;
         case "mirror":
            this.props.dispatch(createUserMirrorSelectionLogsAction({
               session_id: this.props.userSessionId,
               mirror_style_id: this.props.userSelection.setMirrorType ? this.props.userSelection.setMirrorType.id : null,
               sink_size_id: this.props.userSelection.setSinkSizes.id,
               cover_direction_id: this.props.userSelection.setSinkSizeDirection ? this.props.userSelection.setSinkSizeDirection.cover_direction.id : null,
               with_backlight: this.props.userSelection.mirrorWithBackLight,
               action_status: "Completed"
            }))
            break;
         case "tallCabinet":
            this.props.dispatch(createUserTallCabinetSelectionLogsAction({
               session_id: this.props.userSessionId,
               cover_direction_tall_cabinet_id: this.props.userSelection.setTallCabinetCoverDirection ? this.props.userSelection.setTallCabinetCoverDirection.id : null,
               cover_style_id: this.props.userSelection.setCoverStyle ? this.props.userSelection.setCoverStyle.id : null,
               //sink_color_id: logDetail.sink_color_id,
               action_status: "Completed"
            }))
            break;
         case "otherModule":
            this.props.dispatch(createUserOtherModuleSelectionLogsAction({
               session_id: this.props.userSessionId,
               other_module_id: this.props.userSelection.setOtherModules ? this.props.userSelection.setOtherModules.id : null,
               cover_style_id: this.props.userSelection.setCoverStyle ? this.props.userSelection.setCoverStyle.id : null,
               //sink_color_id: logDetail.sink_color_id,
               cover_direction_id: this.props.userSelection.setOtherModuleCoverDirection ? this.props.userSelection.setOtherModuleCoverDirection.cover_direction.id : null,
               cover_color_id: this.props.userSelection.setCoverColour?this.props.userSelection.setCoverColour.cover_color.id:null,
               action_status: "Completed"
            }))
            break;
      }
   }

   back = () => {
      if (this.props.activeStep === 2 && this.props.respColorSelection === "color") {
         this.props.dispatch(changeColorSelectionAction("color_group"))
         return;
      }
      if((this.props.activeStep === 3) && this.props.resDoorSelection === "leg_colors"){
         this.props.dispatch(changeDoorSelection(null))
         return
      }
      if (this.props.activeStep === 4 && this.props.respBathroomSelection === "bath_group") {
         this.props.dispatch(changeBathroomSelectionAction("bath_title"))
         return;
      }
      if (this.props.activeStep === 4 && this.props.respBathroomSelection === "bath_option") {
         this.props.dispatch(changeBathroomSelectionAction("bath_group"))
         return;
      }
      if (this.props.activeStep === 5 && this.props.respCoverSelection === "cover_option") { /*&& this.props.setCoverSelectionType.sink_cover_stands.length>1*/
         this.props.dispatch(changeCoverSelectionAction("cover_group"))
         return;
      }
      // if (this.props.selectionType === "armature" && this.props.respArmatureSelection === "color_option") {
      //    this.props.dispatch(changeArmatureSelectionAction("color_group"))
      //    return;
      // }
      if (this.props.selectionType === "armature") {
         this.props.dispatch(setArmatureColour(this.selectedArmatureColour))
         this.props.dispatch(setArmatureType(this.selectedArmatureType))
         this.props.dispatch(selectionType("sink"))
         return;
      }
      if (this.props.selectionType === "tallCabinet") {
         this.props.dispatch(setTallCabinetCoverDirection(this.selectedTallCabinetDirection))
         this.props.dispatch(setTallCabinet(this.selectedTallCabinet))
         this.props.dispatch(selectionType("sink"))
         return;
      }
      // if (this.props.selectionType === "tallCabinet" && this.props.respTallCabinetSelection === "cabinet_option") {
      //    this.props.dispatch(changeTallCabinetSelectionAction("cabinet_group"))
      //    return;
      // }
      /*if (this.props.selectionType === "mirror") {
         this.props.dispatch(setMirrorBacklight(this.withBacklight))
         this.props.dispatch(setMirrorSize(this.mirrorSize))
         this.props.dispatch(setMirrorType(this.selectedMirror))
         this.props.dispatch(selectionType("sink"))
         return;
      }*/
      // if (this.props.selectionType === "otherModule" && this.props.respOtherSelection === "module_option") {
      //    this.props.dispatch(changeOtherModuleSelectionAction("module_group"))
      //    return;
      // }
      if (this.props.selectionType === "otherModule") {
         this.props.dispatch(setOtherModules(this.selectedOtherModule))
         this.props.dispatch(setOtherModulesCoverDirection(this.selectedOtherModuleCoverDirection))
         this.props.dispatch(selectionType("sink"))
         return;
      }
      // if (this.props.selectionType === "mirror" && this.props.resMirrorSelection === "mirror_option") {
      //    this.props.dispatch(changeMirrorSelectionAction("mirror_group"))
      //    return;
      // }
      if (this.props.selectionType === "mirror" && this.props.resMirrorSelection === "light" && this.props.setMirrorType && this.props.setMirrorType.mirror_style_sizes.length===0) {
         this.props.dispatch(setMirrorBacklight(this.withBacklight))
         this.props.dispatch(setMirrorSize(this.mirrorSize))
         this.props.dispatch(setMirrorType(this.selectedMirror))
         this.props.dispatch(selectionType("sink"))
         return;
      }
      if (this.props.selectionType === "mirror" && this.props.resMirrorSelection === "cover_direction") {
         this.props.dispatch(setMirrorBacklight(this.selectedMirrorCoverDirection))
         this.props.dispatch(setMirrorBacklight(this.withBacklight))
         this.props.dispatch(changeMirrorSelectionAction("light"))
         return;
      }
      if (this.props.selectionType === "mirror" && this.props.resMirrorSelection === "light" && this.props.setMirrorType && this.props.setMirrorType.mirror_style_sizes.length> 0) {

         // this.props.dispatch(setMirrorBacklight(this.withBacklight))
         // this.props.dispatch(setMirrorSize(this.mirrorSize))
         // this.props.dispatch(setMirrorType(this.selectedMirror))
         this.props.dispatch(changeMirrorSelectionAction("mirror_group"))
         return;
      }
      if (this.props.selectionType === "mirror") {
         this.props.dispatch(setMirrorBacklight(this.withBacklight))
         this.props.dispatch(setMirrorSize(this.mirrorSize))
         this.props.dispatch(setMirrorType(this.selectedMirror))
         this.props.dispatch(selectionType("sink"))
         return;
      }



      this.props.dispatch(changeProductStepNumber(-1));
   }

   getStepString = () => {
      switch (this.props.selectionType) {
         case "armature":
            //            return `${this.props.respArmatureSelection === "color_option" ? 2 : 1}/2`;
            return ``;

         case "otherModule":
            return ``;
         //return `${this.props.respOtherSelection === "module_option" ? 2 : 1}/2`;
         case "tallCabinet":
            return ``;
         //            return `${this.props.respTallCabinetSelection === "cabinet_option" ? 2 : (this.props.respTallCabinetSelection === "cabinet_group" ? 1 : 3)}/2`;
      }
   }

   getStepName = () => {
      let { language } = this.props
      if (language) {
         switch (this.props.selectionType) {
            case "armature":
               return `${this.props.respArmatureSelection === "color_option" ? (language.choose_colour) : (language.choose_faucet)}`;
            case "otherModule":
               return `${this.props.respOtherSelection === "module_option" ? (language.choose_door_side) : (language.choose_storage)}`;
            case "tallCabinet":
               return `${this.props.respTallCabinetSelection === "cabinet_option" ? (language.choose_door_side) : (this.props.respTallCabinetSelection === "cabinet_group" ? (language.choose_tall_unit) : (language.choose_door_side))}`;
            case "mirror":
               return `${this.props.resMirrorSelection === "mirror_group" ? (language.choose_mirror) : this.props.resMirrorSelection === "cover_direction" ? language.door_side : (language.lighting)}`;
            case "sink":
               switch (this.props.activeStep) {
                  case 1:
                  case '1':
                     return `${language.choose_door_style}`;
                  case 2:
                  case '2':
                     return (language.choose_washbasin);
                  case 3:
                  case '3':
                     return (language.door_type);
                  // case 4:
                  // case '4':
                  //    return this.props.respBathroomSelection === "bath_title" ? (language.choose_size) : (this.props.respBathroomSelection === "bath_group" ? (language.choose_size) : (language.choose_door_side));
                  // case 5:
                  // case '5':
                  //    return this.props.respCoverSelection === "cover_group" ? (language.door_type) : (language.leg);
                  case 4:
                  case '4':
                     return (language.handle);
                  case 5:
                  case '5':
                     return (language.your_choices);
               }
         }
      }

   }

   getBtnName = () => {
      switch (this.props.selectionType) {
         case 'sink':
            return (this.props.activeStep === 4 ? this.props.language.complete : this.props.language.continue);
         case 'mirror':
            if(!this.props.setMirrorType) return this.props.language.continue
            if(this.props.resMirrorSelection === 'cover_direction' || (this.props.resMirrorSelection === 'light' && this.props.setMirrorType.id === 3) || this.props.setMirrorType.id === 1){
               return this.props.language.save
            }
            if(this.props.selectedSinkSize !== '45 cm' && this.props.selectedSinkSize !== '60 cm' && this.props.setMirrorType.id ===2) return this.props.language.save
            return this.props.language.continue

         default:

            return this.props.language.save

      }
   }
   getBackBtnName=()=>{
      if(this.props.selectionType === 'sink') return this.props.language.back
      else if(this.props.selectionType === 'mirror'){
         if((this.props.resMirrorSelection === 'light' && this.props.setMirrorType && this.props.setMirrorType.id === 3) || this.props.resMirrorSelection === 'cover_direction'){
            return this.props.language.back
         }
      }
      return this.props.language.abort
   }
   checkButtonDisabled = () => {
      if (this.props.selectionType === "sink") {
         switch (this.props.activeStep) {
            case 1:
            case "1":
               return this.props.userSelection.setCoverColour ? "" : "disabled"
            case 2:
            case "2":
               return this.props.userSelection.setSinkSizes ? "" : "disabled"
            case 3:
            case "3":
               let isCoverExist = this.props.clickedSinkStyle ? this.props.clickedSinkStyle.sink_size_covers.findIndex(item => item.sink_size_id === this.props.userSelection.setSinkSizes.id) : null;
               let withDoorsFor80cm = this.props.userSelection.setSinkSizes && this.props.setCoverSelectionType && (this.props.userSelection.setSinkSizes.id === 3 || this.props.userSelection.setSinkSizes.id === 9 ) && this.props.setCoverSelectionType.id === 1
               return this.props.userSelection.setCoverSelectionType && isCoverExist !== -1 && !withDoorsFor80cm ? "" : "disabled"
            case 4:
            case "4":
               return this.props.userSelection.setHandleTypes ? "" : "disabled"
            default:
               return "";
         }
      }

      else if (this.props.selectionType === "armature") {
         return this.props.userSelection.setArmatureColour ? "" : "disabled"
      } else if (this.props.selectionType === "tallCabinet") {
         return this.props.userSelection.setTallCabinet ? "" : "disabled"
      }
      else if (this.props.selectionType === "otherModule") {
         return this.props.userSelection.setOtherModules ? "" : "disabled"
      }
      if (this.props.selectionType === "mirror") {
         switch (this.props.resMirrorSelection) {
            case 'light':
               return this.props.withBacklight ? "" : "disabled"
            case 'cover_direction':
               return this.props.selectedMirrorCoverDirection ? "" : "disabled"
            default:
               return "";
         }
         // return this.props.userSelection.mirrorSize?"":"disabled"
      }

   }

   render() {
      let { activeStep, selectionType, language, setMirrorType, mirrorSize,addSound,voice} = this.props;
      return (
         <div className="vra-mobile-btn-block vra-flex-btn">
            {
               language &&
               <button onClick={()=> {
                  this.back()
                  if(voice) addSound()
               }}
                  className={`vra-btn vra-white ${activeStep === 1 ? "vra-hidden" : ""} ${activeStep === 9 && selectionType === "sink" ? "vra-full" : ""}`}><i
                     className="gg-arrow" />{this.getBackBtnName()}
               </button>
            }
            <div className="vra-mobile-title-item">
               <p>{this.getStepName()}</p>
               {
                  (selectionType === "sink" && activeStep !== 9) ?
                     <span>{`${activeStep}/4`}</span>
                     :
                     <span>{this.getStepString()}</span>
               }
            </div>
            {
               language &&
               <button onClick={()=> {
                  this.next()
                  if(voice) addSound()
               }} className={`vra-btn ${this.checkButtonDisabled()} ${activeStep === 9 && selectionType === "sink" ? "vra-full" : ""}`}>
                  {this.getBtnName()}</button>

            }

         </div>
      );
   }
}

const mapStateToProps = (state) => ({
   activeStep: state.productStepReducer.productStepNumber,
   respColorSelection: state.productStepReducer.respColorSelection,
   respBathroomSelection: state.productStepReducer.respBathroomSelection,
   respCoverSelection: state.productStepReducer.respCoverSelection,
   setSinkSizes: state.userSelectionReducer.setSinkSizes,
   setCoverSelectionType: state.userSelectionReducer.setCoverSelectionType,
   setCoverSelection: state.userSelectionReducer.setCoverSelection,
   selectionType: state.commonReducer.selectionType,
   legColor:state.userSelectionReducer.legColor,
   respArmatureSelection: state.productStepReducer.respArmatureSelection,
   respTallCabinetSelection: state.productStepReducer.respTallCabinetSelection,
   setTallCabinet: state.userSelectionReducer.setTallCabinet,
   respOtherSelection: state.productStepReducer.respOtherSelection,
   userSessionId: state.userSelectionReducer.userSessionId,
   userSelection: state.userSelectionReducer,
   sinkType: state.userSelectionReducer.setSinkType || null,
   isFirstCreated: state.commonReducer.isFirstCreated,
   setArmatureColour: state.userSelectionReducer.setArmatureColour,
   setArmatureType: state.userSelectionReducer.setArmatureType,
   setTallCabinetCoverDirection: state.userSelectionReducer.setTallCabinetCoverDirection,
   setOtherModules: state.userSelectionReducer.setOtherModules,
   setOtherModuleCoverDirection: state.userSelectionReducer.setOtherModuleCoverDirection,
   setMirrorType: state.userSelectionReducer.setMirrorType,
   mirrorSize: state.userSelectionReducer.mirrorSize,
   withBacklight: state.userSelectionReducer.mirrorWithBackLight,
   resMirrorSelection: state.productStepReducer.resMirrorSelection,
   selectedMirrorCoverDirection: state.userSelectionReducer.selectedMirrorCoverDirection,
   language: state.languageReducer[state.commonReducer.activeLang],
   activeLang:state.commonReducer.activeLang,
   resDoorSelection:state.productStepReducer.resDoorSelection,
   selectedSinkSize: state.userSelectionReducer.setSinkSizes ? state.userSelectionReducer.setSinkSizes.name : null,
   clickedSinkStyle: state.sinkReducer.coverTypes && state.userSelectionReducer.setCoverSelectionType ? (state.sinkReducer.coverTypes.find(item => item.id === state.userSelectionReducer.setCoverSelectionType.id) || state.sinkReducer.coverTypes[0]) : null,
   voice:state.commonReducer.voice

})

export default connect(mapStateToProps)(MobileButtonComponent);
