import {
   ASSIGN_PRODUCT_STEP_NUMBER,
   GET_ARMATURE_TYPES,
   GET_COVER_COLOURS,
   GET_MIRROR_TYPES,
   GET_OTHER_MODULES,
   GET_TALL_CABINETS,
   PRODUCT_STEP_NUMBER,
   SELECTION_TYPE,
   SET_ARMATURE_COLOUR,
   SET_ARMATURE_TYPES,
   SET_COVER_SELECTION_TYPE,
   SET_COVER_TYPE,
   SET_MIRROR_TYPES,
   SET_OTHER_MODULES,
   SET_OTHER_MODULES_DIRECTION,
   SET_TALL_CABINETS,
   SET_TALL_CABINETS_DIRECTION,
   TOGGLE_COLLAPSE_MENU,
   RECEIVE_ORDER_SUMMARY,
   CHANGE_SELECTED_MODULES,
   CHANGE_BATHROOM_PICTURE,
   CHANGE_COLOR_SELECTION,
   CHANGE_BATHROOM_SELECTION,
   SET_BATHROOM_TITLE_SELECTION,
   CHANGE_COVER_SELECTION,
   CHANGE_ARMATURE_SELECTION,
   CHANGE_TALL_CABINETS_SELECTION,
   CHANGE_OTHER_MODULE_SELECTION,
   SET_MIRROR_SIZE,
   SET_MIRROR_BACKLIGHT,
   SET_USER_SESSION,
   SET_RANDOM_VALUE,
   SET_ALL_USER_SELECTION,
   RESET_ALL_USER_SELECTION,
   SET_FIRST_CREATED_VALUE,
   CHANGE_BATHROOM_AREA_PICTURE,
   IS_LOADING,
   CHANGE_MIRROR_SELECTION,
   GET_LANDING_DATA,
   SET_LAST_RANDOM_ID,
   GET_LANGUAGE,
   IS_BUTTON_DISABLED,
   DELETE_ADDITIONAL_PRODUCT,
   MODEL_CHANGES, TOGGLE_COVER_MODELS_MODAL, TOGGLE_BG_MODAL,
   RECEIVE_PLACE_PICTURES,
   CHANGE_PLACE,
   SET_MIRROR_COVER_DIRECTION, SET_LEG_COLOR, CHANGE_DOOR_SELECTION,TOGGLE_VOICE
} from "../constants/actionTypes";
import {
   postCoverStyleColors,
   getCoverStyles,
   getSinkTypes,
   getSinkSizes,
   postSinkSize,
   getHandleTypes,
   getArmatureTypes,
   getMirrorTypes,
   postTallCabinet,
   postOtherModules,
   getOrderSummary,
   getBahtroomPicture,
   createUserBatroomSelectionLog,
   createUserArmatureSelectionLogs,
   createUserOtherModuleSelectionLogs,
   createUserTallCabinetSelectionLogs,
   createUserMirrorSelectionLogs,
   getBathroomArea,
   getFeaturedBathroomData,
   sendEmailToUser,
   getLandingData, getLanguageData, getPlacePictures, sendSMS, saveUserForm, userByToken, saveDealerForm, resendSms
} from "../services/webServices";
import * as actionTypes from "../constants/actionTypes";

export const changeProductStepNumber = (payload) => ({
   type: PRODUCT_STEP_NUMBER,
   payload
})
export const getCoverStylesData = (payload) => ({
   type: actionTypes.GET_COVER_STYLES,
   payload
})
export const setCoverStyle = (payload) => ({
   type: actionTypes.SET_COVER_STYLES,
   payload
})
export const setCoverColour = (payload) => ({
   type: actionTypes.SET_COVER_COLOUR,
   payload
})
export const setSinkType = (payload) => ({
   type: actionTypes.SET_SINK_TYPES,
   payload
})

export const setCoverType = (payload) => ({
   type: SET_COVER_TYPE,
   payload
})

export const getCoverColours = (payload) => ({
   type: GET_COVER_COLOURS,
   payload
})

export const getSinkTypeData = (payload) => ({
   type: actionTypes.GET_SINK_TYPES,
   payload
})
export const getSinkSizeData = (payload) => ({
   type: actionTypes.GET_SINK_SIZES,
   payload
})
export const setSinkSizes = (payload) => ({
   type: actionTypes.SET_SINK_SIZES,
   payload
})

export const setSinkCoverDirection = (payload) => ({
   type: actionTypes.SET_SINK_SIZE_COVER_DIRECTION,
   payload
})

export const getCoverTypes = (payload) => ({
   type: actionTypes.GET_COVER_TYPES,
   payload
})
export const setCoverSelectionType = (payload) => ({
   type: SET_COVER_SELECTION_TYPE,
   payload
})

export const setCoverSelection = (payload) => ({
   type: actionTypes.SET_COVER_SELECTION,
   payload
})

export const getHandleTypesData = (payload) => ({
   type: actionTypes.GET_HANDLE_TYPES,
   payload
})
export const setHandleTypes = (payload) => ({
   type: actionTypes.SET_HANDLE_TYPES,
   payload
})
export const toggleSelectedProductModal = (payload) => ({
   type: actionTypes.TOGGLE_SELECTED_PRODUCT_MODAL,
   payload
})

export const assignProductStep = (payload) => ({
   type: ASSIGN_PRODUCT_STEP_NUMBER,
   payload
})

export const setAllUserSelection = (payload) => ({
   type: SET_ALL_USER_SELECTION,
   payload
})

export const getArmatureTypesData = (payload) => ({
   type: GET_ARMATURE_TYPES,
   payload
})
export const setArmatureType = (payload) => ({
   type: SET_ARMATURE_TYPES,
   payload
})
export const setArmatureColour = (payload) => ({
   type: SET_ARMATURE_COLOUR,
   payload
})
export const getMirrorTypesData = (payload) => ({
   type: GET_MIRROR_TYPES,
   payload
})
export const setMirrorType = (payload) => ({
   type: SET_MIRROR_TYPES,
   payload
})

export const setMirrorSize = (payload) => ({
   type: SET_MIRROR_SIZE,
   payload
})

export const setMirrorBacklight = (payload) => ({
   type: SET_MIRROR_BACKLIGHT,
   payload
})

export const setMirrorCoverDirection = (payload) => ({
   type: SET_MIRROR_COVER_DIRECTION,
   payload
})

export const getTallCabinets = (payload) => ({
   type: GET_TALL_CABINETS,
   payload
})
export const setTallCabinet = (payload) => ({
   type: SET_TALL_CABINETS,
   payload
})
export const setTallCabinetCoverDirection = (payload) => ({
   type: SET_TALL_CABINETS_DIRECTION,
   payload
})
export const selectionType = (payload) => ({
   type: SELECTION_TYPE,
   payload
})
export const getOtherModulesData = (payload) => ({
   type: GET_OTHER_MODULES,
   payload
})

export const setOtherModules = (payload) => ({
   type: SET_OTHER_MODULES,
   payload
})
export const setOtherModulesCoverDirection = (payload) => ({
   type: SET_OTHER_MODULES_DIRECTION,
   payload
})
export const toggleCollapseMenu = (payload) => ({
   type: TOGGLE_COLLAPSE_MENU,
   payload
})

export const receiveOrderSummary = (payload) => ({
   type: RECEIVE_ORDER_SUMMARY,
   payload
})

export const changeSelectedModulesAction = (payload) => ({
   type: CHANGE_SELECTED_MODULES,
   payload
})

export const setRandomValue = (payload) => ({
   type: SET_RANDOM_VALUE,
   payload
})

export const setFirstCreatedValue = (payload) => ({
   type: SET_FIRST_CREATED_VALUE,
   payload
})

export const resetUserSelections = () => ({
   type: RESET_ALL_USER_SELECTION
})

export const setActiveLangAction = (payload) => ({
   type: actionTypes.SET_ACTIVE_LANG,
   payload
})

export const setCoverTypeMobileSelection = (payload) => ({
   type: actionTypes.SET_COVER_TYPE_MOBILE_SELECTIION,
   payload
})

export const getCoverStylesAction = () => dispatch => {
   getCoverStyles().then((res) => {
      if (res.data.success) {
         dispatch(getCoverStylesData(res.data.success))
      } else {
         dispatch(getCoverStylesData(false))
      }
   }).catch((err) => {
      console.log(err, "ERROR")
   })
}
export const getSinkTypesAction = () => dispatch => {
   getSinkTypes().then((res) => {
      if (res.data.success) {
         dispatch(getSinkTypeData(res.data.success))
      } else {
         dispatch(getSinkTypeData(false))
      }
   }).catch((err) => {
      console.log(err, "ERROR")
   })
}
export const getSinkSizesAction = () => dispatch => {
   getSinkSizes().then((res) => {
      if (res.data.success) {
         dispatch(getSinkSizeData(res.data.success))
      } else {
         dispatch(getSinkSizeData(false))
      }
   }).catch((err) => {
      console.log(err, "ERROR")
   })
}

export const postCoverStyleIdAction = (params) => dispatch => {
   postCoverStyleColors(params).then((res) => {
      if (res.data.success) {
         dispatch(getCoverColours(res.data.success))
      } else {
         dispatch(getCoverColours(false))
      }
   }).catch((err) => {
      getCoverColours(err);
   })
}

export const postSinkSizeIdAction = (params) => dispatch => {
   postSinkSize(params).then((res) => {
      if (res.data.success) {
         dispatch(getCoverTypes(res.data.success))
      } else {
         dispatch(getCoverTypes(false))
      }
   }).catch((err) => {
      getCoverTypes(err);
   })
}

export const getHandleTypesAction = () => dispatch => {
   getHandleTypes().then((res) => {
      if (res.data.success) {
         dispatch(getHandleTypesData(res.data.success))
      } else {
         dispatch(getHandleTypesData(false))
      }
   }).catch((err) => {
      console.log(err, "ERROR")
   })
}
export const getArmatureAction = () => dispatch => {
   getArmatureTypes().then((res) => {
      if (res.data.success) {
         dispatch(getArmatureTypesData(res.data.success))
      } else {
         dispatch(getArmatureTypesData(false))
      }
   }).catch((err) => {
      console.log(err, "ERROR")
   })
}

export const getMirrorTypesAction = () => dispatch => {
   getMirrorTypes().then((res) => {
      if (res.data.success) {
         dispatch(getMirrorTypesData(res.data.success))
      } else {
         dispatch(getMirrorTypesData(false))
      }
   }).catch((err) => {
      console.log(err, "ERROR")
   })
}

export const postTallCabinetAction = (params) => dispatch => {
   postTallCabinet(params).then(res => {
      if (res.data.success) {
         dispatch(getTallCabinets(res.data.success))
      } else {
         dispatch(getTallCabinets(false))
      }
   }).catch(err => {
      console.log(err, "Error")
   })
}
export const postOtherModuleAction = (params) => dispatch => {
   postOtherModules(params).then(res => {
      if (res.data.success) {
         dispatch(getOtherModulesData(res.data.success))
      } else {
         dispatch(getOtherModulesData(false))
      }
   }).catch(err => {
      console.log(err, "Error")
   })
}

export const getOrderSummaryAction = (params) => dispatch => {
   getOrderSummary(params).then(res => {
      if (res.data.success) {
         dispatch(receiveOrderSummary(res.data.success))
      }
   }).catch(err => {
      console.log(err, "Error")
   })
}


export const changeBathroomPictureData = (payload) => ({
   type: CHANGE_BATHROOM_PICTURE,
   payload
})

export const getBahtroomPictureAction = (params, cb, cancelToken, fromDealer) => dispatch => {
   getBahtroomPicture(params, cancelToken).then(res => {
      if (res.data.success) {
         if (!fromDealer) {
            dispatch(changeBathroomPictureData(res.data.success))
         }
      }
      cb && cb(fromDealer ? res.data.success : null);
   }).catch(err => {
      cb && cb();
      console.log(err, "Error")
   })
}

export const changeBathroomAreaPictureData = (payload) => ({
   type: CHANGE_BATHROOM_AREA_PICTURE,
   payload
})

export const getBathroomAreaAction = (params, cb, cancelToken, fromDealer) => dispatch => {
   getBathroomArea(params, cancelToken).then(res => {
      if (res.data.success) {
         if (params.width) {
            cb(res.data.success);
         } else if (!fromDealer) {
            dispatch(changeBathroomAreaPictureData(res.data.success))
         }
      }
      cb && cb(fromDealer ? res.data.success : null);
   }).catch(err => {
      cb && cb(false);
      console.log(err, "Error")
   })
}

export const changeColorSelectionAction = (payload) => ({
   type: CHANGE_COLOR_SELECTION,
   payload
})
export const changeBathroomSelectionAction = (payload) => ({
   type: CHANGE_BATHROOM_SELECTION,
   payload
})
export const setSinkTitleAction = (payload) => ({
   type: SET_BATHROOM_TITLE_SELECTION,
   payload
})
export const changeCoverSelectionAction = (payload) => ({
   type: CHANGE_COVER_SELECTION,
   payload
})
export const changeArmatureSelectionAction = (payload) => ({
   type: CHANGE_ARMATURE_SELECTION,
   payload
})
export const changeTallCabinetSelectionAction = (payload) => ({
   type: CHANGE_TALL_CABINETS_SELECTION,
   payload
})
export const changeOtherModuleSelectionAction = (payload) => ({
   type: CHANGE_OTHER_MODULE_SELECTION,
   payload
})
export const changeMirrorSelectionAction = (payload) => ({
   type: CHANGE_MIRROR_SELECTION,
   payload
})

export const createSessionIdAction = () => ({
   type: SET_USER_SESSION
})

export const createUserBatroomSelectionLogAction = (params) => dispatch => {
   createUserBatroomSelectionLog(params).then(res => {

   }).catch(err => {
      console.log(err, "Error")
   })
}

export const createUserArmatureSelectionLogsAction = (params) => dispatch => {
   createUserArmatureSelectionLogs(params).then(res => {

   }).catch(err => {
      console.log(err, "Error")
   })
}

export const createUserMirrorSelectionLogsAction = (params) => dispatch => {
   createUserMirrorSelectionLogs(params).then(res => {

   }).catch(err => {
      console.log(err, "Error")
   })
}

export const createUserOtherModuleSelectionLogsAction = (params) => dispatch => {
   createUserOtherModuleSelectionLogs(params).then(res => {

   }).catch(err => {
      console.log(err, "Error")
   })
}

export const createUserTallCabinetSelectionLogsAction = (params) => dispatch => {
   createUserTallCabinetSelectionLogs(params).then(res => {

   }).catch(err => {
      console.log(err, "Error")
   })
}
export const checkIsLoading = (payload) => ({
   type: IS_LOADING,
   payload
})

export const setLastRandomId = (payload) => ({
   type: SET_LAST_RANDOM_ID,
   payload
})

export const getFeaturedBathroomDataAction = (featuredId) => dispatch => {
   getFeaturedBathroomData(featuredId).then(res => {
      if (res.data.success) {
         dispatch(setLastRandomId(featuredId))
         dispatch(setAllUserSelection(res.data.success));
      }
   }).catch(err => {
      console.log(err, "Error")
   })
}
export const getLandingJSONData = (payload) => ({
   type: GET_LANDING_DATA,
   payload
})
export const getLandingDataAction = () => {
   return new Promise((resolve, reject) => {
      getLandingData().then(res => {
         if (res.data.success) {
            resolve(res.data.success)
         } else {
            reject(res.data.status)
         }
      }).catch(err => console.log("ERROR: ", err))
   })
}

export const getLanguageJsonData = (payload) => ({
   type: GET_LANGUAGE,
   payload
})

export const getLanguageDataAction = () => {
   return new Promise((resolve, reject) => {
      getLanguageData().then(res => {
         if (res.data) {
            resolve(res.data)
         } else {
            reject(res.data.status)
         }
      }).catch(err => console.log("ERROR: ", err))
   })
}

const receivePlacePictures = (payload) => ({
   type: RECEIVE_PLACE_PICTURES,
   payload
})

export const getPlacePicturesAction = () => dispatch => {
   getPlacePictures().then(res => {
      if (res.data.success) {
         dispatch(receivePlacePictures(res.data.success))
      }
   }).catch(err => {
      console.log(err, "Error")
   })
}

export const checkButtonDisabledOrNot = (payload) => ({
   type: IS_BUTTON_DISABLED,
   payload
})
export const deleteAdditionalProduct = (payload) => ({
   type: DELETE_ADDITIONAL_PRODUCT,
   payload
})
export const changeModelsModal = (payload) => ({
   type: MODEL_CHANGES,
   payload
})
export const toggleCoverModelModal = (payload) => ({
   type: TOGGLE_COVER_MODELS_MODAL,
   payload
})
export const toggleBgModal = (payload) => ({
   type: TOGGLE_BG_MODAL,
   payload
})

export const changePlaceAction = (payload) => ({
   type: CHANGE_PLACE,
   payload
})

export const setLegColorAction = payload => ({
   type: SET_LEG_COLOR,
   payload
})
export const changeDoorSelection = payload => ({
   type: CHANGE_DOOR_SELECTION,
   payload
})

export const sendSmsAction = (params, cb) => {
   return new Promise((resolve, reject) => {
      sendSMS(params).then(result => {
         if (result.data.success) {
            resolve(result.data.success)
         } else {
            resolve(false)
         }
      }).catch(err => {
         
         resolve(false)
      })
   })
}

export const sendEmailToUserAction = (params, cb) => {
   return new Promise((resolve, reject) => {
      sendEmailToUser(params).then(result => {
         if (result.data.success) {
            resolve(result.data.success)
         } else {
            resolve(false)
         }
      }).catch(err => {
         
         resolve(false)
      })
   })
}


export const resendSmsAction = (params, cb) => {
   resendSms(params).then(result => {
      if (result.data.success) {
         cb(result.data.success)
      } else {
         cb(false)
      }
   }).catch(err => {
      console.log(err)
      cb(false)
   })
}

export const saveUserFormAction = (params, cb) => {
   saveUserForm(params).then(result => {
      if (result.data.success) {
         cb(result.data.success)
      } else {
         cb(false)
      }
   }).catch(err => {
      cb(false)
   })
}

export const userByTokenAction = (params, cb) => dispatch => {
   userByToken(params).then(result => {
      if (result.data.success) {
         cb(result.data.success)
      } else {
         cb(false)
      }
   }).catch(err => {
      cb(false)
   })
}
export const saveDealerFormAction = (params, cb) => {
   saveDealerForm(params).then(result => {
      if (result.data.success) {
         cb(result.data.success)
      } else {
         cb(false)
      }
   }).catch(err => {
      cb(false)
   })
}
export const toggleVoice=(payload)=>({
   type:TOGGLE_VOICE,
   payload
})