import React, {Component} from 'react';
import {connect} from "react-redux";
import {ASSETS} from "../../constants/paths";
import {changeModelsModal} from "../../actions";

class CoverOptionModelComponent extends Component {
   render() {
      let{group, coverStyle, modelsCoverStyle, key, modelsColor, activeLang,setCoverColour,selectedItem}=this.props;
      return (
          <div className="vra-option-content" key={key}>
             <p>{JSON.parse(group.transition)[activeLang] || group.name}<span>{(window.innerWidth<640 && group.id === modelsColor.cover_color.cover_color_group_id) && JSON.parse(modelsColor.cover_color.transition)[activeLang]}</span></p>
             <div className="vra-option-block">
                {
                   group.colors.map((colors,inx)=>
                       <div className={`vra-option-item ${(modelsColor&&modelsColor.cover_color&&modelsColor.cover_color.id === colors.cover_color.id && modelsCoverStyle&&modelsCoverStyle.id===coverStyle.id) ? "active":""}`} key={key+"-"+inx.toString()}
                            onClick={(e)=>{
                               this.props.dispatch(changeModelsModal({group, coverStyle, colors}))
                               e.stopPropagation();
                            }}>
                          <div className="vra-round-item">
                             <picture>
                                <img src={`${ASSETS}/${colors.cover_color.img}`} alt={inx.toString()}/>
                             </picture>
                          </div>
                          <span>{JSON.parse(colors.cover_color.transition)[activeLang] || colors.cover_color.name}</span>
                       </div>
                   )
                }
             </div>
          </div>
      );
   }
}
const mapStateToProps=state=>({
   modelsColor: state.userSelectionReducer.modelsChanges ? state.userSelectionReducer.modelsChanges.colors : {},
   modelsCoverStyle:state.userSelectionReducer.modelsChanges ? state.userSelectionReducer.modelsChanges.coverStyle : {},
   setCoverStyle:state.userSelectionReducer.setCoverStyle,
   setCoverColour:state.userSelectionReducer.setCoverColour,
   activeLang: state.commonReducer.activeLang,
})
export default connect(mapStateToProps) (CoverOptionModelComponent);
