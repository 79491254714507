import React, { Component } from 'react';
import ImageSliderComponent from "./imageSliderComponent";
import { connect } from "react-redux";
import {
   setOtherModulesCoverDirection
} from "../../actions";

class RespOtherModuleCoverDirectionSelectionComponent extends Component {
   sliderComponent = React.createRef();
   state = {
      isMobile: false,
      activeIndex: null
   }

   changeSelectedItemByIndex = (index) => {
      let sizeItem = this.props.clickedOtherModule.other_module_cover_directions[index]
      this.setState({
         activeIndex: null
      })
      this.props.dispatch(setOtherModulesCoverDirection(sizeItem))
   }

   setSize = (sizeItem) => {
      let activeIndex = this.props.clickedOtherModule.other_module_cover_directions.findIndex(item=>item.id===sizeItem.id)
      this.setState({
         activeIndex
      })
      this.props.dispatch(setOtherModulesCoverDirection(sizeItem))
   }
   resize = () => {
      if (window.innerWidth > 640) {
         this.setState({
            isMobile: false
         })
      } else {
         this.setState({
            isMobile: true
         })
      }
   }

   componentDidMount() {
      this.resize();
      window.addEventListener("resize", this.resize)
      if (this.props.setOtherModules) {
         let activeIndex = this.props.clickedOtherModule.other_module_cover_directions.findIndex(item=>item.id===this.props.setOtherModules.id)
         this.setState({
            activeIndex
         })
      }
   }

   componentDidUpdate(prevProps) {
      if (prevProps.clickedOtherModule && this.props.clickedOtherModule && this.props.clickedOtherModule.id !== prevProps.clickedOtherModule.id) {
         this.setState({
            activeIndex: 0
         })
      }
   }

   componentWillUnmount() {
      window.removeEventListener("resize", this.resize)
      this.resize();
   }

   render() {
      let { setOtherModuleCoverDirection, activeLang, clickedOtherModule, language } = this.props;
      return (
         <div className="vra-selections-block vra-mobile-block vra-sizes">
            {
               (clickedOtherModule && clickedOtherModule.other_module_cover_directions.length > 1) &&
               <div className="vra-tooltip-item">
                  <span>{language.door_side}</span>
               </div>
            }

            {
               (clickedOtherModule && clickedOtherModule.other_module_cover_directions.length > 1) &&
               <ImageSliderComponent ref={this.sliderComponent}
                  changeItem={this.changeSelectedItemByIndex}
                  activeIndex={this.state.activeIndex}
               >
                  {
                     clickedOtherModule.other_module_cover_directions.map((sizeItem, index) =>
                        <div key={index}
                           className={`vra-option-item vra-color-option ${setOtherModuleCoverDirection && this.state.isMobile && setOtherModuleCoverDirection.id === sizeItem.id ? "active" : ""}`}
                           onClick={() => {
                              this.setSize(sizeItem);
                           }}>
                           <div className="vra-round-item">
                              <p><span>{JSON.parse(sizeItem.cover_direction.transition)[activeLang]}</span></p>
                           </div>
                           {/*<span>{JSON.parse(_sizes.size_groups.transition)[activeLang]}</span>*/}
                        </div>
                     )
                  }


               </ImageSliderComponent>
            }


         </div>
      );
   }
}
const mapStateToProps = state => ({
   setOtherModules: state.userSelectionReducer.setOtherModules,
   setOtherModuleCoverDirection: state.userSelectionReducer.setOtherModuleCoverDirection,
   language: state.languageReducer[state.commonReducer.activeLang],
   activeLang: state.commonReducer.activeLang,
   clickedOtherModule: state.otherModuleReducer.otherModules && state.userSelectionReducer.setOtherModules ? (state.otherModuleReducer.otherModules.find(item => item.id === state.userSelectionReducer.setOtherModules.id) || state.otherModuleReducer.otherModules[0]) : null

})
export default connect(mapStateToProps)(RespOtherModuleCoverDirectionSelectionComponent);
