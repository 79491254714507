import React, {Component} from 'react';
import {ASSETS} from "../constants/paths";
import {connect} from "react-redux";
import {setLegColorAction} from "../actions";

class LegColorSelectionComponent extends Component {
   changeColor = (color) => {
      this.props.dispatch(setLegColorAction(color))
   }
   render() {
      let{legColor,activeLang,handleTypes,language}=this.props;
      return (
          <div className="vra-option-content asdas" >
             <p>{language.choose_colour}</p>
             <div className="vra-option-block">
                {
                   handleTypes.map(color =>
                       <div
                           className={`vra-option-item ${legColor && color.id === legColor.id ? "active":""}`}
                           key={color.id}
                           onClick={(e) => {
                              this.changeColor(color)
                              e.stopPropagation();
                           }}>
                          <div className="vra-round-item">
                             <picture>
                                <img src={`${ASSETS}/${color.img}`} alt={color.name}/>
                             </picture>
                          </div>
                          <span>{JSON.parse(color.transition)[activeLang] || color.name}</span>
                       </div>
                   )
                }
             </div>
          </div>
      );
   }
}
const mapStateToProps=state=>({
   language:state.languageReducer[state.commonReducer.activeLang],
   activeLang:state.commonReducer.activeLang,
   legColor:state.userSelectionReducer.legColor,
   handleTypes: state.sinkReducer.handleTypes || [],
})
export default connect(mapStateToProps) (LegColorSelectionComponent);
