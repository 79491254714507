import React, {Component} from 'react';

class LoaderComponent extends Component {

   render() {
      return (
          <div  className={`loader-root ${this.props.onlyImg?'img-lb-root':''} ${this.props.isPictureGet?'hasLoaded':''}`} >
          <div className="loader-block">
             <div className={`loader`}/>
          </div>
       </div>
      );
   }
}

export default LoaderComponent;
