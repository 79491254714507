import React, {Component} from 'react';
import {connect} from "react-redux";
import {setCoverSelectionType, setSinkCoverDirection} from "../actions";

class CoverStandDirectionComponent extends Component {
   handleClick = (direction,item) => {
      this.props.dispatch(setSinkCoverDirection(direction))
      this.props.dispatch(setCoverSelectionType(item))
   }
   render() {
      let{item,language,activeLang,setSinkSizeDirection}=this.props;
      return (
          <div className="vra-option-container">
             <p>{language.choose_door_side}</p>
             <div className="vra-option-block">
                {
                   item.sink_size_covers.map(x=>x.sink_size.sink_size_cover_directions.map((direction,inx)=>
                       <div className={`vra-option-item ${setSinkSizeDirection&&setSinkSizeDirection.id === direction.id ? "active":""}`} key={inx} onClick={(e)=>{
                          this.handleClick(direction,item)
                          e.stopPropagation();
                       }}>
                          <span>{JSON.parse(direction.cover_direction.transition)[activeLang] || direction.cover_direction.name}</span>
                       </div>
                   ))
                }
             </div>

          </div>
      );
   }
}
const mapStateToProps = (state) => ({
   activeLang: state.commonReducer.activeLang,
   language: state.languageReducer[state.commonReducer.activeLang],
   setSinkSizeDirection:state.userSelectionReducer.setSinkSizeDirection
})
export default connect(mapStateToProps) (CoverStandDirectionComponent);
