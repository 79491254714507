import React, { Component } from 'react';
import { setArmatureType, setArmatureColour } from "../../actions";
import { connect } from "react-redux";
import MobileSliderComponent from '../mobile/mobileSliderComponent';
import ArmatureSelectBoxComponent from "../armatureSelectBoxComponent";

class ArmatureCustomSelectComponent extends Component {
   state = {
      selectedItem: null
   }
   changeSelectedItemByIndex=(index)=>{
      if(window.innerWidth<641){
         this.props.dispatch(setArmatureType(this.props.armatureTypes[index]))
         this.props.dispatch(setArmatureColour(this.props.armatureTypes[index].armature_style_colors[0]))
      }
   }

   render() {
      let { armatureTypes} = this.props;
      return (
         <div className="vra-custom-select-container">
            {
               armatureTypes &&
               <MobileSliderComponent
                  ref={this.sliderComponent}
                  changeItem={this.changeSelectedItemByIndex}
                  activeIndex={armatureTypes && armatureTypes.length > 0 && this.props.setArmatureType ? armatureTypes.findIndex(item => item.id === this.props.setArmatureType.id) : 0}>
                  {
                     armatureTypes.map((item, index) =>
                        <ArmatureSelectBoxComponent  key={index} item={item} index={index}/>
                     )
                  }
               </MobileSliderComponent>
            }

         </div>
      );
   }
}

const mapStateToProps = (state) => ({
   setCoverColour: state.userSelectionReducer.setCoverColour,
   setArmatureColour: state.userSelectionReducer.setArmatureColour,
   setArmatureType: state.userSelectionReducer.setArmatureType,
   activeLang: state.commonReducer.activeLang,
   language: state.languageReducer[state.commonReducer.activeLang]

})

export default connect(mapStateToProps)(ArmatureCustomSelectComponent);
