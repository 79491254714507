import React, {Component} from 'react';
import CoverOptionSelectComponent from "./fields/coverOptionSelectComponent";
import {connect} from "react-redux";
import {setLegColorAction} from "../actions";
import LegColorSelectionComponent from "./legColorSelectionComponent";

class CoverLegComponent extends Component {

   componentDidUpdate(prevProps, prevState, snapshot) {
      if(!prevProps.setCoverSelection && this.props.setCoverSelection && this.props.setCoverSelection.sink_stand.id === 1){
         this.props.dispatch(setLegColorAction(this.props.handleTypes[0]))
      }
   }
   hasLegColors=["in","fr"]
   render() {
      let {item, language, setCoverFunc, setCoverSelection, handleTypes,activeLang} = this.props;
      return (
          <div className={`vra-option-container ${this.props.onlyFr ? "active":''}`}>
             <p>{language.leg}</p>
             {
               //  activeLang === 'fr' ?
               //      <p style={{margin:'15px 0 0'}}>If a leg is requested, it can be ordered separately.</p>
               //      :
                    <div className="vra-option-block">
                       {
                          item.sink_cover_stands.sort((a,b)=>(a.sink_stand.name > b.sink_stand.name) ? 1 : (b.sink_stand.name > a.sink_stand.name) ? -1 : 0).map((stand, inx) =>

                              <CoverOptionSelectComponent key={inx} item={item}
                                                          setCoverFunc={setCoverFunc}
                                                          inx={inx} {...stand}

                              />
                          )
                       }
                    </div>
             }

             {
                /*todo: if there are three drawers, it will be rendered*/
                (setCoverSelection && handleTypes && handleTypes.length > 0) && setCoverSelection.sink_stand.id === 1 &&
                    <LegColorSelectionComponent />
             }
          </div>
      );
   }
}

const mapStateToProps = state => ({
   language: state.languageReducer[state.commonReducer.activeLang],
   setCoverSelection: state.userSelectionReducer.setCoverSelection,
   handleTypes: state.sinkReducer.handleTypes || [],
   activeLang:state.commonReducer.activeLang

})
export default connect(mapStateToProps)(CoverLegComponent);
