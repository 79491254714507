import React, { Component } from 'react'
import { connect } from 'react-redux';
import { ASSETS } from '../../constants/paths';

class SendMailResultModalComponent extends Component {
   render() {
      let { detailValues, orderSummary, coverStyle, activeLang, bathroomAreaPicture, handleType, leg, language } = this.props;
      return (
         <div className='vra-verification-container'>
            <div className="vra-lb-root road-show-root verification-root">
               <div className="vra-lb-wrapper">
                  <div className="vra-lb-container vra-result-mail" >
                     <div className="vra-model-content">
                        <div className="vra-top-block">
                           <h3>{'Campaign registration'}</h3>
                           <i className="gg-close" onClick={() => this.props.changeFormStep(null)} />
                        </div>
                        <div className="vra-send-mail-mid-block">
                        <div className="vra-img-item">
                   <picture>
                      <source
                          srcSet={`${ASSETS}/img/home/send-result-img.png 1x, ${ASSETS}/img/home/send-result-img-2x.png 2x`}
                          media="(min-width: 1401px)"
                          type="image/png"/>
                      <source
                          srcSet={`${ASSETS}/img/home/send-result-img.webp 1x, ${ASSETS}/img/home/send-result-img-2x.webp 2x`}
                          media="(min-width: 1401px)"
                          type="image/webp"/>
                      {/* <source
                          srcSet={`${ASSETS}/img/home/send-result-img-mobile.png 1x, ${ASSETS}/img/home/send-result-img-mobile-2x.png 2x`}
                          media="(max-width: 1400px)"
                          type="image/png"/>
                      <source
                          srcSet={`${ASSETS}/img/home/send-result-img-mobile.png 1x, ${ASSETS}/img/home/send-result-img-mobile-2x.webp 2x`}
                          media="(max-width: 1400px)"
                          type="image/webp"/> */}
                      <img src={`${ASSETS}/img/home/send-result-img.png`} alt="send-result-img"/>
                   </picture>
                </div>
                <div className="vra-text-block vra-send-mail-text-block">
                   <p>E-mail sent.</p>
                   <span>The products have been successfully sent to the e-mail address you provided.</span>
                </div>
                        </div>
                        <div className="vra-campaign-btn-block">
                           <button onClick={() => this.props.changeFormStep(null)} type="button" className="vra-btn">{activeLang === 'en' ? 'Close' : 'Kapat'}</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

         </div>
      )
   }
}
const mapStateToProps = state => ({
   orderSummary: state.orderSummaryReducer,
   coverStyle: state.userSelectionReducer.setCoverStyle || null,
   coverColour: state.userSelectionReducer.setCoverColour || null,
   sinkType: state.userSelectionReducer.setSinkType || null,
   sinkSize: state.userSelectionReducer.setSinkSizes || null,
   coverSelection: state.userSelectionReducer.setCoverSelectionType || null,
   handleType: state.userSelectionReducer.setHandleTypes || null,
   leg: state.userSelectionReducer.legColor || null,
   isCollapseMenuOpen: state.commonReducer.isCollapseMenuOpen,
   activeLang: state.commonReducer.activeLang,
   language: state.languageReducer[state.commonReducer.activeLang],
   bathroomAreaPicture: state.userSelectionReducer.bathroomAreaPicture
})
export default connect(mapStateToProps)(SendMailResultModalComponent)
