import React, { Component } from 'react'

 class RecommendedPriceComponent extends Component {
  render() {
      let{productStep}=this.props
    return (
      <div className={`recommend-item ${productStep===5 ? "step-5" : ""}`}>
         <p>{`${productStep === 5 ?"*Tavsiye edilen satış fiyatıdır. KDV dahildir.":"Tavsiye edilen satış fiyatıdır. KDV dahildir."}`}
         </p>
      </div>
    )
  }
}
export default RecommendedPriceComponent;