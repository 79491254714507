import {GET_OTHER_MODULES} from "../constants/actionTypes";

const initialState={otherModules:[]}

const otherModuleReducer = (state = initialState, action) => {
   switch (action.type) {
      case GET_OTHER_MODULES:
         return {
            ...state,
            otherModules: action.payload
         }
      default:
         return state
   }
}
export default otherModuleReducer;
