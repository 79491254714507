import React, {Component} from 'react';
import {getArmatureAction} from "../actions";
import {connect} from "react-redux";
import ArmatureCustomSelectComponent from "./fields/armatureCustomSelectComponent";

class ArmatureSelectionComponent extends Component {
   componentDidMount() {
      this.props.dispatch(getArmatureAction())
   }

   render() {
      let{armatureTypes,language}=this.props;
      return (
          <div className="vra-colour-selection-container">
             <div className="vra-title-head-item">
                 {
                     language &&
                     <h3>{language.choose_faucet}</h3>
                 }
             </div>
             <ArmatureCustomSelectComponent  armatureTypes={armatureTypes}/>
          </div>
      );
   }
}
const mapStateToProps=(state)=>({
   armatureTypes: state.armatureReducer.armatureTypes,
    language: state.languageReducer[state.commonReducer.activeLang]

})
export default connect(mapStateToProps) (ArmatureSelectionComponent);
