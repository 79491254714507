import React, { Component } from 'react';
import { connect } from "react-redux";
import {
   setTallCabinet,
   setTallCabinetCoverDirection
} from "../../actions";
import MobileSliderComponent from '../mobile/mobileSliderComponent';
import CabinetSelectBoxComponent from "../cabinetSelectBoxComponent";

class CabinetCustomSelectComponent extends Component {
   state = {
      id: null
   }
   changeSelectedItemByIndex=(index)=>{
      if(window.innerWidth<641){
         this.props.dispatch(setTallCabinet(this.props.tallCabinets[index]))
         if (this.props.tallCabinets[index].cover_direction_tall_cabinets.length > 1) {
            this.props.dispatch(setTallCabinetCoverDirection(this.props.tallCabinets[index].cover_direction_tall_cabinets[0]))
         }else{
            this.props.dispatch(setTallCabinetCoverDirection(null))
         }
      }
   }


   openSelectBox = (item) => {
      //TODO: event.target'den yakalanamaz mi?
      //document.getElementById("vra-select-"+index).classList.add("opened")
      this.props.dispatch(setTallCabinet(item))
      this.props.dispatch(setTallCabinetCoverDirection(item.cover_direction_tall_cabinets[0]))
      // this.setCabinetFunc(item.cover_direction_tall_cabinets[0])
   }

   render() {
      let { tallCabinets} = this.props;
      return (
         <div className="vra-custom-select-container">
            {
               tallCabinets &&
               <MobileSliderComponent
                  ref={this.sliderComponent}
                  changeItem={this.changeSelectedItemByIndex}
                  activeIndex={tallCabinets && tallCabinets.length > 0 && this.props.setTallCabinet ? tallCabinets.findIndex(item => item.id === this.props.setTallCabinet.id) : 0}>
                  {
                     tallCabinets.map((item, index) =>
                       <CabinetSelectBoxComponent  key={index} openSelectBox={this.openSelectBox} item={item} index={index}/>
                     )
                  }
               </MobileSliderComponent>
            }

            {/*{
                tallCabinets && tallCabinets.map((item, index) =>
                    item.cover_direction_tall_cabinets.length ==1 &&
                    <SinkCoverItemComponent setCoverFunc={this.setCoverFunc} {...item} key={index}/>
                )
             }*/}
         </div>
      );
   }
}

const mapStateToProps = (state) => ({
   tallCabinets: state.tallCabinetReducer.tallCabinets,
   setTallCabinetCoverDirection: state.userSelectionReducer.setTallCabinetCoverDirection,
   setTallCabinet: state.userSelectionReducer.setTallCabinet,
   activeLang: state.commonReducer.activeLang,
   language: state.languageReducer[state.commonReducer.activeLang]

})

export default connect(mapStateToProps)(CabinetCustomSelectComponent);
