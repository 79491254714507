import React, { Component, Fragment } from 'react';
import { ASSETS } from "../constants/paths";
import { connect } from "react-redux";
import {
   getMirrorTypesAction,
   setMirrorType,
   changeSelectedModulesAction,
   setCoverSelection,
   setCoverSelectionType,
   setMirrorBacklight,
   setMirrorSize,
   changeMirrorSelectionAction
} from "../actions";
import MobileSliderComponent from './mobile/mobileSliderComponent';
import MirrorSelectBoxComponent from "./mirrorSelectBoxComponent";
import { filter } from '@chakra-ui/react';

class MirrorSelectionComponent extends Component {

   componentDidMount() {
      this.props.dispatch(getMirrorTypesAction())
   }


   changeSelectedItemByIndex = (index) => {

      this.props.dispatch(setMirrorType(this.props.mirrorType[index]))
      this.props.dispatch(setMirrorBacklight({light:true,id:this.props.mirrorType[index].id}))
      if(this.props.setMirrorType.mirror_style_sizes.length>0){
         this.props.dispatch(setMirrorSize(this.props.setMirrorType.mirror_style_sizes[0]))
      }
   }

   setCoverFunc = (item) => {
      this.props.dispatch(setCoverSelection(item))

   }
   /*todo: cagrılmıyor!!*/
   setCoverSelectionTypeFunc = (item) => {
      this.props.dispatch(setCoverSelectionType(item))
      if (item.sink_cover_stands && item.sink_cover_stands[0]) {
         this.setCoverFunc(item.sink_cover_stands[0]);
      } else {
         this.setCoverFunc(null);
      }
   }

   setSize = (size, type) => {
      this.props.dispatch(setMirrorSize(size))
      this.props.dispatch(setMirrorType(type))
   }
   updateCoverDirection=()=>{
      if (this.props.setMirrorType && this.props.setMirrorType.id !== 2 && this.props.resMirrorSelection === 'cover_direction' && this.props.selectionType === 'mirror' ) {
         this.props.dispatch(changeMirrorSelectionAction("light"))
      }
      if (this.props.setMirrorType && this.props.setMirrorType.mirror_style_sizes.length === 0 && this.props.selectionType === 'mirror' ) {
         this.props.dispatch(changeMirrorSelectionAction("light"))
      }
      else{
         this.props.dispatch(changeMirrorSelectionAction("mirror_group"))
      }

   }
   filterMirrorByCountry=(mirror)=>{
      return this.props.activeLang === 'de' ? mirror.id != 2 : mirror
   }
   render() {
      let { mirrorType,language,activeLang} = this.props;
      return (
         <Fragment>
            <div className="vra-colour-selection-container">
               <div className="vra-title-head-item">
                  {
                     language &&
                     <h3>{language.choose_mirror}</h3>
                  }

               </div>
               <div className="vra-custom-select-container">
                  {
                     (mirrorType && mirrorType.length > 0) &&
                     <MobileSliderComponent
                        ref={this.sliderComponent}
                        updateCoverDirection={this.updateCoverDirection}
                        changeItem={this.changeSelectedItemByIndex}
                        activeIndex={mirrorType && mirrorType.length > 0 && this.props.setMirrorType ? mirrorType.findIndex(item => item.id === this.props.setMirrorType.id) : 0}>
                        {
                        mirrorType.filter(this.filterMirrorByCountry).map((item, index) =>
                           <MirrorSelectBoxComponent  key={index} setSize={this.setSize} item={item} index={index}/>
                        )
                        }
                     </MobileSliderComponent>
                  }
               </div>
               {/*{

                   <MobileSliderComponent changeItem={this.changeSelectedItemByIndex}
                                          activeIndex={mirrorType && mirrorType.length > 0 && setMirrorType ? mirrorType.findIndex(item => item.id === setMirrorType.id) : 0}>

                   </MobileSliderComponent>
                }*/}

            </div>
         </Fragment>

      );
   }
}

const mapStateToProps = state => ({
   mirrorType: state.mirrorReducer.mirrorType || [],
   setMirrorType: state.userSelectionReducer.setMirrorType,
   mirrorSize: state.userSelectionReducer.mirrorSize,
   coverTypes: state.sinkReducer.coverTypes || [],
   setCoverSelectionType: state.userSelectionReducer.setCoverSelectionType,
   withBacklight: state.userSelectionReducer.mirrorWithBackLight,
   activeLang: state.commonReducer.activeLang,
   language: state.languageReducer[state.commonReducer.activeLang],
   selectionType:state.commonReducer.selectionType || null,
   resMirrorSelection:state.productStepReducer.resMirrorSelection || null

})
export default connect(mapStateToProps)(MirrorSelectionComponent);
