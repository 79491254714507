export const moduleArr = [

    {
        name: "Armatür ekle ",
        id: 1,
        objKey: "armatureStyleData",
        summaryName: "Armatür",
        productType: "armature",
        languageKey: "add_faucet",
        languageName: "faucet"
    },
    {
        name: "Boy dolabı ekle",
        id: 2,
        objKey: "tallCabinetInfo",
        summaryName: "Boy Dolabı ",
        productType: "tallCabinet",
        languageKey: "add_tall_unit",
        languageName: "tall_unit"
    },
    {
        name: "Ayna ekle",
        id: 3,
        objKey: "mirrorData",
        summaryName: "Ayna",
        productType: "mirror",
        languageKey: "add_mirror",
        languageName: "mirror"
    },
    {
        name: "Ek saklama alanı ekle",
        id: 4,
        objKey: "otherModules",
        summaryName: "Ayna",
        productType: "otherModule",
        languageKey: "add_additional_storage",
        languageName: "additional_storage"
    }
]
export const languages = [
    {
        name: "Türkçe",
        abbr: "Tr",
        code: "tr",
        showingLangs:["en","tr"]
    },
    {
        name: "English",
        abbr: "Eng",
        code: "en",
        showingLangs:["en","tr"]
    },
    {
        name: "Uk",
        abbr: "Uk",
        code: "uk"
    },
    {
        name: "Pусский",
        abbr: "Ru",
        code: "ru"
    },
    {
        name: "Français",
        abbr: "Fr",
        code: "fr"
    },
    {
        name: "Italiano",
        abbr: "It",
        code: "it",
        showingLangs:["en","it"]
    },
    {
        name: "Deutsch",
        abbr: "De",
        code: "de"
    },
    {
        name: "India",
        abbr: "In",
        code: "in"
    }
]
export const orderDocumentHTML = `
<!doctype html>
 <html lang="en">
 <head>
     <meta charset="UTF-8">
     <meta name="viewport"
           content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0">
     <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
    <meta name="x-apple-disable-message-reformatting">
    <meta name="viewport" content="width=320, user-scalable=yes">
     <title>Document</title>

     <style>
      
         body {
            background-color: #ffffff;
            font-family: "Arial", helvetica, sans-serif;
            -webkit-font-smoothing: antialiased;
            font-size: 14px;
            line-height: 1.4;
            margin: 0 auto;
            -ms-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
            /*min-width: 100%;*/
            width: 100%;
            padding: 0;

        }
        .tdp-item{
            font-family: "Palanquin-Medium", Arial, sans-serif;
            font-size: 12px;
            line-height: 12px;
            margin: 0 0 4px;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
            border-spacing: 0;
            border: 0;
            cellpadding: 0;
            cellspacing: 0;
            mso-line-height-rule: exactly;
            mso-margin-bottom-alt: 0;
            mso-margin-top-alt: 0;
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
            border-spacing: 0;
            padding: 0;
            margin-left: auto;
             margin-right: auto;
        }

        table td {
            border-collapse: collapse;
            font-family: sans-serif;
            font-size: 14px;
            margin: 0;
            vertical-align: middle;
            padding: 0;
        }

        img {
            display: block;
            margin: 0;
        }

        a {
            text-decoration: none !important;
        }
        .desc{
            font-family: "OpenSans-Regular", Arial, sans-serif;
        
        }


         @font-face {
             font-family: "Palanquin-Bold";
             src: local("Palanquin-Bold"), url("../../public/assets/fonts/Palanquin-Bold.eot");
             src: local("Palanquin-Bold"), url("../../public/assets/fonts/Palanquin-Bold.eot?#iefix") format("embedded-opentype"), url("../../public/assets/fonts/Palanquin-Bold.woff") format("woff");
             font-weight: normal;
             font-style: normal;
             font-display: swap;
         }

         @font-face {
             font-family: "Palanquin-SemiBold";
             src: local("Palanquin-SemiBold"), url("../../public/assets/fonts/Palanquin-SemiBold.eot");
             src: local("Palanquin-SemiBold"), url("../../public/assets/fonts/Palanquin-SemiBold.eot?#iefix") format("embedded-opentype"), url("../../public/assets/fonts/Palanquin-SemiBold.woff") format("woff");
             font-weight: normal;
             font-style: normal;
             font-display: swap;
         }

         @font-face {
             font-family: "Palanquin-Medium";
             src: local("Palanquin-Medium"), url("../../public/assets/fonts/Palanquin-Medium.eot");
             src: local("Palanquin-Medium"), url("../../public/assets/fonts/Palanquin-Medium.eot?#iefix") format("embedded-opentype"), url("../../public/assets/fonts/Palanquin-Medium.woff") format("woff");
             font-weight: normal;
             font-style: normal;
             font-display: swap;
         }

         @font-face {
             font-family: "Palanquin-Regular";
             src: local("Palanquin-Regular"), url("../../public/assets/fonts/Palanquin-Regular.eot");
             src: local("Palanquin-Regular"), url("../../public/assets/fonts/Palanquin-Regular.eot?#iefix") format("embedded-opentype"), url("../../public/assets/fonts/Palanquin-Regular.woff") format("woff");
             font-weight: normal;
             font-style: normal;
             font-display: swap;
         }

         @font-face {
             font-family: "Palanquin-Light";
             src: local("Palanquin-Light"), url("../../public/assets/fonts/Palanquin-Light.eot");
             src: local("Palanquin-Light"), url("../../public/assets/fonts/Palanquin-Light.eot?#iefix") format("embedded-opentype"), url("../../public/assets/fonts/Palanquin-Light.woff") format("woff");
             font-weight: normal;
             font-style: normal;
             font-display: swap;
         }

         @font-face {
             font-family: 'OpenSans-SemiBold';
             src: local("OpenSans-SemiBold"), url("../../public/assets/fonts/OpenSans-SemiBold.eot");
             src: local('OpenSans-SemiBold'), url("../../public/assets/fonts/OpenSans-SemiBold.eot?#iefix") format("embedded-opentype"), url("../../public/assets/fonts/OpenSans-SemiBold.woff") format("woff");
             font-weight: normal;
             font-style: normal;
             font-display: swap;
         }

         @font-face {
             font-family: 'OpenSans-Regular';
             src: local('OpenSans-Regular'), url("../../public/assets/fonts/OpenSans-Regular.eot");
             src: local('OpenSans-Regular'), url("../../public/assets/fonts/OpenSans-Regular.eot?#iefix") format("embedded-opentype"), url("../../public/assets/fonts/OpenSans-Regular.woff") format("woff");
             font-weight: normal;
             font-style: normal;
             font-display: swap;
         }

         @font-face {
             font-family: 'OpenSans-Bold';
             src: local('OpenSans-Bold'), url("../../public/assets/fonts/OpenSans-Bold.eot");
             src: local('OpenSans-Bold'), url("../../public/assets/fonts/OpenSans-Bold.eot?#iefix") format("embedded-opentype"), url("../../public/assets/fonts/OpenSans-Bold.woff") format("woff");
             font-weight: normal;
             font-style: normal;
             font-display: swap;
         }

         p {
             margin: 0;
         }


         .vra-head-item {
             font-family: "Palanquin-Bold", Arial, sans-serif;
             font-size: 14px;

         }

         th p, span {
             font-family: "Palanquin-SemiBold", Arial, sans-serif;
             font-size: 10px;
             color: #808080;
         }

         th span {
             font-size: 12px;
         }
         .p1{
            font-family: "OpenSans-Regular", Arial, sans-serif;
            font-size: 12px;
            color:black;
         }


         .vra-price-item {
             font-family: "OpenSans-Bold", Arial, sans-serif;
             font-size: 11px;
             color: #000;
             position: relative;
         }
         .vra-price-item.coded{
            color: #ABABAB;
         }
         .vra-price-item.coded::after{
            content: "";
            width: 40px;
            height: 1px;
            position:absolute;
            top: 13px;
            right: 0;
            background-color: #ABABAB;
          
         }

         .vra-code {
             font-family: "OpenSans-Regular", Arial, sans-serif;
             font-size: 11px;
             line-height: 0;
             margin:0;
         }

         .vra-desc {
             font-family: "Palanquin-Regular", Arial, sans-serif;
             font-size: 11px;
             line-height: 12px;   
         }
         .tt-item{
            font-family: "Palanquin-Bold", Arial, sans-serif;
            font-size: 21px;
         }
          .plqmdm{
            font-family: "Palanquin-SemiBold", Arial, sans-serif;
            font-size: 12px;
          }
     </style>
 </head>
 <body  align="center" bgcolor="#FFFFFF" style="margin: 0 auto;">
 <table id="table" style="width: 595px; margin: 0 auto; height: 100%; background-color: white;">
 <tr>
     <td width="12px"></td>
     <td>
         <table>
             <tr>
                 <td>
                     <table>

                         <tr>
                             <td>
                                 <img style="width: 86px; height: 24px;" src="/assets/img/home/logo.png" alt="">
                             </td>
                         </tr>
                         <tr style="height: 24px">
                             <td>
                                 <p class="tt-item">Root</p>
                             </td>
                         </tr>
                         <tr style="height: 24px"></tr>
                         <tr>
                             <td align="center" style="object-fit: contain;">
                                 <img style="object-fit: contain;" width="100%" src="##BATHAREAIMG##" alt="">
                             </td>
                         </tr>
                     </table>
                 </td>
             </tr>
             <tr style="width: 480px;">
                 <td>
                     <table>
                         <tr style="height: 10px"></tr>
                         <tr>
                             <td width="16px"></td>
                             <td>
                                 <table style="width: 545px; max-width: 545px; margin: 0 auto"
                                     align="center">
                                     <tr>
                                         <p style="margin-bottom: 0" class="vra-head-item">##CHOSEN_PRODUCTS##
                                         </p>
                                         <td style="width: 100%">
                                             <table>
                                                 <tr style="border-bottom:1px solid #E1E1E1; height: 45px">
                                                     <th style="text-align: left; width: 248px">
                                                         <p style="font-family: 'Palanquin-SemiBold', Arial, Helvetica, sans-serif; font-size: 10px;" >##PRODUCT_NAME##</p>
                                                     </th>
                                                     ##PRODUCT_CODE_HTML##
                                                     <th style="text-align: right">
                                                         <span style="font-family: 'Palanquin-SemiBold', Arial, Helvetica, sans-serif; font-size: 10px;" >##PRICE_HEAD_TEXT##</span>
                                                     </th>
                                                 </tr>
                                             </table>
                                         </td>
                                     </tr>
                                     <tr style="border-bottom:1px solid #E1E1E1">
                                         <td style="width: 100%">
                                             <table>                                                                                            
                                                 <tr>
                                                     ##WASHBASIN##
                                                     ##BATHROOM_CABINET##
                                                     ##BATHROOM_CABINET_ALL_PRICES##
                                                 </tr>
                                                 <tr style="height: 25px;"></tr>
                                             </table>
                                         </td>
                                     </tr>
                                     ##EXTRA_ITEMS##
                                     <tr style="text-align: right; height: 45px;">
                                         <td>
                                             <table>

                                                 <tr>
                                                     ##TOTAL_PRICE_TEXT##
                                                     <td><span class="vra-price-item">##TOTAL_PRICE##</span></td>
                                                 </tr>
                                             </table>
                                         </td>
                                     </tr>

                                 </table>
                             </td>
                             <td width="16px"></td>
                         </tr>
                     </table>
                 </td>
             </tr>
             <tr>
                 <td>
                     <table>
                         <table>
                             <tr style="height:24px"></tr>
##ECZ_LOGO##
<!--                             <tr style="height: 56px;vertical-align: bottom; border-top: 1px solid #E1E1E1; border-bottom: 1px solid #E1E1E1;">-->
<!--                                 <td-->
<!--                                     style="width: 100%; vertical-align: middle;">-->
<!--                                     <img width="85px" height="32px" src="/assets/img/home/eczacibasi2x.png" alt="">-->
<!--                                         </td>-->
<!--                                 <td-->
<!--                                     style="vertical-align: middle; margin:0 0 5px 0; heigth:100% ">-->
<!--&lt;!&ndash;                                     <a style="width: 155px; text-align:right; margin:0 10px 5px 0; display: block;" href="#" target="_blank" class="p1">##URL##</a>&ndash;&gt;-->
<!--                                 </td>-->
<!--                             </tr>-->
                             <tr style="height:10px"></tr>
                         </table>
                     </table>
                 </td>
             </tr>
         </table>
     </td>
     <td width="12px" />
 </tr>

</table>
 </body>
 </html>
 `;
