import axios from "axios";
import { API_URL, DATA_URL } from "../constants/paths";
import { getActiveLang } from "../helper"

export const getCoverStyles = () => {
   return axios.get(`${API_URL}getCoverStyles?lang=${getActiveLang()}`)
}
export const getSinkTypes = () => {
   return axios.get(`${API_URL}getSinkTypes?lang=${getActiveLang()}`)
}
export const getSinkSizes = () => {
   return axios.get(`${API_URL}getSinkSizes?lang=${getActiveLang()}`)
}
export const getHandleTypes = () => {
   return axios.get(`${API_URL}getHandles?lang=${getActiveLang()}`)
}
export const getArmatureTypes = () => {
   return axios.get(`${API_URL}getArmatureTypes?lang=${getActiveLang()}`)
}
export const getMirrorTypes = () => {
   return axios.get(`${API_URL}getMirrorTypes?lang=${getActiveLang()}`)
}

export const postOtherModules = (params) => {
   return axios.post(`${API_URL}getOtherModules?lang=${getActiveLang()}`, { cover_style_id: params })
}

export const postCoverStyleColors = (params) => {
   return axios.post(`${API_URL}getCoverStyleColors?lang=${getActiveLang()}`, { cover_style_id: params })
}
export const postSinkSize = (params) => {
   return axios.post(`${API_URL}getSinkCovers?lang=${getActiveLang()}`, { size_id: params })
}
export const postTallCabinet = (params) => {
   return axios.post(`${API_URL}getTallCabinets?lang=${getActiveLang()}`, { cover_style_id: params })
}

export const getOrderSummary = (params) => {
   return axios.post(`${API_URL}getOrderSummary?lang=${getActiveLang()}`, params)
}

export const getBahtroomPicture = (params, cancelToken) => {
   return axios.post(`${API_URL}image/getBahtroomPicture?lang=${getActiveLang()}`, params, { cancelToken: cancelToken.token });
}

export const getBathroomArea = (params, cancelToken) => {
   return axios.post(`${API_URL}image/getBathroomArea?lang=${getActiveLang()}`, params, { cancelToken: cancelToken ? cancelToken.token : undefined });
}

export const createUserBatroomSelectionLog = (params) => {
   return axios.post(API_URL + "createUserBatroomSelectionLog", params);
}

export const createUserArmatureSelectionLogs = (params) => {
   return axios.post(API_URL + "createUserArmatureSelectionLogs", params);
}

export const createUserMirrorSelectionLogs = (params) => {
   return axios.post(API_URL + "createUserMirrorSelectionLogs", params);
}

export const createUserOtherModuleSelectionLogs = (params) => {
   return axios.post(API_URL + "createUserOtherModuleSelectionLogs", params);
}

export const createUserTallCabinetSelectionLogs = (params) => {
   return axios.post(API_URL + "createUserTallCabinetSelectionLogs", params);
}


export const getFeaturedBathroomData = (featuredId) => {
   return axios.post(`${API_URL}getFeaturedBathroomData?lang=${getActiveLang()}`, "featuredId=" + featuredId);
}
export const getLandingData = () => {
   return axios.get(DATA_URL + "/landing.json?" + Date.now())
}
export const getLanguageData = () => {
   return axios.get(DATA_URL + "/language.json?" + Date.now())
}

export const getPlacePictures = () => {
   return axios.get(API_URL + "getPlacePictures");
}

export const sendSMS = (params) => {
   let config = {
      headers: {
         "reCaptcha-Response": params.recaptcha,
      }
   }
   return axios.post(`${API_URL}sendSms`, { phone_number: params.phone, name: params.name, surname:params.surname,email:params.email,permission:params.permission  }, config)
}

export const sendEmailToUser = (params) => {
   let config = {
      headers: {
         "reCaptcha-Response": params.recaptcha,
      }
   }
   return axios.post(`${API_URL}sendEmailToUser`, params, config)
}

export const resendSms = (params) => {
   let config = {
      headers: {
         "reCaptcha-Response": params.recaptcha,
      }
   }
   return axios.post(`${API_URL}resendSms`, { phone_number: params.phone, name: params.name, surname:params.surname,email:params.email,permission:params.permission  }, config)
}

export const saveUserForm = (params) => {
   return axios.post(`${API_URL}saveUserForm`, params)
}

export const userByToken = (params) => {
   return axios.get(`${API_URL}userByToken`, {params})
}

export const saveDealerForm=(params)=>{
   let config = {
      headers: {
         "reCaptcha-Response": params.recaptcha,
      }
   }
   return axios.post(`${API_URL}saveDealerForm`,params,config)
}