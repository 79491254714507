import React, {Component} from 'react';
import {connect} from "react-redux";
import {changePlaceAction, getPlacePicturesAction, toggleBgModal} from "../../actions";
import BackgroundColorComponent from "../backgroundColorComponent";

class ChangeBgModalComponent extends Component {
   lbCon=React.createRef();
   state = {
      selectedPlace: null
   }
   constructor(props){
      super(props);
      if(!props.placePictures){
         this.props.dispatch(getPlacePicturesAction())
      }
   }

   openBgModal = () => {
      this.props.dispatch(toggleBgModal(!this.props.isBgModal))
   }

   savePlace = () => {
      this.props.dispatch(changePlaceAction(this.state.selectedPlace))
      this.props.dispatch(toggleBgModal(!this.props.isBgModal))
   }

   changePlace = (selectedPlace) => {
      this.setState({
         selectedPlace
      })
   }

   render() {
      let{isBgModal,placePictures,language}=this.props;
      return (
          <div className={`vra-lb-root vra-models vra-bg-modal ${isBgModal ? "open":""}`} onClick={(e)=>{
            if(this.lbCon&& !this.lbCon.current.contains(e.target)){
               this.openBgModal()
            }
          }}>
             <div className="vra-lb-wrapper">
                <div className="vra-lb-container" ref={this.lbCon}>
                   <div className="vra-model-content">
                      <div className="vra-top-block">
                         <h3>{language.change_bg_color}</h3>
                         <i className="gg-close" onClick={this.openBgModal}/>
                      </div>
                      <div className="vra-cover-container">
                         {
                            placePictures &&
                            <BackgroundColorComponent 
                            selectedPlace={this.state.selectedPlace} 
                            changePlace={this.changePlace} 
                            placePictures={placePictures} />
                         }
                      </div>
                   </div>
                   <div className="vra-btn-block">
                      <button type="button" className="vra-btn vra-white" onClick={this.openBgModal}>{language.abort}</button>
                      <button type="button" className="vra-btn" onClick={this.savePlace} >{language.save}</button>
                   </div>
                </div>
             </div>
          </div>
      );
   }
}
const mapStateToProps=state=>({
   isBgModal: state.commonReducer.isBgModal || false,
   placePictures: state.sinkReducer.placePictures,
   language: state.languageReducer[state.commonReducer.activeLang]
})
export default connect(mapStateToProps) (ChangeBgModalComponent);
