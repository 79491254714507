import {GET_LANGUAGE} from "../constants/actionTypes";

const initialState={};

const LanguageReducer = (state=initialState,action) => {
    switch (action.type) {
        case GET_LANGUAGE:
            return{
                ...state,
              ...action.payload
            }
        default:
            return state
    }
}
export default LanguageReducer;