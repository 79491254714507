import React, {Component} from 'react';
import AddModuleComponent from "./common/addModuleComponent";
import ChosenProductComponent from "./chosenProductComponent";
import SummaryCheckBoxComponent from "./fields/summaryCheckBoxComponent";
import SelectedProductModalComponent from "./modal/selectedProductModalComponent";
import {getCoverStylesAction, getOrderSummaryAction} from "../actions/index";
import {moduleArr} from '../constants/const';
import {connect} from "react-redux";
import {ASSETS} from "../constants/paths";
import ModalButtonComponent from "./modal/modalButtonComponent";
import PreviewProductComponent from "./common/PreviewProductComponent";
import { ROADSHOWLINK } from '../constants/routePaths';
import RecommendedPriceComponent from './common/recommendedPriceComponent';

class OrderSummaryComponent extends Component {


   constructor(props) {
      super(props);
      if (props.userSelection.setCoverColour) {
         this.getSummaryData(props);
      }
      if(!props.coverStyleData || props.coverStyleData.length===0){
         this.props.dispatch(getCoverStylesAction())
      }
   }

   getSummaryData = (props) => {
      let height;
      if (this.props.userSelection.setSinkSizes && this.props.userSelection.setSinkSizes.sink_size_covers[0]) {
         height = this.props.userSelection.setSinkSizes.sink_size_covers[0].height;
         if (this.props.userSelection.setCoverSelectionType && this.props.userSelection.setSinkSizes.sink_size_covers.find(item => item.id === this.props.userSelection.setCoverSelectionType.id)) {
            height = this.props.userSelection.setSinkSizes.sink_size_covers.find(item => item.id === this.props.userSelection.setCoverSelectionType.id).height;
         }
      }
      props.dispatch(getOrderSummaryAction({
         cover_style_color_id: props.userSelection.setCoverColour.id,
         sink_size_id: props.userSelection.setSinkSizes.id,
         sink_cover_id: props.userSelection.setCoverSelectionType.id,
         sink_stand_id: null,
         handle_id: props.userSelection.setHandleTypes && props.userSelection.setHandleTypes.id,
         armature_style_color_id: props.userSelection.setArmatureColour ? props.userSelection.setArmatureColour.id : null,
         cover_direction_tall_cabinet_id: props.userSelection.setTallCabinetCoverDirection ? props.userSelection.setTallCabinetCoverDirection.id : null,
         cover_style_id: props.userSelection.setCoverStyle ? props.userSelection.setCoverStyle.id : null,
         cover_color_id: props.userSelection.setCoverColour ? props.userSelection.setCoverColour.id : null,
         mirror_style_id: props.userSelection.setMirrorType ? props.userSelection.setMirrorType.id : null,
         other_module_id: props.userSelection.setOtherModules ? props.userSelection.setOtherModules.id : null,
         other_module_cover_direction_id: props.userSelection.setOtherModuleCoverDirection ? props.userSelection.setOtherModuleCoverDirection.cover_direction.id : null,
         stand_id: props.userSelection.setCoverSelection ? props.userSelection.setCoverSelection.sink_stand.id : null,
         cover_direction_id: props.userSelection.setSinkSizeDirection ? props.userSelection.setSinkSizeDirection.cover_direction.id : null,
         mirror_size_id: props.userSelection.mirrorSize ? props.userSelection.mirrorSize.size.id : null,
         sink_type_id: props.userSelection.setSinkType ? props.userSelection.setSinkType.id : null,
         tallcabinet_color_id: props.userSelection.setCoverColour ? props.userSelection.setCoverColour.cover_color.id : null,
         withBackLight: props.userSelection.mirrorWithBackLight ? props.userSelection.mirrorWithBackLight.light : 0,
         mirror_cover_direction:this.props.userSelection.selectedMirrorCoverDirection ? this.props.userSelection.selectedMirrorCoverDirection.id : null,
         size_name: `${props.userSelection.setSinkSizes.name.replace("cm","").trim()}x${height}`,
         stand_color_id:this.props.userSelection.legColor ? this.props.userSelection.legColor.id : null
      }))
   }

   componentDidUpdate(prevProps) {
      /*todo: sorulacak !!!*/
      if ((!prevProps.userSelection.setCoverColour && this.props.userSelection.setCoverColour) || (this.props.activeLang!==prevProps.activeLang)) {
         this.getSummaryData(this.props);
      }
      if(prevProps.activeLang !== this.props.activeLang){
         /*todo: yanıp sönme durumu için burda güncellendi [Emre]*/
         this.props.dispatch(getCoverStylesAction())
      }

   }
   /*todo: sıra önemli*/
   arr = [
      "setArmatureType",
      "setTallCabinet",
      "setMirrorType",
      "setOtherModules"
   ]
   // filteredByLang=(moduleItem)=>this.props.activeLang === "fr" ? moduleItem.objKey !== "otherModules":moduleItem
   filteredByLang=(moduleItem)=>{
      if(this.props.activeLang === "fr"){
         return moduleItem.objKey !== "otherModules"
      }
      if(this.props.activeLang === "in"){
         return moduleItem.objKey !== "tallCabinetInfo" && moduleItem.objKey !== "otherModules"
      }
      else{
         return moduleItem
      }
   }
   render() {
      let {orderSummary, language, userSelection,setCoverStyle} = this.props;
      return (
          <div className="vra-summary-container">
             {/*<div className="vra-title-head-item">
               <h3>{language.additional_products}</h3>
               <p>{language.order_documents}</p>
            </div>*/}

             {
                (orderSummary && language) &&
                <div className="vra-summary-block">
                   {
                      orderSummary.bathroomCabinet &&
                      <PreviewProductComponent/>
                      /*<ChosenProductComponent bathroomCabinet={orderSummary.bathroomCabinet}
                                              handleData={orderSummary.handleData} stand={orderSummary.stand}/>*/
                   }
                   {
                      // moduleArr.filter(item => orderSummary[item.objKey]).length > 0 &&
                      <div className="vra-added-product-block">
                         <h3>{language.additional_products}</h3>
                         {
                            moduleArr.map((item, index) =>
                                (orderSummary[item.objKey] && this.props[this.arr[item.id - 1]]) &&
                                <SummaryCheckBoxComponent {...item} orderSummary={orderSummary[item.objKey]}
                                                          key={index} index={index}/>
                            )
                         }
                      </div>
                   }
                   {
                      moduleArr.filter(this.filteredByLang).map((item, index) =>
                          ((!orderSummary || !orderSummary[item.objKey] || !this.props[this.arr[item.id - 1]])) &&
                          <AddModuleComponent orderSummary={orderSummary} key={index} {...item} index={item.id-1}/>
                      )
                   }
               {/*    {*/}
               {/*   this.props.activeLang==="tr" && <RecommendedPriceComponent/>*/}
               {/*}*/}

                </div>
             }

             {/*{
               isModalOpen ?
                  <SelectedProductModalComponent />
                  : <div />
            }*/}
             <ModalButtonComponent
                 orderSummary={orderSummary}
                 setCoverStyle={setCoverStyle}
                 downloadPicturePath={userSelection.bathroomAreaPicture ? userSelection.bathroomAreaPicture : (ASSETS + "/img/home/order-document-img.jpg")}/>
          </div>
      );
   }
}

const mapStateToProps = (state) => ({
   isModalOpen: state.commonReducer.isSelectedProductModalOpen,
   orderSummary: state.orderSummaryReducer,
   userSelection: state.userSelectionReducer,
   language: state.languageReducer[state.commonReducer.activeLang],
   setArmatureType: state.userSelectionReducer.setArmatureType,
   setTallCabinet: state.userSelectionReducer.setTallCabinet,
   setMirrorType: state.userSelectionReducer.setMirrorType,
   setOtherModules: state.userSelectionReducer.setOtherModules,
   setCoverStyle: state.userSelectionReducer.setCoverStyle,
   activeLang: state.commonReducer.activeLang,
   coverStyleData: state.sinkReducer.coverStyles || [],

})

export default connect(mapStateToProps)(OrderSummaryComponent);
