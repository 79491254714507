import React, {Component} from 'react';
import {ASSETS} from "../constants/paths";
import {connect} from "react-redux";

class SinkOptionSelectionComponent extends Component {

   render() {
      let{group,key,sinks,setSinkSizes,setSinkType,activeLang,setCoverStyle}=this.props
      return (
          <div className="vra-option-content" key={key}>
             <p>{JSON.parse(group.transition)[activeLang] || group.name}</p>
             <div className="vra-option-block">
                {
                   group.sizes.sort((a,b)=>parseInt(a.name) > parseInt(b.name) ? 1 : parseInt(b.name) > parseInt(a.name) ? -1 : 0).map((size,inx)=>
                   !(setCoverStyle && setCoverStyle.id === 5 && size.id === 12) &&
                       <div className={`vra-option-item ${setSinkSizes&&setSinkSizes.id===size.id && setSinkType.id === sinks.id?"active":""}`} key={key+"-"+inx.toString()}
                            onClick={(e)=>{
                               this.props.setSinks(sinks,group,size);
                               e.stopPropagation();
                            }}>
                         {/* <div className="vra-round-item">
                             <picture>
                                <img src={`${ASSETS}/${colors.cover_color.img}`} alt={inx.toString()}/>
                             </picture>
                          </div>*/}
                          
                          <span>{JSON.parse(size.transition)[activeLang] || size.name}</span>
                       </div>
                   )
                }

             </div>
          </div>
      );
   }
}
const mapStateToProps=state=>({
   setSinkSizes:state.userSelectionReducer.setSinkSizes,
   setSinkType:state.userSelectionReducer.setSinkType,
   activeLang: state.commonReducer.activeLang,
   setCoverStyle:state.userSelectionReducer.setCoverStyle
})
export default connect(mapStateToProps) (SinkOptionSelectionComponent);
