import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HOME_PAGE, ROADSHOWLINK, ROADSHOW_HOME_PAGE } from "../constants/routePaths";
import {
   setRandomValue,
   resetUserSelections
} from "../actions";
import { ASSETS } from "../constants/paths";
import VerificationComponent from '../components/modal/verificationComponent';
import CampaignResultModalComponent from '../components/modal/campaignResultModalComponent';
import CampaigneModalComponent from '../components/modal/campaigneModalComponent';
import QrImageModalComponent from '../components/modal/qrImageModalComponent';


class RoadshowLandingPageComponent extends Component {
   state = {}
   changeFormStep = (step) => {
      this.setState({
         formStep:step
      })
      if(!step){
         this.resetValues()
      }
   }

   resetValues=()=>{
      this.formValues={}
   }

   componentDidMount() {
      this.props.dispatch(resetUserSelections(true))
      this.props.dispatch(setRandomValue(false))
   }
   setFormValues=(values)=>{
      this.formValues={
         phone_number:values.phone,
         validation_code:null,
         address:null,
         name:values.name,
         surname:values.surname,
         email:values.email,
         subscribed:values.permission
      }
   }

   openConfigurator = () => {
      this.props.history.push((window.location.pathname.indexOf(ROADSHOWLINK) !== -1 ? ROADSHOW_HOME_PAGE.replace(":lang", this.props.activeLang) : HOME_PAGE.replace(":lang", this.props.activeLang)))

   }

   openForm = () => {
      this.setState({
         formStep:1
      })
   }


   setDetailValues=(detailValues)=>{
      this.detailValues=detailValues
   }

   render() {

      return (
         <main className="vra-home-page-root">

            <div className="vra-home-container">
               <div className="vra-content-block">
                  <div className="vra-content-item">
                     <div className="vra-img-text">
                        <div className="vra-img-item">
                           <picture>
                              <source srcSet={`${ASSETS}/img/landing/home-campaign.webp 1x, ${ASSETS}/img/landing/home-campaign_2x.webp 2x`}
                                 type="image/webp" />
                              <source srcSet={`${ASSETS}/img/landing/home-campaign.png 1x, ${ASSETS}/img/landing/home-campaign_2x.png 2x`}
                                 type="image/jpeg" />
                              <img src={`${ASSETS}/img/landing/home-campaign.png`} alt="VitrA" />
                           </picture>

                        </div>

                        <div className="vra-text-item">
                           <p>Kampanyaya katıl</p>
                           <p>Ücretsiz montaj imkanından yararlanabilmek için kampanyaya katılın.</p>
                        </div>
                     </div>

                     <div onClick={this.openForm} className="vra-btn">Şimdi katıl</div>
                  </div>
               </div>
               <div className="vra-content-block">
                  <div className="vra-content-item">

                     <div className="vra-img-text">
                        <div className="vra-img-item">
                           <picture>
                              <source srcSet={`${ASSETS}/img/landing/home-design.webp 1x, ${ASSETS}/img/landing/home-design_2x.webp 2x`}
                                 type="image/webp" />
                              <source srcSet={`${ASSETS}/img/landing/home-design.png 1x, ${ASSETS}/img/landing/home-design_2x.png 2x`}
                                 type="image/jpeg" />
                              <img src={`${ASSETS}/img/landing/home-design.png`} alt="VitrA" />
                           </picture>

                        </div>
                        <div className="vra-text-item">
                           <p>Tasarıma başla ve kampanyaya katıl</p>
                           <p>Adım adım tarz, çeşit, ölçü, renk seçimleri yaparak hayalinizdeki banyoyu kolaylıkla tasarlayın.</p>

                        </div>
                     </div>


                     <div onClick={this.openConfigurator} className="vra-btn">Hemen başla</div>
                  </div>

               </div>
            </div>
            {
               this.state.formStep === 1 &&
               <CampaigneModalComponent setFormValues={this.setFormValues} changeFormStep={this.changeFormStep} />
            }
            {
               this.state.formStep === 2 &&
               <VerificationComponent setDetailValues={this.setDetailValues} formValues={this.formValues} changeFormStep={this.changeFormStep} />

            }
            {
               this.state.formStep === 3 &&
               <QrImageModalComponent changeFormStep={this.changeFormStep} detailValues={this.detailValues}/>
            }
         </main>
      )
   }
}

const mapStateToProps = (state) => ({
   attractedProduct: state.commonReducer.landingData ? state.commonReducer.landingData.attracted_product : [],
   activeLang: state.commonReducer.activeLang,
   language: state.languageReducer[state.commonReducer.activeLang]
})
export default connect(mapStateToProps)(RoadshowLandingPageComponent);
