import React, { Component } from 'react'
import Recaptcha from 'react-google-recaptcha';
import { SITE_KEY } from '../../constants/paths';

class RecaptchaComponent extends Component {
   ref=React.createRef()
   onRecaptchaExpired=()=>{
      this.props.input.onChange(null)
   }
   render() {
      let{input,meta:{error,touched},recaptchaRef}=this.props;
      return (
         <div className={`recaptcha-item ${touched && error ? 'error':""}`}>
            <Recaptcha
               ref={recaptchaRef}
               sitekey={SITE_KEY}
               onExpired={this.onRecaptchaExpired}
               onChange={input.onChange}
            />
         </div>
      )
   }
}

export default RecaptchaComponent
