import React, { Component } from 'react'
import { Form, Field } from 'react-final-form';
import { formatOnlyNumber } from '../../helper';
import TextInputFieldComponent from "../fields/textInputFieldComponent"
import { resendSmsAction, saveUserFormAction, sendSmsAction } from "../../actions"
import { connect } from 'react-redux';
import { ASSETS } from '../../constants/paths';
import moment from "moment"
import LoaderComponent from '../common/loaderComponent';
const validate = (values) => {
   const errors = {}
   if (!values.verification_code || values.verification_code.trim().length < 5) {
      errors.verification_code = true
   }
   return errors;
}
class VerifyFormComponent extends Component {
   myInterval;
   _timerStr='02:00'
   state={
      currentTime: moment(`${moment().format("YYYY-MM-DD HH")}:${this._timerStr}`),
      timerStr:this._timerStr,
      isLoading:false,
      hasError:false
   }
   timer=()=>{
      this.myInterval = setInterval(()=>{
         this.setState({
            timerStr: this.state.currentTime.add("-1", "second").format("mm:ss")
         },()=>{
            if(this.state.timerStr === '00:00'){
               this.clearTimer()
               this.setState({
                  timerStr: "",
                  currentTime: null,
               })
            }
         })
      },1000)
      
   }
   clearTimer=()=>{
      clearInterval(this.myInterval)
      // this.myInterval=null
   }
   componentDidMount(){
      this.timer()
   }
   componentWillUnmount(){
      this.clearTimer()
   }
   restartTimer=()=>{
      this.setState({
         currentTime: moment(`${moment().format("YYYY-MM-DD HH")}:${this._timerStr}`),
         timerStr:this._timerStr,
      })
      this.timer()
      this.resendSms()
   }
   resendSms=()=>{
      let params={
         name:this.props.formValues.name,
         permission:this.props.formValues.subscribed,
         surname:this.props.formValues.surname,
         phone:this.props.formValues.phone_number,
         email:this.props.formValues.email
      }
      resendSmsAction(params,(res)=>{
         if(res){

         }else{
            console.log('HATA sendSmsAction');
         }
      })
   }
   submit = (values) => {
      this.setState({
         isLoading:true,
         hasError:false
      })
      saveUserFormAction({...this.props.formValues,validation_code:values.verification_code,picture_obj:this.getPictureObj(),picturePath:this.props.userSelection.bathroomAreaPicture ? this.props.userSelection.bathroomAreaPicture : (ASSETS + "/img/BanyoAlani/Mekan/p_7457_u.png")},(res)=>{
         if(res){
            this.props.setDetailValues(res)
            this.props.changeFormStep(3)
         }else{
         }
         this.setState({
            isLoading:false,
            isError:res?false:true
         })
      })
      
   }

   getPictureObj = () => {
      let height;
      if (this.props.userSelection.setSinkSizes && this.props.userSelection.setSinkSizes.sink_size_covers[0]) {
         height = this.props.userSelection.setSinkSizes.sink_size_covers[0].height;
         if (this.props.userSelection.setCoverSelectionType && this.props.userSelection.setSinkSizes.sink_size_covers.find(item => item.id === this.props.userSelection.setCoverSelectionType.id)) {
            height = this.props.userSelection.setSinkSizes.sink_size_covers.find(item => item.id === this.props.userSelection.setCoverSelectionType.id).height;
         }
      }
      return {
         serie: this.props.userSelection.setCoverStyle ? this.props.userSelection.setCoverStyle.name : null,
         color: this.props.userSelection.setCoverColour ? `${this.props.userSelection.setCoverType.id == 3 ? this.props.userSelection.setCoverType.name + " " : ""}${this.props.userSelection.setCoverColour.cover_color.name}` : null,
         placeColorName: this.props.userSelection.setCoverColour ? `${this.props.userSelection.setCoverColour.cover_color.name}` : null,
         size: this.props.userSelection.setSinkSizes ? this.props.userSelection.setSinkSizes.name : null,
         cover: this.props.userSelection.setCoverSelectionType ? this.props.userSelection.setCoverSelectionType.name : null,
         direction: this.props.userSelection.setSinkSizeDirection ? this.props.userSelection.setSinkSizeDirection.cover_direction.name : "Sag",
         handleColor: this.props.userSelection.setHandleTypes ? this.props.userSelection.setHandleTypes.name : null,
         stand_size: this.props.userSelection.setCoverSelection ? this.props.userSelection.setSinkSizes.name : null,
         sinkSerie: this.props.userSelection.setSinkType ? this.props.userSelection.setSinkType.serie_name : null,
         height,
         is_stand: this.props.userSelection.setCoverSelection && this.props.userSelection.setCoverSelection.sink_stand ? this.props.userSelection.setCoverSelection.sink_stand.name : null,
         armature: this.props.userSelection.setArmatureColour ? this.props.userSelection.setArmatureType.name : null,
         armatureColorId: this.props.userSelection.setArmatureColour ? this.props.userSelection.setArmatureColour.id : null,
         otherModule: this.props.userSelection.setOtherModules ? this.props.userSelection.setOtherModules.name : null,
         otherModuleDirection: this.props.userSelection.setOtherModuleCoverDirection ? this.props.userSelection.setOtherModuleCoverDirection.cover_direction.name : null,
         mirror: this.props.userSelection.setMirrorType ? this.props.userSelection.setMirrorType.name : null,
         tallCabinet: this.props.userSelection.setTallCabinetCoverDirection ? this.props.userSelection.setTallCabinetCoverDirection.cover_direction.name : null,
         mirrorSize: this.props.userSelection.mirrorSize ? this.props.userSelection.mirrorSize.size.name : null,
         tallCabinetSizeName: this.props.userSelection.setTallCabinet && this.props.userSelection.setTallCabinet.tall_cabinet_sizes && this.props.userSelection.setTallCabinet.tall_cabinet_sizes[0] ? this.props.userSelection.setTallCabinet.tall_cabinet_sizes[0].size_name : null,
         otherModuleSizeName: this.props.userSelection.setSinkTitle ? this.props.userSelection.setSinkTitle.name : "",
         otherModuleSize: this.props.userSelection.setOtherModules ? this.props.userSelection.setOtherModules.size : null,
         withBackLight: this.props.userSelection.mirrorWithBackLight ? this.props.userSelection.mirrorWithBackLight.light : 0,
         place: this.props.userSelection.place,
         mirrorCoverDirection:this.props.userSelection.selectedMirrorCoverDirection ? this.props.userSelection.selectedMirrorCoverDirection.name : "sag",
         leg_color_name: this.props.userSelection.legColor ? this.props.userSelection.legColor.name : null
      }
   }

   render() {
      return (
         <Form
            onSubmit={this.submit}
            validate={validate}
            render={({ handleSubmit, errors }) => (
               <form className='vra-verify-form-root' onSubmit={handleSubmit}>

                  <div className="input-block">
                     <Field
                        name="verification_code"
                        type="text"
                        label="Doğrulama Kodu"
                        format={formatOnlyNumber}
                        msg={'Doğrulama kodunu yanlış girdiniz.'}
                        hasError={this.state.isError}
                        component={TextInputFieldComponent}
                     />
                  </div>
                  <div className="vra-verify-info-item">
                     <p>Bir önceki adımda girdiğiniz telefon numarasına gelen SMS’deki kodu yapıştırın.</p>
                     {
                        this.state.currentTime ?
                        <p>Doğrulama kodunu <span>{this.state.timerStr}</span>’sn içinde tekrar gönderebilirsiniz.</p>
                        :
                        <p>Doğrulama kodu size ulaşmadı mı ?</p>
                     }
                     {
                        !this.state.currentTime &&
                        <button onClick={this.restartTimer} type="button" className="vra-btn">Tekrar Gönder</button>
                     }
                  </div>
                  <div className="vra-campaign-btn-block">
                     <button onClick={()=>this.props.changeFormStep(null)} type="button" className="vra-btn vra-white">Vazgeç</button>
                     <button type="submit" className={`vra-btn ${Object.values(errors).length ? 'disabled' : ''}`}>Devam et</button>
                  </div>
                  {
                     this.state.isLoading &&
                     <LoaderComponent/>
                  }
               </form>
            )}
         />
      )
   }
}


const mapStateToProps = (state) => ({
   productStep: state.productStepReducer.productStepNumber,
   userSelection: state.userSelectionReducer,
   isFromRandom: state.commonReducer.isFromRandom,
   sinkSize: state.sinkReducer.sinkSize || [],
   selectSinkTypeId: state.userSelectionReducer.setSinkType ? state.userSelectionReducer.setSinkType.id : null,
   selectionType: state.commonReducer.selectionType,
   isOpenModelsModal: state.commonReducer.isOpenModelsModal || false,
   isBgModal: state.commonReducer.isBgModal || false
})

export default connect(mapStateToProps)(VerifyFormComponent)
