import React, {Component} from 'react';
import {ASSETS} from "../../constants/paths";
import {assignProductStep, toggleSelectedProductModal} from "../../actions";
import { connect } from 'react-redux';

class AddAdditionalProductComponent extends Component {
   clickSound
   audio= new Audio(`${ASSETS}/sounds/clickExport.mp3`)
   addClickSound=()=>{
       this.clickSound = this.audio.play()
   }
   changeStepValue = (step) => {
      this.props.dispatch(toggleSelectedProductModal(true))
      this.props.dispatch(assignProductStep(step))
   }
   render() {
       let {language,voice}=this.props
      return (
          <div className="vra-additional-container">
             <div className="vra-additional-block">
                <div className="vra-img-item">
                   <picture>
                      <source
                          srcSet={`${ASSETS}/img/home/additional-product.png 1x, ${ASSETS}/img/home/additional-product-2x.png 2x`}
                          media="(min-width: 1401px)"
                          type="image/png"/>
                      <source
                          srcSet={`${ASSETS}/img/home/additional-product.webp 1x, ${ASSETS}/img/home/additional-product-2x.webp 2x`}
                          media="(min-width: 1401px)"
                          type="image/webp"/>
                      <source
                          srcSet={`${ASSETS}/img/home/additional-mobile.png 1x, ${ASSETS}/img/home/additional-mobile-2x.png 2x`}
                          media="(max-width: 1400px)"
                          type="image/png"/>
                      <source
                          srcSet={`${ASSETS}/img/home/additional-mobile.png 1x, ${ASSETS}/img/home/additional-mobile-2x.webp 2x`}
                          media="(max-width: 1400px)"
                          type="image/webp"/>
                      <img src={`${ASSETS}/img/home/additional-product.png`} alt="additional"/>
                   </picture>
                </div>
                <div className="vra-text-block">
                   <p>{language.add_products}</p>
                   <span>{language.bath_desc}</span>
                </div>
             </div>
             <button onClick={()=> {
                 this.changeStepValue(6)
                 if(voice)this.addClickSound()
             }} className="vra-btn">{language.add_now}</button>
          </div>
      );
   }
}

const mapStateToProps = (state) => ({
    language: state.languageReducer[state.commonReducer.activeLang],
    voice:state.commonReducer.voice
})
export default connect(mapStateToProps)(AddAdditionalProductComponent);
