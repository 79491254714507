import React, {Component, Fragment} from 'react';
import {ASSETS} from "../constants/paths";
import {checkIsLoading, getHandleTypesAction, setHandleTypes} from "../actions";
import {connect} from "react-redux";
import MobileSliderComponent from "./mobile/mobileSliderComponent";
import SkeletonComponent from "./common/skeletonComponent";

class HandleSelectionComponent extends Component {

   componentDidMount() {
      // this.props.dispatch(getHandleTypesAction())
      if(!this.props.handleTypes || this.props.handleTypes.length===0){
         this.props.dispatch(checkIsLoading(true))
      }
   }

   componentDidUpdate(prevProps) {
      /*if ((!prevProps.handleTypes || prevProps.handleTypes.length === 0) && (this.props.handleTypes && this.props.handleTypes.length > 0) && !this.props.setHandle) {
         this.handleOnclick(this.props.handleTypes[0])
      }*/
      if ((!prevProps.handleTypes || prevProps.handleTypes.length === 0) && (this.props.handleTypes && this.props.handleTypes.length > 0)) {
         this.props.dispatch(checkIsLoading(false))
      }
   }

   handleOnclick = (item) => {
      this.props.dispatch(setHandleTypes(item))
   }
   changeSelectedItemByIndex = (index) => {
      this.props.dispatch(setHandleTypes(this.props.handleTypes[index]))
   }

   render() {
      let {handleTypes, setHandle, isLoading,activeLang,language} = this.props
      return (
          <Fragment>
             {
                (handleTypes && handleTypes.length > 0 && !isLoading) &&
                <div className="vra-selections-container vra-hdl">
                   <div className="vra-title-head-item">
                      <h3>{language.choose_handle}</h3>
                   </div>
                   {

                      <MobileSliderComponent changeItem={this.changeSelectedItemByIndex}
                                             activeIndex={handleTypes && handleTypes.length > 0 && setHandle ? handleTypes.findIndex(item => item.id === setHandle.id) : 0}>
                         {
                            handleTypes && handleTypes.map((item, index) =>
                                <div
                                    className={`vra-selections-item ${setHandle && setHandle.id === item.id ? "active" : ""}`}
                                    key={index} onClick={() => {
                                   this.handleOnclick(item);


                                }}>
                                   <div className="vra-img-item">
                                      <picture>
                                         <img src={`${ASSETS}${item.img}`} alt="handle"/>
                                      </picture>
                                   </div>
                                   <span>{JSON.parse(item.transition)[activeLang] || item.name}</span>
                                </div>
                            )
                         }
                      </MobileSliderComponent>
                   }
                </div>
             }
             {
                isLoading &&
                <SkeletonComponent/>
             }

          </Fragment>
      );
   }
}

const mapStateToProps = (state) => ({
   handleTypes: state.sinkReducer.handleTypes || [],
   setHandle: state.userSelectionReducer.setHandleTypes,
   isLoading: state.commonReducer.isLoading,
   activeLang: state.commonReducer.activeLang,
   language: state.languageReducer[state.commonReducer.activeLang]

})
export default connect(mapStateToProps)(HandleSelectionComponent);
