import React, { Component } from 'react'
import { Form } from 'react-final-form';
import { Field } from 'react-final-form';
import TextInputFieldComponent from "../fields/textInputFieldComponent"
import CheckBoxComponent from "../fields/checboxFieldComponent"
import RecaptchaComponent from "../fields/recaptchaComponent"
import { saveDealerFormAction } from '../../actions/index';
import LoaderComponent from '../common/loaderComponent';
import SuccessModal from '../modal/successModal';
const validate = (values) => {
   const errors = {}
   if (!values.dealer_code || !values.dealer_code.trim().length) {
      errors.dealer_code = true
   }
   if (!values.recaptcha || !values.recaptcha.trim().length) {
      errors.recaptcha = true
   }
   return errors;
}
class LandingFormComponent extends Component {
   recaptchaRef=React.createRef()
   state = {
      isLoading: false,
      hasError: false,
      isSuccess:false
   }
   submit = (values) => {
      this.setState({
         isLoading: true,
         hasError: false
      })
      let token = window.location.pathname.split('/')[4]
      saveDealerFormAction({ ...values, token }, (res) => {
         if (res) {
            this.setState({
               isSuccess:true
            })
         }else{
            this.resetRecaptcha()
         }
         this.setState({
            isLoading: false,
            hasError: res ? false : true
         })
      })
   }
   closeModal=()=>{
      this.setState({
         isSuccess:false
      })
   }
   resetRecaptcha=()=>{
      this.recaptchaRef.current.reset()
   }
   render() {
      return (
         <div className='vra-dealer-form'>
            <div className="vra-top-block">
               <h3>Bayi / Mağaza ve satış temsilcisi bilgileri</h3>
            </div>
            <Form
               onSubmit={this.submit}
               validate={validate}
               render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                     <div className="input-container">
                        <div className="input-block">
                           <Field
                              name="dealer_code"
                              label="Bayi / mağaza kodu*"
                              type="text"
                              msg={this.state.hasError ? "Bayi / mağaza kodu geçerli değil." : 'Bayi / mağaza kodu giriniz.'}
                              hasError={this.state.hasError}
                              component={TextInputFieldComponent}
                           />
                        </div>
                        <div className="input-block bottom">
                           <Field
                              name="salesman_name"
                              type="text"
                              label="Satış temsilcisi adı"
                              msg={'Satış temsilcisi adı giriniz.'}
                              component={TextInputFieldComponent}
                           />
                           <Field
                              name="salesman_surname"
                              type="text"
                              msg={'Satış temsilcisi soyadı giriniz.'}
                              label="Satış temsilcisi soyadı"

                              component={TextInputFieldComponent}
                           />

                        </div>
                     </div>

                     <div className="vra-campaign-btn-block">
                        <Field
                           name="recaptcha"
                           recaptchaRef={this.recaptchaRef}
                           component={RecaptchaComponent}
                        />
                        <button type="submit" className="vra-btn">Devam et</button>
                     </div>
                     {
                        this.state.isLoading && <LoaderComponent />
                     }
                  </form>
               )}
            />
            {
               this.state.isSuccess &&  <SuccessModal closeModal={this.closeModal}/>
            }
         </div>
      )
   }
}

export default LandingFormComponent
