import React, { Component } from 'react'
import { connect } from 'react-redux';
import VerifyFormComponent from '../forms/verifyFormComponent'
import axios from "axios";
import { getBathroomAreaAction } from '../../actions';

let bathroomAreacancelToken;
class VerificationComponent extends Component {
   componentDidMount(){
      if(!this.props.userSelection.bathroomAreaPicture){
         this.getBathroomAreaPicture();
      }
   }
   getBathroomAreaPicture = () => {
      let height;
      if (this.props.userSelection.setSinkSizes && this.props.userSelection.setSinkSizes.sink_size_covers[0]) {
         height = this.props.userSelection.setSinkSizes.sink_size_covers[0].height;
         if (this.props.userSelection.setCoverSelectionType && this.props.userSelection.setSinkSizes.sink_size_covers.find(item => item.id === this.props.userSelection.setCoverSelectionType.id)) {
            height = this.props.userSelection.setSinkSizes.sink_size_covers.find(item => item.id === this.props.userSelection.setCoverSelectionType.id).height;
         }
      }
      if (typeof bathroomAreacancelToken != typeof undefined) {
         bathroomAreacancelToken.cancel("Operation canceled due to new request.")
      }

      bathroomAreacancelToken = axios.CancelToken.source()
      this.isPictureGet = false;
      this.forceUpdate();
      this.props.dispatch(getBathroomAreaAction({
         serie: this.props.userSelection.setCoverStyle ? this.props.userSelection.setCoverStyle.name : null,
         color: this.props.userSelection.setCoverColour ? `${this.props.userSelection.setCoverType.id == 3 ? this.props.userSelection.setCoverType.name + " " : ""}${this.props.userSelection.setCoverColour.cover_color.name}` : null,
         placeColorName: this.props.userSelection.setCoverColour ? `${this.props.userSelection.setCoverColour.cover_color.name}` : null,
         size: this.props.userSelection.setSinkSizes ? this.props.userSelection.setSinkSizes.name : null,
         cover: this.props.userSelection.setCoverSelectionType ? this.props.userSelection.setCoverSelectionType.name : null,
         direction: this.props.userSelection.setSinkSizeDirection ? this.props.userSelection.setSinkSizeDirection.cover_direction.name : "Sag",
         handleColor: this.props.userSelection.setHandleTypes ? this.props.userSelection.setHandleTypes.name : null,
         stand_size: this.props.userSelection.setCoverSelection ? this.props.userSelection.setSinkSizes.name : null,
         sinkSerie: this.props.userSelection.setSinkType ? this.props.userSelection.setSinkType.serie_name : null,
         height,
         is_stand: this.props.userSelection.setCoverSelection && this.props.userSelection.setCoverSelection.sink_stand ? this.props.userSelection.setCoverSelection.sink_stand.name : null,
         armature: this.props.userSelection.setArmatureColour ? this.props.userSelection.setArmatureType.name : null,
         armatureColorId: this.props.userSelection.setArmatureColour ? this.props.userSelection.setArmatureColour.id : null,
         otherModule: this.props.userSelection.setOtherModules ? this.props.userSelection.setOtherModules.name : null,
         otherModuleDirection: this.props.userSelection.setOtherModuleCoverDirection ? this.props.userSelection.setOtherModuleCoverDirection.cover_direction.name : null,
         mirror: this.props.userSelection.setMirrorType ? this.props.userSelection.setMirrorType.name : null,
         tallCabinet: this.props.userSelection.setTallCabinetCoverDirection ? this.props.userSelection.setTallCabinetCoverDirection.cover_direction.name : null,
         mirrorSize: this.props.userSelection.mirrorSize ? this.props.userSelection.mirrorSize.size.name : null,
         tallCabinetSizeName: this.props.userSelection.setTallCabinet && this.props.userSelection.setTallCabinet.tall_cabinet_sizes && this.props.userSelection.setTallCabinet.tall_cabinet_sizes[0] ? this.props.userSelection.setTallCabinet.tall_cabinet_sizes[0].size_name : null,
         otherModuleSizeName: this.props.userSelection.setSinkTitle ? this.props.userSelection.setSinkTitle.name : "",
         otherModuleSize: this.props.userSelection.setOtherModules ? this.props.userSelection.setOtherModules.size : null,
         withBackLight: this.props.userSelection.mirrorWithBackLight ? this.props.userSelection.mirrorWithBackLight.light : 0,
         place: this.props.userSelection.place,
         mirrorCoverDirection:this.props.userSelection.selectedMirrorCoverDirection ? this.props.userSelection.selectedMirrorCoverDirection.name : "sag",
         leg_color_name: this.props.userSelection.legColor ? this.props.userSelection.legColor.name : null
         //width:true
      }, (result) => {
         if(result === false && this.retryLoad!==true){
            setTimeout(() => {
            this.retryLoad = true;
            this.getBathroomAreaPicture();
            }, 2000);
         }else{
            this.retryLoad = false; 
            this.isPictureGet = true;
            this.forceUpdate();
         }
      }, bathroomAreacancelToken))
   }
   render() {
      let{changeFormStep,formValues,setDetailValues}=this.props;
      return (
         <div className='vra-verification-container'>
            <div className="vra-lb-root road-show-root verification-root">
               <div className="vra-lb-wrapper">
                  <div className="vra-lb-container" >
                     <div className="vra-model-content">
                        <div className="vra-top-block">
                           <h3>{'Telefonunuzu doğrulayın'}</h3>
                           <i className="gg-close" onClick={()=>changeFormStep(null)} />
                        </div>
                        <VerifyFormComponent setDetailValues={setDetailValues} formValues={formValues} changeFormStep={changeFormStep}/>
                        
                     </div>
                    
                  </div>
               </div>
            </div>

         </div>
      )
   }
}


const mapStateToProps = (state) => ({
   userSelection: state.userSelectionReducer,
   sinkSize: state.sinkReducer.sinkSize || [],
   selectSinkTypeId: state.userSelectionReducer.setSinkType ? state.userSelectionReducer.setSinkType.id : null,
   selectionType: state.commonReducer.selectionType
})

export default connect(mapStateToProps)(VerificationComponent)
