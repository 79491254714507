import {RECEIVE_ORDER_SUMMARY, DELETE_ADDITIONAL_PRODUCT} from "../constants/actionTypes";

const initialState={selectedModules:["armatureStyleData","mirrorData","otherModules","tallCabinetInfo"]};

const OrderSummaryReducer = (state=initialState,action) => {
   switch (action.type) {
      case RECEIVE_ORDER_SUMMARY:
         return{
            ...action.payload,
            selectedModules:state.selectedModules
         }
      // case CHANGE_SELECTED_MODULES:
      //    let moduleIndex = state.selectedModules.indexOf(action.payload);
      //    if(moduleIndex === -1){
      //       return {
      //          ...state,
      //          selectedModules:[
      //             ...state.selectedModules,
      //             action.payload
      //          ]
      //       }
      //    }else{
      //       return {
      //          ...state,
      //          selectedModules:[
      //             ...state.selectedModules.slice(0,moduleIndex),
      //             ...state.selectedModules.slice(moduleIndex+1)
      //          ]
      //       }
      //    }
      case DELETE_ADDITIONAL_PRODUCT:
         switch (action.payload) {
            case "armature":
               return{
                  ...state,
                  armatureStyleData:null
               }
            case "tallCabinet":
               return{
                  ...state,
                  tallCabinetInfo:null
               }
            case "mirror":
               return{
                  ...state,
                  mirrorData:null
               }
            case "otherModule":
               return{
                  ...state,
                  otherModules:null
               }
            default:
               return state;
         }
      default:
         return state
   }
}
export default OrderSummaryReducer;
