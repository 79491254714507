import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/main.css';
import App from './App';
import {createStore, applyMiddleware} from "redux";
import reducers from "./reducers";
import thunk from 'redux-thunk'
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import { ChakraProvider } from "@chakra-ui/react"
import {Fonts} from "./constants/fonts";
import theme from "./constants/customTheme";
//import {createLogger} from 'redux-logger'

const middleware = [thunk];
if (process.env.NODE_ENV !== 'production') {
    // middleware.push(createLogger());
}
let store = createStore(reducers, window.initialStoreData ? window.initialStoreData : {}, applyMiddleware(...middleware));


ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
           <ChakraProvider resetCSS={true} theme={theme}>
              <Fonts/>
              <App/>
           </ChakraProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);
