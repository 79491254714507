import React, {Component, Fragment} from 'react';
import {setCoverSelection} from "../../actions";
import {connect} from "react-redux";
class OtherOptionSelectComponent extends Component {

   render() {
      let {cover_direction,id,other_module_id,setOtherModulesFunc,coverDirection,activeLang,otherModule} = this.props;
      return (
          <Fragment>
             {
                cover_direction&&
                <div className={`vra-option-item ${coverDirection && coverDirection.id === id ? "active":""}`}
                     onClick={(event) => {
                        setOtherModulesFunc({cover_direction,id,other_module_id},otherModule)
                        event.stopPropagation()
                     }}>
                   <span>{JSON.parse(cover_direction.transition)[activeLang] || cover_direction.name}</span>
                </div>
             }
          </Fragment>

      );
   }
}

const mapStateToProps = (state) => ({
   coverDirection:state.userSelectionReducer.setOtherModuleCoverDirection,
   activeLang:state.commonReducer.activeLang
})
export default connect(mapStateToProps)(OtherOptionSelectComponent);
