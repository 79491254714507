import React, { Component } from "react";

class ProductItemComponent extends Component {
    render() {
        let {name,desc,product_code} = this.props;
        return (
            <div className="vra-campaign-product-item">
                <div className="vra-info-item">
                    <p>{name}</p>
                    <span>{desc}</span>
                </div>
                <p className="vra-product-item">
                    {product_code}
                </p>
            </div>
        )
    }
};

export default ProductItemComponent