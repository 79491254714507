import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import ImageSliderComponent from "./imageSliderComponent";
import {ASSETS} from "../../constants/paths";
import {setCoverColour, setCoverStyle, setCoverType} from '../../actions';

class resColourOptionComponent extends Component {
   sliderComponent = React.createRef();
   state = {
      isMobile: false,
      activeIndex: null
   }
   changeSelectedItemByIndex = (index) => {
      let coverStyle = this.props.setCoverStyle || this.props.coverStyles[0];
      let activeColor;
      let activeColorGroup;
      let activeIndex = 0;
      coverStyle.color_groups.map((groupItem) => {
         groupItem.colors.map((colorItem) => {
            if (activeIndex === index) {
               activeColor = colorItem;
               activeColorGroup = groupItem
            }
            activeIndex++
         })
      })
      this.setState({
         activeIndex: null
      })
      this.props.dispatch(setCoverColour(activeColor))
      this.props.dispatch(setCoverType(activeColorGroup))
   }
   setColor = (color, coverStyle, colorGroup) => {
      let index = 0;
      let activeIndex;
      coverStyle.color_groups.map((groupItem) => {
         groupItem.colors.map((colorItem) => {
            if (colorItem.id === color.id) {
               activeIndex = index
            }
            index++
         })
      })
      this.setState({
         activeIndex
      })
      this.props.dispatch(setCoverColour(color))
      this.props.dispatch(setCoverStyle(coverStyle))
      this.props.dispatch(setCoverType(colorGroup))
   }
   resize = () => {
      if (window.innerWidth > 640) {
         this.setState({
            isMobile: false
         })
      } else {
         this.setState({
            isMobile: true
         })
      }
   }

   setSelectedItem = () => {
      let index = 0;
      let activeIndex;
      this.props.clickedCoverStyle.color_groups.map((groupItem) => {
         groupItem.colors.map((colorItem) => {
            if (colorItem.id === this.props.setCoverColour.id) {
               activeIndex = index
            }
            index++
         })
      })
      this.setState({
         activeIndex
      })
   }

   componentDidMount() {
      if (this.props.setCoverColour&&this.props.clickedCoverStyle) {
         this.setSelectedItem()
      }
      this.resize();
      window.addEventListener("resize", this.resize)
   }

   componentDidUpdate(prevProps){
      if(prevProps.clickedCoverStyle && this.props.clickedCoverStyle && this.props.clickedCoverStyle.id!==prevProps.clickedCoverStyle.id){
         this.setState({
            activeIndex: 0
         })
      }
      if(this.props.setCoverColour && !prevProps.clickedCoverStyle && this.props.clickedCoverStyle){
         this.setSelectedItem()
      }
   }

   componentWillUnmount() {
      window.removeEventListener("resize", this.resize)
      this.resize();
   }

   render() {
      let {setCoverStyle, setCoverColour, coverStyles, activeLang,clickedCoverStyle} = this.props;
      let {isMobile} = this.state
      return (
          <div className="vra-selections-block vra-mobile-block">
             {
                this.props.setCoverType &&
                <div className="vra-tooltip-item">
                   <span>{`${JSON.parse(this.props.setCoverType.transition)[activeLang]} | ${JSON.parse(setCoverColour.cover_color.transition)[activeLang]}`}</span>
                </div>
             }
             {
                clickedCoverStyle &&
                <ImageSliderComponent ref={this.sliderComponent} changeItem={this.changeSelectedItemByIndex}
                                      activeIndex={this.state.activeIndex}
                                      groupAndCounts={clickedCoverStyle.color_groups.map((item) => item.colors.length)}>
                   {
                      clickedCoverStyle.color_groups.map((g, index) =>
                          <Fragment key={index}>
                             {
                                g.colors.map((_colors, inx) => {
                                       return (
                                           <div key={inx}
                                               className={`vra-option-item vra-color-option ${setCoverColour && isMobile && setCoverColour.id === _colors.id ? "active" : ""}`}
                                               onClick={() => {
                                                  this.setColor(_colors, clickedCoverStyle, g);

                                               }}>
                                              <div className="vra-round-item">
                                                 <picture>
                                                    <img src={`${ASSETS}${_colors.cover_color.img}`} alt=""/>
                                                 </picture>
                                              </div>
                                              {/*<span>{JSON.parse(_colors.cover_color.transition)[activeLang]}</span>*/}
                                           </div>
                                       )
                                    }
                                )
                             }
                             {
                                (index !== clickedCoverStyle.color_groups.length - 1) &&
                                <span className="vra-line-item"/>
                             }
                          </Fragment>
                      )
                   }
                </ImageSliderComponent>
             }
          </div>
      );
   }
}

export default connect(state => ({
   setCoverColour: state.userSelectionReducer.setCoverColour,
   setCoverType: state.userSelectionReducer.setCoverType,
   setCoverStyle: state.userSelectionReducer.setCoverStyle,
   setArmatureColour: state.userSelectionReducer.setArmatureColour,
   coverStyles: state.sinkReducer.coverStyles || [],
   activeLang: state.commonReducer.activeLang,
   clickedCoverStyle: state.sinkReducer.coverStyles && state.userSelectionReducer.setCoverStyle ? (state.sinkReducer.coverStyles.find(item=>item.id===state.userSelectionReducer.setCoverStyle.id) || state.sinkReducer.coverStyles[0]) : null
}))(resColourOptionComponent);
