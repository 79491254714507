import React, {Component} from 'react';
import {connect} from "react-redux";
import {setCoverSelection, setCoverSelectionType, setSinkCoverDirection} from "../actions";
import CoverStandDirectionComponent from "./coverStandDirectionComponent";
import CoverLegComponent from "./coverLegComponent";

class CoverSelectBoxComponent extends Component {
   selectBlock = React.createRef();
   state = {
      id: null
   }

   componentDidMount() {
      window.addEventListener("click", this.closeOutsideClick, false)
   }

   componentWillUnmount() {
      window.removeEventListener("click", this.closeOutsideClick, false)
   }

   closeOutsideClick = (e) => {
      if (this.selectBlock.current && !this.selectBlock.current.contains(e.target)) {
         this.setState({
            id: null
         })
      }
   }
   setCoverSelectionTypeFunc = (item) => {

      if ((item.sink_cover_stands.length <= 1 && this.props.setSinkSizeId.sink_size_cover_directions.length <= 1) || this.props.activeLang === 'fr') {

         this.props.dispatch(setCoverSelectionType(item))
         this.props.dispatch(setCoverSelection(null))
         let sinkSizeCovers = item.sink_size_covers.find(item => item.sink_size_id === this.props.setSinkSizeId.id);
         this.props.dispatch(setSinkCoverDirection(sinkSizeCovers && sinkSizeCovers.sink_size.sink_size_cover_directions.length > 1 ? sinkSizeCovers.sink_size.sink_size_cover_directions[0] : null))
      } else {
         this.handleOnclick(item)
      }

      /*TODO: KAPAK YÖNÜ SEÇİMİ*/
      /*if(item.sink_cover_stands && item.sink_cover_stands[0]){
         this.setCoverFunc(item.sink_cover_stands[0]);
      }else{
         this.setCoverFunc(null);
      }*/
   }
   handleOnclick = (item) => {


      // this.setCoverSelectionTypeFunc(item)
      if (this.state.id) {
         this.setState({
            id: null
         })
      } else {
         this.setState({
            id: item.id
         })
      }
      if (window.innerWidth < 641) {
         this.props.dispatch(setCoverSelectionType(item))
         this.props.dispatch(setCoverSelection(this.props.activeLang !== "fr" && item.sink_cover_stands && item.sink_cover_stands.length > 1 ? item.sink_cover_stands[0] : null))
         let sinkSizeCovers = item.sink_size_covers.find(item => item.sink_size_id === this.props.setSinkSizeId.id);
         this.props.dispatch(setSinkCoverDirection(sinkSizeCovers && sinkSizeCovers.sink_size.sink_size_cover_directions.length > 1 ? sinkSizeCovers.sink_size.sink_size_cover_directions[0] : null))
      }
   }
   filteredByLang=(sinkSize)=> (JSON.parse(sinkSize.available_conturies).indexOf(this.props.activeLang)>-1 && sinkSize.sink_size_id === this.props.setSinkSizeId.id)

   render() {
      let {setCoverSelectionType, language, activeLang, item, index, setSinkSizeId, setCoverFunc,setSinkSizeDirection} = this.props;
      let sinkCover = item.sink_size_covers.find(x=>x.sink_size_id === setSinkSizeId.id);
      return (
          <div ref={this.selectBlock}
               className={`vra-custom-select-block  vra-cap-select ${(this.state.id === item.id && (item.sink_cover_stands.length > 1 || setSinkSizeId.sink_size_cover_directions.length > 1)) ? "opened" : ""} ${(window.innerWidth < 641 || !this.state.id) && setCoverSelectionType && (setCoverSelectionType.id === item.id) ? "active" : ""} ${setSinkSizeId && item.sink_size_covers.filter(this.filteredByLang).length < 1 ? "disabled" : ""}`}
               onClick={() => {
                  if (!item.sink_size_covers.filter(c => c.sink_size_id === setSinkSizeId.id).length < 1 || window.innerWidth < 641) {
                     this.handleOnclick(item)
                  }
               }}
               key={index}>
             <div className="vra-tooltip-item">
                <span>{`${activeLang === "tr" ? setSinkSizeId.name : ""}${language.door_desc}`}</span>
             </div>
             <div
                 className={`vra-custom-select-item ${(setCoverSelectionType && setCoverSelectionType.id === item.id && (item.sink_cover_stands.length <= 1 || activeLang === 'fr') &&  sinkCover && sinkCover.sink_size.sink_size_cover_directions.length<=1)  ?  "active" : ""} `}
                 onClick={(e) => {
                    if(item.sink_cover_stands && item.sink_cover_stands.length > 1){
                       this.setCoverSelectionTypeFunc(item);
                    }else {
                       this.setCoverSelectionTypeFunc(item);

                    }


                    // e.stopPropagation()

                 }}>
                <div className="vra-text-item">
                   <p>{JSON.parse(item.transition)[activeLang] || item.name}</p>
                </div>
             </div>
             {
                (((item.sink_cover_stands && item.sink_cover_stands.length > 1) || (sinkCover&& sinkCover.sink_size.sink_size_cover_directions && sinkCover.sink_size.sink_size_cover_directions.length>1)) && setSinkSizeId && item.sink_size_covers.filter(this.filteredByLang).length > 0) &&
                <i className="gg-chevron"/>
             }
             {
                (item.sink_cover_stands && item.sink_size_covers.find(sizeCover => sizeCover.sink_size.sink_size_cover_directions.length <=1) && item.sink_cover_stands.length > 1) &&
                <CoverLegComponent  setCoverFunc={setCoverFunc} item={item} onlyFr={(setCoverSelectionType && setCoverSelectionType.id === item.id && (item.sink_cover_stands.length <= 1 || activeLang === 'fr') &&  sinkCover && sinkCover.sink_size.sink_size_cover_directions.length<=1)}/>
             }
             {
                (setSinkSizeId.sink_size_cover_directions.length > 1) &&
                <CoverStandDirectionComponent item={item}/>
             }

          </div>
      );
   }
}

const mapStateToProps = (state) => ({
   coverTypes: state.sinkReducer.coverTypes || [],
   setCoverSelectionType: state.userSelectionReducer.setCoverSelectionType,
   activeLang: state.commonReducer.activeLang,
   language: state.languageReducer[state.commonReducer.activeLang],
   setSinkSizeId: state.userSelectionReducer.setSinkSizes,
   setSinkSizeDirection:state.userSelectionReducer.setSinkSizeDirection
})
export default connect(mapStateToProps)(CoverSelectBoxComponent);
