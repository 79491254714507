import React, { Component } from 'react'
import { getBathroomAreaAction } from '../../actions'
import CampaigneFormComponent from '../forms/campaigneFormComponent'
import EmailSendFormComponent from '../forms/emailSendFormComponent'
import axios from "axios";
import { connect } from 'react-redux';

let bathroomAreacancelToken;
class SendEmailModalComponent extends Component {
   getPicture = () => {
      let height;
      if (this.props.userSelection.setSinkSizes && this.props.userSelection.setSinkSizes.sink_size_covers[0]) {
         height = this.props.userSelection.setSinkSizes.sink_size_covers[0].height;
         if (this.props.userSelection.setCoverSelectionType && this.props.userSelection.setSinkSizes.sink_size_covers.find(item => item.id === this.props.userSelection.setCoverSelectionType.id)) {
            height = this.props.userSelection.setSinkSizes.sink_size_covers.find(item => item.id === this.props.userSelection.setCoverSelectionType.id).height;
         }
      }
      if (typeof bathroomAreacancelToken != typeof undefined) {
          bathroomAreacancelToken.cancel("Operation canceled due to new request.")
      }

      bathroomAreacancelToken = axios.CancelToken.source()
      this.isPictureGet = false;
      this.forceUpdate();
      this.props.dispatch(getBathroomAreaAction({
         serie: this.props.userSelection.setCoverStyle ? this.props.userSelection.setCoverStyle.name : null,
         color: this.props.userSelection.setCoverColour ? `${this.props.userSelection.setCoverType.id == 3 ? this.props.userSelection.setCoverType.name + " " : ""}${this.props.userSelection.setCoverColour.cover_color.name}` : null,
         placeColorName: this.props.userSelection.setCoverColour ? `${this.props.userSelection.setCoverColour.cover_color.name}` : null,
         size: this.props.userSelection.setSinkSizes ? this.props.userSelection.setSinkSizes.name : null,
         cover: this.props.userSelection.setCoverSelectionType ? this.props.userSelection.setCoverSelectionType.name : null,
         direction: this.props.userSelection.setSinkSizeDirection ? this.props.userSelection.setSinkSizeDirection.cover_direction.name : "Sag",
         handleColor: this.props.userSelection.setHandleTypes ? this.props.userSelection.setHandleTypes.name : null,
         stand_size: this.props.userSelection.setCoverSelection ? this.props.userSelection.setSinkSizes.name : null,
         sinkSerie: this.props.userSelection.setSinkType ? this.props.userSelection.setSinkType.serie_name : null,
         height,
         is_stand: this.props.userSelection.setCoverSelection && this.props.userSelection.setCoverSelection.sink_stand ? this.props.userSelection.setCoverSelection.sink_stand.name : null,
         armature: this.props.userSelection.setArmatureColour ? this.props.userSelection.setArmatureType.name : null,
         armatureColorId: this.props.userSelection.setArmatureColour ? this.props.userSelection.setArmatureColour.id : null,
         otherModule: this.props.userSelection.setOtherModules ? this.props.userSelection.setOtherModules.name : null,
         otherModuleDirection: this.props.userSelection.setOtherModuleCoverDirection ? this.props.userSelection.setOtherModuleCoverDirection.cover_direction.name : null,
         mirror: this.props.userSelection.setMirrorType ? this.props.userSelection.setMirrorType.name : null,
         tallCabinet: this.props.userSelection.setTallCabinetCoverDirection ? this.props.userSelection.setTallCabinetCoverDirection.cover_direction.name : null,
         mirrorSize: this.props.userSelection.mirrorSize ? this.props.userSelection.mirrorSize.size.name : null,
         tallCabinetSizeName: this.props.userSelection.setTallCabinet && this.props.userSelection.setTallCabinet.tall_cabinet_sizes && this.props.userSelection.setTallCabinet.tall_cabinet_sizes[0] ? this.props.userSelection.setTallCabinet.tall_cabinet_sizes[0].size_name : null,
         otherModuleSizeName: this.props.userSelection.setSinkTitle ? this.props.userSelection.setSinkTitle.name : "",
         otherModuleSize: this.props.userSelection.setOtherModules ? this.props.userSelection.setOtherModules.size : null,
         withBackLight: this.props.userSelection.mirrorWithBackLight ? this.props.userSelection.mirrorWithBackLight.light : 0,
         place: this.props.userSelection.place,
         mirrorCoverDirection: this.props.userSelection.selectedMirrorCoverDirection ? this.props.userSelection.selectedMirrorCoverDirection.name : "sag",
         leg_color_name: this.props.userSelection.legColor ? this.props.userSelection.legColor.name : null
      }, (result) => {
          if (result === false && this.retryLoad !== true) {
              setTimeout(() => {
                  this.retryLoad = true;
                  this.getPicture();
              }, 2000);
          } else {
              this.retryLoad = false;
              this.isPictureGet = true;
              this.bathPicture = result;
              this.forceUpdate();
          }
      }, bathroomAreacancelToken, true))
  }
   state = {
      isError: false
   }
   componentDidMount(){
      this.getPicture();
   }
   showErrorMsg = (err) => {
      this.setState({
         isError: err
      })
   }
   
   render() {
      let { changeFormStep, setFormValues } = this.props;
      return (
         <div className="vra-lb-root road-show-root  vra-campaign-register-root">
            <div className="vra-lb-wrapper">
               <div className="vra-lb-container vra-added-mail">
                  <div className="vra-model-content">
                     <div className="vra-top-block">
                        <h3>{'Send via e-mail'}</h3>
                        <i className="gg-close" onClick={() => changeFormStep(null)} />
                     </div>
                     <EmailSendFormComponent bathPicture={this.bathPicture} showErrorMsg={this.showErrorMsg} setFormValues={setFormValues} changeFormStep={changeFormStep} />

                  </div>
                  <div className="vra-campaign-btn-block">
                     {
                         
                        <p style={this.state.isError?{margin:'0 16px 16px 0'}:{}}>{this.state.isError?'Confirm your e-mail address.!':''}</p>

                     }
                     <div className="vra-btns-block">
                        <button onClick={() => changeFormStep(null)} type="button" className="vra-btn vra-white vra-close">Close</button>
                        <button type="submit" form='road-map-form' className="vra-btn vra-send">Send</button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      )
   }
}


const mapStateToProps = (state) => ({
   productStep: state.productStepReducer.productStepNumber,
   userSelection: state.userSelectionReducer,
   isFromRandom: state.commonReducer.isFromRandom,
   sinkSize: state.sinkReducer.sinkSize || [],
   selectSinkTypeId: state.userSelectionReducer.setSinkType ? state.userSelectionReducer.setSinkType.id : null,
   selectionType: state.commonReducer.selectionType,
   isOpenModelsModal: state.commonReducer.isOpenModelsModal || false,
   isBgModal: state.commonReducer.isBgModal || false,
   voice:state.commonReducer.voice
})

export default connect(mapStateToProps)(SendEmailModalComponent)
