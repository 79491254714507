import React, {Component} from 'react';
import {ASSETS} from "../../constants/paths";
import {Link} from "react-router-dom";
import CustomSelectBoxComponent from "../fields/customSelectBoxComponent";
import {connect} from "react-redux";
import { ROADSHOWLINK } from '../../constants/routePaths';
import {InIframe} from "../../helper";

class HeaderComponent extends Component {
   isSoundİcon= false
   isConfiqurator ;

   state = {
      isMobile:false

   }
   componentDidMount() {
      window.addEventListener("resize",this.setIsMobile)
      this.isConfiqurator= window.location.pathname.indexOf("configurator") > 0;
    
      this.setIsMobile()
   
   }

   componentDidUpdate(prevProps){
      if( this.props.location.pathname !== prevProps.location.pathname ){
         this.isConfiqurator= window.location.pathname.indexOf("configurator") > 0;
         this.forceUpdate()

      }
   }

   setIsMobile = () =>{
      if(typeof window !== "undefined" && window.innerWidth <= 720){
         this.setState({
            isMobile: true
         })
      }else{
         this.setState({
            isMobile: false
         })
      }
   }

   componentWillUnmount() {
      window.removeEventListener("resize",this.setIsMobile)

   }

   setTitle = () => {
      if(this.props.activeLang === "fr"){
         return "Configurateur de Meubles ROOT"
      }
      else if(this.props.activeLang === "de"){
         return "Root Konfigurator"
      }else if(this.props.activeLang === "ru")
      {
         return "Конструктор мебели Root"
      }
      else{
         return "Root Configurator"
      }
   }
   render() {
      let{activeLang,language}=this.props;
      let homeLink = window.location.pathname.indexOf("/rootkonfigurator")!==-1?"/rootkonfigurator/de":(window.location.pathname.indexOf(ROADSHOWLINK)!==-1? `/${ROADSHOWLINK}/tr` :`/`+activeLang)
      let{isMobile}=this.state
      return (
          <header className="vra-header-root" id="vra-header-root">
             {
                InIframe()===false &&
                <>
                   {
                      isMobile &&
                      <>
                         {
                            (activeLang === "ru" || window.location.origin === "https://rootconfigurator.vitraglobal.com") ?
                                <></>
                                :
                                <a href={`tel:${language && language.contact_number}`}  className="vra-contact-block">
                                   <svg width={23} height={21} viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <rect x="3.5" y={8} width={3} height={6} rx="1.5" stroke="#973129" strokeWidth={2} />
                                      <rect x="18.5" y={8} width={3} height={6} rx="1.5" stroke="#973129" strokeWidth={2} />
                                      <rect x={8} y={17} width={6} height={3} rx="1.5" stroke="#973129" strokeWidth={2} />
                                      <path d="M3.16429 11V11C2.01829 11 1.13458 12.0094 1.28604 13.1453L1.56608 15.2456C1.81449 17.1086 3.40368 18.5 5.28319 18.5H8" stroke="#973129" strokeWidth={2} />
                                      <path d="M20 8C20 4.13401 16.6421 1 12.5 1C8.35786 1 5 4.13401 5 8" stroke="#973129" strokeWidth={2} />
                                   </svg>
                                   <div className="text-item">
                                      <span>{language && language.contact_center}</span>
                                      <p>{language && language.contact_number}</p>
                                   </div>
                                </a>
                         }
                      </>
                   }
                </>
             }
             <div className="vra-header-container">

                   <div className="vra-logo-block">
                      {
                         InIframe()===false &&
                          <Link to={homeLink} className="vra-logo-item">
                            <picture>
                               <img src={`${ASSETS}/img/home/root-app-logo.svg`} alt="LOGO"/>
                            </picture>
                         </Link>
                      }
                      <h4>{this.setTitle()}</h4>
                   </div>

                {
                  this.props.location.pathname.indexOf(ROADSHOWLINK) === -1 &&
                  <CustomSelectBoxComponent language={language} location={this.props.location} history={this.props.history} isConfiqurator = {this.isConfiqurator}/>
                }
               
             </div>
          </header>
      );
   }
}
const mapStateToProps=state=>({
   language: state.languageReducer[state.commonReducer.activeLang],
   activeLang: state.commonReducer.activeLang,
})
export default connect(mapStateToProps) (HeaderComponent);
