import React, {Component} from 'react';
import {ASSETS} from "../constants/paths";
import {connect} from "react-redux";
import SinkOptionSelectionComponent from "./sinkOptionSelectionComponent";
import {
   setSinkSizes,
   setSinkTitleAction,
   setSinkType,
   setCoverSelectionType,
   setCoverSelection,
   setSinkCoverDirection, setHandleTypes, setLegColorAction
} from "../actions";

class SinkComponent extends Component {
   selectBlock=React.createRef();
   constructor(props) {
      super(props);
      this.state={
         isIndia:props.activeLang === "in"
      }
   }
   state={
      id:null
   }
   componentDidMount() {
      window.addEventListener("click",this.closeOutsideClick,false)
      this.checkIsIndia()
      if(window.innerWidth<641 && this.state.isIndia){
         this.props.dispatch(setSinkType(this.props.sinks))
         this.props.dispatch(setSinkTitleAction(this.props.sinks.size_groups[0]))
         this.props.dispatch(setSinkSizes(this.props.sinks.size_groups[0].sizes[0]))
         this.props.dispatch(setCoverSelectionType(null))
         this.props.dispatch(setCoverSelection(null))
         this.props.dispatch(setSinkCoverDirection(null))
      }
   }
   componentWillUnmount() {
      window.removeEventListener("click",this.closeOutsideClick,false)
   }
   componentDidUpdate(prevProps, prevState, snapshot) {
      if(this.props.activeLang !== prevProps.activeLang){
         this.checkIsIndia()
      }
   }

   closeOutsideClick = (e) => {
      if(this.selectBlock.current && !this.state.isIndia && !this.selectBlock.current.contains(e.target)){
         this.setState({
            id:null
         })
      }
   }
   setSinks = (type,title,size) => {
      this.props.dispatch(setSinkType(type))
      this.props.dispatch(setSinkSizes(size))
      this.props.dispatch(setSinkCoverDirection(null))
      this.props.dispatch(setLegColorAction(null))

      this.props.dispatch(setCoverSelection(null))
      this.props.dispatch(setCoverSelectionType(null))
      //TODO:!!
      this.props.dispatch(setHandleTypes(null))
      
      // if(!size.sink_size_covers.find(item=>item.sink_cover_id===this.props.userSelectedCoverId)){
      //    this.props.dispatch(setCoverSelectionType(null))
      //    this.props.dispatch(setCoverSelection(null))
      // }

      /*dimension*/
      this.props.dispatch(setSinkTitleAction(title))
      if(this.props.activeLang === "in" && this.props.setCoverStyle){
         this.props.dispatch(setHandleTypes(this.props.setCoverStyle.defaultHandle))
      }
   }

   handleOnChange = (item) => {

      /*if (this.props.userSelectedSinkSizeId !== null && item.sink_type_sizes.findIndex(sinkTypeSize => sinkTypeSize.sink_size_id === this.props.userSelectedSinkSizeId) === -1) {
         this.props.dispatch(setSinkSizes(item.sink_type_sizes[0].sink_size))
         this.props.dispatch(setSinkTitleAction(item.sink_type_sizes[0].sink_size.sink_dimension))
         if (this.props.userSelectedCoverId !== null) {
            this.props.dispatch(setCoverSelectionType(item.sink_type_sizes[0].sink_size.sink_size_covers[0].sink_cover))
            this.props.dispatch(setCoverSelection(null))
         }
      }
     */
      if(this.state.isIndia && this.props.stepNumber === 2){
         return
      }
      if (window.innerWidth < 641) {
         this.props.dispatch(setSinkType(item))
         this.props.dispatch(setSinkTitleAction(item.size_groups[0]))
         this.props.dispatch(setSinkSizes(item.size_groups[0].sizes[0]))
         this.props.dispatch(setCoverSelectionType(null))
         this.props.dispatch(setCoverSelection(null))
         this.props.dispatch(setSinkCoverDirection(null))
      }
      if (this.state.id === item.id) {
         this.setState({
            id: null
         })
      } else {
         this.setState({
            id: item.id
         })
      }
   }

   checkIsIndia=()=>{
      // debugger
      if(this.props.activeLang === "in"){
         this.setState({
            isIndia:this.props.activeLang === "in",
            id:this.props.sinks.id
         },()=>{
         })
      }
   }
   render() {
      let{sinks,activeLang,sinkTypeId,key,stateId,sinkTypes}=this.props;
      let{id,isIndia}=this.state;
      return (
          <div ref={this.selectBlock} className={`vra-custom-select-block ${window.innerWidth>641?(sinkTypeId && sinkTypeId.id === sinks.id && !id ? "active" : ""):(sinkTypeId && sinkTypeId.id === sinks.id ? "active" : "")} ${id===sinks.id ? "opened":""}`}  key={key} onClick={()=> {
             this.handleOnChange(sinks);
          }}>
             <div className="vra-custom-select-item">
                <div className="vra-img-item">
                   <picture>
                      <source
                          srcSet={`${ASSETS}${sinks.img.substr(0,sinks.img.lastIndexOf("."))+".webp"}`}
                          type="image/webp"/>
                      <source
                          srcSet={`${ASSETS}${sinks.img.substr(0,sinks.img.lastIndexOf("."))+".png"}`}
                          type="image/png"/>
                      <img src={`${ASSETS}${sinks.img}`} alt={key}/>
                   </picture>
                </div>
                <div className="vra-text-item">
                   <p>{JSON.parse(sinks.transition)[activeLang]||sinks.serie_name}</p>
                </div>
             </div>
             {
                !isIndia &&
                <i className="gg-chevron"/>
             }
             <div className="vra-option-container">
                {
                   sinks.size_groups.map((group,i)=>
                     <SinkOptionSelectionComponent  sinks={sinks} setSinks={this.setSinks} group={group} key={i}/>
                   )
                }
             </div>
          </div>
      );
   }
}
const mapStateToProps = (state) => ({
   sinkTypes: state.sinkReducer.sinkTypes || [],
   sinkTypeId: state.userSelectionReducer.setSinkType,
   userSelectedSinkSizeId: state.userSelectionReducer.setSinkSizes ? state.userSelectionReducer.setSinkSizes.id : null,
   userSelectedCoverId: state.userSelectionReducer.setCoverSelectionType ? state.userSelectionReducer.setCoverSelectionType.id : null,
   isLoading: state.commonReducer.isLoading,
   activeLang:state.commonReducer.activeLang,
   stepNumber:state.productStepReducer.productStepNumber,
   setCoverStyle:state.userSelectionReducer.setCoverStyle,
})
export default connect(mapStateToProps) (SinkComponent);
