import React, {Component} from 'react';
import {changeModelsModal, setCoverColour, setCoverStyle, setCoverType, toggleCoverModelModal,setSinkType} from "../actions";
import {connect} from "react-redux";
import CoverModelSelectionComponent from "./modal/coverModelSelectionComponent";

class ChangeModelComponent extends Component {
   lbCon=React.createRef()
   setChanges=()=>{
      let selectedSink;
      if(this.props.activeLang === "in"){
         /*id:4 => Root Flat*/
         if(this.props.modelsChanges.coverStyle.id == 4){
            selectedSink = this.props.sinkTypes.find(item=>item.id==5)||{id:5}
         }
         /*id:6 => Root Flat*/
         if(this.props.modelsChanges.coverStyle.id == 6){
            selectedSink = this.props.sinkTypes.find(item=>item.id==2)||{id:2}
         }
         this.props.dispatch(setSinkType(selectedSink))
      }
      this.props.dispatch(setCoverColour(this.props.modelsChanges.colors))
      this.props.dispatch(setCoverStyle(this.props.modelsChanges.coverStyle))
      this.props.dispatch(setCoverType(this.props.modelsChanges.group))
      this.openModelsModal()
   }
   deleteChanges=()=>{
      this.props.dispatch(changeModelsModal(null))
      this.openModelsModal()
   }
   openModelsModal=()=>{
      this.props.dispatch(toggleCoverModelModal(!this.props.isOpenModelsModal))
   }
   render() {
      let{isOpenModelsModal,language}=this.props;
      return (
          <div className={`vra-lb-root vra-models ${isOpenModelsModal ? "open":""}`}  onClick={(e)=>{
            if(this.lbCon&& !this.lbCon.current.contains(e.target)){
               this.openModelsModal()
            }
          }}>
             <div className="vra-lb-wrapper">
                <div className="vra-lb-container" ref={this.lbCon}>
                   <div className="vra-model-content">
                      <div className="vra-top-block">
                         <h3>{language.change_model}</h3>
                         <i className="gg-close" onClick={this.openModelsModal}/>
                      </div>
                      <div className="vra-cover-container">
                         <CoverModelSelectionComponent/>
                      </div>
                   </div>
                   <div className="vra-btn-block">
                      <button type="button" className="vra-btn vra-white" onClick={this.deleteChanges}>{language.abort}</button>
                      <button type="button" className="vra-btn" onClick={this.setChanges}>{language.save}</button>
                   </div>
                </div>
             </div>
          </div>
      );
   }
}
const mapStateToProps=state=>({
   modelsChanges: state.userSelectionReducer.modelsChanges,
   isOpenModelsModal: state.commonReducer.isOpenModelsModal || false,
   language: state.languageReducer[state.commonReducer.activeLang],
   activeLang: state.commonReducer.activeLang,
   sinkTypes: state.sinkReducer.sinkTypes
})
export default connect(mapStateToProps) (ChangeModelComponent);
