import React, {Component} from 'react';
import {ASSETS} from "../constants/paths";
import ColourOptionSelectComponent from "./fields/colourOptionSelectComponent";
import {connect} from "react-redux";
import {setArmatureColour, setArmatureType} from "../actions";

class ArmatureSelectBoxComponent extends Component {
   selectBlock=React.createRef();
   state={
      selectedItem:null
   }
   componentDidMount() {
      window.addEventListener("click",this.closeOutsideClick,false)
   }
   componentWillUnmount() {
      window.removeEventListener("click",this.closeOutsideClick,false)
   }
   closeOutsideClick = (e) => {
      if(this.selectBlock.current && !this.selectBlock.current.contains(e.target)){
         this.setState({
            selectedItem:null
         })
      }
   }
   handleOnClick = (item) => {
      if (!this.props.setArmatureColour) {
         // this.props.dispatch(changeSelectedModulesAction("armatureStyleData"))
      }
      // this.props.dispatch(setArmatureType(item))
      if (item.id === this.state.selectedItem) {
         this.setState({
            selectedItem: null
         })
      } else {
         // this.props.dispatch(setArmatureColour(item.armature_style_colors[0]))
         this.setState({
            selectedItem: item.id
         })
      }
      if(window.innerWidth<641){
         this.props.dispatch(setArmatureType(item))
         this.props.dispatch(setArmatureColour(item.armature_style_colors[0]))
      }
   }
   render() {
      let{item,index,setArmatureType,activeLang,language}=this.props;
      return (
          <div ref={this.selectBlock} className={`vra-custom-select-block ${this.state.selectedItem === item.id ? "opened" : ""} ${setArmatureType && setArmatureType.id === item.id && (window.innerWidth<641 || !this.state.selectedItem) ? "active" : ""}`} key={index.toString()}
               onClick={() => {
                  this.handleOnClick(item)
                  // e.preventDefault()
                  // e.stopPropagation()
               }}
          >
             <div className="vra-custom-select-item">
                <div className="vra-img-item vra-bordered">
                   <picture>
                      <source srcSet={`${ASSETS}${item.img.substr(0,item.img.indexOf("."))+".webp"}`}  type="image/webp"/>
                      <source srcSet={`${ASSETS}${item.img.substr(0,item.img.indexOf("."))+".png"}`}  type="image/png"/>
                      <img src={`${ASSETS}${item.img}`} alt={JSON.parse(item.transition)[activeLang] || item.name} />
                   </picture>
                </div>
                <div className="vra-text-item">
                   <p>{JSON.parse(item.transition)[activeLang] || item.name}</p>
                </div>
             </div>

             <i className="gg-chevron" />
             <div className="vra-option-container">
                <p>{language.colour}</p>
                <ColourOptionSelectComponent armature_type={item} isArmature={true} colors={item.armature_style_colors} />
             </div>
          </div>
      );
   }
}
const mapStateToProps = (state) => ({
   setCoverColour: state.userSelectionReducer.setCoverColour,
   setArmatureColour: state.userSelectionReducer.setArmatureColour,
   setArmatureType: state.userSelectionReducer.setArmatureType,
   activeLang: state.commonReducer.activeLang,
   language: state.languageReducer[state.commonReducer.activeLang]

})
export default connect(mapStateToProps) (ArmatureSelectBoxComponent);
