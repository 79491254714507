import React, { Component } from 'react'
import { ASSETS } from '../../constants/paths';

 class SuccessModal extends Component {
  render() {
    return (
      <div className="vra-lb-root road-show-root success-root">
      <div className="vra-lb-wrapper">
         <div className="vra-lb-container" >
            <div className="vra-model-content">
               <div className="vra-top-block">
                  <h3>{'Tebrikler'}</h3>
                  <i className="gg-close" onClick={this.props.closeModal}/>
               </div>
               <div className="success-block">
                  <div className="vra-img-item">
                     <picture>
                        <img src={`${ASSETS}/img/home/success.svg`} alt="success" />
                     </picture>
                  </div>
                  <p>Bayi / mağaza kodunuz başarıyla kaydedildi.</p>
               </div>
               
            </div>
            <div className="vra-success-btn-block">
               <button onClick={this.props.closeModal} type="submit" className="vra-btn vra-grey">Kapat</button>
            </div>
         </div>
      </div>
   </div>

    )
  }
}
export default SuccessModal