import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {assignProductStep, setAllUserSelection, setRandomValue, getFeaturedBathroomDataAction} from "../../actions";
import {DE_HOME_PAGE, HOME_PAGE} from '../../constants/routePaths';
import {ASSETS} from "../../constants/paths";

const randomBathroomJSON = require("../../constants/dummyBathroom.json");

class ProductItemComponent extends Component {
   setRandom = () => {

      this.props.dispatch(getFeaturedBathroomDataAction(this.props.featuredId));
      //this.props.dispatch(setAllUserSelection(randomBathroomJSON))
      this.props.dispatch(assignProductStep(5))
      this.props.dispatch(setRandomValue(true))
      this.props.history.push(window.location.pathname.indexOf("/rootkonfigurator")!==-1?DE_HOME_PAGE.replace(":lang", this.props.activeLang) : HOME_PAGE.replace(":lang",this.props.activeLang));
   }

   render() {
      let {img1x, img2x,productInfos} = this.props;
      return (
          <div className="vra-product-item" onClick={this.setRandom}>
             <div className="vra-img-item">
                <picture>
                   <source
                       srcSet={`${ASSETS}${img1x.substr(0, img1x.lastIndexOf(".")) + ".webp"} 1x, ${ASSETS}${img2x.substr(0, img2x.lastIndexOf(".")) + "-2x.webp"} 2x`}
                       type="image/webp"/>
                   <source
                       srcSet={`${ASSETS}${img1x} 1x, ${ASSETS}${img2x.substr(0,img2x.lastIndexOf("."))+"-2x.png"} 2x`}
                       type="image/png"/>
                   <img src={`${ASSETS}${img1x}`} alt="slide"/>
                </picture>
             </div>
             <div className="vra-custom-item">
                {
                    productInfos[this.props.activeLang] &&  productInfos[this.props.activeLang].map((info,index)=>
                       <span key={index.toString()}>{info}</span>
                   )
                }
             </div>
          </div>
      );
   }
}

const mapStateToProps=(state)=>({
   activeLang: state.commonReducer.activeLang
})

export default withRouter(connect(mapStateToProps)(ProductItemComponent));
