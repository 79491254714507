import React, { Component } from 'react'
import { ASSETS } from '../../constants/paths';

class ErrorMessageComponent extends Component {
   render() {
      let { message } = this.props;
      return (
         <div className="hover-text-item">
            <div className="triangle-left">
               <div className="inner-triangle"></div>
            </div>
            <span>{message}</span>
         </div>
      )
   }
}

export default ErrorMessageComponent
