import React, {Component} from "react";
import * as ReactDOM from "react-dom";

class BannerSliderComponent extends Component {
   slideBlock = React.createRef();
   slideContainer = React.createRef();
   translateValue = 0
   sliderTimeout;
   handleTouchStart = (evt) => {
      this.xDown = evt.touches[0].clientX;
      this.yDown = evt.touches[0].clientY;
   };

   handleTouchMove = (evt) => {
      if (!this.xDown || !this.yDown) {
         return;
      }
      this.xUp = evt.touches[0].clientX;
      this.yUp = evt.touches[0].clientY;

      let xDiff = this.xDown - this.xUp;
      let yDiff = this.yDown - this.yUp;
      if (Math.abs(xDiff) + Math.abs(yDiff) > 10) {
         if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
               this.props.changeActiveIndex(1);

            } else {
               this.props.changeActiveIndex(-1);

            }
         }
         this.xDown = null;
         this.yDown = null;
      }
   };

   componentDidMount() {
      this.setFirstValue()
      this.slideContainer.current.addEventListener("touchstart", this.handleTouchStart, false)
      this.slideContainer.current.addEventListener("touchmove", this.handleTouchMove, false)
      window.addEventListener("resize", this.resizingWindow)

   }

   resizingWindow = () => {
      if(this.slideBlock.current){
         clearTimeout(this.sliderTimeout)
         this.sliderTimeout = setTimeout(() => {
            this.calculateTranslateValue();
            this.slideBlock.current.style.transform = `translate3d(${-this.translateValue}px, 0px, 0px)`;
         }, 500)
      }
   }

   setFirstValue = () => {
      clearTimeout(this.sliderTimeout)
      if (this.slideBlock.current && this.slideContainer.current) {
         this.sliderTimeout = setTimeout(() => {
            //this.forceUpdate();
         this.calculateTranslateValue()
         this.slideBlock.current.style.transform = `translate3d(${-this.translateValue}px, 0px, 0px)`;
         }, 500)
      }

   }

   componentWillUnmount() {
      this.slideContainer.current.removeEventListener("touchstart", this.handleTouchStart, false)
      this.slideContainer.current.removeEventListener("touchmove", this.handleTouchMove, false)
      window.removeEventListener("resize", this.resizingWindow)
      clearTimeout(this.sliderTimeout)
   }

   calculateTranslateValue = () => {
      let {activeIndex} = this.props;
      this.translateValue = 0
      if(this.slideContainer.current){
         let activeElement = document.getElementsByClassName(this.props.children[0].ref.current.imgItem.current.className.split(" ")[0])[activeIndex + 1];
         if(activeElement.offsetLeft>0){
            this.translateValue = activeElement.offsetLeft - (this.slideContainer.current.offsetWidth / 2) + (activeElement.offsetWidth / 2);
         }
      }

      /*todo: desktop'da ortadan baslamıyordu o yüzden yoruma alındı*/
      /*if (activeIndex > 0 && this.slideContainer.current) {
         let activeElement = document.getElementsByClassName(this.props.children[0].ref.current.imgItem.current.className.split(" ")[0])[activeIndex + 1];
         this.translateValue = activeElement.offsetLeft - (this.slideContainer.current.offsetWidth / 2) + (activeElement.offsetWidth / 2);
      } else if (this.slideContainer.current && window.innerWidth < 640) {
         let activeElement = document.getElementsByClassName(this.props.children[0].ref.current.imgItem.current.className.split(" ")[0])[activeIndex + 1];
         this.translateValue = activeElement.offsetLeft - (this.slideContainer.current.offsetWidth / 2) + (activeElement.offsetWidth / 2);
      }*/
   }

   render() {
      let {activeIndex} = this.props;
      this.calculateTranslateValue()
      return (
          <div className="vra-slider-container" ref={this.slideContainer}>
             <div ref={this.slideBlock} className="vra-slide-block"
                  style={{transform: `translate3d(${-this.translateValue}px, 0px, 0px)`}}>
                {
                   this.props.children
                }
             </div>
          </div>
      )
   }
}

export default BannerSliderComponent
