import React, { Component } from 'react'
import { connect } from 'react-redux';
import { ASSETS } from '../../constants/paths';

class QrImageModalComponent extends Component {
   render() {
      let { detailValues, activeLang, language, orderSummary } = this.props;
      return (
         <div className="vra-lb-root road-show-root verification-root">
            <div className="vra-lb-wrapper">
               <div className="vra-lb-container" >
                  <div className="vra-model-content">
                     <div className="vra-top-block">
                        <h3>{'Kampanya katılım detaylarınız'}</h3>
                        <i className="gg-close" onClick={() => this.props.changeFormStep(null)} />
                     </div>
                     <div className="vra-campaign-result-root vra-qr-root">
                        <div className="vra-campaign-result-img-container">
                           <div className="vra-img-campaign-block">
                              <div className="vra-img-campaign-item">
                                 <picture>
                                    <img src={detailValues.qrData} alt="QRCode" />
                                   
                                 </picture>
                              </div>
                              <p>{detailValues.campaignCode}</p>
                           </div>
                           <div className="vra-right-item">
                              <h4>Ücretsiz montaj kodu</h4>
                              <p>Kampanya bilgileri e-posta adresinize gönderilmiştir.</p>
                           </div>
                        </div>


                     </div>
                     <div className="vra-campaign-btn-block">
                        <button onClick={() => this.props.changeFormStep(null)} type="button" className="vra-btn">{activeLang === 'en' ? 'Close' : 'Kapat'}</button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      )
   }
}
const mapStateToProps = state => ({
   orderSummary: state.orderSummaryReducer,
   activeLang: state.commonReducer.activeLang,
   language: state.languageReducer[state.commonReducer.activeLang],
})
export default connect(mapStateToProps)(QrImageModalComponent);