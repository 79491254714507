import React, { Component } from 'react';
import { ASSETS } from "../constants/paths";

class OrderPageComponent extends Component {

    render() {
        return (

            <div className="vra-app-root">
                <div className="vra-design-wrapper vra-order-wrapper">
                    <div className="vra-design-img-block">
                        <div className="vra-design-img-item">
                            <picture>
                                <img src={`${ASSETS}/img/home/banyo.png`} alt="Tasarım"/>
                            </picture>
                        </div>
                    </div>
                    <div className="vra-tool-wrapper">

                        <div className="vra-selections-container">
                            <div className="vra-title-head-item">
                                <h3>Kapak tarzı seçiniz</h3>
                            </div>
                            <div className="vra-selections-block">
                                <div className="vra-selections-item selected">
                                    <picture>
                                        <img src={`${ASSETS}/img/landing/66294-1.png`} alt="bath cabinet "/>
                                    </picture>
                                    <span>Flat</span>
                                </div>
                                <div className="vra-selections-item">
                                    <picture>
                                        <img src={`${ASSETS}/img/landing/66294-1.png`} alt="bath cabinet "/>
                                    </picture>
                                    <span>Fuge</span>
                                </div>
                                <div className="vra-selections-item">
                                    <picture>
                                        <img src={`${ASSETS}/img/landing/66294-1.png`} alt="bath cabinet "/>
                                    </picture>
                                    <span>Klasik</span>
                                </div>
                                <div className="vra-selections-item">
                                    <picture>
                                        <img src={`${ASSETS}/img/landing/66294-1.png`} alt="bath cabinet "/>
                                    </picture>
                                    <span>Klasik</span>
                                </div>
                            </div>
                            <div className="vra-btn-block">
                                <div className="vra-continue-btn-item">
                                    <a href="/#">Devam et</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}

export default OrderPageComponent;




