import React, {Component} from "react";
import {ASSETS} from "../constants/paths";
import {toggleBgModal, toggleCoverModelModal} from "../actions";
import {connect} from "react-redux";

class RefactorButtonsComponent extends Component {
   openModelsModal = () => {
      this.props.dispatch(toggleCoverModelModal(!this.props.isOpenModelsModal))
   }
   openBgModal = () => {
      this.props.dispatch(toggleBgModal(!this.props.isBgModal))
   }
   render() {
      let{language}=this.props;
      return (
          <div className="vra-refactor-container">
             <div className="vra-refactor-block">
                <div className="vra-refactor-item" onClick={this.openModelsModal}>
                   <picture>
                      <img src={`${ASSETS}/icons/kapak-tarzi-v2.svg`} alt="kapak-tarzi"/>
                   </picture>
                   <div className="vra-hint-item">
                      <span>{language && language.change_model}</span>
                   </div>
                </div>
                {/* <div className="vra-refactor-item" onClick={this.openBgModal}>
                   <picture>
                      <img src={`${ASSETS}/icons/painting.svg`} alt="painting"/>
                   </picture>
                   <div className="vra-hint-item">
                      <span>{language.change_color}</span>
                   </div>
                </div> */}
             </div>
          </div>
      )
   }
}

const mapStateToProps = state => ({
   isOpenModelsModal: state.commonReducer.isOpenModelsModal || false,
   isBgModal: state.commonReducer.isBgModal || false,
   language: state.languageReducer[state.commonReducer.activeLang]
})
export default connect(mapStateToProps)(RefactorButtonsComponent);
