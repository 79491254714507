import React, {Component, Fragment} from 'react';
import ImageSliderComponent from "./imageSliderComponent";
import {ASSETS} from "../../constants/paths";
import {connect} from "react-redux";
import {setLegColorAction} from "../../actions";

class RespLegColorOptionComponent extends Component {
   sliderComponent=React.createRef();
   state={
      isMobile:false
   }
   resize = () => {
      if (window.innerWidth > 640) {
         this.setState({
            isMobile: false
         })
      } else {
         this.setState({
            isMobile: true
         })
      }
   }
   componentDidMount() {
      this.resize();
      window.addEventListener("resize", this.resize)
      if(this.props.legColor){
         this.setState({
            activeIndex:this.props.handleTypes.findIndex(handle=>handle.id === this.props.legColor.id)
         })
      }
   }
   componentWillUnmount() {
      window.removeEventListener("resize", this.resize)
      this.resize();
   }
   setColor = (color) => {
      this.props.dispatch(setLegColorAction(color))
   }
   changeSelectedItemByIndex = (index) => {
      this.setState({
         activeIndex: null
      })
      this.setColor(this.props.handleTypes[index])
   }

   render() {
      let{handleTypes,legColor,activeLang}=this.props;
      return (
          <div className="vra-selections-block vra-mobile-block">
             {
                this.props.legColor &&
                <div className="vra-tooltip-item">
                   <span>{`${JSON.parse(this.props.legColor.transition)[activeLang]}`}</span>
                </div>
             }
             {
                <ImageSliderComponent ref={this.sliderComponent} changeItem={this.changeSelectedItemByIndex}
                                      activeIndex={this.state.activeIndex}>
                   {
                      handleTypes.map((color, index) =>
                          <Fragment key={color.id}>
                             {
                                <div
                                     className={`vra-option-item vra-color-option ${legColor && color.id === legColor.id ? "active":""}`}
                                     onClick={() => {
                                        this.setColor(color);
                                     }}>
                                   <div className="vra-round-item">
                                      <picture>
                                         <img src={`${ASSETS}${color.img}`} alt=""/>
                                      </picture>
                                   </div>
                                   {/*<span>{JSON.parse(_colors.cover_color.transition)[activeLang]}</span>*/}
                                </div>
                             }
                          </Fragment>
                      )
                   }
                </ImageSliderComponent>
             }
          </div>
      );
   }
}

const mapStateToProps = state => ({
   language: state.languageReducer[state.commonReducer.activeLang],
   activeLang:state.commonReducer.activeLang,
   setCoverSelection: state.userSelectionReducer.setCoverSelection,
   handleTypes: state.sinkReducer.handleTypes || [],
   legColor:state.userSelectionReducer.legColor
})
export default connect(mapStateToProps)(RespLegColorOptionComponent);
