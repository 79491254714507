import { extendTheme } from "@chakra-ui/react"
const theme = extendTheme({
   fonts: {
      bold: "Palanquin-Bold",
      semiBold: "Palanquin-SemiBold",
      medium: "Palanquin-Medium",
      regular:"Palanquin-Regular",
      opSemiBold:"OpenSans-SemiBold",
      opRegular:"OpenSans-Regular",
      opBold:"OpenSans-Bold",
      body:"Palanquin-Regular"
   },
})
export default theme
