import React, {Component, Fragment} from 'react';
import RespDoorOptionComponent from "../components/mobile/respDoorOptionComponent";
import {connect} from "react-redux";
import RespLegColorOptionComponent from "../components/mobile/respLegColorOptionComponent";

class RespDoorSelectionContainer extends Component {
   render() {
      let {resDoorSelection, setCoverSelection} = this.props;
      return (
          <Fragment>
             {
                ((resDoorSelection === "leg_colors" && setCoverSelection) && setCoverSelection.sink_stand.id === 1) ?
                    <RespLegColorOptionComponent />
                    :
                    <RespDoorOptionComponent />
             }

          </Fragment>
      );
   }
}

const mapStateToProps = state => ({
   resDoorSelection: state.productStepReducer.resDoorSelection,
   setCoverSelection: state.userSelectionReducer.setCoverSelection,
   activeLang: state.commonReducer.activeLang
})
export default connect(mapStateToProps)(RespDoorSelectionContainer);
