import React, { Component } from 'react';
import { connect } from "react-redux";
import { ASSETS } from "../../constants/paths";
import OtherOptionSelectComponent from "./otherOptionSelectComponent";
import { setOtherModules, setOtherModulesCoverDirection, changeSelectedModulesAction } from "../../actions";
import MobileSliderComponent from '../mobile/mobileSliderComponent';
import OtherSelectBoxComponent from "../otherSelectBoxComponent";

class OtherCustomSelectComponent extends Component {
   state = {
      selectedItem: null
   }
   changeSelectedItemByIndex=(index)=>{
      if(window.innerWidth<641){
         this.props.dispatch(setOtherModules(this.props.otherModules[index]))
         if (this.props.otherModules[index].other_module_cover_directions && this.props.otherModules[index].other_module_cover_directions.length > 1) {
            this.props.dispatch(setOtherModulesCoverDirection(this.props.otherModules[index].other_module_cover_directions[0]))
         }
      }
   }

   setOtherModulesFunc = (item, type) => {
      if (!this.props.setOtherModuleCoverDirection) {
         this.props.dispatch(changeSelectedModulesAction("otherModules"))
      }
      if (item.other_module_cover_directions && item.other_module_cover_directions.length > 1) {
         this.props.dispatch(setOtherModulesCoverDirection(item.other_module_cover_directions[0]))
      }
      this.props.dispatch(setOtherModulesCoverDirection(item))
      this.props.dispatch(setOtherModules(type))
   }

   render() {
      let { otherModules, activeLang, language, setOtherModules} = this.props;
      return (
         <div className="vra-custom-select-container">
            {
               otherModules &&
               <MobileSliderComponent
                  ref={this.sliderComponent}
                  changeItem={this.changeSelectedItemByIndex}
                  activeIndex={otherModules && otherModules.length > 0 && this.props.setOtherModules ? otherModules.findIndex(item => item.id === this.props.setOtherModules.id) : 0}>
                  {
                     otherModules.map((item, index) =>
                        <OtherSelectBoxComponent  key={index} setOtherModulesFunc={this.setOtherModulesFunc} item={item} index={index}/>
                     )
                  }
               </MobileSliderComponent>
            }

         </div>
      );
   }
}
const mapStateToProps = (state) => ({
   otherModules: state.otherModuleReducer.otherModules || [],
   setOtherModuleCoverDirection: state.userSelectionReducer.setOtherModuleCoverDirection,
   setOtherModules: state.userSelectionReducer.setOtherModules,
   activeLang: state.commonReducer.activeLang,
   language: state.languageReducer[state.commonReducer.activeLang]

})
export default connect(mapStateToProps)(OtherCustomSelectComponent);
