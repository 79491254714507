import React, {Component, Fragment} from 'react';
import {Skeleton, Stack, Flex, Box} from "@chakra-ui/react"

class SkeletonComponent extends Component {
   state = {
      isMobile: false
   }

   componentDidMount() {
      this.setSize()
      window.addEventListener("resize", this.setSize)
   }

   componentWillUnmount() {
      window.removeEventListener("resize", this.setSize)
   }

   setSize = () => {
      if (window.innerWidth < 640) {
         this.setState({
            isMobile: true
         })
      }else{
         this.setState({
            isMobile: false
         })
      }
   }

   render() {
      return (
          <Fragment>
             {
                !this.state.isMobile &&
                <Box className="vra-skeleton-loader" h="100%" p="24px" pt="32px" display="flex" flexDir="column"
                     justifyContent="space-between">
                   <Stack>
                      <Skeleton borderRadius={8} maxW={218} mb="24px" height="22px"/>
                      <Skeleton borderRadius={8} height="56px"/>
                      <Skeleton borderRadius={8} mt="8px" height="56px"/>
                      <Skeleton borderRadius={8} mt="8px" height="56px"/>
                   </Stack>
                   <Flex>
                      <Skeleton w="100%" borderRadius={8} height="49px"/>
                      <Skeleton w="100%" borderRadius={8} ml={16} height="49px"/>
                   </Flex>
                </Box>
             }
             {
                this.state.isMobile &&
                <Box h="100%" p="16px" pt="0" display="flex"
                     justifyContent="center">
                   <Skeleton w="125px" borderRadius="28px" height="56px"/>
                   <Skeleton w="125px" borderRadius="28px" ml={4} height="56px"/>
                   <Skeleton w="125px" borderRadius="28px" ml={4} height="56px"/>
                </Box>
             }
          </Fragment>

      );
   }
}

export default SkeletonComponent;
