import { ROADSHOWLINK } from '../constants/routePaths';

const queryString = require('query-string');

export const getElementWidth = (element) => {
    if(element){
        let style = element.currentStyle || window.getComputedStyle(element),
        width = element.offsetWidth, // or use style.width
        margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight);
        return width + margin;
    }
}

export const getElementMargin = (element) => {
    if(element){
        let style = element.currentStyle || window.getComputedStyle(element),
        width = element.offsetWidth, // or use style.width
        margin = parseFloat(style.marginLeft);
        return margin;
    }
}

export const getElementPosition = (el) => {
    var xPos = 0;
    var yPos = 0;
    while (el) {
        if (el.tagName == "BODY") {
            xPos += (el.offsetLeft + el.clientLeft);
            yPos += (el.offsetTop + el.clientTop);
        } else {
            // for all other non-BODY elements
            xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
            yPos += (el.offsetTop - el.scrollTop + el.clientTop);
        }

        el = el.offsetParent;
    }
    return {
        x: xPos,
        y: yPos
    };
};

export const getActiveLang = () => {
    const parsed = queryString.parse(window.location.search);
    let urlArr = window.location.pathname.split("/")
    return parsed.lang? parsed.lang.toLowerCase(): urlArr[1]==="rootkonfigurator"?"de": (urlArr[1]===ROADSHOWLINK?"tr":urlArr[1]);
}
export const createGUID = (length) => {
    const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!@()_+-={}[]:,.|";
    let result = "";
    for (let i = length; i > 0; --i) {
        result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result + ("." + Date.now());
}

export const formatPrice = (price) => {
    var formatter = new Intl.NumberFormat('tr-TR', {
        currency: 'TRY'
      });
      return formatter.format(price)
}

export const turkishToLower = (text) => {
    const letters = {
        İ: "I",
        ı: "i",
        I: "I",
        ş: "s",
        Ş: "S",
        ğ: "g",
        Ğ: "G",
        ü: "u",
        Ü: "U",
        ö: "o",
        Ö: "O",
        Ç: "C",
        ç: "c"
    };
    text = text.replace(/(([ışğüçöİIŞĞÜÇÖ]))+/g, (letter) => {
        let textItem = "";
        letter.split("").map((item) => {
            textItem += letters[item];
        });
        return textItem;
    });
    return text;
}
export const formatOnlyNumber = value => {
    if (!value) return value;
    let onlyNumbers = value.replace(/[^\d]/g, "");
    return onlyNumbers
}

export const normalizePhone = value => {
    if (!value) {
        return value
    }

    const onlyNums = value.replace(/[^\d]/g, '')
    // if (onlyNums.length <= 3) {
    //     return `${onlyNums.length>0 ? "(":""}${onlyNums}${onlyNums.length>3?")":""}`
    // }
    // if (onlyNums.length <= 6) {
    //     return `(${onlyNums.slice(0, 3)})${onlyNums.length>3?" ":""}${onlyNums.slice(3)}`
    // }
    return `${onlyNums.slice(0, 3)}${onlyNums.length>3?" ":""}${onlyNums.slice(3, 6)}${onlyNums.length>6?" ":""}${onlyNums.slice(6,8)}${onlyNums.length>8?" ":""}${onlyNums.slice(8,10)}`
}

export const InIframe = () => {
    try {
        if (typeof window !== "undefined") {
            return window.self !== window.top;
        } else {
            return false
        }
    } catch (e) {
        return true;
    }
}