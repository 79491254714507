import React, { Component } from 'react'
import { Form, Field } from 'react-final-form'
import TextInputFieldComponent from "../fields/textInputFieldComponent"
import RecaptchaComponent from '../fields/recaptchaComponent';
import { normalizePhone } from '../../helper';
import CheckBoxComponent from '../fields/checboxFieldComponent';
import { sendSmsAction } from '../../actions';
import LoaderComponent from '../common/loaderComponent';
const validate = (values) => {
   let emailRegexp = /^([a-zA-Z0-9!#$%&'*-`{|}~_.]{1}[a-zA-Z0-9!#$%&'*-`{|}~_.]*)(@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6})$/i
   const errors = {}
   if (!values.name || !values.name.trim().length) {
      errors.name = true
   }
   if (!values.surname || !values.surname.trim().length) {
      errors.surname = true
   }
   if (!values.phone || values.phone.length <10) {
      errors.phone = true
   }
   // if (!values.email || !values.email.trim().length || !emailRegexp.test(values.email)) {
   //    errors.email = true
   // }
   if (!values.kvkk || !values.kvkk.length === 0) {
      errors.kvkk = true
   }
   if (!values.recaptcha || !values.recaptcha.length === 0) {
      errors.recaptcha = true
   }
   return errors;
}
class CampaigneFormComponent extends Component {
   recaptchaRef=React.createRef()
   state = {
      isLoading:false,
      hasError:false
   }
   submit = (values) => {
      
      this.setState({
         isLoading:true,
         hasError:false
      })
      sendSmsAction({...values,phone:values.phone.replace(/\s/g, '')}).then(res=>{
         if(res){
            this.props.setFormValues(values)
            this.props.changeFormStep(2)
         }else{
            this.resetRecaptcha()
            this.props.showErrorMsg(!res)
            this.setState({
               isLoading:false,
               hasError:true
            })
         }
         
      }).finally(()=>{
         this.setState({
            isLoading:false,
         })
      })
   }
   
   resetRecaptcha=()=>{
      this.recaptchaRef.current.reset()
   }
   componentDidMount(){
      this.props.showErrorMsg(false)
   }
   
   render() {
      
      return (
         <Form
            onSubmit={this.submit}
            validate={validate}
            render={({ handleSubmit }) => (
               <form className='road-show-form' onSubmit={handleSubmit} id='road-map-form'>
                  <div className="input-container">
                     <div className="input-block">
                        <Field
                           name="name"
                           label="Adınız*"
                           type="text"
                           msg={'Ad giriniz.'}
                           component={TextInputFieldComponent}
                        />
                        <Field
                           name="surname"
                           type="text"
                           label="Soyadınız*"
                           msg={'Soyad giriniz.'}
                           
                           component={TextInputFieldComponent}
                        />
                        <Field
                           name="phone"
                           type="text"
                           msg={'Telefon giriniz.'}
                           label="Telefon numaranız*"
                           placeholder={'( 5xx ) xxx xx xx'}

                           parse={normalizePhone}

                           component={TextInputFieldComponent}
                        />
                        <Field
                           name="email"
                           type="text"
                           msg={'Email giriniz.'}
                           label="Email adresiniz"
                           component={TextInputFieldComponent}
                        />
                        <Field
                           name="permission"
                           type="text"
                           label={`<a href="https://www.vitra.com.tr/kesfet/aydinlatma-metni/" target="_blank" rel="noreferrer">Aydınlatma Metnine</a>  uygun olarak SMS, telefon, e-posta ve sair araçlarla elektronik ticari ileti almayı <a class="vra-accept-text" href="https://www.vitra.com.tr/kesfet/kvk-riza-metni/" target="_blank" rel="noreferrer">kabul ediyorum.</a>`}
                           component={CheckBoxComponent}
                        />
                        <Field
                           name="kvkk"
                           type="text"
                           label={`<a href="https://www.vitra.com.tr/kesfet/aydinlatma-metni/" target="_blank" rel="noreferrer">Aydınlatma Metni’nde </a> belirtilen kapsam, amaç ve süre dahilinde kişisel verilerimin işlenmesini, yurt içi ve yurt dışı gerçek ve tüzel kişilere aktarılmasını <a class="vra-accept-text" href="https://www.vitra.com.tr/kesfet/kvk-riza-metni/" target="_blank" rel="noreferrer">kabul ediyorum.</a>`}
                           component={CheckBoxComponent}
                        />
                     </div>
                  </div>
                  <Field
                     name="recaptcha"
                     component={RecaptchaComponent}
                     recaptchaRef={this.recaptchaRef}
                  />
                  {
                     this.state.isLoading === true &&
                     <LoaderComponent/>
                  }
               </form>
            )}
         />
      )
   }
}

export default CampaigneFormComponent