import React, { Component } from 'react';
import { ASSETS } from "../constants/paths";

class BackgroundColorComponent extends Component {
   
   render() {
      let { placePictures,selectedPlace,changePlace } = this.props;
      return (
         <div className="vra-bg-wrapper">
            {
               placePictures.length > 3 &&
               <i className="gg-arrow" />
            }
            <div className="vra-bg-container">

               <div className="vra-bg-block">
                  {
                     placePictures.map((placeItem, key) =>
                        <div className={`vra-bg-slide-item ${selectedPlace===placeItem?"active":""}`} key={key} onClick={() => { changePlace(placeItem) }}>
                           <div className="vra-img-item">
                              <picture>
                                 {/* <source srcSet={`${ASSETS}/img/home/bg_blue-1x.webp`} type="image/webp" /> */}
                                 <img src={`${ASSETS}/img/BanyoAlani/Mekan/${placeItem}.png`} alt={placeItem} />
                              </picture>
                           </div>
                        </div>
                     )
                  }
               </div>
               {
                  placePictures.length > 3 &&
                  <div className="vra-dots">
                     <span />
                     <span className="active" />
                     <span />
                     <span />
                  </div>
               }
            </div>
            {
               placePictures.length > 3 &&
               <i className="gg-arrow right" />
            }
         </div>
      );
   }
}

export default BackgroundColorComponent;
