import React, { Component } from "react";
import { connect } from "react-redux";
import ImageSliderComponent from "./imageSliderComponent";
import {setMirrorCoverDirection } from "../../actions";

class RespMirrorCoverDirection extends Component {
    state = {
        activeIndex: null
    }
    selectedItem;
    setCoverDirection = (item) => {
        this.selectedItem=item
        let activeIndex = this.props.setMirrorType.coverDirections.findIndex(x=>x.id === item.id)
        this.props.dispatch(setMirrorCoverDirection(item))
        this.setState({
            activeIndex
        })
    }

    changeSelectedItemByIndex = (index) => {
        this.props.dispatch(setMirrorCoverDirection(this.props.setMirrorType.coverDirections[index]))
    }
    render() {
        let { language, activeLang, selectedSinkSize, mirrorType, setMirrorType,selectedMirrorCoverDirection} = this.props;
        return (
            <div className="vra-selections-block vra-mobile-block vra-sizes">
                {

                    <div className="vra-tooltip-item">
                        <span>{language.choose_door_side}</span>
                    </div>
                }

                {
                    (selectedSinkSize === "45 cm" || selectedSinkSize === "60 cm") &&
                    <ImageSliderComponent ref={this.sliderComponent}
                        changeItem={this.changeSelectedItemByIndex}
                        activeIndex={this.state.activeIndex}>
                        {
                            setMirrorType && setMirrorType.coverDirections.map((coverItem, index) =>
                                <div className={`vra-option-item vra-color-option ${ selectedMirrorCoverDirection && coverItem.id === selectedMirrorCoverDirection.id ? "active":""}`} key={index}>
                                    <div onClick={() => {
                                        this.setCoverDirection(coverItem)

                                    }} className={`vra-round-item `}>
                                        <p>{JSON.parse(coverItem.transition)[activeLang] || coverItem.name}</p></div>

                                </div>
                            )
                        }

                    </ImageSliderComponent>
                }

            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    setMirrorType: state.userSelectionReducer.setMirrorType,
    withBacklight: state.userSelectionReducer.mirrorWithBackLight,
    mirrorType: state.mirrorReducer.mirrorType || [],
    activeLang: state.commonReducer.activeLang,
    language: state.languageReducer[state.commonReducer.activeLang],
    selectedSinkSize: state.userSelectionReducer.setSinkSizes.name,
    selectedMirrorCoverDirection: state.userSelectionReducer.selectedMirrorCoverDirection,
})
export default connect(mapStateToProps)(RespMirrorCoverDirection)
