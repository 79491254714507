import React, {Component} from 'react';
import {ASSETS} from "../constants/paths";
import OtherOptionSelectComponent from "./fields/otherOptionSelectComponent";
import {setOtherModules, setOtherModulesCoverDirection} from "../actions";
import {connect} from "react-redux";

class OtherSelectBoxComponent extends Component {
   selectBlock=React.createRef();
   state={
      selectedItem:null
   }
   componentDidMount() {
      window.addEventListener("click",this.closeOutsideClick,false)
   }
   componentWillUnmount() {
      window.removeEventListener("click",this.closeOutsideClick,false)
   }
   closeOutsideClick = (e) => {
      if(this.selectBlock.current && !this.selectBlock.current.contains(e.target)){
         this.setState({
            selectedItem:null
         })
      }
   }
   handleOnclick = (item) => {
      if (item.id === this.state.selectedItem) {
         this.setState({
            selectedItem: null
         })
      } else {
         this.setState({
            selectedItem: item.id
         })
      }
      if(window.innerWidth<641){
         this.props.dispatch(setOtherModules(item))
         if (item.other_module_cover_directions && item.other_module_cover_directions.length > 1) {
            this.props.dispatch(setOtherModulesCoverDirection(item.other_module_cover_directions[0]))
         }
      }
   }
   render() {
      let{setOtherModules,language,activeLang,item,index,setOtherModulesFunc}=this.props;
      return (
          <div ref={this.selectBlock}
              className={`vra-custom-select-block ${this.state.selectedItem === item.id ? "opened" : ""} ${setOtherModules && setOtherModules.id === item.id && (window.innerWidth<641 || !this.state.selectedItem) ? "active" : ""}`}
              onClick={() => this.handleOnclick(item)} key={index}>
             <div className="vra-custom-select-item">
                <div className="vra-img-item">
                   <picture>
                      <source srcSet={`${ASSETS}${item.img.substr(0,item.img.indexOf("."))+".webp"}`}  type="image/webp"/>
                      <source srcSet={`${ASSETS}${item.img.substr(0,item.img.indexOf("."))+".png"}`}  type="image/png"/>
                      <img src={`${ASSETS}${item.img}`} alt={item.name} />
                   </picture>
                </div>
                <div className="vra-text-item">
                   <p>{JSON.parse(item.transition)[activeLang] || item.name}</p>
                </div>
             </div>
             <i className="gg-chevron" />
             {
                <div className="vra-option-container">
                   <p>{language.door_side}</p>
                   <div className="vra-option-block">
                      {
                         item.other_module_cover_directions.map((direction, _) =>
                             <OtherOptionSelectComponent  otherModule={item} setOtherModulesFunc={this.props.setOtherModulesFunc} key={index + "-" + _} {...direction} />
                         )
                      }
                   </div>
                </div>
             }
          </div>
      );
   }
}
const mapStateToProps = (state) => ({
   otherModules: state.otherModuleReducer.otherModules || [],
   setOtherModuleCoverDirection: state.userSelectionReducer.setOtherModuleCoverDirection,
   setOtherModules: state.userSelectionReducer.setOtherModules,
   activeLang: state.commonReducer.activeLang,
   language: state.languageReducer[state.commonReducer.activeLang]

})
export default connect(mapStateToProps) (OtherSelectBoxComponent);
