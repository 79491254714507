import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {setMirrorBacklight,setMirrorCoverDirection} from "../actions";

class MirrorOptionComponent extends Component {
   setBacklight = (item) => {
      this.props.dispatch(setMirrorBacklight(item))
      if(this.props.item.coverDirections && this.props.item.coverDirections[0] && this.props.item.id===2 && (this.props.selectedSinkSize === "45 cm" || this.props.selectedSinkSize === "60 cm") && (!this.props.selectedMirrorCoverDirection)){
         this.props.dispatch(setMirrorCoverDirection(this.props.item.coverDirections[0]))
      }
   }
   setCoverDirection = (item) => {
      this.props.dispatch(setMirrorCoverDirection(item))
   }
   render() {
      let{item,setSize,language,activeLang,withBacklight,mirrorSize,index,setMirrorType,selectedSinkSize,selectedMirrorCoverDirection} =this.props;
      return (
          <div className="vra-option-container">
             <div className="vra-option-content">
                {
                   (item.mirror_style_sizes && item.mirror_style_sizes.length > 1) &&
                   <p>{language.size}</p>
                }
                {
                   (item.mirror_style_sizes && item.mirror_style_sizes.length > 1) &&
                   <div className="vra-option-block">
                      {
                         item.mirror_style_sizes.sort((a,b)=>(parseInt(a.size.name)>parseInt(b.size.name) ? 1 : parseInt(b.size.name) > parseInt(a.size.name) ? -1 : 0)).map((sizeItem, subIndex) =>
                             <div
                                 className={`vra-option-item ${mirrorSize && sizeItem.id === mirrorSize.id ? "active" : ""}`}
                                 key={index + "-" + subIndex} onClick={(e) => {
                                 setSize(sizeItem, item);
                                 if(!setMirrorType){
                                    this.setBacklight({light:false,id:item.id})
                                 }
                                 if(item.id!==2 || (selectedSinkSize !== "45 cm" && selectedSinkSize !== "60 cm")){
                                    this.setCoverDirection(null)
                                 }
                                 e.stopPropagation()
                             }}>
                                <span>{JSON.parse(sizeItem.size.transition)[activeLang] || sizeItem.size.name}</span>
                             </div>
                         )
                      }
                   </div>

                }
             </div>

             {
                item.with_backlight &&
                <Fragment>
                   <div className="vra-option-content">
                      <p>{language.lighting}</p>
                      <div className="vra-option-block">
                         <div onClick={(e) => {
                            if(!item.mirror_style_sizes || item.mirror_style_sizes.length === 0){
                              setSize(null,item)
                            }
                            if(item.id!==2 || (selectedSinkSize !== "45 cm" && selectedSinkSize !== "60 cm")){
                              this.setCoverDirection(null)
                           }
                           // else{
                           //    this.setCoverDirection({name:"sag",id:1})
                           // }
                              this.setBacklight({light:true,id:item.id})

                            e.stopPropagation()
                         }} className={`vra-option-item ${setMirrorType && withBacklight&&withBacklight.light === true && item.id===setMirrorType.id ? "active" : ""}`}>
                            <span>{language.available}</span></div>
                         <div onClick={(e) => {
                            if(!item.mirror_style_sizes || item.mirror_style_sizes.length === 0){
                                 setSize(null,item)
                            }
                              this.setBacklight({light:false,id:item.id})

                            e.stopPropagation()
                         }} className={`vra-option-item ${withBacklight&&withBacklight.light === false && (setMirrorType) && item.id===setMirrorType.id ? "active" : ""}`}>
                            <span>{language.not_available}</span></div>
                      </div>
                   </div>
                </Fragment>
             }
             {
                (item.id===2 && (selectedSinkSize === "45 cm" || selectedSinkSize === "60 cm")) &&
                <div className="vra-option-content">
                <p>{language.door_side}</p>
                <div className="vra-option-block">
                   {
                      item.coverDirections.map((coverItem,key)=>
                      <div key={key} onClick={(e) => {
                        if(!item.mirror_style_sizes || item.mirror_style_sizes.length === 0){
                          setSize(null,item)
                        }
                          this.setCoverDirection(coverItem)
                        e.stopPropagation()

                     }} className={`vra-option-item ${setMirrorType && selectedMirrorCoverDirection && selectedMirrorCoverDirection.id === coverItem.id && item.id===setMirrorType.id ? "active" : ""}`}>
                        <span>{JSON.parse(coverItem.transition)[activeLang]}</span></div>
                      )
                   }
                </div>
             </div>
             }

          </div>
      );
   }
}
const mapStateToProps = state => ({
   mirrorType: state.mirrorReducer.mirrorType || [],
   setMirrorType: state.userSelectionReducer.setMirrorType,
   mirrorSize: state.userSelectionReducer.mirrorSize,
   coverTypes: state.sinkReducer.coverTypes || [],
   setCoverSelectionType: state.userSelectionReducer.setCoverSelectionType,
   withBacklight: state.userSelectionReducer.mirrorWithBackLight,
   activeLang: state.commonReducer.activeLang,
   language: state.languageReducer[state.commonReducer.activeLang],
   selectedSinkSize: state.userSelectionReducer.setSinkSizes.name,
   selectedMirrorCoverDirection: state.userSelectionReducer.selectedMirrorCoverDirection
})
export default connect(mapStateToProps) (MirrorOptionComponent);
