import React, {Component} from 'react';
import { connect } from 'react-redux';

class VideoModalComponent extends Component {
   render() {
      let{toggleModal,isModalOpen,videoLink}=this.props;
      return (
          <div className={`vra-lb-root vra-video ${isModalOpen?"open":""}`} onClick={(e)=>{
             toggleModal()
             // e.stopPropagation()
          }}>

             <div className="vra-lb-wrapper">
                <div className="vra-lb-container">

                   <div className="vra-iframe-item">
                      <i className="gg-close" onClick={toggleModal}/>
                      <iframe width={560} height={315} src={videoLink} title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                   </div>
                </div>
             </div>
          </div>
      );
   }
}


const mapStateToProps = (state) => ({
   videoLink: state.commonReducer.landingData && state.commonReducer.landingData.video ? state.commonReducer.landingData.video[state.commonReducer.activeLang]:null
})

export default connect(mapStateToProps)(VideoModalComponent);
