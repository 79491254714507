import {
   GET_COVER_COLOURS,
   GET_COVER_STYLES, GET_COVER_TYPES, GET_HANDLE_TYPES, GET_SINK_SIZES, GET_SINK_TYPES,
   RECEIVE_PLACE_PICTURES
} from "../constants/actionTypes";
const initialState = { coverStyles: [], coverColours: [], sinkTypes: [], sinkSize: [], coverTypes: [], handleTypes: [] }

const SinkReducer = (state = initialState, action) => {
   /*//TODO: SHOWING CHOSEN comp. adım sayısı sink reducerdaki keys sayısından alınıyor(6) yeni key eklenirse düzenlenecek*/
   switch (action.type) {
      case GET_COVER_STYLES:
         return {
            ...state,
            coverStyles: action.payload
         }

      case GET_COVER_COLOURS:
         return {
            ...state,
            coverColours: action.payload
         }
      case GET_SINK_TYPES:
         return {
            ...state,
            sinkTypes: action.payload
         }
      case GET_SINK_SIZES:
         return {
            ...state,
            sinkSize: action.payload
         }
      case GET_COVER_TYPES:
         return {
            ...state,
            coverTypes: action.payload
         }
      case GET_HANDLE_TYPES:
         return {
            ...state,
            handleTypes: action.payload
         }
      case RECEIVE_PLACE_PICTURES:
         return {
            ...state,
            placePictures: action.payload
         }
      default:
         return state
   }
}
export default SinkReducer;
