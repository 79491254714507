import React, { Component } from 'react';
import { getElementWidth, getElementMargin } from "../../helper";

class MobileSliderComponent extends Component {

   sliderComponent = React.createRef();
   activeIndex = 0
   itemWidth = 0
   translateValue = 0
   initTranslate = 0
   margin = 20
   startPosition = 0;
   setTranslateValue = (activeIndex, translateValue) => {
      // translateValue-=this.margin;
      activeIndex = activeIndex || this.props.activeIndex;
      if(this.sliderComponent.current && this.sliderComponent.current.children[0]){
         if (window.innerWidth < 641) {
            this.itemWidth = getElementWidth(this.sliderComponent.current.children[0])
            this.initTranslate = Math.floor((window.innerWidth / 2) - (this.itemWidth / 2))
            this.sliderComponent.current.style.transform = `translate(${translateValue === null ? 0 : (this.initTranslate - (translateValue || 0))}px,0)`
         } else {
            this.sliderComponent.current.style.transform = null
         }
      }
   }

   componentDidUpdate(prevProps) {
      if (prevProps.activeIndex !== this.props.activeIndex) {
         this.activeIndex = this.props.activeIndex;
         this.calculateTranslateValue();
         this.setTranslateValue(this.props.activeIndex, this.translateValue);
      }
   }

   calculateTranslateValue = () => {
      //this.itemWidth = getElementWidth(this.sliderComponent.current.children[this.activeIndex])
      //this.translateValue = this.activeIndex * this.itemWidth
      this.translateValue = 0;
      if(this.sliderComponent.current && this.sliderComponent.current.children){
         for (let i = 0; i < this.sliderComponent.current.children.length; i++) {
            if (i < this.activeIndex) {
               this.translateValue += getElementWidth(this.sliderComponent.current.children[i])
            }
         }
         this.translateValue += getElementMargin(this.sliderComponent.current.children[this.activeIndex])
      }
   }

   slideFunction = (direction) => {

      // let activeIndex = this.props.activeIndex;
      if (direction === 1 && this.activeIndex !== this.sliderComponent.current.children.length - 1) {
         this.activeIndex = this.activeIndex + direction

      } else if (direction === -1 && this.activeIndex !== 0) {
         this.activeIndex = this.activeIndex + direction

      }
      /*todo:updating reducer below*/
      this.props.changeItem && this.props.changeItem(this.activeIndex)
      this.calculateTranslateValue();
      this.setTranslateValue(this.activeIndex, this.translateValue)

      this.forceUpdate()
   }

   componentDidMount() {
      /*{emre} todo: yapı değişecek!!*/

      setTimeout(() => {
         this.activeIndex = this.props.activeIndex ? this.props.activeIndex : this.activeIndex;
         this.calculateTranslateValue();
         this.setTranslateValue(this.activeIndex, this.translateValue)
      }, 250)
      if (typeof window !== "undefined") {
         this.sliderComponent.current.addEventListener('touchstart', this.handleTouchStart, false);
         this.sliderComponent.current.addEventListener('touchmove', this.handleTouchMove, false);
         window.addEventListener("resize", this.resizeListener)
      }
   }

   resizeListener = () => {
      if (window.innerWidth > 640) {
         this.setTranslateValue(this.activeIndex, this.translateValue)
      } else {
         this.calculateTranslateValue();
         this.setTranslateValue(this.activeIndex, this.translateValue)
      }
   }

   componentWillUnmount() {
      this.sliderComponent.current.removeEventListener("touchstart", this.handleTouchStart, false)
      this.sliderComponent.current.removeEventListener("touchmove", this.handleTouchMove, false)
      window.removeEventListener("resize", this.resizeListener)
   }


   handleTouchStart = (evt) => {
      this.xDown = evt.touches[0].clientX;
      this.yDown = evt.touches[0].clientY;
   };

   handleTouchMove = (evt) => {
      if (!this.xDown || !this.yDown) {
         return;
      }
      this.xUp = evt.touches[0].clientX;
      this.yUp = evt.touches[0].clientY;

      let xDiff = this.xDown - this.xUp;
      let yDiff = this.yDown - this.yUp;
      if (Math.abs(xDiff) + Math.abs(yDiff) > 10) {
         if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
               this.slideFunction(1);
               this.props.updateCoverDirection && this.props.updateCoverDirection()

            } else {
               this.slideFunction(-1);
               this.props.updateCoverDirection && this.props.updateCoverDirection()
            }
         }
         this.xDown = null;
         this.yDown = null;
      }
   };

   render() {
      return (
         <div className={`vra-custom-select-container ${this.props.className ? this.props.className : ""}`} ref={this.sliderComponent}>
            {
               this.props.children
            }
         </div>
      );
   }
}

export default MobileSliderComponent;
