export const ROADSHOWLINK = "roadshow-568bvth85vitra1";
export const LANDING_PAGE = "/:lang"
export const HOME_PAGE = LANDING_PAGE+"/configurator"
export const ORDER_PAGE = "/order"
export const DE_LANDING_PAGE = "/rootkonfigurator/:lang"
export const DE_HOME_PAGE = DE_LANDING_PAGE+"/configurator"
export const DE_ORDER_PAGE = "/rootkonfigurator/order"
export const DEALER_FORM_PAGE = `/${ROADSHOWLINK}/:lang/dealer-form/:token`
export const CMS_PAGE = "/cms"
export const NOT_FOUND = "/404"
export const ROADSHOW_LANDING_PAGE = `/${ROADSHOWLINK}/:lang`
export const ROADSHOW_HOME_PAGE = ROADSHOW_LANDING_PAGE+"/configurator"
export const ROADSHOW_ORDER_PAGE = `/${ROADSHOWLINK}/order`;
