import React, {Component, Fragment} from 'react';
import {
   checkIsLoading,
   postSinkSizeIdAction, getHandleTypesAction
} from "../actions";
import {connect} from "react-redux";
import CoverCustomSelectComponent from "./fields/coverCustomSelectComponent";
import SkeletonComponent from "./common/skeletonComponent";
import {ASSETS} from "../constants/paths";


class CoverSelectionComponent extends Component {

   componentDidMount() {
      this.props.dispatch(postSinkSizeIdAction(this.props.setSinkSizeId.id))
      this.props.dispatch(getHandleTypesAction())
      if(!this.props.coverTypes || this.props.coverTypes.length===0){
         this.props.dispatch(checkIsLoading(true))
      }
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      if(this.props.coverTypes &&this.props.coverTypes.length>0 ){
         this.props.dispatch(checkIsLoading(false))
      }
      if(prevProps.activeLang !== this.props.activeLang){
         this.props.dispatch(postSinkSizeIdAction(this.props.setSinkSizeId.id))
      }
      /*if ((!prevProps.coverTypes || prevProps.coverTypes.length === 0) && (this.props.coverTypes && this.props.coverTypes.length > 0)) {
         if (this.props.setCoverSelectionType && !this.props.setCoverSelectionType.sink_cover_stands) {
            let activeItem = this.props.coverTypes.find(item => item.id === this.props.setCoverSelectionType.id);
            this.props.dispatch(setCoverSelectionType(activeItem))
            if (activeItem && activeItem.sink_cover_stands && activeItem.sink_cover_stands.length > 1) {
               this.props.dispatch(setCoverSelection(activeItem.sink_cover_stands[0]))
            }
         }

      }*/
   }

   render() {
      let {isLoading,coverTypes,language} = this.props;
      return (
          <Fragment>
             {
                (coverTypes&&coverTypes.length>0 && !isLoading)&&
                <div className="vra-colour-selection-container">
                   <div className="vra-title-head-item">
                      <h3>{language.door_type}</h3>
                   </div>
                   <CoverCustomSelectComponent />
                </div>
             }
             {
                isLoading &&
                <SkeletonComponent/>
             }
          </Fragment>
      );
   }
}

const mapStateToProps = (state) => ({
   setSinkSizeId: state.userSelectionReducer.setSinkSizes,
   isLoading: state.commonReducer.isLoading,
   coverTypes: state.sinkReducer.coverTypes || [],
   setCoverSelectionType: state.userSelectionReducer.setCoverSelectionType,
   language: state.languageReducer[state.commonReducer.activeLang],
   activeLang: state.commonReducer.activeLang

})
export default connect(mapStateToProps)(CoverSelectionComponent);
