import React, {Component, Fragment} from "react";
import {Link} from "react-router-dom";
import {DE_HOME_PAGE, HOME_PAGE} from "../../constants/routePaths";
import {assignProductStep, getFeaturedBathroomDataAction, setAllUserSelection, setRandomValue} from "../../actions";
import {connect} from "react-redux";

const randomBathroomJSON = require("../../constants/dummyBathroom.json");

class RandomBtnComponent extends Component {

   setRandom = () => {
      let randmArr = this.props.randomArr.filter(item=>item.available_countries.indexOf(this.props.activeLang)!==-1);
      let randmInx = Math.floor(Math.random() * (randmArr.length))
      this.props.dispatch(getFeaturedBathroomDataAction(randmArr[randmInx].featuredId));
      //this.props.dispatch(setAllUserSelection(randomBathroomJSON))
      this.props.dispatch(assignProductStep(5))
      this.props.dispatch(setRandomValue(true))
      this.setDataLayer()
   }
   setDataLayer = () => {
      if(!window.dataLayer) window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
         'event': 'configurator',
         'buttonName': 'Rastgele Tasarım Oluştur',//Burası rastgele tasarım oluştur olarakta gelebilir.
         'configuratorStep' : '1'
      });
   }
   render() {
      let {language, activeLang} = this.props
      return (
          <div className="vra-design-btn">

             {
                activeLang !== "de" &&
                <Fragment>
                   <div className="vra-icon-item">
                      <svg width={21} height={19} viewBox="0 0 21 19" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                         <path
                             d="M1.5 15.5H3.5C4.75903 15.5 5.94458 14.9072 6.7 13.9L13.3 5.1C14.0554 4.09278 15.241 3.5 16.5 3.5H19.5"
                             stroke="black" strokeWidth={2} strokeLinecap="round"
                             strokeLinejoin="round"/>
                         <path d="M17.5 1.5L19.5 3.5L17.5 5.5" stroke="black" strokeWidth={2}
                               strokeLinecap="round" strokeLinejoin="round"/>
                         <path fillRule="evenodd" clipRule="evenodd"
                               d="M8.75 6.16667L7.5 4.5C6.55573 3.24097 5.07379 2.5 3.5 2.5H1.5C0.947715 2.5 0.5 2.94771 0.5 3.5C0.5 4.05229 0.947715 4.5 1.5 4.5H3.5C4.44427 4.5 5.33344 4.94458 5.9 5.7L7.5 7.83333L8.75 6.16667ZM11.25 12.8333L12.5 14.5C13.4443 15.759 14.9262 16.5 16.5 16.5H19.5C20.0523 16.5 20.5 16.0523 20.5 15.5C20.5 14.9477 20.0523 14.5 19.5 14.5H16.5C15.5557 14.5 14.6666 14.0554 14.1 13.3L12.5 11.1667L11.25 12.8333Z"
                               fill="black"/>
                         <path d="M17.5 17.5L19.5 15.5L17.5 13.5" stroke="black" strokeWidth={2}
                               strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                   </div>
                   <div className="vra-text-item">
                      <Link onClick={this.setRandom}
                            to={window.location.pathname.indexOf("/rootkonfigurator")!==-1?DE_HOME_PAGE.replace(":lang", this.props.activeLang) :HOME_PAGE.replace(":lang", this.props.activeLang)}>{language.create_design}</Link>
                   </div>
                </Fragment>
             }


          </div>
      )
   }
}


const mapStateToProps = (state) => ({
   randomArr: state.commonReducer.landingData.attracted_product ? state.commonReducer.landingData.attracted_product.filter(item => item.featuredId !== state.commonReducer.lastRandomId) : [],
   activeLang: state.commonReducer.activeLang,
   language: state.languageReducer[state.commonReducer.activeLang]
})

export default connect(mapStateToProps)(RandomBtnComponent)
