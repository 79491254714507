import React, { Component } from 'react'
import { Form, Field } from 'react-final-form'
import TextInputFieldComponent from "../fields/textInputFieldComponent"
import RecaptchaComponent from '../fields/recaptchaComponent';
import CheckBoxComponent from '../fields/checboxFieldComponent';
import { sendEmailToUserAction } from '../../actions';
import LoaderComponent from '../common/loaderComponent';
import { connect } from 'react-redux';
import { ASSETS } from '../../constants/paths';
const validate = (values) => {
   let emailRegexp = /^([a-zA-Z0-9!#$%&'*-`{|}~_.]{1}[a-zA-Z0-9!#$%&'*-`{|}~_.]*)(@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6})$/i
   const errors = {}
   
   if (!values.email || !values.email.trim().length || !emailRegexp.test(values.email)) {
      errors.email = true
   }
   if (!values.kvkk_allowed || !values.kvkk_allowed.length === 0) {
      errors.kvkk_allowed = true
   }
   if (!values.recaptcha || !values.recaptcha.length === 0) {
      errors.recaptcha = true
   }
   return errors;
}
class EmailSendFormComponent extends Component {
   recaptchaRef=React.createRef()
   state = {
      isLoading:false,
      hasError:false
   }
   submit = (values) => {
      this.setState({
         isLoading:true,
         hasError:false
      })
      sendEmailToUserAction({
         ...values,
         bahtroom_sale_code:this.props.orderSummary.bathroomCabinet.sale_code,
         bahtroom_handle_code:this.props.orderSummary.handleData.sale_code,
         bathroom_leg_code:this.props.orderSummary.stand ? this.props.orderSummary.stand.sale_code : null,
         armature_code:this.props.orderSummary.armatureStyleData ? this.props.orderSummary.armatureStyleData.model_code:null,
         tallcabinet_code:this.props.orderSummary.tallCabinetInfo ? this.props.orderSummary.tallCabinetInfo.sale_code:null,
         mirror_code:this.props.orderSummary.mirrorData ? this.props.orderSummary.mirrorData.sale_id:null,
         othermodule_code:this.props.orderSummary.otherModules ? this.props.orderSummary.otherModules.sale_code:null,
         picture_obj:this.getPictureObj(),
         picturePath:this.props.userSelection.bathroomAreaPicture ? this.props.userSelection.bathroomAreaPicture : this.props.bathPicture}).then(res=>{
         if(res){
            this.props.setFormValues(values)
            this.props.changeFormStep(2)
         }else{
            this.resetRecaptcha()
            this.props.showErrorMsg(!res)
            this.setState({
               isLoading:false,
               hasError:true
            })
         }
         
      }).finally(()=>{
         this.setState({
            isLoading:false,
         })
      })
   }
   resetRecaptcha=()=>{
      this.recaptchaRef.current.reset()
   }
   componentDidMount(){
      this.props.showErrorMsg(false)
   }
   

   getPictureObj = () => {
      let height;
      if (this.props.userSelection.setSinkSizes && this.props.userSelection.setSinkSizes.sink_size_covers[0]) {
         height = this.props.userSelection.setSinkSizes.sink_size_covers[0].height;
         if (this.props.userSelection.setCoverSelectionType && this.props.userSelection.setSinkSizes.sink_size_covers.find(item => item.id === this.props.userSelection.setCoverSelectionType.id)) {
            height = this.props.userSelection.setSinkSizes.sink_size_covers.find(item => item.id === this.props.userSelection.setCoverSelectionType.id).height;
         }
      }
      return {
         serie: this.props.userSelection.setCoverStyle ? this.props.userSelection.setCoverStyle.name : null,
         color: this.props.userSelection.setCoverColour ? `${this.props.userSelection.setCoverType.id == 3 ? this.props.userSelection.setCoverType.name + " " : ""}${this.props.userSelection.setCoverColour.cover_color.name}` : null,
         placeColorName: this.props.userSelection.setCoverColour ? `${this.props.userSelection.setCoverColour.cover_color.name}` : null,
         size: this.props.userSelection.setSinkSizes ? this.props.userSelection.setSinkSizes.name : null,
         cover: this.props.userSelection.setCoverSelectionType ? this.props.userSelection.setCoverSelectionType.name : null,
         direction: this.props.userSelection.setSinkSizeDirection ? this.props.userSelection.setSinkSizeDirection.cover_direction.name : "Sag",
         handleColor: this.props.userSelection.setHandleTypes ? this.props.userSelection.setHandleTypes.name : null,
         stand_size: this.props.userSelection.setCoverSelection ? this.props.userSelection.setSinkSizes.name : null,
         sinkSerie: this.props.userSelection.setSinkType ? this.props.userSelection.setSinkType.serie_name : null,
         height,
         is_stand: this.props.userSelection.setCoverSelection && this.props.userSelection.setCoverSelection.sink_stand ? this.props.userSelection.setCoverSelection.sink_stand.name : null,
         armature: this.props.userSelection.setArmatureColour ? this.props.userSelection.setArmatureType.name : null,
         armatureColorId: this.props.userSelection.setArmatureColour ? this.props.userSelection.setArmatureColour.id : null,
         otherModule: this.props.userSelection.setOtherModules ? this.props.userSelection.setOtherModules.name : null,
         otherModuleDirection: this.props.userSelection.setOtherModuleCoverDirection ? this.props.userSelection.setOtherModuleCoverDirection.cover_direction.name : null,
         mirror: this.props.userSelection.setMirrorType ? this.props.userSelection.setMirrorType.name : null,
         tallCabinet: this.props.userSelection.setTallCabinetCoverDirection ? this.props.userSelection.setTallCabinetCoverDirection.cover_direction.name : null,
         mirrorSize: this.props.userSelection.mirrorSize ? this.props.userSelection.mirrorSize.size.name : null,
         tallCabinetSizeName: this.props.userSelection.setTallCabinet && this.props.userSelection.setTallCabinet.tall_cabinet_sizes && this.props.userSelection.setTallCabinet.tall_cabinet_sizes[0] ? this.props.userSelection.setTallCabinet.tall_cabinet_sizes[0].size_name : null,
         otherModuleSizeName: this.props.userSelection.setSinkTitle ? this.props.userSelection.setSinkTitle.name : "",
         otherModuleSize: this.props.userSelection.setOtherModules ? this.props.userSelection.setOtherModules.size : null,
         withBackLight: this.props.userSelection.mirrorWithBackLight ? this.props.userSelection.mirrorWithBackLight.light : 0,
         place: this.props.userSelection.place,
         mirrorCoverDirection:this.props.userSelection.selectedMirrorCoverDirection ? this.props.userSelection.selectedMirrorCoverDirection.name : "sag",
         leg_color_name: this.props.userSelection.legColor ? this.props.userSelection.legColor.name : null
      }
   }

   render() {
      
      return (
         <Form
            onSubmit={this.submit}
            validate={validate}
            render={({ handleSubmit }) => (
               <form className='road-show-form' onSubmit={handleSubmit} id='road-map-form'>
                  <div className="input-container vra-mail-input-add">
                     <div className="input-block">
                        <Field
                           name="email"
                           type="text"
                           msg={'Your e-mail address'}
                           label="Your e-mail address"
                           component={TextInputFieldComponent}
                        />
                        <Field
                           name="kvkk_allowed"
                           type="text"
                          
                             
                           label={`By ticking this box you agree to our <a href="https://www.vitraglobal.com/UPLOAD/document/Politikalar/privacy_policy_and_data_protection_policy.pdf" target="_blank" rel="noreferrer">"Terms and Conditions"</a>which set out how and where we hold and process your data.`}
                           component={CheckBoxComponent}
                        />
                        <Field
                           name="etk_allowed"
                           type="text"
                           
                           label={`By ticking this box you agree to be communiated via my e-mail address as per  <a href="https://www.vitraglobal.com/UPLOAD/document/Politikalar/privacy_policy_and_data_protection_policy.pdf" target="_blank" rel="noreferrer">"Terms and Conditions" </a> `}
                           component={CheckBoxComponent}
                        />
                     </div>
                  </div>
                  <Field
                     name="recaptcha"
                     component={RecaptchaComponent}
                     recaptchaRef={this.recaptchaRef}
                  />
                  {
                     this.state.isLoading === true &&
                     <LoaderComponent/>
                  }
               </form>
            )}
         />
      )
   }
}


const mapStateToProps = (state) => ({
   productStep: state.productStepReducer.productStepNumber,
   userSelection: state.userSelectionReducer,
   isFromRandom: state.commonReducer.isFromRandom,
   sinkSize: state.sinkReducer.sinkSize || [],
   selectSinkTypeId: state.userSelectionReducer.setSinkType ? state.userSelectionReducer.setSinkType.id : null,
   selectionType: state.commonReducer.selectionType,
   isOpenModelsModal: state.commonReducer.isOpenModelsModal || false,
   isBgModal: state.commonReducer.isBgModal || false,
   orderSummary: state.orderSummaryReducer,

})

export default connect(mapStateToProps)(EmailSendFormComponent);