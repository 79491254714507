import React, {Component} from "react";
import {ASSETS} from "../../constants/paths";
import SelectedProductComponent from "../selectedProductComponent";
import ModalButtonComponent from "./modalButtonComponent";
import {connect} from "react-redux";
import {toggleSelectedProductModal} from "../../actions";

class SelectedProductModalComponent extends Component{
   componentDidMount(){
      let height;
      if (this.props.userSelection.setSinkSizes && this.props.userSelection.setSinkSizes.sink_size_covers[0]) {
         height = this.props.userSelection.setSinkSizes.sink_size_covers[0].height;
         if (this.props.userSelection.setCoverSelectionType && this.props.userSelection.setSinkSizes.sink_size_covers.find(item => item.id === this.props.userSelection.setCoverSelectionType.id)) {
            height = this.props.userSelection.setSinkSizes.sink_size_covers.find(item => item.id === this.props.userSelection.setCoverSelectionType.id).height;
         }
      }
      // this.props.dispatch(getBathroomAreaAction({
      //    serie: this.props.userSelection.setCoverStyle ? this.props.userSelection.setCoverStyle.name : null,
      //    color: this.props.userSelection.setCoverColour ? `${this.props.userSelection.setCoverType.id==3?this.props.userSelection.setCoverType.name+" ":""}${this.props.userSelection.setCoverColour.name}` : null,
      //    size: this.props.userSelection.setSinkSizes ? this.props.userSelection.setSinkSizes.name : null,
      //    cover: this.props.userSelection.setCoverSelectionType ? this.props.userSelection.setCoverSelectionType.name : null,
      //    direction: this.props.userSelection.setSinkSizeDirection?this.props.userSelection.setSinkSizeDirection.cover_direction.name : "Sag",
      //    handleColor: this.props.userSelection.setHandleTypes ? this.props.userSelection.setHandleTypes.name : null,
      //    stand_size: this.props.userSelection.setCoverSelection ? this.props.userSelection.setSinkSizes.name : null,
      //    sinkSerie: this.props.userSelection.setSinkType ? this.props.userSelection.setSinkType.serie_name : null,
      //    height,
      //    is_stand: this.props.userSelection.setCoverSelection && this.props.userSelection.setCoverSelection.sink_stand ? this.props.userSelection.setCoverSelection.sink_stand.name : null,
      //    armature: this.props.userSelection.setArmatureColour?this.props.userSelection.setArmatureType.name:null,
      //    armatureColorId: this.props.userSelection.setArmatureColour?this.props.userSelection.setArmatureColour.id:null,
      //    otherModule: this.props.userSelection.setOtherModules?this.props.userSelection.setOtherModules.name:null,
      //    mirror: this.props.userSelection.setMirrorType?this.props.userSelection.setMirrorType.name:null,
      //    tallCabinet: this.props.userSelection.setTallCabinetCoverDirection?this.props.userSelection.setTallCabinetCoverDirection.cover_direction.name:null,
      //    mirrorSize: this.props.userSelection.mirrorSize?this.props.userSelection.mirrorSize.size.name:null,
      //    tallCabinetSizeName: this.props.userSelection.setTallCabinet && this.props.userSelection.setTallCabinet.tall_cabinet_sizes && this.props.userSelection.setTallCabinet.tall_cabinet_sizes[0]?this.props.userSelection.setTallCabinet.tall_cabinet_sizes[0].size_name:null,
      //    otherModuleSizeName: this.props.userSelection.setSinkTitle ?this.props.userSelection.setSinkTitle.name:"",
      //    width:1920,
      //    height:1080
      // }, () => {
      //    this.isPictureGet = true;
      //    this.forceUpdate();
      // }))
   }

   closeSelectedProductModal = () => {
      this.props.dispatch(toggleSelectedProductModal(false))
   }
   render(){
      let {userSelection,language} = this.props;
      return(
          <div className="vra-lb-root">
             <div className="vra-lb-wrapper">
                <div className="vra-lb-container">
                   <div className="vra-top-block">
                      <div className="vra-left-block">
                         <div className="vra-img-item">
                            <picture>
                               <img src={userSelection.bathroomAreaPicture?userSelection.bathroomAreaPicture:(ASSETS + "/img/home/order-document-img.jpg")} alt="selected-img"/>
                            </picture>
                         </div>
                      </div>
                      <div className="vra-right-block">
                         <div className="vra-head-item">
                            <h2>{language.chosen_products}</h2>
                            <i className="gg-close" onClick={this.closeSelectedProductModal}/>
                         </div>
                         <div className="vra-module-block">
                            <SelectedProductComponent/>
                         </div>
                         <div className="vra-bottom-block">
                            <ModalButtonComponent downloadPicturePath={userSelection.bathroomAreaPicture?userSelection.bathroomAreaPicture:(ASSETS + "/img/home/order-document-img.jpg")}/>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
      )
   }
}


const mapStateToProps = (state) => ({
   productStep: state.productStepReducer.productStepNumber,
   userSelection: state.userSelectionReducer,
   isFromRandom: state.commonReducer.isFromRandom,
   language: state.languageReducer[state.commonReducer.activeLang]
})

export default connect(mapStateToProps)(SelectedProductModalComponent);
