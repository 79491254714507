import {
   SET_ARMATURE_COLOUR,
   SET_ARMATURE_TYPES,
   SET_COVER_COLOUR,
   SET_COVER_SELECTION,
   SET_COVER_SELECTION_TYPE,
   SET_COVER_STYLES,
   SET_COVER_TYPE,
   SET_HANDLE_TYPES,
   SET_MIRROR_TYPES,
   SET_OTHER_MODULES,
   SET_OTHER_MODULES_DIRECTION,
   SET_SINK_SIZES,
   SET_SINK_TYPES,
   SET_TALL_CABINETS,
   SET_TALL_CABINETS_DIRECTION,
   SET_SINK_SIZE_COVER_DIRECTION,
   CHANGE_BATHROOM_PICTURE,
   SET_BATHROOM_TITLE_SELECTION,
   SET_MIRROR_SIZE,
   SET_MIRROR_BACKLIGHT,
   SET_USER_SESSION,
   SET_ALL_USER_SELECTION,
   RESET_ALL_USER_SELECTION,
   CHANGE_BATHROOM_AREA_PICTURE,
   DELETE_ADDITIONAL_PRODUCT,
   SET_COVER_TYPE_MOBILE_SELECTIION,
   MODEL_CHANGES,
   CHANGE_PLACE,
   SET_MIRROR_COVER_DIRECTION, SET_LEG_COLOR
} from "../constants/actionTypes";
import { createGUID } from "../helper";

const initialState = {
   setCoverStyle: null,
   setCoverColour: null,
   setSinkType: null,
   setSinkSizes: null,
   setCoverSelection: null,
   setCoverSelectionType: null,
   setHandleTypes: null,
   setArmatureType: null,
   setArmatureColour: null,
   setMirrorType: null,
   setTallCabinet: null,
   setCoverType: null,
   setTallCabinetCoverDirection: null,
   setOtherModules: null,
   setOtherModuleCoverDirection: null,
   bathroomPicture: null,
   setSinkTitle: null,
   mirrorSize: null,
   mirrorWithBackLight: null,
   mobileTypeSelectionId: null,
   modelsChanges: null
};

const userSelectionReducer = (state = initialState, action) => {
   switch (action.type) {
      case SET_COVER_STYLES:
         return {
            ...state,
            setCoverStyle: action.payload
         }
      case SET_COVER_TYPE:
         return {
            ...state,
            setCoverType: action.payload
         }
      case SET_COVER_COLOUR:
         return {
            ...state,
            setCoverColour: action.payload
         }
      case SET_SINK_TYPES:
         return {
            ...state,
            setSinkType: action.payload
         }
      case SET_SINK_SIZES:
         return {
            ...state,
            setSinkSizes: action.payload
         }
      case SET_COVER_SELECTION_TYPE:
         return {
            ...state,
            setCoverSelectionType: action.payload
         }
      case SET_COVER_SELECTION:
         return {
            ...state,
            setCoverSelection: action.payload
         }
      case SET_HANDLE_TYPES:
         return {
            ...state,
            setHandleTypes: action.payload
         }
      case SET_ARMATURE_TYPES:
         return {
            ...state,
            setArmatureType: action.payload
         }
      case SET_ARMATURE_COLOUR:
         return {
            ...state,
            setArmatureColour: action.payload
         }
      case SET_MIRROR_TYPES:
         return {
            ...state,
            setMirrorType: action.payload
         }
      case SET_TALL_CABINETS:
         return {
            ...state,
            setTallCabinet: action.payload
         }
      case SET_TALL_CABINETS_DIRECTION:
         return {
            ...state,
            setTallCabinetCoverDirection: action.payload
         }
      case SET_OTHER_MODULES:
         return {
            ...state,
            setOtherModules: action.payload
         }
      case SET_OTHER_MODULES_DIRECTION:
         return {
            ...state,
            setOtherModuleCoverDirection: action.payload
         }
      case SET_SINK_SIZE_COVER_DIRECTION:
         return {
            ...state,
            setSinkSizeDirection: action.payload
         }
      case CHANGE_BATHROOM_PICTURE:
         return {
            ...state,
            bathroomPicture: action.payload
         }
      case SET_BATHROOM_TITLE_SELECTION:
         return {
            ...state,
            setSinkTitle: action.payload
         }
      case SET_MIRROR_SIZE:
         return {
            ...state,
            mirrorSize: action.payload
         }
      case SET_MIRROR_BACKLIGHT:
         return {
            ...state,
            mirrorWithBackLight: action.payload
         }
      case SET_USER_SESSION:
         return {
            ...state,
            userSessionId: createGUID(10)
         }
      case SET_ALL_USER_SELECTION:
         return {
            ...state,
            ...action.payload
         }
      case RESET_ALL_USER_SELECTION:
         return {
            ...state,
            ...initialState
         }
      case CHANGE_BATHROOM_AREA_PICTURE:
         return {
            ...state,
            bathroomAreaPicture: action.payload
         }
      case DELETE_ADDITIONAL_PRODUCT:
         switch (action.payload) {
            case "armature":
               return {
                  ...state,
                  setArmatureType: null,
                  setArmatureColour: null
               }
            case "tallCabinet":
               return {
                  ...state,
                  setTallCabinet: null,
                  setTallCabinetCoverDirection: null
               }
            case "mirror":
               return {
                  ...state,
                  setMirrorType: null,
                  mirrorSize: null
               }
            case "otherModule":
               return {
                  ...state,
                  setOtherModules: null,
                  setOtherModuleCoverDirection: null
               }
            default:
               return state;
         }
      case SET_COVER_TYPE_MOBILE_SELECTIION:
         return {
            ...state,
            mobileTypeSelectionId: action.payload
         }
      case MODEL_CHANGES:
         return {
            ...state,
            modelsChanges: action.payload
         }
      case CHANGE_PLACE:
         return {
            ...state,
            place: action.payload
         }
      case SET_MIRROR_COVER_DIRECTION:
         return {
            ...state,
            selectedMirrorCoverDirection: action.payload
         }
      case SET_LEG_COLOR:
         return{
            ...state,
            legColor:action.payload
         }
      default:
         return state;
   }
}
export default userSelectionReducer;
