import React, { Component } from 'react';
import { ASSETS } from "../constants/paths";
import { moduleArr } from "../constants/const";
import {connect} from "react-redux";

class SelectedModuleComponent extends Component {
   getModulePicture = () => {
      let { userSelection, mirror_style, other_module, objKey } = this.props;
      if (mirror_style) {
         return userSelection.setMirrorType.img;
      }
      if (other_module) {
         return userSelection.setOtherModules.img;
      }
      if (objKey === "armatureStyleData") {
         return userSelection.setArmatureType.img;
      }

      if (objKey === "tallCabinetInfo") {
         return userSelection.setTallCabinet.img;
      }
      return userSelection.bathroomPicture ? userSelection.bathroomPicture : "/img/home/small-cabinet.png"
   }
   render() {
      let { objKey, mirror_style, other_module, sale_code, price, sale_id, model_code, activeLang,language } = this.props;
      let item = moduleArr.find(moduleItem => moduleItem.objKey === objKey);
      return (
         <div className="vra-selected-top-block vra-selected-order">
            <div className="vra-left-item">
               <picture>
                  <img src={`${ASSETS}${this.getModulePicture()}`} alt="" />
               </picture>
               <div className="vra-text-item">
                  <p>{mirror_style ? (JSON.parse(mirror_style.transition)[activeLang] || mirror_style.name) : (other_module ? (JSON.parse(other_module.transition)[activeLang] || other_module.summaryName) :language[item.languageName])}</p>
                  <span>{sale_code || sale_id || model_code}</span>
               </div>
            </div>
            {
               activeLang === "tr" &&
               <div className="vra-chosen-price-item">
                  <b>{price ? parseInt(price) : ""}<span>&#8378;</span></b>
               </div>
            }
         </div>
      );
   }
}

const mapStateToProps = (state)=>({
   language: state.languageReducer[state.commonReducer.activeLang]

})

export default connect(mapStateToProps)(SelectedModuleComponent);
