export const PRODUCT_STEP_NUMBER = "PRODUCT_STEP_NUMBER";
export const GET_COVER_STYLES = "GET_COVER_STYLES";
export const SET_COVER_STYLES = "SET_COVER_STYLES";
export const GET_COVER_COLOURS = "GET_COVER_COLOURS";
export const GET_ALL_SINK_STYLE_DATA = "GET_ALL_SINK_STYLE_DATA";
export const SET_COVER_COLOUR = "SET_COVER_COLOUR";
export const GET_SINK_TYPES = "GET_SINK_TYPES"
export const SET_SINK_TYPES = "SET_SINK_TYPES"
export const GET_SINK_SIZES = "GET_SINK_SIZES"
export const SET_SINK_SIZES = "SET_SINK_SIZES"
export const SET_SINK_SIZE_COVER_DIRECTION = "SET_SINK_SIZE_COVER_DIRECTION"
export const GET_COVER_TYPES = "GET_COVER_TYPES"
export const SET_COVER_SELECTION = "SET_COVER_SELECTION"
export const GET_HANDLE_TYPES = "GET_HANDLE_TYPES"
export const SET_HANDLE_TYPES = "SET_HANDLE_TYPES"
export const TOGGLE_SELECTED_PRODUCT_MODAL = "TOGGLE_SELECTED_PRODUCT_MODAL"
export const ASSIGN_PRODUCT_STEP_NUMBER="ASSIGN_PRODUCT_STEP_NUMBER";
export const GET_ARMATURE_TYPES="GET_ARMATURE_TYPES";
export const SET_ARMATURE_TYPES="SET_ARMATURE_TYPES";
export const SET_ARMATURE_COLOUR="SET_ARMATURE_COLOUR";
export const GET_MIRROR_TYPES="GET_MIRROR_TYPES";
export const SET_MIRROR_TYPES="SET_MIRROR_TYPES";
export const SET_MIRROR_SIZE="SET_MIRROR_SIZE";
export const SET_MIRROR_BACKLIGHT="SET_MIRROR_BACKLIGHT";
export const SET_MIRROR_COVER_DIRECTION="SET_MIRROR_COVER_DIRECTION";
export const GET_TALL_CABINETS="GET_TALL_CABINETS";
export const SET_TALL_CABINETS="SET_TALL_CABINETS";
export const SELECTION_TYPE="SELECTION_TYPE";
export const SET_COVER_TYPE="SET_COVER_TYPE";
export const SET_COVER_SELECTION_TYPE="SET_COVER_SELECTION_TYPE";
export const SET_TALL_CABINETS_DIRECTION="SET_TALL_CABINETS_DIRECTION";
export const GET_OTHER_MODULES="GET_OTHER_MODULES";
export const SET_OTHER_MODULES="SET_OTHER_MODULES";
export const SET_OTHER_MODULES_DIRECTION="SET_OTHER_MODULES_DIRECTION";
export const TOGGLE_COLLAPSE_MENU="TOGGLE_COLLAPSE_MENU";
export const RECEIVE_ORDER_SUMMARY="RECEIVE_ORDER_SUMMARY";
export const CHANGE_SELECTED_MODULES = "CHANGE_SELECTED_MODULES";
export const CHANGE_BATHROOM_PICTURE = "CHANGE_BATHROOM_PICTURE";
export const CHANGE_BATHROOM_AREA_PICTURE = "CHANGE_BATHROOM_AREA_PICTURE";
export const CHANGE_COLOR_SELECTION = "CHANGE_COLOR_SELECTION";
export const CHANGE_BATHROOM_SELECTION = "CHANGE_BATHROOM_SELECTION";
export const SET_BATHROOM_TITLE_SELECTION="SET_BATHROOM_TITLE_SELECTION"
export const CHANGE_COVER_SELECTION="CHANGE_COVER_SELECTION"
export const CHANGE_ARMATURE_SELECTION="CHANGE_ARMATURE_SELECTION"
export const CHANGE_TALL_CABINETS_SELECTION="CHANGE_TALL_CABINETS_SELECTION"
export const CHANGE_OTHER_MODULE_SELECTION="CHANGE_OTHER_MODULE_SELECTION"
export const SET_USER_SESSION="SET_USER_SESSION"
export const SET_RANDOM_VALUE="SET_RANDOM_VALUE"
export const SET_ALL_USER_SELECTION="SET_ALL_USER_SELECTION";
export const RESET_ALL_USER_SELECTION="RESET_ALL_USER_SELECTION";
export const SET_FIRST_CREATED_VALUE="SET_FIRST_CREATED_VALUE";
export const IS_LOADING="IS_LOADING"
export const SET_LAST_RANDOM_ID="SET_LAST_RANDOM_ID"
export const CHANGE_MIRROR_SELECTION="CHANGE_MIRROR_SELECTION"
export const GET_LANDING_DATA="GET_LANDING_DATA"
export const GET_LANGUAGE="GET_LANGUAGE";
export const SET_ACTIVE_LANG="SET_ACTIVE_LANG"
export const IS_BUTTON_DISABLED="IS_BUTTON_DISABLED";
export const DELETE_ADDITIONAL_PRODUCT="DELETE_ADDITIONAL_PRODUCT"
export const SET_COVER_TYPE_MOBILE_SELECTIION="SET_COVER_TYPE_MOBILE_SELECTIION"
export const MODEL_CHANGES="MODEL_CHANGES";
export const TOGGLE_COVER_MODELS_MODAL ="TOGGLE_COVER_MODELS_MODAL"
export const TOGGLE_BG_MODAL ="TOGGLE_BG_MODAL"
export const RECEIVE_PLACE_PICTURES ="RECEIVE_PLACE_PICTURES"
export const CHANGE_PLACE ="CHANGE_PLACE"
export const SET_LEG_COLOR ="SET_LEG_COLOR"
export const CHANGE_DOOR_SELECTION ="CHANGE_DOOR_SELECTION"
export const TOGGLE_VOICE ="TOGGLE_VOICE"
