import React, { Component } from 'react';
import ProductTypeProgressComponent from "../components/productTypeProgressComponent";
import ProductImageComponent from "../components/productImageComponent";
import ButtonComponent from "../components/common/buttonComponent";
import { connect } from "react-redux";
import ArmatureSelectionComponent from "../components/armatureSelectionComponent";
import MirrorSelectionComponent from "../components/mirrorSelectionComponent";
import TallCabinetSelectionComponent from "../components/tallCabinetSelectionComponent";
import OtherModuleComponent from "../components/otherModuleComponent";
import SinkSelectionContainer from '../containers/sinkSelectionContainer';
import CollapseMenuComponent from "../components/collapseMenuComponent";
import MobileButtonComponent from "../components/mobile/mobileButtonComponent";
import { setRandomValue, resetUserSelections } from "../actions";
import {ASSETS} from "../constants/paths";


class HomePageComponent extends Component {

   clickSound
   state={
      isMobile: false
   }
   componentDidMount() {
      window.addEventListener("resize",this.resize)
      this.resize()
   }

   componentWillUnmount() {

      this.props.dispatch(resetUserSelections(true))
      this.props.dispatch(setRandomValue(false))
      window.removeEventListener("resize",this.resize)
   }
   addClickSound=()=>{
      this.clickSound= new Audio(`${ASSETS}/sounds/clickExport.mp3`).play()
   }

   resize = () => {
      if(window.innerWidth>720){
         this.setState({
            isMobile:false
         })
      }else{
         this.setState({
            isMobile:true
         })
      }
   }


   render() {
      let { productStep, selectionType, isLoading,isBgModal,isOpenModelsModal,activeLang } = this.props;
      //console.log("productStep",productStep)
      //console.log("activeLang",activeLang)

      return (
         <main className={`vra-app-root ${this.state.isMobile && activeLang === "ru" ? "mobile" : ""} ${productStep === 5 && selectionType === "sink" ? "done" : ""} ${productStep === 6 && selectionType === "sink" ? "vra-choice-root" : ""} ${isOpenModelsModal || isBgModal ? "vra-top-layer":""} `} id="vra-choice-root">
            <div className={`vra-design-wrapper ${productStep === 5 ? "vra-congrats-step" : ""}`}>
               <ProductImageComponent />
               <div
                  className={`vra-tool-wrapper ${productStep >= 7 && selectionType === "sink" ? "vra-order-wrapper" : ""} `}>
                  {
                     productStep < 6 &&
                     <ProductTypeProgressComponent />
                  }
                  <div className={`vra-tool-container ${productStep&&productStep === 6 ? "mobile" : ""}`}>
                     <CollapseMenuComponent />
                     {
                        selectionType === "sink" &&
                        <SinkSelectionContainer />
                     }
                     {
                        selectionType === "armature" &&
                        <ArmatureSelectionComponent  />
                     }
                     {/*{
                         selectionType === "armature" &&
                         <ResArmatureContainer/>
                      }*/}
                     {
                        selectionType === "mirror" &&
                        <MirrorSelectionComponent />
                     }
                     {/*{
                         selectionType === "mirror" &&
                         <ResMirrorContainer/>
                      }*/}
                     {
                        selectionType === "tallCabinet" &&
                        <TallCabinetSelectionComponent />
                     }
                     {/*{
                         selectionType === "tallCabinet" &&
                         <ResTallCabinetContainer/>
                      }*/}
                     {
                        selectionType === "otherModule" &&
                        <OtherModuleComponent />
                     }
                     {/*{
                         selectionType === "otherModule" &&
                         <ResOtherModuleContainer/>
                      }*/}



                     {
                        isLoading !== true &&
                        <ButtonComponent />
                     }
                     {
                        (selectionType === "armature" || selectionType === "mirror" || selectionType === "tallCabinet" || selectionType === "otherModule" || (productStep !== 5 && productStep !== 6)) &&
                        <MobileButtonComponent addSound={this.addClickSound}/>
                     }
                     {/*{
                         productStep === 6 &&
                         <MobilePickButtonComponent/>
                      }*/}
                  </div>
               </div>
            </div>

         </main>

      )
   }
}

const mapStateToProps = (state) => ({
   productStep: state.productStepReducer.productStepNumber,
   selectionType: state.commonReducer.selectionType,
   isLoading: state.commonReducer.isLoading,
   isOpenModelsModal: state.commonReducer.isOpenModelsModal || false,
   isBgModal: state.commonReducer.isBgModal || false,
   activeLang: state.commonReducer.activeLang,
})
export default connect(mapStateToProps)(HomePageComponent);




