import React, {Component} from 'react';
import {ASSETS} from "../constants/paths";
import {connect} from "react-redux";

class OptionSelectionComponent extends Component {
   render() {
      let{group,coverStyle,setCoverColour,key,setCoverStyle,activeLang}=this.props;
      return (
          <div className="vra-option-content" key={key}>
             <p>{JSON.parse(group.transition)[activeLang]||group.name}</p>
             <div className="vra-option-block">
                {
                   group.colors.map((colors,inx)=>
                       <div className={`vra-option-item ${(setCoverColour&&setCoverColour.cover_color.id === colors.cover_color.id && setCoverStyle&&setCoverStyle.id===coverStyle.id)  ? "active":""}`} key={key+"-"+inx.toString()}
                            onClick={(e)=>{
                               this.props.setColours(group,coverStyle,colors);
                               e.stopPropagation();


                            }}>
                          <div className="vra-round-item">
                             <picture>
                                <img src={`${ASSETS}/${colors.cover_color.img}`} alt={inx.toString()}/>
                             </picture>
                          </div>
                          <span>{JSON.parse(colors.cover_color.transition)[activeLang]||colors.cover_color.name}</span>
                       </div>
                   )
                }
             </div>
          </div>
      );
   }
}
const mapStateToProps=state=>({
   setCoverColour: state.userSelectionReducer.setCoverColour,
   setCoverStyle:state.userSelectionReducer.setCoverStyle,
   activeLang: state.commonReducer.activeLang,
})
export default connect(mapStateToProps) (OptionSelectionComponent);
