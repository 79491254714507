import React, {Component} from 'react';
import {connect} from "react-redux";

class FooterPolicyComponent extends Component {

    state ={
        isMobile: false
    }

    componentDidMount() {
        window.addEventListener("resize",this.setIsMobile)
        this.setIsMobile()
    }


    setIsMobile=()=> {
        if (typeof window !== "undefined" && window.innerWidth < 550) {
            this.setState({
                isMobile: true
            })
        }else{
            this.setState({
                isMobile: false
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize",this.setIsMobile)
    }
   render() {
      let{language,activeLang}=this.props;
      let {isMobile}=this.state
      return (
          <div className="vra-policy-wrapper">
             <div className="vra-policy-container safe-area">
                {
                   language&&
                   <div className={`vra-policy-block ${activeLang === "ru" && "vra-ru-policy-block"}`}>
                      <ul className="vra-up-policy">
                         <li><a href={language.privacy_policy_link} target="_blank" rel="noreferrer">{language.privacy_policy}</a></li>
                         <li><a href={language.quality_policy_link} target="_blank" rel="noreferrer">{language.quality_policy}</a></li>
                         <li><a href={language.safe_policy_link} target="_blank" rel="noreferrer">{language.safe_policy}</a></li>
                         <li><a href={language.env_policy_link} target="_blank" rel="noreferrer">{language.env_policy}</a></li>
                         <li><a href={language.energy_policy_link} target="_blank" rel="noreferrer">{language.energy_policy}</a></li>
                          {
                              activeLang !== "tr" && !isMobile &&
                              <li><a href={language.investor_link} target="_blank" rel="noreferrer">{language.investor}</a></li>
                          }
                      </ul>
                      <ul className="vra-down-policy">
                         {
                            activeLang === "tr" &&
                            <li><a href={language.warning_link} target="_blank" rel="noreferrer">{language.warning}</a></li>
                         }
                         {
                            activeLang === "tr" &&
                            <li><a href={language.storage_and_disposal_policy_link} target="_blank" rel="noreferrer">{language.storage_and_disposal_policy}</a></li>
                         }
                         {
                            activeLang === "tr" &&
                            <li><a href={language.protection_link} target="_blank" rel="noreferrer">{language.protection}</a></li>
                         }
                         {
                              activeLang === "tr" &&
                              <li><a href={language.investor_link} target="_blank" rel="noreferrer">{language.investor}</a></li>
                         }
                          {
                              activeLang !== "tr" && isMobile &&
                              <li><a href={language.investor_link} target="_blank" rel="noreferrer">{language.investor}</a></li>
                          }
                      </ul>
                   </div>
                }
             </div>
          </div>
      );
   }
}
const mapStateToProps=state=>({
   language: state.languageReducer[state.commonReducer.activeLang],
   activeLang: state.commonReducer.activeLang
})
export default connect(mapStateToProps) (FooterPolicyComponent);
