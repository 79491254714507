import React, { Component } from 'react';
import { ASSETS } from "../constants/paths";
import { connect } from "react-redux";
import RandomBathProductComponent from "./randomBathProductComponent";
import { getBahtroomPictureAction, getBathroomAreaAction } from '../actions/index';
import ShowingChosenItemComponent from "./showingChosenItemComponent";
import LoaderComponent from "./common/loaderComponent";
import LackSizeComponent from "./lackSizeComponent";
import ResColourSelectionContainer from "../containers/resColourSelectionContainer";
import RespOptionSelectionComponent from "./mobile/respOptionSelectionComponent";
import RespDoorOptionComponent from "./mobile/respDoorOptionComponent";
import RespMirrorSizeSelectionComponent from "./mobile/respMirrorSizeSelectionComponent";
import RespOtherModuleCoverDirectionSelectionComponent from "./mobile/respOtherModuleCoverDirectionSelectionComponent";
import RespArmatureColourOptionComponent from "./mobile/respArmatureColourOptionComponent";
import RespTallCabinetCoverDirectionComponent from "./mobile/respTallCabinetCoverDirectionSelectionComponent";
import axios from "axios";
import RefactorButtonsComponent from "./refactorButtonsComponent";
import ChangeModelComponent from "../components/changeModelComponent"
import ResMirrorContainer from "../containers/resMirrorContainer"
import ChangeBgModalComponent from "../components/modal/changeBgModalComponent"
import RespDoorSelectionContainer from "../containers/respDoorSelectionContainer";
let cancelToken;
let bathroomAreacancelToken;

class ProductImageComponent extends Component {
   isPictureGet = true;
   selectionSound
   audio = new Audio(`${ASSETS}/sounds/selectionExport.mp3`);
   addSelectionSound=()=>{
      this.selectionSound = this.audio.play()
   }
   componentDidMount() {
      this.checkWindowWidth()
      window.addEventListener("resize", this.checkWindowWidth)

      if (this.props.userSelection && !this.props.isFromRandom) {
         this.getPicture(true)
      }

   }

   componentWillUnmount() {
      window.removeEventListener("resize", this.checkWindowWidth)
   }

   checkWindowWidth = () => {
      if (window.innerWidth < 640) {
         this.setState({
            isMobile: true
         })
      } else {
         this.setState({
            isMobile: false
         })
      }
   }
   state = {
      isMobile: false,
      imgLoaded: false
   }

   componentDidUpdate(prevState) {
      if (this.props.userSelection.setCoverStyle && this.props.productStep !== 6 && JSON.stringify(this.props.userSelection) !== JSON.stringify(prevState.userSelection) || (this.props.productStep === 6 && this.props.userSelection.setCoverStyle.name !== prevState.userSelection.setCoverStyle.name || this.props.userSelection.setCoverColour !== prevState.userSelection.setCoverColour)) {
         this.getPicture(false)
      }
      let newUserSelection = { ...this.props.userSelection, modelsChanges: null };
      let oldUserSelection = { ...prevState.userSelection, modelsChanges: null };
      if (this.props.userSelection.setCoverStyle && this.props.productStep === 6 && (JSON.stringify(newUserSelection) !== JSON.stringify(oldUserSelection) || this.props.productStep !== prevState.productStep) && prevState.userSelection.bathroomAreaPicture === this.props.userSelection.bathroomAreaPicture) {
         this.getBathroomAreaPicture(false);
      }

   }

   getPicture = (isFirstLoad) => {
      let height;
      if (this.props.userSelection.setSinkSizes && this.props.userSelection.setSinkSizes.sink_size_covers[0]) {
         height = this.props.userSelection.setSinkSizes.sink_size_covers[0].height;
         if (this.props.userSelection.setCoverSelectionType && this.props.userSelection.setSinkSizes.sink_size_covers.find(item => item.id === this.props.userSelection.setCoverSelectionType.id)) {
            height = this.props.userSelection.setSinkSizes.sink_size_covers.find(item => item.id === this.props.userSelection.setCoverSelectionType.id).height;
         }
      }


      if(!cancelToken){

         cancelToken = axios.CancelToken.source()
         this.isPictureGet = false;
         this.forceUpdate();
         this.props.dispatch(getBahtroomPictureAction({
            serie: this.props.userSelection.setCoverStyle ? this.props.userSelection.setCoverStyle.name : null,
            color: this.props.userSelection.setCoverColour && this.props.userSelection.setCoverType ? `${this.props.userSelection.setCoverType.id == 3 ? this.props.userSelection.setCoverType.name + " " : ""}${this.props.userSelection.setCoverColour.cover_color.name}` : null,
            size: this.props.userSelection.setSinkSizes ? this.props.userSelection.setSinkSizes.name : null,
            cover: this.props.userSelection.setCoverSelectionType ? this.props.userSelection.setCoverSelectionType.name : null,
            direction: this.props.userSelection.setSinkSizeDirection ? this.props.userSelection.setSinkSizeDirection.cover_direction.name : "Sag",
            handleColor: this.props.userSelection.setHandleTypes ? this.props.userSelection.setHandleTypes.name : null,
            stand_size: this.props.userSelection.setCoverSelection ? this.props.userSelection.setSinkSizes.name : null,
            sinkSerie: this.props.userSelection.setSinkType ? this.props.userSelection.setSinkType.serie_name : null,
            height,
            is_stand: this.props.userSelection.setCoverSelection && this.props.userSelection.setCoverSelection.sink_stand ? this.props.userSelection.setCoverSelection.sink_stand.name : null,
            leg_color_name: this.props.userSelection.legColor ? this.props.userSelection.legColor.name : null
         }, (result) => {
            cancelToken = undefined;
            this.isPictureGet = true;
            if(this.props.voice && !isFirstLoad && (result || result === null)){
               this.addSelectionSound();
            }
            this.forceUpdate();
         }, cancelToken))
      }else{
         cancelToken.cancel("Operation canceled due to new request.")
      }
      
   }

   getBathroomAreaPicture = (isFirstLoad) => {
      let height;
      if (this.props.userSelection.setSinkSizes && this.props.userSelection.setSinkSizes.sink_size_covers[0]) {
         height = this.props.userSelection.setSinkSizes.sink_size_covers[0].height;
         if (this.props.userSelection.setCoverSelectionType && this.props.userSelection.setSinkSizes.sink_size_covers.find(item => item.id === this.props.userSelection.setCoverSelectionType.id)) {
            height = this.props.userSelection.setSinkSizes.sink_size_covers.find(item => item.id === this.props.userSelection.setCoverSelectionType.id).height;
         }
      }
      if (typeof bathroomAreacancelToken != typeof undefined) {
         bathroomAreacancelToken.cancel("Operation canceled due to new request.")
      }

      bathroomAreacancelToken = axios.CancelToken.source()
      this.isPictureGet = false;
      this.forceUpdate();
      this.props.dispatch(getBathroomAreaAction({
         serie: this.props.userSelection.setCoverStyle ? this.props.userSelection.setCoverStyle.name : null,
         color: this.props.userSelection.setCoverColour ? `${this.props.userSelection.setCoverType.id == 3 ? this.props.userSelection.setCoverType.name + " " : ""}${this.props.userSelection.setCoverColour.cover_color.name}` : null,
         placeColorName: this.props.userSelection.setCoverColour ? `${this.props.userSelection.setCoverColour.cover_color.name}` : null,
         size: this.props.userSelection.setSinkSizes ? this.props.userSelection.setSinkSizes.name : null,
         cover: this.props.userSelection.setCoverSelectionType ? this.props.userSelection.setCoverSelectionType.name : null,
         direction: this.props.userSelection.setSinkSizeDirection ? this.props.userSelection.setSinkSizeDirection.cover_direction.name : "Sag",
         handleColor: this.props.userSelection.setHandleTypes ? this.props.userSelection.setHandleTypes.name : null,
         stand_size: this.props.userSelection.setCoverSelection ? this.props.userSelection.setSinkSizes.name : null,
         sinkSerie: this.props.userSelection.setSinkType ? this.props.userSelection.setSinkType.serie_name : null,
         height,
         is_stand: this.props.userSelection.setCoverSelection && this.props.userSelection.setCoverSelection.sink_stand ? this.props.userSelection.setCoverSelection.sink_stand.name : null,
         armature: this.props.userSelection.setArmatureColour ? this.props.userSelection.setArmatureType.name : null,
         armatureColorId: this.props.userSelection.setArmatureColour ? this.props.userSelection.setArmatureColour.id : null,
         otherModule: this.props.userSelection.setOtherModules ? this.props.userSelection.setOtherModules.name : null,
         otherModuleDirection: this.props.userSelection.setOtherModuleCoverDirection ? this.props.userSelection.setOtherModuleCoverDirection.cover_direction.name : null,
         mirror: this.props.userSelection.setMirrorType ? this.props.userSelection.setMirrorType.name : null,
         tallCabinet: this.props.userSelection.setTallCabinetCoverDirection ? this.props.userSelection.setTallCabinetCoverDirection.cover_direction.name : null,
         mirrorSize: this.props.userSelection.mirrorSize ? this.props.userSelection.mirrorSize.size.name : null,
         tallCabinetSizeName: this.props.userSelection.setTallCabinet && this.props.userSelection.setTallCabinet.tall_cabinet_sizes && this.props.userSelection.setTallCabinet.tall_cabinet_sizes[0] ? this.props.userSelection.setTallCabinet.tall_cabinet_sizes[0].size_name : null,
         otherModuleSizeName: this.props.userSelection.setSinkTitle ? this.props.userSelection.setSinkTitle.name : "",
         otherModuleSize: this.props.userSelection.setOtherModules ? this.props.userSelection.setOtherModules.size : null,
         withBackLight: this.props.userSelection.mirrorWithBackLight ? this.props.userSelection.mirrorWithBackLight.light : 0,
         place: this.props.userSelection.place,
         mirrorCoverDirection: this.props.userSelection.selectedMirrorCoverDirection ? this.props.userSelection.selectedMirrorCoverDirection.name : "sag",
         leg_color_name: this.props.userSelection.legColor ? this.props.userSelection.legColor.name : null
         //width:true
      }, (result) => {
         if (result === false && this.retryLoad !== true) {
            setTimeout(() => {
               this.retryLoad = true;
               this.getBathroomAreaPicture();
            }, 2000);
         } else {
            this.retryLoad = false;
            this.isPictureGet = true;
            this.forceUpdate();
         }
         if(this.props.voice && isFirstLoad === false && (result || result===null)){
            this.addSelectionSound();
         }
      }, bathroomAreacancelToken))
   }

   showLoader = () => {
      this.isPictureGet = false;
      this.forceUpdate();
   }
   render() {
      let { productStep, userSelection, isFromRandom, sinkSize, selectSinkTypeId, selectionType, isOpenModelsModal, isBgModal } = this.props;
      return (
         (window.innerWidth > 640 || productStep !== 5) ?
            <div className={`vra-design-img-block ${productStep === 6 ? "vra-module-step" : ""}`}>
               <div className="vra-design-img-item">
                  {
                     userSelection &&
                     userSelection.bathroomPicture ?
                        <picture>
                           <img
                              src={`${productStep === 6 ? (userSelection.bathroomAreaPicture ? userSelection.bathroomAreaPicture : (ASSETS + "/img/BanyoAlani/Mekan/p_7457_u.png")) : (userSelection.bathroomPicture ? userSelection.bathroomPicture : ASSETS + "/img/home/dolap.png")}`}
                              alt="" />
                        </picture>
                        : <LoaderComponent />

                  }

                  {/* <picture>
                              <img
                                  src={`${productStep === 6 ? (userSelection.bathroomAreaPicture ? `${ASSETS}/img/bahtroom-area/${userSelection.bathroomAreaPicture}` : (ASSETS + "/img/BanyoAlani/Mekan/p_7457_u.png")) : (userSelection.bathroomPicture ? `${ASSETS}/img/bahtroom/${userSelection.bathroomPicture}` : ASSETS + "/img/home/dolap.png")}`}
                                  alt=""/>
                           </picture> */}
                  {
                     userSelection && userSelection.bathroomPicture && <LoaderComponent isPictureGet={this.isPictureGet} onlyImg/>
                  }
               </div>
               {/*todo:Loader*/}

               {
                  (productStep === 5 && isFromRandom) &&
                  <RandomBathProductComponent showLoader={this.showLoader} />
               }
               <ShowingChosenItemComponent/>
               {
                  (this.state.isMobile && sinkSize) && sinkSize.filter(item => item.sink_sizes.filter(size => size.sink_type_sizes.findIndex(x => x.sink_type_id == selectSinkTypeId) === -1).length > 0).length > 0 &&
                  <LackSizeComponent isProductImage={true} selectSinkTypeId={selectSinkTypeId} sizes={sinkSize} />
               }
               {
                  productStep === 1 &&
                  <ResColourSelectionContainer />
               }
               {
                  productStep === 2 &&
                  <RespOptionSelectionComponent  />
               }
               {
                  productStep === 3 &&
                  <RespDoorSelectionContainer/>
               }
               {
                  selectionType === "mirror" &&
                  <ResMirrorContainer  />
               }

               {
                  selectionType === "armature" &&
                  <RespArmatureColourOptionComponent  />
               }

               {
                  selectionType === "tallCabinet" &&
                  <RespTallCabinetCoverDirectionComponent />
               }
               {
                  selectionType === "otherModule" &&
                  <RespOtherModuleCoverDirectionSelectionComponent   />
               }
               {
                  productStep === 6 &&
                  <RefactorButtonsComponent />
               }
               {
                  (productStep === 6 && isOpenModelsModal) &&
                  <ChangeModelComponent />
               }
               {
                  (productStep === 6 && isBgModal) &&
                  <ChangeBgModalComponent />
               }
            </div>
            :
            <div></div>
      );
   }
}

const mapStateToProps = (state) => ({
   productStep: state.productStepReducer.productStepNumber,
   userSelection: state.userSelectionReducer,
   isFromRandom: state.commonReducer.isFromRandom,
   sinkSize: state.sinkReducer.sinkSize || [],
   selectSinkTypeId: state.userSelectionReducer.setSinkType ? state.userSelectionReducer.setSinkType.id : null,
   selectionType: state.commonReducer.selectionType,
   isOpenModelsModal: state.commonReducer.isOpenModelsModal || false,
   isBgModal: state.commonReducer.isBgModal || false,
   voice:state.commonReducer.voice
})
export default connect(mapStateToProps)(ProductImageComponent);
