import {Global} from "@emotion/react";
import React from "react";

export const Fonts=()=>(
    <Global styles={`
       @font-face {
             font-family: "Palanquin-Bold";
             src: local("Palanquin-Bold"), url("../../public/assets/fonts/Palanquin-Bold.eot");
             src: local("Palanquin-Bold"), url("../../public/assets/fonts/Palanquin-Bold.eot?#iefix") format("embedded-opentype"), url("../../public/assets/fonts/Palanquin-Bold.woff") format("woff");
             font-weight: normal;
             font-style: normal;
             font-display: swap;
         }

         @font-face {
             font-family: "Palanquin-SemiBold";
             src: local("Palanquin-SemiBold"), url("../../public/assets/fonts/Palanquin-SemiBold.eot");
             src: local("Palanquin-SemiBold"), url("../../public/assets/fonts/Palanquin-SemiBold.eot?#iefix") format("embedded-opentype"), url("../../public/assets/fonts/Palanquin-SemiBold.woff") format("woff");
             font-weight: normal;
             font-style: normal;
             font-display: swap;
         }

         @font-face {
             font-family: "Palanquin-Medium";
             src: local("Palanquin-Medium"), url("../../public/assets/fonts/Palanquin-Medium.eot");
             src: local("Palanquin-Medium"), url("../../public/assets/fonts/Palanquin-Medium.eot?#iefix") format("embedded-opentype"), url("../../public/assets/fonts/Palanquin-Medium.woff") format("woff");
             font-weight: normal;
             font-style: normal;
             font-display: swap;
         }

         @font-face {
             font-family: "Palanquin-Regular";
             src: local("Palanquin-Regular"), url("../../public/assets/fonts/Palanquin-Regular.eot");
             src: local("Palanquin-Regular"), url("../../public/assets/fonts/Palanquin-Regular.eot?#iefix") format("embedded-opentype"), url("../../public/assets/fonts/Palanquin-Regular.woff") format("woff");
             font-weight: normal;
             font-style: normal;
             font-display: swap;
         }

         @font-face {
             font-family: "Palanquin-Light";
             src: local("Palanquin-Light"), url("../../public/assets/fonts/Palanquin-Light.eot");
             src: local("Palanquin-Light"), url("../../public/assets/fonts/Palanquin-Light.eot?#iefix") format("embedded-opentype"), url("../../public/assets/fonts/Palanquin-Light.woff") format("woff");
             font-weight: normal;
             font-style: normal;
             font-display: swap;
         }

         @font-face {
             font-family: 'OpenSans-SemiBold';
             src: local("OpenSans-SemiBold"), url("../../public/assets/fonts/OpenSans-SemiBold.eot");
             src: local('OpenSans-SemiBold'), url("../../public/assets/fonts/OpenSans-SemiBold.eot?#iefix") format("embedded-opentype"), url("../../public/assets/fonts/OpenSans-SemiBold.woff") format("woff");
             font-weight: normal;
             font-style: normal;
             font-display: swap;
         }

         @font-face {
             font-family: 'OpenSans-Regular';
             src: local('OpenSans-Regular'), url("../../public/assets/fonts/OpenSans-Regular.eot");
             src: local('OpenSans-Regular'), url("../../public/assets/fonts/OpenSans-Regular.eot?#iefix") format("embedded-opentype"), url("../../public/assets/fonts/OpenSans-Regular.woff") format("woff");
             font-weight: normal;
             font-style: normal;
             font-display: swap;
         }

         @font-face {
             font-family: 'OpenSans-Bold';
             src: local('OpenSans-Bold'), url("../../public/assets/fonts/OpenSans-Bold.eot");
             src: local('OpenSans-Bold'), url("../../public/assets/fonts/OpenSans-Bold.eot?#iefix") format("embedded-opentype"), url("../../public/assets/fonts/OpenSans-Bold.woff") format("woff");
             font-weight: normal;
             font-style: normal;
             font-display: swap;
         }
    `}/>
)
