import React, {Component} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {assignProductStep, toggleCollapseMenu} from "../actions";

class CollapseMenuComponent extends Component {
   handleChange = (step) => {
      if (
          (step === 1) ||
          // (step === 2 && this.props.coverStyle) ||
          // (step === 3 && this.props.coverColour) ||
          // (step === 4 && this.props.sinkType) ||

          (step === 2 &&this.props.coverStyle) ||
          (step === 3 &&  this.props.sinkType) ||
          (step === 4 &&  this.props.coverSelection) ||
          (step === 5 &&  this.props.coverSelection)
      ) {
         this.props.dispatch(assignProductStep(step))
      } else {
         return
      }

      this.props.dispatch(toggleCollapseMenu(false))
   }

   render() {
      let {coverStyle, coverColour, sinkType, sinkSize, coverSelection, handleType, isCollapseMenuOpen, activeLang,language} = this.props;
      return (
          <div className={`vra-congrats-container vra-collapse-menu-container ${isCollapseMenuOpen ? "opened" : ""}`}>
             <div className="vra-list-block">
                <div className="vra-title-head-item">
                   {
                      language &&
                      <h3>{language.your_choices}</h3>
                   }
                   <i className="gg-close" onClick={()=>this.props.dispatch(toggleCollapseMenu(false))}/>
                </div>
                {
                   language &&
                   <ul>
                      <li onClick={() => this.handleChange(1)}><Link to="#" className={`${coverStyle ? "":"vra-is-empty"}`}>{language.style_door}
                         {
                            coverStyle&&
                            <span>{`${coverStyle&&(JSON.parse(coverStyle.transition)[activeLang]+", "+ JSON.parse(coverColour.cover_color.transition)[activeLang] || coverStyle.name)}`} <i className="gg-chevron"/>
                      </span>
                         }
                      </Link></li>
                      <li onClick={() => this.handleChange(2)}><Link to="#" className={`${sinkType ? "":"vra-is-empty"}`}>{language.washbasin}
                         {
                            (sinkType&& sinkSize) &&
                            <span>{`${sinkType&&(JSON.parse(sinkType.transition)[activeLang] +", "+ sinkSize.name  || sinkType.name)}`}
                               <i className="gg-chevron"/>
                      </span>
                         }
                      </Link></li>
                      <li onClick={() => this.handleChange(3)}><Link to="#" className={`${coverSelection ? "":"vra-is-empty"}`}>{language.bathroom_size}<span>{coverSelection&&(JSON.parse(coverSelection.transition)[activeLang] || coverSelection.name)}<i
                          className="gg-chevron"/></span></Link></li>
                      <li onClick={() => this.handleChange(4)}><Link to="#" className={`${handleType ? "":"vra-is-empty"}`}>{language.type_door}<span>{handleType&&(JSON.parse(handleType.transition)[activeLang] || handleType.name)}<i
                          className="gg-chevron"/></span></Link></li>
                      {/*<li onClick={() => this.handleChange(5)}><Link to="#" className={`${coverSelection ? "":"vra-is-empty"}`}>{language.type_door}<span>{coverSelection&&(JSON.parse(coverSelection.transition)[activeLang] || coverSelection.name)}<i
                          className="gg-chevron"/></span></Link></li>*/}
                          {/*todo: KONTROL EDİLECEK*/}
                      <li onClick={() => this.handleChange(5)}><Link to="#" className={`${handleType ? "":"vra-is-empty"}`}>{language.your_choices}<span>{""}<i
                          className="gg-chevron"/></span></Link></li>
                   </ul>
                }

             </div>
          </div>
      )
   }
}

const mapStateToProps = (state) => ({
   coverStyle: state.userSelectionReducer.setCoverStyle || null,
   coverColour: state.userSelectionReducer.setCoverColour || null,
   sinkType: state.userSelectionReducer.setSinkType || null,
   sinkSize: state.userSelectionReducer.setSinkSizes || null,
   coverSelection: state.userSelectionReducer.setCoverSelectionType || null,
   handleType: state.userSelectionReducer.setHandleTypes || null,
   isCollapseMenuOpen: state.commonReducer.isCollapseMenuOpen,
   activeLang: state.commonReducer.activeLang,
   language: state.languageReducer[state.commonReducer.activeLang]
})
export default connect(mapStateToProps)(CollapseMenuComponent);
