import React, { Component } from 'react';
import { connect } from "react-redux";
import ImageSliderComponent from "./imageSliderComponent";
import { ASSETS } from "../../constants/paths";
import { setArmatureColour } from '../../actions';

class RespArmatureColourOptionComponent extends Component {
   sliderComponent = React.createRef();
   state = {
      isMobile: false,
      activeIndex: null
   }
   changeSelectedItemByIndex = (index) => {

      this.setState({
         activeIndex: null
      })
      this.props.dispatch(setArmatureColour(this.props.clickedArmatureStyle.armature_style_colors[index]))
   }
   setColor = (color) => {
      let activeIndex = this.props.clickedArmatureStyle.armature_style_colors.findIndex(item=>item.id===color.id)
      this.setState({
         activeIndex
      })
      this.props.dispatch(setArmatureColour(color))

   }
   resize = () => {
      if (window.innerWidth > 640) {
         this.setState({
            isMobile: false
         })
      } else {
         this.setState({
            isMobile: true
         })
      }
   }

   componentDidMount() {
      if (this.props.setArmatureColour) {
         let activeIndex = this.props.clickedArmatureStyle.armature_style_colors.findIndex(item=>item.id===this.props.setArmatureColour.id)
         this.setState({
            activeIndex
         })
      }
      this.resize();
      window.addEventListener("resize", this.resize)
   }

   componentDidUpdate(prevProps) {
      if (prevProps.clickedArmatureStyle && this.props.clickedArmatureStyle && this.props.clickedArmatureStyle.id !== prevProps.clickedArmatureStyle.id) {
         this.setState({
            activeIndex: 0
         })
      }
   }

   componentWillUnmount() {
      window.removeEventListener("resize", this.resize)
      this.resize();
   }

   render() {
      let { setArmatureColour, activeLang, clickedArmatureStyle } = this.props;
      let { isMobile } = this.state

      return (
         <div className="vra-selections-block vra-mobile-block vra-armature-block">
            {
               this.props.setArmatureColour &&
               <div className="vra-tooltip-item">
                  <span>{`${JSON.parse(setArmatureColour.armature_color.transition)[activeLang]}`}</span>
               </div>
            }
            {
               clickedArmatureStyle &&
               <ImageSliderComponent ref={this.sliderComponent} changeItem={this.changeSelectedItemByIndex}
                  activeIndex={this.state.activeIndex}>
                  {
                     clickedArmatureStyle.armature_style_colors.map((colorItem, index) =>

                        <div key={index}
                           className={`vra-option-item vra-color-option ${setArmatureColour && isMobile && setArmatureColour.id === colorItem.id ? "active" : ""}`}
                           onClick={() => {
                              this.setColor(colorItem);

                           }}>
                           <div className="vra-round-item">
                              <picture>
                                 <source srcSet={`${ASSETS}${colorItem.picture.substr(0,colorItem.picture.indexOf("."))+".webp"}`} type="image/webp"/>
                                 <img src={`${ASSETS}${colorItem.picture}`} alt="" />
                              </picture>
                           </div>
                        </div>
                     )
                  }
               </ImageSliderComponent>
            }
         </div>
      );
   }
}

export default connect(state => ({
   // setArmatureColour: state.userSelectionReducer.setArmatureColour,
   setCoverType: state.userSelectionReducer.setCoverType,
   setCoverStyle: state.userSelectionReducer.setCoverStyle,
   setArmatureColour: state.userSelectionReducer.setArmatureColour,
   coverStyles: state.sinkReducer.coverStyles || [],
   activeLang: state.commonReducer.activeLang,
   clickedArmatureStyle: state.armatureReducer.armatureTypes && state.userSelectionReducer.setArmatureType ? (state.armatureReducer.armatureTypes.find(item => item.id === state.userSelectionReducer.setArmatureType.id) || state.armatureReducer.armatureTypes[0]) : null
}))(RespArmatureColourOptionComponent);
