import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ASSETS } from "../constants/paths";
import SelectedModuleComponent from "./selectedModuleComponent";

class SelectedProductComponent extends Component {
   render() {
      let { orderSummary, selectedModules, userSelection, activeLang ,language,setCoverStyle} = this.props;
      return (
         <div className="vra-selected-product-container">
            {
               (orderSummary && orderSummary.bathroomCabinet) &&
               <div className="vra-content-block">
                  <div className="vra-selected-top-block">
                     <div className="vra-left-item">
                        <picture>
                           <img src={userSelection.bathroomPicture ? userSelection.bathroomPicture : `${ASSETS}/img/home/small-cabinet.png`} alt="" />
                        </picture>
                        <div className="vra-text-item">

                           <p>{language.washbasin_door}</p>
                           <span> {orderSummary.bathroomCabinet.sale_code}</span>
                        </div>
                     </div>
                     {
                        activeLang === "tr" &&
                        <div className="vra-chosen-price-item">
                           <b>{parseInt(orderSummary.bathroomCabinet.price)}<span>&#8378;</span></b>
                        </div>
                     }

                  </div>
                  {
                     (orderSummary && orderSummary.handleData && orderSummary.handleData.id !== 1) &&
                     <div className="vra-product-code">
                        <div className="vra-product-code-left">
                           <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3 4V12H21M21 12L16 7M21 12L16 17" stroke="#A3A3A3" strokeLinecap="round" strokeLinejoin="round" />
                           </svg>
                           <div className="vra-code-info">
                              <p>{JSON.parse(orderSummary.handleData.transition)[activeLang] || orderSummary.handleData.name} {language.handle}</p>
                              <span>{setCoverStyle.id==4?orderSummary.handleData.model_code:orderSummary.handleData.sale_code}</span>
                           </div>

                        </div>
                        {
                           activeLang === "tr" &&
                           <div className="vra-chosen-price-item">
                              <b>{orderSummary.handleData.price}<span>₺</span></b>
                           </div>
                        }
                     </div>
                  }
                  {
                     (orderSummary && orderSummary.stand) &&
                     <div className="vra-product-code">
                        <div className="vra-product-code-left">
                           <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3 4V12H21M21 12L16 7M21 12L16 17" stroke="#A3A3A3" strokeLinecap="round" strokeLinejoin="round" />
                           </svg>
                           <div className="vra-code-info">
                              {
                                 language &&
                                 <p>{language.leg}</p>
                              }

                              <span>{orderSummary.stand.sale_code}</span>
                           </div>

                        </div>
                        {
                           activeLang === "tr" &&
                           <div className="vra-chosen-price-item">
                              <b>{orderSummary.stand.price}<span>₺</span></b>
                           </div>
                        }

                     </div>
                  }
               </div>
            }
            {
               selectedModules.map((item, key) =>
                  (orderSummary && orderSummary[item]) &&
                  <SelectedModuleComponent activeLang={activeLang} key={key} objKey={item} {...orderSummary[item]} userSelection={this.props.userSelection} />
               )
            }
         </div>
      );
   }
}

const mapStateToProps = (state) => ({
   selectedModules: state.orderSummaryReducer.selectedModules,
   orderSummary: state.orderSummaryReducer,
   userSelection: state.userSelectionReducer,
   activeLang: state.commonReducer.activeLang,
   language: state.languageReducer[state.commonReducer.activeLang],
   setCoverStyle: state.userSelectionReducer.setCoverStyle

})

export default connect(mapStateToProps)(SelectedProductComponent);
