import React, {Component} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom'
import {
   LANDING_PAGE,
   HOME_PAGE,
   ORDER_PAGE,
   DE_LANDING_PAGE,
   DE_HOME_PAGE,
   DE_ORDER_PAGE,
   NOT_FOUND,
   DEALER_FORM_PAGE,
   ROADSHOW_LANDING_PAGE,
   ROADSHOW_HOME_PAGE,
   ROADSHOW_ORDER_PAGE,
   ROADSHOWLINK
} from "../../constants/routePaths";
import LandingPageComponent from "../../pages/landingPage";
import HomePageComponent from "../../pages/homePage";
import OrderPageComponent from "../../pages/orderPage";
import {connect} from 'react-redux';

import {
   createSessionIdAction, setActiveLangAction,
   getLanguageDataAction,
   getLanguageJsonData
} from "../../actions"
import HeaderComponent from "../layout/headerComponent";
import FooterComponent from "../layout/footer/footerComponent";
import NotFoundPage from "../../pages/notFoundPage";
import DealerFormPage from '../../pages/dealerFormPage';
import RoadShowLandingPage from '../../pages/roadShowLandingPage';


class RoutesComponent extends Component {
   constructor(props) {
      super(props);
      let urlArr = props.location.pathname.split("/")
      let langUrlArr=["tr","en","uk","ru","fr","it","rootkonfigurator","de","in",ROADSHOWLINK]
      let isExistInURL=langUrlArr.findIndex(langParam=>langParam===urlArr[1].toLowerCase())>-1
      if(window.location.hostname === "rootkonfigurator.vitra-bad.de"){
         document.title = "VitrA Root Konfigurator";
      }
      if(!isExistInURL && urlArr[1] !== ''){
         this.props.dispatch(setActiveLangAction("tr"));
         this.props.history.push("/tr/404")
         return;
      }
      if (isExistInURL) {
         if(urlArr[1].toLowerCase() === "rootkonfigurator"){
            this.props.dispatch(setActiveLangAction("de"))
         }else if(urlArr[1].toLowerCase() === ROADSHOWLINK){
            this.props.dispatch(setActiveLangAction("tr"))
            if(urlArr[3]!=="dealer-form"){
               this.props.history.push("/tr")
            }
         }else{
            this.props.dispatch(setActiveLangAction(urlArr[1].toLowerCase()))
         }
      } else {
         this.setDefaultLang();
      }
   }

   setDefaultLang = () => {
      switch(window.location.hostname){
         case "rootconfigurator.vitraglobal.com":
            this.props.dispatch(setActiveLangAction("en"));
            this.props.history.push("/en")
            break;
         case "rootconfigurator.vitra-india.com":
            this.props.dispatch(setActiveLangAction("in"));
            this.props.history.push("/in")
            break;

         case "rootconfigurator.vitra.co.uk":
            this.props.dispatch(setActiveLangAction("uk"));
            this.props.history.push("/uk")
            break;

         case "rootconfigurator.vitra.co.uk":
            this.props.dispatch(setActiveLangAction("uk"));
            this.props.history.push("/uk")
            break;
         case "rootconfigurator.vitra-russia.ru":
            this.props.dispatch(setActiveLangAction("ru"));
            this.props.history.push("/ru")
            document.title = "VitrA Root Konfigurator";
            break;
         default:
            this.props.dispatch(setActiveLangAction("tr"));
            this.props.history.push("/tr")

      }

   }

   componentDidMount() {
      getLanguageDataAction().then(res => {
         this.props.dispatch(getLanguageJsonData(res))
      }).catch(err => console.log("err", err))
      this.props.dispatch(createSessionIdAction())
   }


   render() {
      return (
          <div>
             <Route component={HeaderComponent}/>
             <Switch>
                <Route exact path={LANDING_PAGE} component={LandingPageComponent}/>
                <Route exact path={DE_LANDING_PAGE} component={LandingPageComponent}/>
                <Route exact path={ROADSHOW_LANDING_PAGE} component={RoadShowLandingPage}/>
                <Route exact path={HOME_PAGE} component={HomePageComponent}/>
                <Route exact path={DE_HOME_PAGE} component={HomePageComponent}/>
                {/* <Route exact path={ROADSHOW_HOME_PAGE} component={HomePageComponent}/> */}
                <Route exact path={ORDER_PAGE} component={OrderPageComponent}/>
                <Route exact path={DE_ORDER_PAGE} component={OrderPageComponent}/>
                <Route exact path={ROADSHOW_ORDER_PAGE} component={OrderPageComponent}/>
                <Route exact path={DEALER_FORM_PAGE} component={DealerFormPage}/>
                <Route exact path={NOT_FOUND} component={NotFoundPage}/>
                <Route component={NotFoundPage}/>
             </Switch>
             <Switch>
                <Route exact path={[LANDING_PAGE,DEALER_FORM_PAGE]} component={FooterComponent}/>
             </Switch>
          </div>
      )
   }

}


const mapStateToProps = (state) => ({
   language: state.languageReducer[state.commonReducer.activeLang],
   activeLang: state.commonReducer.activeLang
})
export default withRouter(connect(mapStateToProps)(RoutesComponent));

