import React, { Component } from "react";
import { connect } from "react-redux";
import { userByTokenAction } from "../actions";
import ProductItemComponent from "../components/dealerForm/productItemComponent";
import LandingFormComponent from "../components/forms/landingFormComponent";
import { ASSETS } from "../constants/paths";
import axios from "axios";
import { getBathroomAreaAction } from '../actions/index';
import ImageModalComponent from "../components/modal/imageModalComponent";
import SuccessModal from "../components/modal/successModal";

let bathroomAreacancelToken;

class DealerFormPage extends Component {
    data;
    qrImg = React.createRef();
    state = {
        isOpenModal: false
    }
    componentDidMount() {
        this.props.dispatch(userByTokenAction({ token: this.props.match.params.token }, (result) => {
            if (result) {
                this.data = result;
                this.forceUpdate();
                this.getPicture();
            }
        }))
    }
    getPicture = () => {
        if (typeof bathroomAreacancelToken != typeof undefined) {
            bathroomAreacancelToken.cancel("Operation canceled due to new request.")
        }

        bathroomAreacancelToken = axios.CancelToken.source()
        this.isPictureGet = false;
        this.forceUpdate();
        this.props.dispatch(getBathroomAreaAction(this.data.userInfo.picture_obj, (result) => {
            if (result === false && this.retryLoad !== true) {
                setTimeout(() => {
                    this.retryLoad = true;
                    this.getPicture();
                }, 2000);
            } else {
                this.retryLoad = false;
                this.isPictureGet = true;
                this.bathPicture = result;
                this.forceUpdate();
            }
        }, bathroomAreacancelToken, true))
    }
    toggleModal = () => {
        this.setState({
            isOpenModal: !this.state.isOpenModal
        })
    }
    render() {
        
        return (
            <div className="vra-campaign-landing-root">
                {
                    this.data &&
                    <div className="vra-campaign-landing-container ">
                        <div className="vra-campaign-head">
                            <h1>Kampanya Detay</h1>
                            <picture>
                                <source srcSet={`${ASSETS}/img/landing/vra-announcement.webp 1x, ${ASSETS}/img/landing/vra-announcement_2x.webp 2x`}
                                    type="image/webp" />
                                <source srcSet={`${ASSETS}/img/landing/vra-announcement.png 1x, ${ASSETS}/img/landing/vra-announcement_2x.png 2x`}
                                    type="image/jpeg" />
                                <img src={`${ASSETS}/img/landing/vra-announcement.png`} alt="VitrA Road Show" />
                            </picture>
                        </div>
                        <div className="vra-customer-info">
                            <p className="vra-sub-text">Müşteri Bilgileri</p>
                            <div className="vra-customer-block">
                                <div className="vra-customer-item">
                                    <span>Adı Soyadı</span>
                                    <p>{this.data.userInfo.name}</p>
                                </div>
                                <div className="vra-customer-item">
                                    <span>Telefon numarası</span>
                                    <p>{this.data.userInfo.phone_number}</p>
                                </div>
                                {
                                    this.data.userInfo.email &&
                                    <div className="vra-customer-item last-item">
                                        <span>E-posta</span>
                                        {/*<p>{this.data.userInfo.email}</p>*/}
                                        <p>{this.data.userInfo.email}</p>
                                    </div>

                                }
                                {/*{
                                    this.data.userInfo.email &&
                                    <div className="vra-customer-item">
                                        <span>E-posta</span>
                                        <p>{this.data.userInfo.email}</p>
                                    </div>

                                }*/}
                                <div className="vra-customer-item vra-code-item">
                                    <div className="vra-customer-text-item">
                                        <span>Kampanya kodu</span>
                                        <p className="vra-color-text">{this.data.userInfo.smart_campaign_code}</p>
                                    </div>
                                    <div className="vra-customer-qr-item">
                                        <img ref={this.qrImg} src={this.data.userInfo.qrData} alt="VitrA Road Show" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            (this.data.list && this.data.list.bahtroom_cabinet && this.props.language) &&
                            <div className="vra-selected-product-block">
                                <p className="vra-sub-text">Seçilen ürünler</p>
                                <div className="vra-selected-product-item">
                                    <div className="vra-img-item">
                                        <img src={this.bathPicture} alt="" />
                                    </div>
                                    <div className="vra-product-text">
                                        <p>Ürünlerin banyo görünümü</p>
                                        <span onClick={this.toggleModal}>Büyük görüntüle</span>
                                    </div>
                                </div>
                                <div className="vra-campaign-result-container">
                                    <div className="vra-campaign-result-block">
                                        {
                                            this.data.list.bahtroom_cabinet &&
                                            <ProductItemComponent name={this.props.language && this.props.language.washbasin_only}
                                                desc={this.data.list.bahtroom_cabinet.desc}
                                                product_code={this.data.list.bahtroom_cabinet.code}
                                            />
                                        }
                                        {
                                            this.data.list.handle &&
                                            <ProductItemComponent name={`${this.data.list.handle.desc} Kulp`}
                                                desc={""}
                                                product_code={this.data.list.handle.model_code}
                                            />
                                        }
                                        {
                                            this.data.list.stand &&
                                            <ProductItemComponent name={this.props.language.leg}
                                                desc={""}
                                                product_code={this.data.list.stand.model_code}
                                            />
                                        }
                                        {
                                            this.data.list.armature &&
                                            <ProductItemComponent name={this.props.language.faucet}
                                                desc={this.data.list.armature.desc}
                                                product_code={this.data.list.armature.model_code}
                                            />
                                        }
                                        {
                                            this.data.list.mirror &&
                                            <ProductItemComponent name={this.props.language.mirror}
                                                desc={this.data.list.mirror.desc}
                                                product_code={this.data.list.mirror.model_code}
                                            />
                                        }
                                        {
                                            this.data.list.other_module &&
                                            <ProductItemComponent name={this.props.language.other_module}
                                                desc={this.data.list.other_module.desc}
                                                product_code={this.data.list.other_module.model_code}
                                            />
                                        }

                                        {
                                            this.data.list.tall_cabinet &&
                                            <ProductItemComponent name={this.props.language.tall_unit}
                                                desc={this.data.list.tall_cabinet.desc}
                                                product_code={this.data.list.tall_cabinet.model_code}
                                            />
                                        }
                                    </div>
                                </div>

                            </div>
                        }

                        <div className="vra-campaign-form-area">
                            <LandingFormComponent />
                        </div>
                        <div className="road-show-img-container">
                            {
                                this.state.isOpenModal &&
                                <ImageModalComponent bathPicture={this.bathPicture} toggleModal={this.toggleModal} />
                            }
                        </div>

                    </div>
                }


            </div>
        )
    }
};

const mapStateToProps = (state) => ({
    language: state.languageReducer[state.commonReducer.activeLang],
})


export default connect(mapStateToProps)(DealerFormPage);