import React, {Component, Fragment} from 'react';
import {assignProductStep, getOrderSummaryAction, selectionType} from "../../actions";
import {connect} from "react-redux";
import RandomBathProductComponent from '../randomBathProductComponent';
import LoaderSkeletonComponent from "./loaderSkeletonComponent";

class PreviewProductComponent extends Component {
   constructor(props) {
      super(props);
      if (props.userSelection.setCoverColour && props.productStep !== 6) {
         this.getOrderSummary(props);
      }
   }
   state={
      isMobile:false
   }
   resize = () => {
      if(window.innerWidth>641){
         this.setState({
            isMobile:false
         })
      }else{
         this.setState({
            isMobile:true
         })
      }
   }
   componentDidMount() {
      this.resize()
      window.addEventListener("resize",this.resize)
   }
   componentWillUnmount() {
      window.removeEventListener("resize",this.resize)
   }
   getOrderSummary = (props) => {
      let height;
      if (this.props.userSelection.setSinkSizes && this.props.userSelection.setSinkSizes.sink_size_covers[0]) {
         height = this.props.userSelection.setSinkSizes.sink_size_covers[0].height;
         if (this.props.userSelection.setCoverSelectionType && this.props.userSelection.setSinkSizes.sink_size_covers.find(item => item.id === this.props.userSelection.setCoverSelectionType.id)) {
            height = this.props.userSelection.setSinkSizes.sink_size_covers.find(item => item.id === this.props.userSelection.setCoverSelectionType.id).height;
         }
      }
      props.dispatch(getOrderSummaryAction({
         cover_style_color_id: props.userSelection.setCoverColour.id,
         sink_size_id: props.userSelection.setSinkSizes.id,
         sink_cover_id: props.userSelection.setCoverSelectionType.id,
         sink_stand_id: null,
         handle_id: props.userSelection.setHandleTypes && props.userSelection.setHandleTypes.id,
         armature_style_color_id: props.userSelection.setArmatureColour ? props.userSelection.setArmatureColour.id : null,
         cover_direction_tall_cabinet_id: props.userSelection.setTallCabinetCoverDirection ? props.userSelection.setTallCabinetCoverDirection.id : null,
         cover_style_id: props.userSelection.setCoverStyle ? props.userSelection.setCoverStyle.id : null,
         cover_color_id: props.userSelection.setCoverColour ? props.userSelection.setCoverColour.id : null,
         mirror_style_id: props.userSelection.setMirrorType ? props.userSelection.setMirrorType.id : null,
         mirror_size_id: props.userSelection.mirrorSize ? props.userSelection.mirrorSize.size.id : null,

         other_module_id: props.userSelection.setOtherModules ? props.userSelection.setOtherModules.id : null,
         other_module_cover_direction_id: props.userSelection.setOtherModuleCoverDirection ? props.userSelection.setOtherModuleCoverDirection.cover_direction.id : null,
         stand_id: props.userSelection.setCoverSelection ? props.userSelection.setCoverSelection.sink_stand.id : null,
         cover_direction_id: props.userSelection.setSinkSizeDirection ? props.userSelection.setSinkSizeDirection.cover_direction.id : null,
         sink_type_id: props.userSelection.setSinkType ? props.userSelection.setSinkType.id : null,
         tallcabinet_color_id: props.userSelection.setCoverColour ? props.userSelection.setCoverColour.cover_color.id : null,
         withBackLight: props.userSelection.mirrorWithBackLight ? props.userSelection.mirrorWithBackLight.light : 0,
         mirror_cover_direction:this.props.userSelection.selectedMirrorCoverDirection ? this.props.userSelection.selectedMirrorCoverDirection.id : null,
         stand_color_id:props.userSelection.legColor ? props.userSelection.legColor.id : null,
         size_name: `${props.userSelection.setSinkSizes.name.replace("cm","").trim()}x${height}`,

      }))
   }

   componentDidUpdate(prevProps) {
      if (!prevProps.userSelection.setCoverColour && this.props.userSelection.setCoverColour && this.props.productStep !== 6) {
         this.getOrderSummary(this.props);
      }
      if((prevProps.userSelection.setCoverColour !== this.props.userSelection.setCoverColour) || (prevProps.userSelection.setCoverStyle !== this.props.userSelection.setCoverStyle)){
         this.getOrderSummary(this.props);
      }
   }

   editSelection = () => {
      this.props.dispatch(selectionType("sink"));
      this.props.dispatch(assignProductStep(1));
   }
   showLoader = () => {
      this.isPictureGet = false;
      this.forceUpdate();
   }

   render() {
      let {orderSummary, isCongrats, userSelection, activeLang, language, isFromRandom, productStep, setCoverStyle} = this.props;
      return (
          <Fragment>
             {
                orderSummary.bathroomCabinet ?
                    <div className={`vra-chosen-product-wrapper vra-preview ${isCongrats ? "vra-congrats" : ""}`}>
                       <div className="vra-chosen-top-block">
                          <div className="vra-upper-container">
                             <div className="vra-right-block">
                                {
                                   userSelection.bathroomPicture ?
                                       <div className="vra-img-item">
                                          <picture>
                                             <img
                                                 src={userSelection.bathroomPicture}
                                                 alt=""/>
                                          </picture>
                                       </div>
                                       : <LoaderSkeletonComponent width={"75px"} height={"66px"}/>
                                }


                             </div>
                             <div className="vra-outer-layer">
                                <div className="vra-left-block">
                                   <div className="vra-text-item">
                                      <p>{language.washbasin_only}</p>
                                      <span>{orderSummary.bathroomCabinet.sale_code}</span>
                                   </div>
                                   {
                                      activeLang === "tr" &&
                                    <div className={`vra-chosen-price-item ${this.props.withoutEdit ? "vra-between" : ""}`}>
                                      <b>
                                         <p>{parseInt(parseInt(orderSummary.bathroomCabinet.price) + (orderSummary.handleData && orderSummary.handleData.id !== 1 ? parseInt(orderSummary.handleData.price) : 0) + (orderSummary.stand ? parseInt(orderSummary.stand.price) : 0)).toLocaleString()}
                                            <span>&#8378;</span></p>
                                         {parseInt(orderSummary.bathroomCabinet.campaign_price).toLocaleString()}
                                         <span>&#8378;</span></b>
                                   </div>
                                   }

                                </div>
                                {
                                   (activeLang === "tr" || activeLang === "en" || activeLang === "de" || activeLang === "uk" || activeLang === "fr") &&
                                   <div className="vra-product-code-block">
                                      {
                                         (orderSummary.handleData && setCoverStyle && setCoverStyle.defaultHandle.id !== orderSummary.handleData.id) &&
                                         <div className="vra-product-code">
                                            <p>{JSON.parse(orderSummary.handleData.transition)[activeLang] || orderSummary.handleData.name} {language.handle}</p>
                                            <span>{setCoverStyle.id==4?orderSummary.handleData.model_code: orderSummary.handleData.sale_code}</span>
                                         </div>
                                      }
                                      {
                                         orderSummary.stand &&
                                         <div className="vra-product-code">
                                            <p>{language.leg}</p>
                                            <span>{orderSummary.stand.sale_code}</span>
                                         </div>
                                      }
                                      {
                                         (activeLang !== "tr" && activeLang !== "en" && orderSummary.sinkData) &&
                                         <div className="vra-product-code">
                                            <p>{language.washbasin_door}</p>
                                            <span>{orderSummary.sinkData.sale_code}</span>
                                         </div>
                                      }
                                   </div>
                                }
                             </div>
                          </div>
                       </div>
                       <div className="vra-chosen-bottom-block">
                          {
                             this.props.withoutEdit !== true &&
                             <div className="vra-edit-item" onClick={this.editSelection}>
                                <svg width={16} height={16} viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                   <path d="M4 7L10 13L14 14L13 10L7 4" stroke="black" strokeWidth={2}
                                         strokeLinecap="round"
                                         strokeLinejoin="round"/>
                                   <path d="M2 5L5 2L8 5L5 8L2 5Z" stroke="black" strokeWidth={2} strokeLinecap="round"
                                         strokeLinejoin="round"/>
                                </svg>
                                <span>{window.innerWidth < 641 && isCongrats ? language.edit : language.edit_wash}</span>
                             </div>
                          }
                          {
                             (productStep === 5 && isFromRandom && window.innerWidth < 641) &&
                             <RandomBathProductComponent isPreview={true} showLoader={this.showLoader}/>
                          }
                          {
                             activeLang === "tr" &&
                             <div className={`vra-chosen-price-item ${this.props.withoutEdit ? "vra-between" : ""}`}>
                                <p>{language.total_price}</p>
                                <b>
                                   <p>{parseInt(parseInt(orderSummary.bathroomCabinet.price) + ((orderSummary.handleData && setCoverStyle && setCoverStyle.defaultHandle.id !== orderSummary.handleData.id) ? orderSummary.handleCount * parseInt(orderSummary.handleData.price) : 0) + (orderSummary.stand ? parseInt(orderSummary.stand.price) : 0)).toLocaleString()}
                                      <span>&#8378;</span></p>
                                   {parseInt(parseInt(orderSummary.bathroomCabinet.campaign_price)+((orderSummary.handleData && setCoverStyle && setCoverStyle.defaultHandle.id !== orderSummary.handleData.id) ? orderSummary.handleCount * parseInt(orderSummary.handleData.campaign_price) : 0) + (orderSummary.stand ? parseInt(orderSummary.stand.campaign_price) : 0)).toLocaleString()}
                                   <span>&#8378;</span></b>
                             </div>
                          }

                       </div>
                    </div>
                    : <LoaderSkeletonComponent/>
             }
          </Fragment>

      );
   }
}

const mapStateToProps = (state) => ({
   orderSummary: state.orderSummaryReducer,
   userSelection: state.userSelectionReducer,
   activeLang: state.commonReducer.activeLang,
   language: state.languageReducer[state.commonReducer.activeLang],
   isFromRandom: state.commonReducer.isFromRandom,
   productStep: state.productStepReducer.productStepNumber,
   setCoverStyle: state.userSelectionReducer.setCoverStyle
})
export default connect(mapStateToProps)(PreviewProductComponent);
