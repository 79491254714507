import React, {Component} from 'react';
import {postTallCabinetAction} from "../actions";
import {connect} from "react-redux";
import CabinetCustomSelectComponent from "./fields/cabinetCustomSelectComponent";

class TallCabinetSelectionComponent extends Component {
   componentDidMount() {
      this.props.dispatch(postTallCabinetAction(this.props.setCoverStyle.id))
   }

   render() {
       let {language}=this.props
      return (
          <div className="vra-colour-selection-container">
             <div className="vra-title-head-item">
                 {
                     language &&
                     <h3>{language.choose_tall_unit}</h3>
                 }
             </div>
             <CabinetCustomSelectComponent />
          </div>
      );
   }
}
const mapStateToProps=(state)=>({
   setCoverStyle:state.userSelectionReducer.setCoverStyle,
    language: state.languageReducer[state.commonReducer.activeLang]

})
export default connect(mapStateToProps)(TallCabinetSelectionComponent);
