import React, {Component, Fragment} from 'react';
import {setCoverSelection} from "../../actions";
import {connect} from "react-redux";

class CoverOptionSelectComponent extends Component {
   /*handleOnclick = (item) => {
      this.props.dispatch(setCoverSelection(item))
   }*/

   render() {
      let {inx,sink_stand, id,setCoverSelection,setCoverFunc,activeLang,item,setCoverSelectionType} = this.props;
      return (
          <Fragment>
             {
                sink_stand&&
                <div className={`vra-option-item ${setCoverSelection && setCoverSelection.id === id ? "active" : ""}`}
                     onClick={(event) => {

                        setCoverFunc(item,{sink_stand,id})
                        event.stopPropagation()
                     }} key={inx}>
                   <span>{JSON.parse(sink_stand.transition)[activeLang] || sink_stand.name}</span>
                </div>
             }
          </Fragment>

      );
   }
}

const mapStateToProps = (state) => ({
   setCoverSelection: state.userSelectionReducer.setCoverSelection,
   setCoverSelectionType: state.userSelectionReducer.setCoverSelectionType,
   activeLang:state.commonReducer.activeLang
})
export default connect(mapStateToProps)(CoverOptionSelectComponent);
