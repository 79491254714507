import React, { Component } from 'react'
import { connect } from 'react-redux';

class CampaignResultModalComponent extends Component {
   render() {
      let { detailValues, orderSummary, coverStyle, activeLang, bathroomAreaPicture, handleType, leg, language } = this.props;
      return (
         <div className='vra-verification-container'>
            <div className="vra-lb-root road-show-root verification-root">
               <div className="vra-lb-wrapper">
                  <div className="vra-lb-container" >
                     <div className="vra-model-content">
                        <div className="vra-top-block">
                           <h3>{'Kampanya katılım detaylarınız'}</h3>
                           <i className="gg-close" onClick={() => this.props.changeFormStep(null)} />
                        </div>
                        <div className="vra-campaign-result-root">
                           <div className="vra-campaign-result-img-container">
                              {
                                 orderSummary.bathroomCabinet &&
                                 <div className="vra-img-big-campaign-item">
                                    <picture>
                                       <img src={bathroomAreaPicture} alt="campaign" />
                                    </picture>
                                 </div>

                              }
                              <div className="vra-right-item">
                                 <h4>Ücretsiz montaj kodu</h4>
                                 <div className="vra-img-campaign-block">
                                    <div className="vra-img-campaign-item">
                                       <picture>
                                          <img src={detailValues.qrData} alt="QRCode" />
                                       </picture>
                                    </div>
                                    <p>{detailValues.campaignCode}</p>
                                 </div>

                                 <p>Kampanya bilgileri e-posta adresinize gönderilmiştir.</p>
                              </div>
                           </div>
                           {
                              orderSummary.bathroomCabinet &&
                              <div className="vra-campaign-result-container">
                                 <h3>Seçtiğiniz ürünler</h3>
                                 <div className="vra-campaign-result-block">
                                    {
                                       language &&
                                       <div className="vra-table-head">
                                          <span>{language.product_name}</span>
                                          <span>ürün kodu</span>
                                       </div>
                                    }

                                    {
                                       orderSummary.bathroomCabinet &&
                                       <div className="vra-campaign-product-item">
                                          <div className="vra-info-item">
                                             {
                                                language &&
                                                <p>{language.washbasin_only}</p>
                                             }

                                             <span>{JSON.parse(orderSummary.bathroomCabinet.description_transition).tr}</span>
                                          </div>
                                          <p className="vra-product-item">
                                             {orderSummary.bathroomCabinet.sale_code}
                                          </p>
                                       </div>
                                    }
                                    {
                                       orderSummary.armatureStyleData &&
                                       <div className="vra-campaign-product-item">
                                          <div className="vra-info-item">
                                             {
                                                language &&
                                                <p>{language.faucet}</p>
                                             }
                                             <span>{(orderSummary.armatureStyleData.description_transition ? JSON.parse(orderSummary.armatureStyleData.description_transition)[activeLang] || JSON.parse(orderSummary.armatureStyleData.description_transition).en : "")}</span>
                                          </div>
                                          {
                                             <p className="vra-product-item">
                                                {orderSummary.armatureStyleData.model_code}
                                             </p>
                                          }

                                       </div>

                                    }
                                    {
                                       handleType &&
                                       <div className="vra-campaign-product-item">
                                          <div className="vra-info-item">

                                             {
                                                language &&
                                                <p>{(JSON.parse(handleType.transition)[activeLang] || handleType.name) + " " + language.handle}</p>
                                             }

                                             <span>{orderSummary.handleData.description_transition ? JSON.parse(orderSummary.handleData.description_transition)[coverStyle.id == 4 ? "4" : "5"][activeLang] || JSON.parse(orderSummary.handleData.description_transition)[coverStyle.id == 4 ? "4" : "5"].en : ""}</span>
                                          </div>
                                          {
                                             orderSummary.handleData &&
                                             <p className="vra-product-item">
                                                {orderSummary.handleData.sale_code}
                                             </p>
                                          }

                                       </div>
                                    }
                                    {
                                       (leg && orderSummary.stand) &&
                                       <div className="vra-campaign-product-item">
                                          <div className="vra-info-item">

                                             {
                                                language &&
                                                <p>{language.leg}</p>
                                             }
                                             <span>{(orderSummary.stand.description_transition ? JSON.parse(orderSummary.stand.description_transition)[activeLang] || JSON.parse(orderSummary.stand.description_transition).en : "")}</span>
                                          </div>
                                          {
                                             <p className="vra-product-item">
                                                {orderSummary.stand.sale_code}
                                             </p>
                                          }

                                       </div>
                                    }
                                    {
                                       orderSummary.mirrorData &&
                                       <div className="vra-campaign-product-item">
                                          <div className="vra-info-item">

                                             {
                                                language &&
                                                <p>{language.mirror}</p>
                                             }
                                             <span>{(orderSummary.mirrorData.description_transition ? JSON.parse(orderSummary.mirrorData.description_transition)[activeLang] || JSON.parse(orderSummary.mirrorData.description_transition).en : "")}</span>
                                          </div>
                                          {
                                             <p className="vra-product-item">
                                                {orderSummary.mirrorData.sale_id}
                                             </p>
                                          }

                                       </div>

                                    }
                                    {
                                       orderSummary.tallCabinetInfo &&
                                       <div className="vra-campaign-product-item">
                                          <div className="vra-info-item">

                                             {
                                                language &&
                                                <p>{language.tall_unit}</p>
                                             }
                                             <span>{(orderSummary.tallCabinetInfo.description_transition ? JSON.parse(orderSummary.tallCabinetInfo.description_transition)[activeLang] || JSON.parse(orderSummary.tallCabinetInfo.description_transition).en : "")}</span>
                                          </div>
                                          {
                                             <p className="vra-product-item">
                                                {orderSummary.tallCabinetInfo.sale_code}
                                             </p>
                                          }

                                       </div>

                                    }
                                    {
                                       orderSummary.otherModules &&
                                       <div className="vra-campaign-product-item">
                                          <div className="vra-info-item">
                                             {
                                                language &&
                                                <p>{language.other_module}</p>
                                             }
                                             <span>{(orderSummary.otherModules.description_transition ? JSON.parse(orderSummary.otherModules.description_transition)[activeLang] || JSON.parse(orderSummary.otherModules.description_transition).en : "")}</span>
                                          </div>
                                          {
                                             <p className="vra-product-item">
                                                {orderSummary.otherModules.sale_code}
                                             </p>
                                          }

                                       </div>
                                    }




                                 </div>
                              </div>
                           }

                        </div>
                        <div className="vra-campaign-btn-block">
                           <button onClick={() => this.props.changeFormStep(null)} type="button" className="vra-btn">{activeLang === 'en' ? 'Close' : 'Kapat'}</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

         </div>
      )
   }
}
const mapStateToProps = state => ({
   orderSummary: state.orderSummaryReducer,
   coverStyle: state.userSelectionReducer.setCoverStyle || null,
   coverColour: state.userSelectionReducer.setCoverColour || null,
   sinkType: state.userSelectionReducer.setSinkType || null,
   sinkSize: state.userSelectionReducer.setSinkSizes || null,
   coverSelection: state.userSelectionReducer.setCoverSelectionType || null,
   handleType: state.userSelectionReducer.setHandleTypes || null,
   leg: state.userSelectionReducer.legColor || null,
   isCollapseMenuOpen: state.commonReducer.isCollapseMenuOpen,
   activeLang: state.commonReducer.activeLang,
   language: state.languageReducer[state.commonReducer.activeLang],
   bathroomAreaPicture: state.userSelectionReducer.bathroomAreaPicture
})
export default connect(mapStateToProps)(CampaignResultModalComponent)
