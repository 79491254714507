import React, {Component, Fragment} from 'react';
import CoverOptionSelectComponent from "./coverOptionSelectComponent";
import {connect} from "react-redux";
import SinkCoverItemComponent from "../sinkCoverItemComponent";
import {setCoverSelection, setCoverSelectionType, setSinkCoverDirection} from "../../actions";
import DisabledHoverComponent from "../common/disabledHoverComponent";
import MobileSliderComponent from "../mobile/mobileSliderComponent";
import CoverSelectBoxComponent from "../coverSelectBoxComponent";

class CoverCustomSelectComponent extends Component {
   state = {
      id: null,
      isOpen: false
   }

   changeSelectedItemByIndex = (index) => {
      if (window.innerWidth < 641) {
         this.props.dispatch(setCoverSelectionType(this.props.coverTypes[index]))
         this.props.dispatch(setCoverSelection(this.props.coverTypes[index].sink_cover_stands && this.props.coverTypes[index].sink_cover_stands.length > 1 ? this.props.coverTypes[index].sink_cover_stands[0] : null))
         let sinkSizeCovers = this.props.coverTypes[index].sink_size_covers.find(item => item.sink_size_id === this.props.setSinkSizeId.id);
         this.props.dispatch(setSinkCoverDirection(sinkSizeCovers && sinkSizeCovers.sink_size.sink_size_cover_directions.length > 1 ? sinkSizeCovers.sink_size.sink_size_cover_directions[0] : null))
      }
   }


   setCoverFunc = (selectionType, item) => {
      this.props.dispatch(setCoverSelection(item))
      this.props.dispatch(setCoverSelectionType(selectionType))
   }


   render() {
      let {coverTypes} = this.props;

      return (
          <Fragment>

             {
                coverTypes &&
                <MobileSliderComponent
                    changeItem={this.changeSelectedItemByIndex}
                    activeIndex={coverTypes && coverTypes.length > 0 && this.props.setCoverSelectionType ? coverTypes.findIndex(item => item.id === this.props.setCoverSelectionType.id) : 0}
                >

                   {
                      coverTypes.map((item, index) =>
                          <CoverSelectBoxComponent  key={index} setCoverFunc={this.setCoverFunc} item={item} index={index}/>
                      )
                   }
                   {/*{
                coverTypes && coverTypes.map((item, index) =>
                    (item.sink_cover_stands.length === 0 || item.sink_cover_stands.length === 1) &&
                    <SinkCoverItemComponent setCoverTypeFunc={this.setCoverSelectionTypeFunc} {...item} key={index}/>
                )
             }*/}
                </MobileSliderComponent>
             }

          </Fragment>

      );
   }
}

const mapStateToProps = (state) => ({
   coverTypes: state.sinkReducer.coverTypes || [],
   setSinkSizeId: state.userSelectionReducer.setSinkSizes,
   setCoverSelectionType: state.userSelectionReducer.setCoverSelectionType,
})
export default connect(mapStateToProps)(CoverCustomSelectComponent);
