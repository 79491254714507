import {
   SELECTION_TYPE,
   TOGGLE_COLLAPSE_MENU,
   TOGGLE_SELECTED_PRODUCT_MODAL,
   SET_RANDOM_VALUE,
   RESET_ALL_USER_SELECTION,
   SET_FIRST_CREATED_VALUE,
   IS_LOADING, GET_LANDING_DATA,
   SET_LAST_RANDOM_ID,
   SET_ACTIVE_LANG, IS_BUTTON_DISABLED, TOGGLE_COVER_MODELS_MODAL, TOGGLE_BG_MODAL,TOGGLE_VOICE
} from "../constants/actionTypes";

const initialState = { isSelectedProductModalOpen: false, selectionType: "sink", isCollapseMenuOpen: false, isFromRandom: false, isFirstCreated: false, isLoading: false, landingData: [], isButtonDisabled: false,voice:true};

const CommonReducer = (state = initialState, action) => {
   switch (action.type) {
      case TOGGLE_SELECTED_PRODUCT_MODAL:
         return {
            ...state,
            isSelectedProductModalOpen: action.payload
         }
      case SELECTION_TYPE:
         return {
            ...state,
            selectionType: action.payload ? action.payload : "sink"
         }
      case TOGGLE_COLLAPSE_MENU:
         return {
            ...state,
            isCollapseMenuOpen: action.payload
         }
      case SET_RANDOM_VALUE:
         return {
            ...state,
            isFromRandom: action.payload,
            isFirstCreated: action.payload === true ? true : state.isFirstCreated,
            selectionType: "sink"
         }

      case RESET_ALL_USER_SELECTION:
         return {
            ...state,
            ...initialState
         }
      case SET_FIRST_CREATED_VALUE:
         return {
            ...state,
            isFirstCreated: true
         }
      case IS_LOADING:
         return {
            ...state,
            isLoading: action.payload
         }
      case GET_LANDING_DATA:
         return {
            ...state,
            landingData: action.payload
         }
      case SET_LAST_RANDOM_ID:
         return {
            ...state,
            lastRandomId: action.payload
         }
      case SET_ACTIVE_LANG:
         return {
            ...state,
            activeLang: action.payload
         }
      case IS_BUTTON_DISABLED:
         return {
            ...state,
            isButtonDisabled: action.payload
         }
      case TOGGLE_COVER_MODELS_MODAL:
         return {
            ...state,
            isOpenModelsModal: action.payload
         }
      case TOGGLE_BG_MODAL:
         return {
            ...state,
            isBgModal: action.payload
         }
      case TOGGLE_VOICE:
         return {
            ...state,
            voice: action.payload
         }
      default:
         return state
   }
}
export default CommonReducer

