import React, {Component, Fragment} from 'react';
import CongratsComponent from "../components/congratsComponent";
import OrderSummaryComponent from "../components/orderSummaryComponent";
import TypeSelectionComponent from "../components/typeSelectionComponent";
import SinkTypeSelectionComponent from "../components/sinkTypeSelectionComponent";
import HandleSelectionComponent from "../components/handleSelectionComponent";
import CoverSelectionComponent from "../components/coverSelectionComponent";
import {connect} from "react-redux";
import {ASSETS} from "../constants/paths";

class SinkSelectionContainer extends Component {
   render() {
      let {productStep} = this.props;
      return (
          <Fragment>
             {
                productStep === 1 &&
                <TypeSelectionComponent/>
             }
             {
                productStep === 2 &&
                <SinkTypeSelectionComponent/>
             }
           {/*  {
                productStep === 2 &&
                <ResColourSelectionContainer/>

             }*/}

             {/* //todo:TYPE SELECTION COMPONENT 'DE  " KAPAK TARZI , LAVABO TİPİ VE KULP SECİMİ VAR " bakılacak!!!!! */}
             {/* //todo: " ortak selectboxlar kullanılacak mı"  bakılacak!!!!! */}

             {
                productStep === 3 &&
                <CoverSelectionComponent/>

             }
             {
                productStep === 4 &&
                <HandleSelectionComponent/>
                // <BathroomSizeComponent/>

             }
             {/*{
                productStep === 4 &&
                <ResBathroomSelectionContainer/>
             }*/}
             {/*{
                productStep === 5 &&
                <CoverSelectionComponent/>

             }*/}
             {/* {
                productStep === 5 &&
                <ResCoverSelectionContainer/>

             } */}
             {/*{
                productStep === 6 &&
                <HandleSelectionComponent/>

             }*/}
             {
                productStep === 5 &&
                <CongratsComponent productStep={productStep} />

             }
             {/*{
                productStep === 8 &&
                <EditSelectionComponent/>
             }*/}
             {
                productStep === 6 &&
                <OrderSummaryComponent />
             }
          </Fragment>
      )
   }
};


const mapStateToProps = (state) => ({
   productStep: state.productStepReducer.productStepNumber,
   language: state.languageReducer.language ? state.languageReducer.language : []
})

export default connect(mapStateToProps)(SinkSelectionContainer);
