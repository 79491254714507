import React, {Component} from "react"
import {connect} from "react-redux";
class SinkCoverItemComponent extends Component{
   render(){
      let {name,id,setCoverSelectionType,setCoverTypeFunc} = this.props;
      return(
          <div className={`vra-sink-item ${setCoverSelectionType && setCoverSelectionType.id===id ? "active":""} `} onClick={()=>setCoverTypeFunc(this.props)}>
             {
                name &&
                <p>{name}</p>
             }
          </div>
      )
   }
}
const mapStateToProps = (state) => ({
   setCoverSelectionType: state.userSelectionReducer.setCoverSelectionType
})
export default connect(mapStateToProps) (SinkCoverItemComponent);
