import React, {Component} from 'react';
import {ASSETS} from "../constants/paths";
import CabinetOptionSelectComponent from "./fields/cabinetOptionSelectComponent";
import {connect} from "react-redux";
import {changeSelectedModulesAction, setTallCabinet, setTallCabinetCoverDirection} from "../actions";

class CabinetSelectBoxComponent extends Component {
   selectBlock=React.createRef();
   state={
      id:null
   }
   componentDidMount() {
      window.addEventListener("click",this.closeOutsideClick,false)
   }
   componentWillUnmount() {
      window.removeEventListener("click",this.closeOutsideClick,false)
   }
   closeOutsideClick = (e) => {
      if(this.selectBlock.current && !this.selectBlock.current.contains(e.target)){
         this.setState({
            id:null
         })
      }
   }
   setCabinetFunc = (item, type) => {
      if (!this.props.setTallCabinetCoverDirection) {
         this.props.dispatch(changeSelectedModulesAction("tallCabinetInfo"))
      }
      this.props.dispatch(setTallCabinetCoverDirection(item))
      this.props.dispatch(setTallCabinet(type))

   }
   handleOnclick = (item) => {
      /*this.props.dispatch(setTallCabinet(item))
      if (item.cover_direction_tall_cabinets.length < 2) {
         this.setCabinetFunc(item.cover_direction_tall_cabinets[0])
      }*/
      if (item.id === this.state.id) {
         this.setState({
            id: null
         })
      } else {
         this.setState({
            id: item.id
         })
      }
      if (window.innerWidth < 641) {
         this.props.dispatch(setTallCabinet(item))
         if (item.cover_direction_tall_cabinets.length > 1) {
            this.props.dispatch(setTallCabinetCoverDirection(item.cover_direction_tall_cabinets[0]))
         }else{
            this.props.dispatch(setTallCabinetCoverDirection(null))
         }
      }
   }
   render() {
      let{setTallCabinet,item,index,activeLang,language,openSelectBox}=this.props;
      return (
          <div ref={this.selectBlock}
              className={`vra-custom-select-block ${(this.state.id === item.id && item.cover_direction_tall_cabinets.length > 1) ? "opened" : ""} ${setTallCabinet && setTallCabinet.id === item.id && (window.innerWidth<641 || !this.state.id) ? "active" : ""}`}
              onClick={() => {
                 if (item.cover_direction_tall_cabinets.length > 1) {
                    this.handleOnclick(item)
                 } else {
                    openSelectBox(item)
                 }

              }} key={index}>
             <div
                 className={`vra-custom-select-item ${(setTallCabinet && setTallCabinet.id === item.id && (window.innerWidth<641 || item.cover_direction_tall_cabinets.length <= 1)) ? "active" : ""}`}>

                <div className="vra-img-item">
                   <picture>

                      <source srcSet={`${ASSETS}${item.img.substr(0,item.img.indexOf("."))+".webp"}`}  type="image/webp"/>
                      <source srcSet={`${ASSETS}${item.img.substr(0,item.img.indexOf("."))+".png"}`}  type="image/png"/>
                      <img src={`${ASSETS}${item.img}`} alt={JSON.parse(item.transition)[activeLang] || item.name} />
                   </picture>
                </div>
                <div className="vra-text-item">
                   <p>{JSON.parse(item.transition)[activeLang] || item.name}</p>
                </div>
             </div>
             {
                item.cover_direction_tall_cabinets.length > 1 && <i className="gg-chevron" />
             }
             {
                item.cover_direction_tall_cabinets.length > 1 &&
                <div className="vra-option-container">
                   <p>{language.door_side}</p>
                   <div className="vra-option-block">
                      {
                         item.cover_direction_tall_cabinets.map((direction, _) =>
                                 <CabinetOptionSelectComponent
                                     cabinet={item} setCabinetFunc={this.setCabinetFunc}
                                                               key={index + "-" + _} {...direction} />

                             // <CoverOptionSelectComponent setCabinetFunc={this.setCabinetFunc} key={index + "-" + _} {...direction}/>
                         )
                      }
                   </div>
                </div>
             }
          </div>
      );
   }
}
const mapStateToProps = (state) => ({
   tallCabinets: state.tallCabinetReducer.tallCabinets,
   setTallCabinetCoverDirection: state.userSelectionReducer.setTallCabinetCoverDirection,
   setTallCabinet: state.userSelectionReducer.setTallCabinet,
   activeLang: state.commonReducer.activeLang,
   language: state.languageReducer[state.commonReducer.activeLang]

})
export default connect(mapStateToProps) (CabinetSelectBoxComponent);
