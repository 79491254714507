import React, {Component} from 'react';
import {ASSETS} from "../../constants/paths";
import { connect } from 'react-redux';
import CampaigneModalComponent from '../modal/campaigneModalComponent';
import VerificationComponent from '../modal/verificationComponent';
import CampaignResultModalComponent from '../modal/campaignResultModalComponent';

class RoadShowJoinCmapaignComponent extends Component {
   state = {}
   changeFormStep = (step) => {
      this.setState({
         formStep:step
      })
      if(!step){
         this.resetValues()
         document.getElementById('vra-choice-root').style.zIndex = null
      }
   }

   resetValues=()=>{
      this.formValues={}
   }
   setFormValues=(values)=>{
      this.formValues={
         phone_number:values.phone,
         validation_code:null,
         address:null,
         name:values.name,
         surname:values.surname,
         email:values.email,
         subscribed:values.permission,
         bahtroom_sale_code:this.props.orderSummary.bathroomCabinet.sale_code,
         bahtroom_handle_code:this.props.orderSummary.handleData.sale_code,
         bathroom_leg_code:this.props.orderSummary.stand ? this.props.orderSummary.stand.sale_code : null,
      }
   }

   openForm = () => {
      this.setState({
         formStep:1
      },()=>{
         if(this.state.formStep){
            document.getElementById('vra-choice-root').style.zIndex ='11'
         }
         
      })
   }

   setDetailValues=(detailValues)=>{
      this.detailValues=detailValues
   }
   render() {
      return (
          <div className="vra-additional-container">
             <div className="vra-additional-block">
                <div className="vra-img-item">
                   <picture>
                      <source
                          srcSet={`${ASSETS}/img/home/announce-img.webp 1x, ${ASSETS}/img/home/announce-img-2x.webp 2x`}
                          media="(min-width: 1401px)"
                          type="image/webp"/>
                      <source
                          srcSet={`${ASSETS}/img/home/announce-img.png 1x, ${ASSETS}/img/home/announce-img-2x.png 2x`}
                          media="(min-width: 1401px)"
                          type="image/png"/>
                      {/* <source
                          srcSet={`${ASSETS}/img/home/additional-mobile.png 1x, ${ASSETS}/img/home/additional-mobile-2x.png 2x`}
                          media="(max-width: 1400px)"
                          type="image/png"/>
                      <source
                          srcSet={`${ASSETS}/img/home/additional-mobile.png 1x, ${ASSETS}/img/home/additional-mobile-2x.webp 2x`}
                          media="(max-width: 1400px)"
                          type="image/webp"/> */}
                      <img src={`${ASSETS}/img/home/announce-img.png`} alt="additional"/>
                   </picture>
                </div>
                <div className="vra-text-block">
                   <p>Kampanyaya katıl</p>
                   <span>Ücretsiz montaj hakkından yararlanabilmek için kampanyaya katıl</span>
                </div>
             </div>
             <button onClick={this.openForm} className="vra-btn">Şimdi katıl</button>
             {
               this.state.formStep === 1 &&
               <CampaigneModalComponent setFormValues={this.setFormValues} changeFormStep={this.changeFormStep} />
            }
            {
               this.state.formStep === 2 &&
               <VerificationComponent setDetailValues={this.setDetailValues} formValues={this.formValues} changeFormStep={this.changeFormStep} />

            }
            {
               this.state.formStep === 3 &&
               <CampaignResultModalComponent detailValues={this.detailValues} changeFormStep={this.changeFormStep} />
            }
          </div>
      );
   }
}

const mapStateToProps = (state) => ({
    language: state.languageReducer[state.commonReducer.activeLang],
    orderSummary: state.orderSummaryReducer,
   userSelection: state.userSelectionReducer

})
export default connect(mapStateToProps)(RoadShowJoinCmapaignComponent);
