import {GET_MIRROR_TYPES} from "../constants/actionTypes";

const initialState = {mirrorType: []};

const mirrorReducer = (state = initialState, action) => {
   switch (action.type) {
      case GET_MIRROR_TYPES:
         return {
            ...state,
            mirrorType: action.payload
         }
      default:
         return state
   }
}
export default mirrorReducer;
