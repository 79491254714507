import React, { Component, Fragment } from 'react';
import ImageSliderComponent from "./imageSliderComponent";
import { connect } from "react-redux";
import {
   setCoverSelection,
   setCoverSelectionType,
   setSinkCoverDirection,
   setSinkSizes,
   setSinkTitleAction,
   setSinkType
} from "../../actions";

class RespOptionSelectionComponent extends Component {
   sliderComponent = React.createRef();
   state = {
      isMobile: false,
      activeIndex: null
   }

   changeSelectedItemByIndex = (index) => {
      let sinkStyle = this.props.setSinkType || this.props.sinkTypes[0];
      let activeSize;
      let activeSizeGroup;
      let activeIndex = 0;
      sinkStyle.size_groups.map((groupItem) => {
         groupItem.sizes.map((sizeItem) => {
            if (activeIndex === index) {
               activeSize = sizeItem;
               activeSizeGroup = groupItem
            }
            activeIndex++
         })
      })
      this.setState({
         activeIndex: null
      })
      this.props.dispatch(setSinkTitleAction(activeSizeGroup))
      this.props.dispatch(setSinkSizes(activeSize))
      this.props.dispatch(setCoverSelectionType(null))
      this.props.dispatch(setCoverSelection(null))
      this.props.dispatch(setSinkCoverDirection(null))
   }

   setColor = (title, type, _sizes) => {
      let index = 0;
      let activeIndex;
      type.size_groups.map((groupItem) => {
         groupItem.sizes.map((sizeItem) => {
            if (sizeItem.id === _sizes.id) {
               activeIndex = index
            }
            index++
         })
      })
      this.setState({
         activeIndex
      })
      this.props.dispatch(setSinkTitleAction(title))
      this.props.dispatch(setSinkType(type))
      this.props.dispatch(setSinkSizes(_sizes))
      this.props.dispatch(setCoverSelectionType(null))
      this.props.dispatch(setCoverSelection(null))
      this.props.dispatch(setSinkCoverDirection(null))
   }
   resize = () => {
      if (window.innerWidth > 640) {
         this.setState({
            isMobile: false
         })
      } else {
         this.setState({
            isMobile: true
         })
      }
   }

   componentDidMount() {
      this.resize();
      window.addEventListener("resize", this.resize)
      if (this.props.setSinkSizes && this.props.clickedSinkStyle) {
         this.setSelectedItem()
      }
   }

   setSelectedItem = () => {
      let index = 0;
      let activeIndex;
      this.props.clickedSinkStyle.size_groups.map((groupItem) => {
         groupItem.sizes.map((sizeItem) => {
            if (sizeItem.id === this.props.setSinkSizes.id) {
               activeIndex = index
            }
            index++
         })
      })
      this.setState({
         activeIndex
      })
   }

   componentDidUpdate(prevProps) {
      if (prevProps.clickedSinkStyle && this.props.clickedSinkStyle && this.props.clickedSinkStyle.id !== prevProps.clickedSinkStyle.id) {
         this.setState({
            activeIndex: 0
         })
      }
      if(this.props.setSinkSizes && !prevProps.clickedSinkStyle && this.props.clickedSinkStyle){
         this.setSelectedItem();
      }
   }

   componentWillUnmount() {
      window.removeEventListener("resize", this.resize)
      this.resize();
   }


   render() {
      let { setSinkTitle, setSinkSizes, activeLang, clickedSinkStyle } = this.props;
      return (
         <div className="vra-selections-block vra-mobile-block vra-sizes">
            {
               setSinkTitle &&
               <div className="vra-tooltip-item">
                  <span>{`${JSON.parse(setSinkTitle.transition)[activeLang]}`}</span>
               </div>
            }

            {
               (clickedSinkStyle && clickedSinkStyle.size_groups.length > 0) &&
               <ImageSliderComponent ref={this.sliderComponent} changeItem={this.changeSelectedItemByIndex}
                  activeIndex={this.state.activeIndex}
                  groupAndCounts={(clickedSinkStyle.size_groups.map((item) => item.sizes.length))}>
                  {
                     clickedSinkStyle.size_groups.map((g, index) =>
                        <Fragment key={index}>
                           {
                              g.sizes.map((_sizes, inx) => {
                                 return (
                                    <div key={inx}
                                       className={`vra-option-item vra-color-option  ${setSinkSizes && this.state.isMobile && setSinkSizes.id === _sizes.id ? "active" : ""}`}
                                       onClick={() => {
                                          this.setColor(g, clickedSinkStyle, _sizes);

                                       }}>
                                       <div className="vra-round-item">
                                          <p><span>{JSON.parse(_sizes.transition)[activeLang].split(" ")[0]}</span>{JSON.parse(_sizes.transition)[activeLang].split(" ")[1]}</p>
                                       </div>
                                       {/*<span>{JSON.parse(_sizes.size_groups.transition)[activeLang]}</span>*/}
                                    </div>
                                 )
                              }
                              )
                           }
                           {
                              (index !== clickedSinkStyle.size_groups.length - 1) &&
                              <span className="vra-line-item" />
                           }
                        </Fragment>
                     )
                  }


               </ImageSliderComponent>
            }


         </div>
      );
   }
}
const mapStateToProps = state => ({
   setSinkSizes: state.userSelectionReducer.setSinkSizes,
   setSinkTitle: state.userSelectionReducer.setSinkTitle,
   sinkTypes: state.sinkReducer.sinkTypes,
   setSinkType: state.userSelectionReducer.setSinkType,
   activeLang: state.commonReducer.activeLang,
   clickedSinkStyle: state.sinkReducer.sinkTypes && state.userSelectionReducer.setSinkType ? (state.sinkReducer.sinkTypes.find(item => item.id === state.userSelectionReducer.setSinkType.id) || state.sinkReducer.sinkTypes[0]) : null

})
export default connect(mapStateToProps)(RespOptionSelectionComponent);
