import React, { Component } from 'react';
import ErrorMessageComponent from '../common/errorMessageComponent';

class TextInputComponent extends Component {
    render() {
        const {hasError,input,label,name, disabled,msg, placeholder,disableInput,maxLength, meta: {active, error, touched },isVraMail } = this.props;
        return (
                <div className={`input-item ${touched && error ? "error" : ""} ${input.value&& input.value.length>0 ? "valid":""} ${active ? 'touche' : ''} `} >
                    <label htmlFor={name} >{label}</label>
                    <input className={`${input.value&& input.value.length>0 ? "valid":""}`} onChange={()=>{}} {...input}  name={name} placeholder={placeholder} disabled={disableInput ? disableInput : false} maxLength={maxLength}/>
                    <div className="error-img">
                       
                    </div>
                    {
                        ((touched && error )|| hasError) && !isVraMail ?
                        <ErrorMessageComponent message={msg}/>:
                        null
                    }
                </div>
        )
    }
};

export default TextInputComponent;
