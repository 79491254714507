import React, {Component} from 'react';
import VideoModalComponent from "../modal/videoModalComponent";
import {connect} from "react-redux";
import {ASSETS} from "../../constants/paths";

class VideoItemComponent extends Component {
   state={
      isModalOpen:false
   }
   toggleModal = () => {
      this.setState({
         isModalOpen:!this.state.isModalOpen
      })
   }
   filteredByLang=(videoItem)=>videoItem.available_countries.indexOf(this.props.activeLang)>-1
   render() {
      let{language,videoImages,activeLang}=this.props;
      return (
          <div className="vra-design-root">
             <div className="vra-design-container safe-area">
                <div className="vra-img-block">
                   {
                      language ?
                      <div className="vra-item-block">
                         <div className="vra-top-block">
                            <div className="vra-text-block">
                               <h3>{language.how_to_design}</h3>
                               <p>{language.design_properties}</p>
                            </div>
                            <div className="vra-list-block">
                               <div className="vra-list-item short-item">
                                  <div className="vra-number-item">
                                     <span>1</span>
                                  </div>
                                  <div className="vra-text-item">
                                     <p>{language.create_cabinet}</p>
                                  </div>
                               </div>
                               <div className="vra-list-item ">
                                  <div className="vra-number-item">
                                     <span>2</span>
                                  </div>
                                  <div className="vra-text-item">
                                     <p>{language.addi_products}</p>
                                  </div>
                               </div>
                               <div className="vra-list-item">
                                  <div className="vra-number-item">
                                     <span>3</span>
                                  </div>
                                  <div className="vra-text-item">
                                     <p>{language.create_list}</p>
                                  </div>
                               </div>
                            </div>
                         </div>
                         {/*todo:diğer dillerde video gelmedi dahs sonra güncellenecek {Emre}*/}
                         {
                            activeLang === "tr" &&
                            <div className="vra-watch-btn-block" onClick={this.toggleModal}>
                               <div className="vra-icon-item">
                                  <svg width={14} height={16} viewBox="0 0 14 16" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                     <path
                                         d="M12.9011 6.25685L2.98052 0.676544C1.64731 -0.0733855 3.28421e-07 0.890043 5.9422e-07 2.4197L2.53353e-06 13.5803C2.79933e-06 15.11 1.64732 16.0734 2.98053 15.3235L12.9011 9.74315C14.2604 8.97854 14.2604 7.02145 12.9011 6.25685Z"
                                         fill="black"/>
                                  </svg>

                               </div>

                               <div className="vra-text-item" >
                                  <span>{language.click_to_watch}</span>
                               </div>
                            </div>
                         }
                      </div>
                          :<div/>
                   }
                   {
                      (videoImages && videoImages.length>0) ? videoImages.filter(this.filteredByLang).map((item,index)=>
                          <div className="vra-img-item" key={index}>
                             <picture>
                                <source media="(min-width: 769px)"
                                        srcSet={`${ASSETS}${item.img1x.substr(0,item.img1x.indexOf("."))+".webp"} 1x, ${ASSETS}${item.img2x.substr(0,item.img2x.indexOf("."))+".webp"} 2x`}
                                        type="image/webp"/>
                                <source media="(min-width: 769px)"
                                        srcSet={`${ASSETS}${item.img1x} 1x, ${ASSETS}${item.img2x} 2x`}
                                        type="image/png"/>
                                <source media="(max-width: 768px)"
                                        srcSet={`${ASSETS}${item.imgMobil1x.substr(0,item.imgMobil1x.indexOf("."))+".webp"} 1x, ${ASSETS}${item.imgMobil2x.substr(0,item.imgMobil1x.indexOf("."))+".webp"} 2x`}
                                        type="image/webp"/>
                                <source media="(max-width: 768px)"
                                        srcSet={`${ASSETS}${item.imgMobil1x} 1x, ${ASSETS}${item.imgMobil2x} 2x`}
                                        type="image/png"/>
                                <img src={`${ASSETS}${item.img1x}`} alt="slide-img"/>
                             </picture>
                          </div>
                      )
                          :<div/>
                   }
                </div>
             </div>
             {
               this.state.isModalOpen &&
                <VideoModalComponent isModalOpen={this.state.isModalOpen} toggleModal={this.toggleModal}/>
             }
          </div>
      );
   }
}
const mapStateToProps=state=>({
   language: state.languageReducer[state.commonReducer.activeLang],
   videoImages: state.commonReducer.landingData ? state.commonReducer.landingData.video_images : [],
   activeLang:state.commonReducer.activeLang
})
export default connect(mapStateToProps) (VideoItemComponent);
