
import React, {Component} from 'react';
import {connect} from "react-redux";
import {
   changeProductStepNumber,
   selectionType,
   toggleSelectedProductModal,
   createUserBatroomSelectionLogAction,
   createUserArmatureSelectionLogsAction,
   createUserMirrorSelectionLogsAction,
   createUserTallCabinetSelectionLogsAction,
   createUserOtherModuleSelectionLogsAction,
   assignProductStep,
   setArmatureType,
   setArmatureColour,
   setTallCabinetCoverDirection,
   setTallCabinet,
   setOtherModules,
   setOtherModulesCoverDirection,
   setMirrorBacklight,
   setMirrorType,
   setMirrorSize, checkButtonDisabledOrNot
} from "../../actions";
import {ASSETS} from "../../constants/paths";


class ButtonComponent extends Component {
   selectedArmatureColour
   selectedArmatureType
   clickSound
   state = {
      notSelected: false
   }

   componentDidMount() {
      this.checkUnselectedValue()
      this.selectedArmatureColour = this.props.setArmatureColour;
      this.selectedArmatureType = this.props.setArmatureType;

      this.selectedTallCabinetDirection = this.props.setTallCabinetCoverDirection;
      this.selectedTallCabinet = this.props.setTallCabinet;

      this.selectedOtherModule = this.props.setOtherModules;
      this.selectedOtherModuleCoverDirection = this.props.setOtherModuleCoverDirection;

      this.selectedMirror = this.props.setMirrorType;
      this.mirrorSize = this.props.mirrorSize;
      this.withBacklight = this.props.withBacklight;
   }

   skipStep = () => {
      this.setDataLayer()
      if (this.props.isDisabled) {
         return;
      }
      if (this.props.selectionType !== "sink") {
         this.createOtherModulesLog()
         this.changeSelectionType()
         return;
      }
      if (this.props.productStepNumber < 9) {
         this.props.dispatch(createUserBatroomSelectionLogAction({
            session_id: this.props.userSessionId,
            cover_style_color_id: this.props.userSelection.setCoverColour ? this.props.userSelection.setCoverColour.id : null,
            sink_size_id: this.props.userSelection.setSinkSizes ? this.props.userSelection.setSinkSizes.id : null,
            sink_cover_id: this.props.userSelection.setCoverSelectionType ? this.props.userSelection.setCoverSelectionType.id : null,
            sink_stand_id: this.props.userSelection.setCoverSelection && this.props.userSelection.setCoverSelection.sink_stand ? this.props.userSelection.setCoverSelection.sink_stand.id : null,
            handle_id: this.props.userSelection.setHandleTypes ? this.props.userSelection.setHandleTypes.id : null,
            cover_style_id: this.props.userSelection.setCoverStyle ? this.props.userSelection.setCoverStyle.id : null,
            cover_color_id: this.props.userSelection.setCoverColour ? this.props.userSelection.setCoverColour.id : null,
            stand_id: this.props.userSelection.setCoverSelection ? this.props.userSelection.setCoverSelection.id : null,
            sink_type_id: this.props.sinkType ? this.props.sinkType.id : null,
            cover_direction_id: this.props.userSelection.setSinkSizeDirection ? this.props.userSelection.setSinkSizeDirection.cover_direction.id : null,
            action_status: this.props.productStepNumber == 8 ? "Completed" : "Selected"
         }))
         if (this.props.activeLang === "in" && this.props.productStepNumber === 3) {
            /*todo: kulp 'india' da yok o yüzden 1 step ileriye gidiyor*/
            this.props.dispatch(assignProductStep(5))
            return;
         }
         if (this.props.productStepNumber === 6) {
            this.props.dispatch(assignProductStep(this.props.isFirstCreated === true ? 8 : 7))
         } else {
            this.props.dispatch(changeProductStepNumber(1))
            // this.checkUnselectedValue()
         }
         return;
      }
      if (this.props.productStepNumber === 9) {
         this.openSelectedProductModal()
      }

      // this.props.dispatch(changeProductStepNumber(1))

   }
   setDataLayer = () => {
      if (!window.dataLayer) window.dataLayer = window.dataLayer || [];
      if(this.props.selectionType === "sink" ) {
         switch (this.props.productStepNumber) {
            case 1:
               window.dataLayer.push({
                  'event': 'configurator',
                  'kapakTarzi': this.props.coverStyle && this.props.coverStyle.name,
                  'renk': this.props.coverColour && this.props.coverColour.cover_color && this.props.coverColour.cover_color.name,
                  'configuratorStep': '2'
               });
               return
            case 2:
               window.dataLayer.push({
                  'event': 'configurator',
                  'lavaboTipi': this.props.sinkType && this.props.sinkType.name,
                  'configuratorStep': '2.1'
               });
               return
            case 3:
               window.dataLayer.push({
                  'event': 'configurator',
                  'kapakTipi': this.props.coverSelection && this.props.coverSelection.name,
                  'configuratorStep': '2.2'
               });
               return;
            case 4:
               window.dataLayer.push({
                  'event': 'configurator',
                  'kulp': this.props.handleType && this.props.handleType.name,
                  'configuratorStep': '2.3'
               });
               return
            default:
               return;
         }
      }
      else{
         switch (this.props.selectionType) {
            case "armature":
               window.dataLayer.push({
                  'event': 'configurator',
                  'ArmatürName': this.props.setArmatureType && this.props.setArmatureType.name,
                  'ArmatürType': this.props.setArmatureColour && this.props.setArmatureColour.armature_color && this.props.setArmatureColour.armature_color.name,
                  'configuratorStep' : '3'
               });
               return
            case 'tallCabinet':
               window.dataLayer.push({
                  'event': 'configurator',
                  'boyDolabi': this.props.setTallCabinet.name,
                  'boyDolabiType': this.props.setTallCabinetCoverDirection.cover_direction.name,
                  'configuratorStep' : '3.1'
               });
               return
            case 'mirror':
               window.dataLayer.push({
                  'event': 'configurator',
                  'Ayna': this.props.setMirrorType && this.props.setMirrorType.name,
                  'configuratorStep' : '3.2'
               });
               return;
            case 'otherModule':
               window.dataLayer.push({
                  'event': 'configurator',
                  'ekSaklamaAlani': this.props.setOtherModules && this.props.setOtherModules.name,
                  'ekSaklamaAlaniType': this.props.setOtherModuleCoverDirection && this.props.setOtherModuleCoverDirection.cover_direction.name,
                  'configuratorStep' : '3.3'
               });

               return
            default:
               return;
         }
      }
   }
   addClickSound=()=>{
      this.clickSound = new Audio(`${ASSETS}/sounds/clickExport.mp3`).play()
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.userSelection !== this.props.userSelection || prevProps.productStepNumber !== this.props.productStepNumber || prevProps.selectionType !== this.props.selectionType) {
         this.checkUnselectedValue()
      }
      if (this.props.selectionType === "sink") {
         this.selectedArmatureColour = this.props.setArmatureColour;
         this.selectedArmatureType = this.props.setArmatureType;

         this.selectedTallCabinetDirection = this.props.setTallCabinetCoverDirection;
         this.selectedTallCabinet = this.props.setTallCabinet;

         this.selectedOtherModule = this.props.setOtherModules;
         this.selectedOtherModuleCoverDirection = this.props.setOtherModuleCoverDirection;

         this.selectedMirror = this.props.setMirrorType;
         this.mirrorSize = this.props.mirrorSize;
         this.withBacklight = this.props.withBacklight;
      }
   }

   createOtherModulesLog = () => {
      switch (this.props.selectionType) {
         case "armature":
            this.props.dispatch(createUserArmatureSelectionLogsAction({
               session_id: this.props.userSessionId,
               size: this.props.userSelection.mirrorSize ? this.props.userSelection.mirrorSize.size.id : this.props.userSelection.setSinkSizes.id,
               armature_style_color_id: this.props.userSelection.setArmatureColour ? this.props.userSelection.setArmatureColour.id : null,
               action_status: "Completed"
            }))
            break;
         case "mirror":
            this.props.dispatch(createUserMirrorSelectionLogsAction({
               session_id: this.props.userSessionId,
               mirror_style_id: this.props.userSelection.setMirrorType ? this.props.userSelection.setMirrorType.id : null,
               sink_size_id: this.props.userSelection.setSinkSizes.id,
               cover_direction_id: this.props.userSelection.setSinkSizeDirection ? this.props.userSelection.setSinkSizeDirection.cover_direction.id : null,
               with_backlight: this.props.userSelection.mirrorWithBackLight,
               action_status: "Completed"
            }))
            break;
         case "tallCabinet":
            this.props.dispatch(createUserTallCabinetSelectionLogsAction({
               session_id: this.props.userSessionId,
               cover_direction_tall_cabinet_id: this.props.userSelection.setTallCabinetCoverDirection ? this.props.userSelection.setTallCabinetCoverDirection.id : null,
               cover_style_id: this.props.userSelection.setCoverStyle ? this.props.userSelection.setCoverStyle.id : null,
               //sink_color_id: logDetail.sink_color_id,
               action_status: "Completed"
            }))
            break;
         case "otherModule":
            this.props.dispatch(createUserOtherModuleSelectionLogsAction({
               session_id: this.props.userSessionId,
               other_module_id: this.props.userSelection.setOtherModules ? this.props.userSelection.setOtherModules.id : null,
               cover_style_id: this.props.userSelection.setCoverStyle ? this.props.userSelection.setCoverStyle.id : null,
               //sink_color_id: logDetail.sink_color_id,
               cover_direction_id: this.props.userSelection.setOtherModuleCoverDirection ? this.props.userSelection.setOtherModuleCoverDirection.cover_direction.id : null,
               cover_color_id: this.props.userSelection.setCoverColour ? this.props.userSelection.setCoverColour.cover_color.id : null,
               action_status: "Completed"
            }))
            break;
      }
   }

   backStep = () => {
      if (this.props.selectionType !== "sink") {
         this.checkOtherModulesSet();
         this.changeSelectionType()
         return;
      }
      /* //todo:congratsin stepi 7 editSelection 8 oldugunda burası değişecek şimdilik böyle kalsın*/
      if (this.props.productStepNumber === 9) {
         this.props.dispatch(assignProductStep(8))
         return;
      }
      this.props.dispatch(changeProductStepNumber(-1))
   }

   checkOtherModulesSet = () => {
      switch (this.props.selectionType) {
         case "armature":
            this.props.dispatch(setArmatureColour(this.selectedArmatureColour))
            this.props.dispatch(setArmatureType(this.selectedArmatureType))
            break;
         case "tallCabinet":
            this.props.dispatch(setTallCabinetCoverDirection(this.selectedTallCabinetDirection))
            this.props.dispatch(setTallCabinet(this.selectedTallCabinet))
            break;
         case "otherModule":
            this.props.dispatch(setOtherModules(this.selectedOtherModule))
            this.props.dispatch(setOtherModulesCoverDirection(this.selectedOtherModuleCoverDirection))
            break;
         case "mirror":
            this.props.dispatch(setMirrorBacklight(this.withBacklight))
            this.props.dispatch(setMirrorSize(this.mirrorSize))
            this.props.dispatch(setMirrorType(this.selectedMirror))
            break;
      }
   }
   openSelectedProductModal = () => {
      this.props.dispatch(toggleSelectedProductModal(true))
   }
   changeSelectionType = () => {
      this.props.dispatch(selectionType("sink"))
   }
   arr = [
      "coverStyle",
      "sinkType",
      "coverSelection",
      "handleType",
      "",
      {
         mirror: "setMirrorType",
         armature: "setArmatureType",
         tallCabinet: "setTallCabinet",
         otherModule: "setOtherModules"
      }
   ]
   checkUnselectedValue = () => {
      if (this.props.productStepNumber === 6) {
         if (!this.arr[this.props.productStepNumber - 1] || !this.arr[this.props.productStepNumber - 1][this.props.selectionType] || !this.props[this.arr[this.props.productStepNumber - 1][this.props.selectionType]]) {
            this.props.dispatch(checkButtonDisabledOrNot(true))
         } else {
            this.props.dispatch(checkButtonDisabledOrNot(false))
         }
         return;
      }
      if (!this.props[this.arr[this.props.productStepNumber - 1]]) {
         this.props.dispatch(checkButtonDisabledOrNot(true))
      } else {
         this.props.dispatch(checkButtonDisabledOrNot(false))
      }
   }

   render() {
      let {productStepNumber, selectionType, language, isDisabled,voice} = this.props;
      return (
          <div
              className={`vra-btn-block vra-flex-btn ${productStepNumber === 5 || productStepNumber === 6 && selectionType === "sink" ? "vra-none" : ""} ${productStepNumber === 8 ? "vra-reverse" : ""}`}>
             {
                language &&
                // productStepNumber !== 1 &&
                <button className={`vra-btn vra-white ${productStepNumber === 1 ? "vra-hidden" : ""}`}
                        onClick={()=> {
                           this.backStep();
                           if(voice)this.addClickSound()
                        }}><i
                    className="gg-arrow"/>{`${selectionType === "sink" ? language.back : language.abort}`}
                </button>
             }
             <div className="vra-mobile-title-item">
                <p>Root</p>
                {
                   <span>{`${productStepNumber + "/9"}`}</span>
                }

             </div>
             {
                language &&
                <button className={`vra-btn ${isDisabled ? "disabled" : ""}`}
                        onClick={()=> {
                           this.skipStep();
                           if(voice) this.addClickSound()
                        }}>{this.props.selectionType === "sink" && this.props.productStepNumber === 4 ? `${language.complete}` : `${selectionType !== "sink" ? language.save : language.continue}`}
                </button>
             }


          </div>
      );
   }
}

const mapStateToProps = (state) => ({
   productStepNumber: state.productStepReducer.productStepNumber,
   coverStyleId: state.sinkReducer.coverStyleId || null,
   selectionType: state.commonReducer.selectionType,
   coverStyle: state.userSelectionReducer.setCoverStyle || null,
   coverColour: state.userSelectionReducer.setCoverColour || null,
   sinkType: state.userSelectionReducer.setSinkType || null,
   sinkSize: state.userSelectionReducer.setSinkSizes || null,
   coverSelection: state.userSelectionReducer.setCoverSelectionType || null,
   handleType: state.userSelectionReducer.setHandleTypes || null,
   userSessionId: state.userSelectionReducer.userSessionId,
   userSelection: state.userSelectionReducer,
   isFirstCreated: state.commonReducer.isFirstCreated,
   setArmatureColour: state.userSelectionReducer.setArmatureColour,
   setArmatureType: state.userSelectionReducer.setArmatureType,
   setTallCabinetCoverDirection: state.userSelectionReducer.setTallCabinetCoverDirection,
   setTallCabinet: state.userSelectionReducer.setTallCabinet,
   setOtherModules: state.userSelectionReducer.setOtherModules,
   setOtherModuleCoverDirection: state.userSelectionReducer.setOtherModuleCoverDirection,
   setMirrorType: state.userSelectionReducer.setMirrorType,
   mirrorSize: state.userSelectionReducer.mirrorSize,
   withBacklight: state.userSelectionReducer.mirrorWithBackLight,
   language: state.languageReducer[state.commonReducer.activeLang],
   isDisabled: state.commonReducer.isButtonDisabled,
   activeLang: state.commonReducer.activeLang,
   voice:state.commonReducer.voice
})
export default connect(mapStateToProps)(ButtonComponent);
