import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";

class CabinetOptionSelectComponent extends Component {
   render() {
      let {cover_direction, id, cover_direction_id, tall_cabinet_id, setCabinetFunc, cabinetCoverDirection,activeLang,cabinet} = this.props;

      return (
          <Fragment>
             {
                cover_direction &&
                <div
                    className={`vra-option-item ${cabinetCoverDirection && cabinetCoverDirection.id === id ? "active" : ""}`}
                    onClick={(event) => {
                       setCabinetFunc({cover_direction, id, cover_direction_id, tall_cabinet_id},cabinet)
                       event.stopPropagation()

                    }}>
                   <span>{JSON.parse(cover_direction.transition)[activeLang] || cover_direction.name}</span>
                </div>
             }
          </Fragment>
      );
   }
}

const mapStateToProps = (state) => ({
   cabinetCoverDirection: state.userSelectionReducer.setTallCabinetCoverDirection,
   activeLang:state.commonReducer.activeLang
})
export default connect(mapStateToProps)(CabinetOptionSelectComponent);
