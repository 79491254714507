import React, {Component, Fragment} from 'react';
import ResColourOptionComponent from "../components/mobile/resColourOptionComponent";
import {connect} from "react-redux";
import {ASSETS} from "../constants/paths";

class ResColourSelectionContainer extends Component {



   render() {
      let {respColorSelection} = this.props;
      return (
          <Fragment>
             <ResColourOptionComponent />
          </Fragment>
      );
   }
}

const mapStateToProps = (state) => ({
   respColorSelection: state.productStepReducer.respColorSelection
})

export default connect(mapStateToProps)(ResColourSelectionContainer);
